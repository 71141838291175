<template>
  <!--操作时间组件-->
  <div class="operating-time" v-loading="showTimeLoading">
    <div class="operating-time-h">
      <div class="operating-time-h-l">
        <svg aria-hidden="true" class="icon time-h-l-img">
          <use href="#icon-icon"></use>
        </svg>
        <div class="time-h-l-text">
          <!--          操作时间-->
          <div class="time-h-l-text-title">
            {{ $t("label.operation.date") }}
          </div>
          <div class="time-h-l-text-remark">{{ operationTime.value }}</div>
        </div>
      </div>
      <el-button
        @click="editOperationTime"
        v-show="hasData"
        class="operating-time-h-r"
        plain
      >
        {{ $t("label.edit") }}
      </el-button>
    </div>
    <!--          无数据-->
    <div v-show="!hasData" class="operating-time-nodata">
      <div v-show="!noPermission" class="operating-time-nodata-base">
        <svg aria-hidden="true" class="icon operating-time-c-img">
          <use href="#icon-nodata"></use>
        </svg>
        <div v-show="!noOperateId" class="operating-time-c-tip">
          <!--        似乎没有任何时隙，现在让我们添加一些！-->
          {{ $t("label.service.without.timeslot") }}
        </div>
        <div v-show="noOperateId" class="operating-time-c-tip">
          <!--        当前记录没有关联操作时间！-->
          {{ $t("label.service.no.operation.time") }}
        </div>
        <el-button
          v-show="!noOperateId"
          class="operating-time-c-btn"
          type="primary"
          @click="newOperateTime"
        >
          <!--        添加时隙-->
          {{ $t("label.service.add.timeslot") }}
        </el-button>
      </div>
      <!--    无权限-->
      <div v-show="noPermission">
        <svg class="operating-time-c-img" aria-hidden="true">
          <use :href="noPermissionImgSrc"></use>
        </svg>
        <!--        <img :src="noPermissionImgSrc" class="operating-time-c-img" />-->
        <p class="noPermissionTip">
          <!-- 抱歉，您无权限查看该条数据，请联系系统管理员。 -->
          {{ $t("vue_label_notice_nopermission_view") }}
        </p>
      </div>
    </div>

    <!--          有数据-->
    <div v-show="hasData" class="operating-time-hasdata">
      <!--            时区-->
      <div class="time-zone-box">
        <div class="time-zone-box-t">
          <!--          时区:-->
          {{ $t("label.service.timezone") }}:
        </div>
        <div>{{ timeZone }}</div>
      </div>
      <div class="operating-time-box">
        <div class="operating-time-box-title">
          <!--          服务预约时段-->
          {{ $t("label.service.booking.sessions") }}
        </div>
        <div class="operating-time-box-table">
          <el-table
            :data="tableData"
            :row-style="{ height: '30px' }"
            :cell-style="{ padding: '0px', color: '#080707', fontSize: '12px' }"
            :header-cell-style="{
              background: ' #F5F5F5',
              height: '30px',
              padding: '0',
            }"
            style="width: 100%"
          >
            <el-table-column prop="dayofweek" :label="$t('label.chatter.day')">
            </el-table-column>
            <el-table-column prop="hours" :label="$t('label.ems.hour')">
              <template slot-scope="scope">
                {{ scope.row.starttime + "-" + scope.row.endtime }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <create-edit-obj
      ref="createEditObj"
      prefix="s33"
      :id="operationTime.id"
      objectApi="OperatingHours"
      realObjId="operatinghoursstdobj"
      :object-name="$t('label.operation.date')"
      type="active"
      @save="save"
    >
    </create-edit-obj>
  </div>
</template>

<script>
import createEditObj from "./create-edit-obj.vue";
import * as CommonObjApi from "./api";

export default {
  name: "OperatingTime",
  components: {
    createEditObj,
  },
  props: {
    operationTime: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    operationTime: {
      handler(newData) {
        if (newData.id && newData.value) {
          this.noOperateId = false;
          this.showTimeLoading = true;
          // 1、首先请求详情接口，拿到名称时区。
          this.getOperateTime(newData.id);
          // 2、请求相关列表接口, 拿到时隙相关参数。
          // 3、请求时隙的相关列表接口，获取列表数据。
          // 4、添加、编辑时隙请求标准新建编辑接口。
        } else if (newData.id === "" || newData.value === "") {
          this.showTimeLoading = false;
          this.noOperateId = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      noPermissionImgSrc: "#icon-file404",
      showTimeLoading: true,
      noOperateId: true,
      timeZone: "",
      hasData: false,
      tableData: [],
      noPermission: false,
    };
  },
  methods: {
    getOperateTime(id) {
      // 查询记录权限
      let op = {
        id: id,
      };
      CommonObjApi.getPermissionById(op).then((res) => {
        if (res.result) {
          if (res.data.isQuery) {
            CommonObjApi.getDetail({
              id: id,
              objectApi: "",
              operation: "DETAIL",
              recordType: "",
            }).then((res) => {
              if (res.result) {
                res.data.dataList.forEach((item) => {
                  if (item.selectionId === "adfOperatingHours001") {
                    item.data.forEach((itemA) => {
                      if (itemA.left.apiname === "timezone") {
                        this.timeZone = itemA.left.value;
                      } else if (itemA.right.apiname === "timezone") {
                        this.timeZone = itemA.right.value;
                      }
                    });
                  }
                });
              }
            });
            this.getTimeSlot(id);
            this.noPermission = false;
          } else {
            this.noPermission = true;
            this.showTimeLoading = false;
          }
        }
      });
    },
    getTimeSlot(id) {
      CommonObjApi.GetRelationList({ recordId: id }).then((res) => {
        if (res.result) {
          res.data.forEach((item) => {
            if (item.relatedlistId === "aeeOperatingHoursR03") {
              this.getRelatedItem(item, id);
            }
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    getRelatedItem(val, id) {
      let data = {
        recordId: id,
        layoutId: val.layoutId ? val.layoutId : "",
        relatedlistId: val.relatedlistId ? val.relatedlistId : "",
        perPage: this.perPage,
        attachementType: val.attachementType ? val.attachementType : "",
      };
      CommonObjApi.GetDetailRelatedItems(data).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.tableData = res.data.data;
          if (this.tableData.length === 0) {
            this.hasData = false;
          } else {
            this.hasData = true;
          }
          this.showTimeLoading = false;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    editOperationTime() {
      this.$refs.createEditObj.edit();
    },
    save() {
      this.showTimeLoading = true;
      this.$Bus.$emit("approvalrefresh");
    },
    newOperateTime() {
      this.$refs.createEditObj.edit();
    },
  },
};
</script>

<style scoped lang="scss">
.operating-time {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  //禁止文本被选中
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;

  .operating-time-h {
    height: 60px;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .operating-time-h-l {
      display: flex;

      .time-h-l-img {
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }

      .time-h-l-text {
        .time-h-l-text-title {
          font-size: 12px;
          color: #333333;
        }

        .time-h-l-text-remark {
          font-size: 14px;
          color: #080707;
          line-height: 30px;
        }
      }
    }

    .operating-time-h-r {
      width: 54px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #006dcc;
    }
  }

  .operating-time-nodata {
    padding: 90px 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .operating-time-nodata-base {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .operating-time-c-img {
      width: 202px;
      height: 111px;
      margin-bottom: 16px;
    }

    .operating-time-c-tip {
      font-size: 12px;
      color: #333333;
      margin-bottom: 24px;
    }

    .operating-time-c-btn {
      width: 90px;
      height: 30px;
      background: #006dcc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .operating-time-hasdata {
    box-sizing: border-box;
    .time-zone-box {
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 12px;
      color: #333333;
      margin-bottom: 8px;
      .time-zone-box-t {
        margin-bottom: 2px;
      }
    }

    .operating-time-box {
      .operating-time-box-title {
        font-size: 14px;
        color: #080707;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 8px;
      }

      .operating-time-box-table {
      }
    }
  }
}
</style>
