<!--
 * @Author: 贾洁飞
-->
<template>
  <div class="quick" v-loading="loading">
    <div class="client" v-if="isMenue == '001' || isMenue == '003'">
      <div v-if="result == false">
        <div class="title_search">
          <el-input v-model="input" size="small"></el-input>
          <el-button size="small" class="search_color" @click="searchQuick()">
            <i class="el-icon-search" style="color: #333"></i>
            {{ $t("label.quickbooks.button.searchinquickbooks") }}</el-button
          >
          <el-button size="small" @click="dialogFormVisible = true">
            <!-- 新建QuickBooks客户 -->
            {{ $t("label.ems.create") }}QuickBooks{{
              $t("label.weixin..xsld.kehu")
            }}
          </el-button>
        </div>
        <p :class="!searchList || searchList.length == 0 ? 'search-p' : ''">
          {{ searchText }}
        </p>
        <!-- 搜索获得数据 -->
        <el-table
          v-if="searchLen != 0"
          :header-cell-style="{
            border: '1px solid #DDDBDA ',
            background: '#FAFAF9',
            fontSize: '12px',
          }"
          :data="searchList"
          style="width: 100%; border: 1px solid #dddbda"
        >
          <el-table-column
            :label="$t('label.account')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <a
                :href="
                  'https://qbo.intuit.com/app/customerdetail?nameId=' +
                  scope.row.id
                "
                style="color: #006dcc"
              >
                {{ scope.row.name }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="company"
            :label="$t('label.isCompanyCurrency')"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="email"
            :label="$t('UG_Lead_005')"
          ></el-table-column>
          <el-table-column
            :label="$t('label_partner_all_operate')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <a
                class="guanLian"
                size="mini"
                style="color: #006dcc"
                @click="handleEdit(scope.row)"
                >{{ $t("label.icon.145") }}</a
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="expense" v-else>
        <div class="header">
          <div class="header-lf">
            <span>{{ name }}</span>
            <span>{{ ValidateForm.company }}</span>
          </div>
          <div class="header-rl">
            <el-button
              class="examine"
              size="small"
              type="primary"
              @click="handleCheck('cha')"
              >{{ $t("button_partner_viewdetail") }}</el-button
            >
            <el-button-group class="downer">
              <el-button size="small" @click="Cancel('quick')">{{
                $t("front-sales-button-fromQB")
              }}</el-button>
              <el-button
                size="small"
                icon="el-icon-caret-bottom"
                class="pullDown"
                @click="down"
              ></el-button>
            </el-button-group>
            <ul class="down" v-if="pullDown == true">
              <!-- <li @click="Cancel('cloud')">{{$t("lable.quote.syncing.syncingbutton")+$t("label.appointment.wizard.subtitle2-2")}}QuickBook</li> -->
              <!-- <li @click="Cancel('quick')">从QuickBooks更新</li> -->
              <li @click="Cancel('quxiao')">
                {{ $t("label.quickbooks.button.unlink") }}
              </li>
              <!-- <li @click="Cancel('see')">{{$t("label.quickbooks.button.viewinqb")}}</li>-->
            </ul>
          </div>
        </div>
        <div class="contenter">
          <div class="contenter-lf">
            <p style="color: #006dcc">open invoices</p>
            <h3 style="color: #006dcc">
              <span style="font-size: 14px" v-if="openBalance !== 0">{{
                currency
              }}</span
              >{{ openBalance }}
            </h3>
          </div>
          <div class="contenter-zj">
            <p style="color: #c12217">overdue invoices</p>
            <h3 style="color: #c12217">
              <span style="font-size: 14px" v-if="overDueBalance !== 0">{{
                currency
              }}</span
              >{{ overDueBalance }}
            </h3>
          </div>
          <div class="contaner-fr">
            <p style="color: #06844b">paid</p>
            <h3 style="color: #06844b">
              <span style="font-size: 14px" v-if="income !== 0">{{
                currency
              }}</span
              >{{ income }}
            </h3>
          </div>
        </div>
        <ul class="tableForm">
          <li>
            <p class="tableForm_p">{{ $t("E") }}</p>
            <p class="tableForm_last">{{ email }}</p>
          </li>
          <li>
            <p class="tableForm_p">
              {{ $t("label.quickbooks.fieldlabel.billingaddress") }}
            </p>
            <p class="tableForm_last">{{ kpdz }}</p>
          </li>
          <!-- <li>
            <p class="tableForm_p">{{$t("label.quickbooks.fieldlabel.taxresale")}}</p>
            <p class="tableForm_last">{{sszs}}</p>
          </li> -->
          <li>
            <p class="tableForm_p">{{ $t("label.phone") }}</p>
            <p class="tableForm_last">{{ dianhua }}</p>
          </li>
          <!-- <li>
            <p class="tableForm_p">{{$t("vue_label_normal_contract_sf")}}</p>
            <p class="tableForm_last">{{contract}}</p>
          </li> -->
          <li>
            <p class="tableForm_p">{{ $t("label.mr.title.URL") }}</p>
            <p class="tableForm_last">{{ wangzhi }}</p>
          </li>
          <li>
            <p class="tableForm_p">{{ $t("label.mobile") }}</p>
            <p class="tableForm_last">{{ phone }}</p>
          </li>
          <!-- <li>
            <p class="tableForm_p">{{$t("label.quickbooks.fieldlabel.payment")}}</p>
            <p class="tableForm_last">{{paymentt}}</p>
          </li> -->
          <li>
            <p class="tableForm_p">
              {{ $t("label.weixin.group.description") }}
            </p>
            <p class="tableForm_last">{{ beizhu }}</p>
          </li>
        </ul>
        <p style="clear: both"></p>
        <div class="table">
          <p class="table_p">
            {{ $t("label.quickbooks.view") }}：
            <el-select v-model="value" size="small" @change="viewChang">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              ></el-option>
            </el-select>
          </p>
          <!-- <p>{{Viewport}}</p> -->
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{
              'border-bottom': '1px solid #DDDBDA',
              'border-right': '1px solid #DDDBDA',
              background: '#FAFAF9',
            }"
          >
            <!---->
            <el-table-column
              :show-overflow-tooltip="true"
              prop="invoiceDate"
              :label="$t('label.monitorsys.delete.date')"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="qbtype"
              :label="$t('label.type')"
              width="80"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="DocNumber"
              :label="$t('label.weixin.id')"
            ></el-table-column>
            <!-- <el-table-column
              prop="customername"
              :label="$t('label.quickbooks.searchresault.company')"
              width="80"
            ></el-table-column> -->
            <el-table-column
              :show-overflow-tooltip="true"
              prop="dueDate"
              :label="$t('label_chatter_maturiyDate')"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="Balance"
              :label="$t('label.quickbooks.viewlist.balance')"
              width="110"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="TotalAmt"
              :label="$t('label.reporttotal')"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="status"
              :label="$t('label_chatter_state')"
            ></el-table-column>
            <el-table-column
              prop="date"
              :label="$t('label.partner.all.operate')"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleCheck(scope.row)"
                  type="text"
                  size="small"
                  >{{ $t("button_partner_viewdetail") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 新建客户或联系人弹框-->
      <el-dialog
        :visible.sync="dialogFormVisible"
        width="36%"
        :before-close="closecreated"
      >
        <div slot="title" class="dialog-title">
          <svg class="icon quickeImg" aria-hidden="true">
            <use href="#icon-quickbooks1"></use>
          </svg>
          <span class="newUser">
            <b>{{ $t("label.quickbooks.new") }}</b>
          </span>
        </div>
        <el-form
          size="small"
          :model="ValidateForm"
          ref="numberValidateForm"
          class="demo-ruleForm"
          status-icon
          label-width="auto"
        >
          <el-form-item
            label="First name"
            prop="firstname"
            :rules="[
              { required: true, message: $t('vue_label_lead_required_fields') },
            ]"
          >
            <!--lable.name.cannot.be.empty -->
            <el-input
              style="margin: 10px 0 0 10px"
              v-model="ValidateForm.firstname"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="Last name" prop="lastname">
            <el-input
              style="margin: 10px 0 0 10px"
              v-model="ValidateForm.lastname"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Display name"
            prop="customername"
            :rules="[
              { required: true, message: $t('vue_label_lead_required_fields') },
            ]"
          >
            <el-input
              style="margin: 10px 0 0 10px"
              autocomplete="off"
              v-model="ValidateForm.customername"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="company"
            prop="company"
            :rules="[
              { required: true, message: $t('vue_label_lead_required_fields') },
              { min: 1, max: 255, message: $t('label_length_20') },
            ]"
          >
            <el-input
              style="margin: 10px 0 0 10px"
              v-model="ValidateForm.company"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Email"
            prop="email"
            type="email"
            :rules="[
              {
                type: 'email',
                message: $t('front-kefu-moudle-v1-yxgsbzq-reinput'),
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input
              style="margin: 10px 0 0 10px"
              v-model="ValidateForm.email"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closecreated">
            <!-- 取消 -->
            {{ $t("button_partner_cancel") }}
          </el-button>
          <el-button type="primary" @click="addUser('numberValidateForm')">
            <!-- 确 定 -->
            {{ $t("button.partner.create") }}
          </el-button>
        </div>
      </el-dialog>
    </div>
    <div class="business" v-else>
      <div class="business-up">
        <p class="search-up">
          <span class="span-lf">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-gaisuan"></use>
            </svg>
            Invoices
            <!-- <svg class="icon" aria-hidden="true" @click="refersh('fapiao')">
                <use href="#icon-shuaxin-changtai"></use>
            </svg> -->
          </span>
          <!-- <el-button size="small" @click="searchT('fapiao')">{{ searchTent }}</el-button> -->
        </p>
        <p v-if="searchTent == $t('lable.Cancel.the.search')">
          <el-input
            size="small"
            v-model="number"
            :placeholder="$t('label.weixin.id')"
          ></el-input>
          <el-input
            size="small"
            v-model="startDate"
            :placeholder="$t('label.startdate')"
          ></el-input>
          <el-input
            size="small"
            v-model="endDate"
            :placeholder="$t('label.schedulejob.setup.schedule.enddate')"
          ></el-input>
          <el-input
            size="small"
            v-model="displayName"
            :placeholder="$t('lable.SalesCommitmentKanBan.accountName')"
          ></el-input>
          <el-button
            style="backgroundcolor: #006dcc; color: #fff"
            size="small"
            @click="QuickBook('fapiao')"
            >{{ $t("lable.Search.in.QuickBook") }}</el-button
          >
        </p>
        <quickChild
          class="quick-up"
          :fapiao="fapiao"
          :InvoiceList="InvoiceList"
          @correlation="correlation"
          :searchFa="searchFa"
        ></quickChild>
      </div>
      <div class="business-up">
        <p class="search-up">
          <span class="span-lf">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-gaisuan"></use>
            </svg>
            Payment
            <!-- <svg class="icon" aria-hidden="true" @click="refersh('fukuan')">
                <use href="#icon-shuaxin-changtai"></use>
            </svg> -->
          </span>
          <span class="span-fr">
            <!-- <el-button size="small" @click="searchT('fukuan')">{{ searchCancel }}</el-button>
            <el-button
              size="small"
              @click="Synchronize"
            >{{ $t('lable.Synchronize.from.QuickBook') }}</el-button> -->
          </span>
        </p>
        <p v-if="searchCancel == $t('lable.Cancel.the.search')">
          <el-input
            size="small"
            v-model="number1"
            :placeholder="$t('label.weixin.id')"
          ></el-input>
          <el-input
            size="small"
            v-model="startDate1"
            :placeholder="$t('label.startdate')"
          ></el-input>
          <el-input
            size="small"
            v-model="endDate1"
            :placeholder="$t('label.schedulejob.setup.schedule.enddate')"
          ></el-input>
          <el-input
            size="small"
            v-model="displayName1"
            :placeholder="$t('lable.SalesCommitmentKanBan.accountName')"
          ></el-input>
          <el-button
            style="backgroundcolor: #006dcc; color: #fff"
            size="small"
            @click="QuickBook('fukuan')"
            >{{ $t("lable.Search.in.QuickBook") }}</el-button
          >
        </p>
        <quickChild
          class="quick-up"
          :fukuan="fukuan"
          :PaymentList="PaymentList"
          @correlation="correlation"
          :searchFu="searchFu"
        ></quickChild>
      </div>
      <div class="business-up" v-if="isMenue !== '032'">
        <p class="search-up">
          <span class="span-lf">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-gaisuan"></use>
            </svg>
            {{ $t("label.quickbooks.estimates") }}
            <!-- <svg class="icon" aria-hidden="true" @click="refersh('gaisuan')">
                <use href="#icon-shuaxin-changtai"></use>
            </svg> -->
          </span>
          <!-- <el-button size="small" @click="searchT">{{ searchThe }}</el-button> -->
        </p>
        <p v-if="searchThe == $t('lable.Cancel.the.search')">
          <el-input
            size="small"
            v-model="number2"
            :placeholder="$t('label.weixin.id')"
          ></el-input>
          <el-input
            size="small"
            v-model="startDate2"
            :placeholder="$t('label.startdate')"
          ></el-input>
          <el-input
            size="small"
            v-model="endDate2"
            :placeholder="$t('label.schedulejob.setup.schedule.enddate')"
          ></el-input>
          <el-input
            size="small"
            v-model="displayName2"
            :placeholder="$t('lable.SalesCommitmentKanBan.accountName')"
          ></el-input>
          <el-button
            style="backgroundcolor: #006dcc; color: #fff"
            size="small"
            @click="QuickBook"
            >{{ $t("lable.Search.in.QuickBook") }}</el-button
          >
        </p>
        <quickChild
          class="quick-up"
          :searchGai="searchGai"
          :EstimateList="EstimateList"
          @correlation="correlation"
        ></quickChild>
      </div>
    </div>
    <el-dialog :visible.sync="openewQB" width="30%">
      <div slot="title" class="dialog-title">
        {{ $t("report.label.searchresult") }}
      </div>
      <p
        style="
          width: 100%;
          text-align: center;
          min-height: 90px;
          line-height: 90px;
        "
      >
        {{ $t("front-sales-search-QB") }}
      </p>
      <span
        slot="footer"
        class="dialog-footer"
        style="width: 100%; display: flex; justify-content: space-around"
      >
        <el-button type="primary" @click="openQBcreated">{{
          $t("label.tabpage.ok")
        }}</el-button>
        <el-button @click="openewQB = false">{{
          $t("label.tabpage.cancel")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  quickSearch,
  newUser,
  // inquireQb,
  relatedCustomer,
  quickBooksXiang,
  queryAllItems,
  deleteRelatedCustomer,
  quickBooksToCloudcc,
  // cloudccToQuickBooks,
  refreshItems,
  searchItems,
  QBPaymentToCloudccSkjh,
  // queryItems,
  queryRelatedCustomer,
  queryRelatedItemsLighting,
} from "./api";
import quickChild from "./quickChild";
// <el-button size="small">{{ $t('label.quickbooks.createcustomer') }}</el-button>
export default {
  // import引入的组件需要注入到对象中才能使用
  name: "quickBooks",
  props: {
    bind: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    isMenue: {
      type: String,
      default: "",
    },
    username: {
      type: String,
      default: "",
    },
  },
  components: {
    quickChild,
  },
  data() {
    // 这里存放数据
    return {
      fapiao: "fapiao",
      fukuan: "fukuan",
      fuxing: ["欧阳", "慕容", "司马"] /* 复姓数组 */,
      name: "",
      email: "",

      kpdz: "",
      sszs: "",
      dianhua: "",
      contract: "",
      wangzhi: "",
      phone: "",
      paymentt: "",
      beizhu: "",

      tableData: [], //列表数据
      Viewport: "", //选择视图文字
      pullDown: false, //下拉框显示隐藏
      input: "",
      searchText: "",
      searchList: null,
      searchLen: 0,
      dialogFormVisible: false, //弹框显示隐藏
      ValidateForm: {
        // accessToken: this.bind,
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        customername: "", //客户名称
        // id:'00120218422B2D5diV7q',
        id: sessionStorage.getItem("id"),
      },
      id: "", //发票id
      error: "",
      result: false,

      // tableList: [],
      // 下拉视图
      value: "optionone",
      options: [
        // {
        //   value: "全部",
        //   label: "label.quickbooks.view.all"
        // },
        // {
        //   value: "全部Invoices",
        //   label: "label.quickbooks.view.allinvoices"
        // },
        // {
        //   value: "进行中的Invoices",
        //   label: "label.quickbooks.view.openinvoices"
        // },
        // {
        //   value: "逾期的Invoices",
        //   label: "label.quickbooks.view.overdueinvoices"
        // },
        // {
        //   value: "进行中的概算",
        //   label: "label.quickbooks.view.openestimates"
        // },
        // {
        //   value: "近期付款",
        //   lable: "label.quickbooks.view.recentlypaid"
        // },
        // {
        //   value: "收款",
        //   lable: "label.quickbooks.view.moneyreceived"
        // }
        {
          value: this.$i18n.t("label.quickbooks.view.all"),
          label: "optionone",
        },
        {
          value: "Openinvoice",
          label: "optiontwo",
        },
        {
          value: "Overdue invoice",
          label: "optionthree",
        },
        {
          value: "Estimate-accepted",
          label: "optionfour",
        },
        {
          value: "Payment",
          label: "optionfive",
        },
      ],
      // 业务机会数据
      searchTent: this.$i18n.t("label_tabpage_searchz"),
      // searchFu:
      searchCancel: this.$i18n.t("label_tabpage_searchz"),
      searchThe: this.$i18n.t("label_tabpage_searchz"),
      // 发票
      displayName: "", //客户名称
      number: "", //编号
      endDate: "", //结束时间
      startDate: "", //开始时间
      // 付款
      displayName1: "", //客户名称
      number1: "", //编号
      endDate1: "", //结束时间
      startDate1: "", //开始时间
      // 概算
      displayName2: "", //客户名称
      number2: "", //编号
      endDate2: "", //结束时间
      startDate2: "", //开始时间
      InvoiceList: [], //发票数据
      PaymentList: [], //付款
      EstimateList: [
        //         {
        //   qbtype:'1222',
        //   invoiceDate:'sss',
        //   docnumber:'null',
        //   customername:'nnnnnnn'
        // }
      ], //概算,
      // 搜索
      searchFa: false,
      searchFu: false,
      searchGai: false,
      income: "", //当前客户paid的账目金额
      openBalance: "", //当前客户open invoice的账目金额
      overDueBalance: "", //当前客户OVERDUE invoice的账目金额
      currency: "", //币种
      datalists: [],
      openewQB: false, //提示没有返回数据的弹框
      loading: false, //loading开关
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    result() {
      if (this.result == true) {
        // this.Synchronization();
        this.queryAll();
      }
    },
    dialogFormVisible(bool) {
      if (bool == false) {
        // this.ValidateForm.firstname = "";
        // this.ValidateForm.lastname = "";
        // this.ValidateForm.company = "";
        // this.ValidateForm.email = "";
        this.xiang();
      }
    },
    "ValidateForm.firstname"() {
      this.ValidateForm.customername = this.ValidateForm.lastname
        ? this.ValidateForm.firstname + " " + this.ValidateForm.lastname
        : this.ValidateForm.firstname;
    },
    "ValidateForm.lastname"() {
      this.ValidateForm.customername = this.ValidateForm.firstname
        ? this.ValidateForm.firstname + " " + this.ValidateForm.lastname
        : this.ValidateForm.lastname;
    },
  },
  // 方法集合
  methods: {
    closecreated() {
      this.dialogFormVisible = false;
      this.$refs.numberValidateForm.resetFields();
    },
    openQBcreated() {
      this.openewQB = false;
      this.dialogFormVisible = true;
      this.searchText = "";
    },
    async searchQuick(val) {
      this.loading = false;
      //label.In.the.search
      this.searchText = this.$i18n.t("label.In.the.search");
      let keyword = {
        searchKeyWord: this.input,
      };
      const res = await quickSearch(keyword);
      this.searchList = [...JSON.parse(res.data.customerListjson)];
      this.searchLen = this.searchList.length;
      if (this.searchList.length == 0) {
        this.searchText = this.$i18n.t("lable.no.matching.record");
        this.searchList = [];
        if (val && val == "once") {
          this.openewQB = false;
        } else {
          this.openewQB = true;
        }
      } else {
        this.searchText = "";
        if (val == "once") {
          for (let i = 0; i < this.searchList.length; i++) {
            if (this.searchList[i].name == this.input) {
              this.handleEdit(this.searchList[i]);
              return;
            }
          }
          for (let i = 0; i < this.searchList.length; i++) {
            if (this.searchList[i].company == this.input) {
              this.handleEdit(this.searchList[i]);
              return;
            }
          }
        }
      }
      // this.input=""
    },
    //遍历 关联
    relevance(val) {
      if (val == "once") {
        for (let i = 0; i < this.searchList.length; i++) {
          if (this.searchList[i].name == this.input) {
            this.handleEdit(this.searchList[i]);
            return;
          }
        }
        for (let i = 0; i < this.searchList.length; i++) {
          if (this.searchList[i].company == this.input) {
            this.handleEdit(this.searchList[i]);
            return;
          }
        }
        this.searchQuick("once");
      }
    },
    // 添加新客户
    addUser(numberValidateForm) {
      this.$refs[numberValidateForm].validate((valid) => {
        if (valid) {
          // // 姓名拆分
          // if (this.ValidateForm.lastname == "") {
          //   /* 不是复姓*/
          //   var flag = 0;
          //   var temp = this.ValidateForm.firstname.slice(0, 2);
          //   for (var i = 0; i < this.fuxing.length; i++) {
          //     if (temp == this.fuxing[i]) {
          //       /* 是复姓就执行 */
          //       flag = 1;
          //       break;
          //     }
          //   }
          //   if (flag == 0) {
          //     /* 不是复姓*/
          //     this.ValidateForm.firstname = this.ValidateForm.firstname.slice(
          //       0,
          //       1
          //     );
          //     this.ValidateForm.lastname = this.ValidateForm.firstname.slice(
          //       1
          //     ); /* 不管名字几个字，都从第一个开始以后都是名字 */
          //   } else {
          //     /* 是复姓就执行 */
          //     this.ValidateForm.firstname = temp;
          //     this.ValidateForm.lastname = this.ValidateForm.firstname.slice(
          //       2
          //     ); /* 不管名字几个字，都从第二个开始以后都是名字 */
          //   }
          // }
          newUser(this.ValidateForm).then((res) => {
            if (res.result && res.returnCode == "1") {
              this.dialogFormVisible = false;

              if (res.data) {
                this.$message.error(res.data);
              } else {
                this.input = this.ValidateForm.customername;
                this.xiang();
              }
              this.ValidateForm.firstname = "";
              this.ValidateForm.lastname = "";
              this.ValidateForm.company = "";
              this.ValidateForm.email = "";
              this.ValidateForm.customername = "";
            } else {
              //
              this.$message.error(res.returnInfo);
              this.xiang();
            }
          });
          this.$refs.numberValidateForm.resetFields();
        } else {
          return false;
        }
      });
    },
    async xiang(item) {
      this.loading = true;
      let particular = {
        id: this.ValidateForm.id,
      };
      const res = await quickBooksXiang(particular);
      if (res.result == true) {
        if (!res.data.customer) {
          this.loading = false;
          if (res.data.customerList) {
            this.searchList = res.data.customerList;
            this.searchLen = this.searchList.length;
            if (item && item == "once") {
              this.relevance("once");
            }
          } else {
            if (item && item == "once") {
              this.searchQuick("once");
            } else {
              this.searchList = [];
            }
          }
          if (res.data.lastname) {
            this.ValidateForm.customername = res.data.lastname;
          } else {
            this.ValidateForm.customername = res.data.company;
          }

          this.ValidateForm.email = res.data.email;
          this.ValidateForm.firstname = res.data.lastname;
          this.ValidateForm.lastname = res.data.firstname;
          // if(item&&item=="once"){
          //   this.searchQuick('once')
          // }
          //  this.loading=false
        } else {
          this.result = true;
          if (!res.data.customer.lastname) {
            this.name = res.data.customer.firstname;
          } else {
            this.name =
              res.data.customer.lastname + "  " + res.data.customer.firstname;
          }
          this.email = res.data.customer.email;
          this.kpdz = res.data.customer.kpdz;
          this.sszs = res.data.customer.sszs;
          this.dianhua = res.data.customer.dianhua;
          this.contract = res.data.customer.contract;
          this.wangzhi = res.data.customer.wangzhi;
          this.phone = res.data.customer.phone;
          this.paymentt = res.data.customer.payment;
          this.beizhu = res.data.customer.beizhu;
          this.ValidateForm.company = res.data.customer.company;
          this.ValidateForm.customername = res.data.customer.company;
          this.id = res.data.customer.customerid;
          this.currency = res.data.customer.currency;
        }
      }
    },
    //通过订单id获取客户id  并获取QB
    getEstimate() {
      this.loading = true;
      let invid = {
        id: this.ValidateForm.id,
      };
      queryRelatedCustomer(invid).then((res) => {
        if (res.result) {
          let ids = res.data[0].accountid;
          this.getQBest(ids);
        }
      });
    },
    //获取订单下 QB数据
    getQBest(ids) {
      this.PaymentList = [];
      this.InvoiceList = [];
      queryRelatedItemsLighting({
        id: ids,
        orderid: this.ValidateForm.id,
      }).then((res) => {
        if (res.result) {
          this.PaymentList = res.data.payment;
          this.InvoiceList = res.data.invoice;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    // 下拉框显示隐藏
    down() {
      this.pullDown = !this.pullDown;
    },
    // 视图改变时触发事件
    viewChang(val) {
      this.tableData = [];
      if (val == "optionone") {
        this.tableData = [...this.datalists];
      } else if (val == "optiontwo") {
        this.datalists.forEach((res) => {
          if (res.qbtype == "Invoice" && res.status == "Open") {
            this.tableData.push(res);
          }
        });
      } else if (val == "optionthree") {
        this.datalists.forEach((res) => {
          if (res.qbtype == "Invoice" && res.status == "Overdue") {
            this.tableData.push(res);
          }
        });
      } else if (val == "optionfour") {
        this.datalists.forEach((res) => {
          if (res.qbtype == "Estimate" && res.status == "Accepted") {
            this.tableData.push(res);
          }
        });
      } else if (val == "optionfive") {
        this.datalists.forEach((res) => {
          if (res.qbtype == "Payment") {
            this.tableData.push(res);
          }
        });
      }
    },
    // 关联
    async handleEdit(obj) {
      this.loading = true;
      this.name = obj.name;
      this.email = obj.email;
      this.id = obj.id;
      var data = {
        id: this.ValidateForm.id,
        customerid: obj.id,
      };
      const res = await relatedCustomer(data);
      if (res.result) {
        if (res.data) {
          this.$message.error(res.data);
          this.loading = false;
          this.result = false;
        } else {
          this.result = true;
          this.xiang();
        }
      }
    },
    // 查看
    handleCheck(val) {
      if (val == "cha") {
        window.open(
          `https://qbo.intuit.com/app/customerdetail?nameId=${this.id}`
        );
      } else {
        if (val.qbtype == "Invoice") {
          window.open(`https://qbo.intuit.com/app/invoice?txnId=${val.id}`);
        } else if (val.qbtype == "Estimate") {
          window.open(`https://qbo.intuit.com/app/estimate?txnId=${val.id}`);
        } else if (val.qbtype == "Payment") {
          window.open(`https://qbo.intuit.com/app/recvpayment?txnId=${val.id}`);
        }
      }
    },

    // 取消关联 下拉框所有事件
    async Cancel(val) {
      var data = {
        id: this.ValidateForm.id,
      };
      if (val == "quxiao") {
        const res = await deleteRelatedCustomer(data);
        if (res.result == true) {
          this.$message.success(
            this.$i18n.t("label.emailsync.status.disconnect") +
              this.$i18n.t("label.systemlog.s")
          );
          this.result = false;
          this.xiang();
        } else {
          this.$message.error(
            this.$i18n.t("label.emailsync.status.disconnect") +
              this.$i18n.t("label.import.index.failed")
          );
        }
      } else if (val == "quick") {
        const res = await quickBooksToCloudcc(data);
        if (res.result) {
          this.$message.success(
            this.$i18n.t("label.projectManagement.update.succeeded")
          );
          this.xiang("once");
        } else {
          this.$message.error(
            this.$i18n.t("label.import.page1.update") +
              this.$i18n.t("label.import.index.failed")
          );
        }
      } else if (val == "cloud") {
        // const res = await cloudccToQuickBooks(data);
      }
      //  else {
      //   window.open("https://c1.qbo.intuit.com/qbo1/login?&useNeo=true");
      // }
    },
    // 查找全部视图
    async queryAll() {
      var data = {
        id: this.id,
        displayName: "lighting",
      };
      queryAllItems(data).then((res) => {
        this.loading = false;
        this.income = res.data.income;
        this.openBalance = res.data.openBalance;
        this.overDueBalance = res.data.overDueBalance;
        this.datalists = res.data.list;
        this.tableData = res.data.list;
      });
    },

    // // 查看发票
    // async queryItems() {
    //   var data = {
    //     id: this.ValidateForm.id,
    //     qbtype: "Invoice"
    //   };
    //   const res = await queryItems(data);
    //   this.InvoiceList = res.data;
    //   this.nullList(res.data)
    // },
    // // 付款数据
    // async payment() {
    //   var fu = {
    //     id: this.ValidateForm.id,
    //     qbtype: "Payment "
    //   };
    //   const resFu = await queryItems(fu);
    //   this.PaymentList = resFu.data;
    //   this.nullList(resFu.data)

    // },
    // // 概算数据
    // async estimate() {
    //   var gu = {
    //     id: this.ValidateForm.id,
    //     qbtype: "Estimate"
    //   };
    //   const resGu = await queryItems(gu);
    //   this.EstimateList = resGu.data;
    //   this.nullList(resGu.data)
    // },
    // 数据返回null处理封装
    nullList(val) {
      val.forEach((item) => {
        if (item.balance === "null") {
          item.balance = "";
        } else if (item.customername === "null") {
          item.customername = "";
        } else if (item.docnumber === "null") {
          item.docnumber = "";
        } else if (item.invoiceDate === "null") {
          item.invoiceDate = "";
        } else if (item.qbtype === "null") {
          item.qbtype = "";
        } else if (item.dueDate === "null") {
          item.dueDate = "";
        } else if (item.totalamt === "null") {
          item.totalamt = "";
        } else if (item.status === "null") {
          item.status = "";
        }
      });
    },
    // 业务机会下的
    searchT(val) {
      if (val == "fapiao") {
        if (this.searchTent == this.$i18n.t("label_tabpage_searchz")) {
          this.searchTent = this.$i18n.t("lable.Cancel.the.search");
        } else {
          this.searchTent = this.$i18n.t("label_tabpage_searchz");
          (this.displayName = ""), //客户名称
            (this.number = ""), //编号
            (this.endDate = ""), //结束时间
            (this.startDate = ""); //开始时间
        }
      } else if (val == "fukuan") {
        if (this.searchCancel == this.$i18n.t("label_tabpage_searchz")) {
          this.searchCancel = this.$i18n.t("lable.Cancel.the.search");
        } else {
          this.searchCancel = this.$i18n.t("label_tabpage_searchz");
          (this.displayName1 = ""), //客户名称
            (this.number1 = ""), //编号
            (this.endDate1 = ""), //结束时间
            (this.startDate1 = ""); //开始时间
        }
      } else {
        if (this.searchThe == this.$i18n.t("label_tabpage_searchz")) {
          this.searchThe = this.$i18n.t("lable.Cancel.the.search");
        } else {
          this.searchThe = this.$i18n.t("label_tabpage_searchz");
          (this.displayName2 = ""), //客户名称
            (this.number2 = ""), //编号
            (this.endDate2 = ""), //结束时间
            (this.startDate2 = ""); //开始时间
        }
      }
    },
    // 搜索
    async QuickBook(val) {
      var data;
      if (val == "fapiao") {
        data = {
          id: this.ValidateForm.id,
          qbtype: "Invoice",
          displayName: this.displayName, //客户名称
          number: this.number, //编号
          endDate: this.endDate, //结束时间
          startDate: this.startDate, //开始时间 res.data
        };
        const res = await searchItems(data);
        if (res.result == true) {
          this.InvoiceList = res.data;
          this.searchFa = true;
        }
      } else if (val == "fukuan") {
        data = {
          id: this.ValidateForm.id,
          qbtype: "Payment",
          displayName: this.displayName1, //客户名称
          number: this.number1, //编号
          endDate: this.endDate1, //结束时间
          startDate: this.startDate1, //开始时间
        };
        const res = await searchItems(data);
        if (res.result == true) {
          this.PaymentList = res.data;
          this.searchFu = true;
        }
      } else {
        data = {
          id: this.ValidateForm.id,
          qbtype: "Estimate",
          displayName: this.displayName2, //客户名称
          number: this.number2, //编号
          endDate: this.endDate2, //结束时间
          startDate: this.startDate2, //开始时间
        };
        const res = await searchItems(data);
        if (res.result == true) {
          this.EstimateList = res.data;
          this.searchGai = true;
        }
      }
    },
    // 刷新
    async refersh(val) {
      var data;
      if (val == "fapiao") {
        data = {
          id: this.ValidateForm.id,
          qbtype: "Invoice",
        };
        const res = await refreshItems(data);
        if (res.result == true) {
          this.InvoiceList = res.data;
        }
      } else if (val == "fukuan") {
        data = {
          id: this.ValidateForm.id,
          qbtype: "Payment",
        };
        const res = await refreshItems(data);
        if (res.result == true) {
          this.PaymentList = res.data;
        }
      } else {
        data = {
          id: this.ValidateForm.id,
          qbtype: "Estimate",
        };
        const res = await refreshItems(data);
        if (res.result == true) {
          this.EstimateList = res.data;
        }
      }
    },
    correlation(val) {
      this.refersh(val);
    },
    async Synchronize() {
      if (this.PaymentList.length == 0) {
        this.$message.error(
          this.$i18n.t("lable.Please.bind.the.QuickBooks.record.first")
        );
        return;
      }
      var data = {
        id: "201500000542694NnBk9", //this.PaymentList[0].id
      };
      const res = await QBPaymentToCloudccSkjh(data);
      if (res.result == true) {
        this.$message({
          message: this.$il8n.t("label.companyprofile.syncsuccess"),
          type: "success",
        });
      } else {
        this.$message.error(this.$il8n.t("lable.Synchronization.failure"));
      }
    },
    eventClickFn(e) {
      const iconEl = document.querySelector(".downer"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        //               返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.pullDown = false; // flagChart 弹窗的开关
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.addEventListener("click", this.eventClickFn);
  },
  // 生命周期 - 挂载完成（可以访问DOM元素)
  mounted() {
    this.input = this.username;
    this.ValidateForm.company = this.username;
    if (this.isMenue == "001" || this.isMenue == "003") {
      this.xiang("once");
    } else if (this.isMenue == "032") {
      this.getEstimate();
    } else {
      this.queryItems();
      this.payment();
      this.estimate();
    }
  },
  // 这里存放数据
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {
    document.removeEventListener("click", this.eventClickFn);
  },
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
};
</script>
<style lang='scss' scoped>
/* @import url(); 引入公共css类 */
ul {
  list-style: none;
}
.quick {
  // padding: 10px 10px 0;
  padding-top: 10px;
  .search-p {
    font-size: 12px;
    color: #080707;
    line-height: 56px;
    height: 150px;
  }
}

.title_search {
  width: 100%;
  display: flex;
  .el-input {
    width: 300px;
    margin: 0;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  ::v-deep .el-input__inner {
    border-radius: 0px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    margin: 0;
    border-right: none;
  }
  .el-button {
    height: 32px;
    font-size: 12px;
  }
  .search_color {
    background: #006dcc;
    color: #fff;
  }
}
.el-table {
  //   border-left: 1px solid #DDDBDA;
  //   border-right: 1px solid #DDDBDA ;
  margin-top: 10px;

  .guanLian {
    color: blue;
    cursor: pointer;
  }
  .guanLian.hover {
    cursor: pointer;
  }
}
.dialog-title {
  height: 26px;
  line-height: 30px;
  overflow: hidden;

  span {
    display: inline-block;
    margin-right: 52px;
  }
  .newUser {
    font-size: 14px;
    line-height: 24px;
  }
  .quickeImg {
    width: 88px;
    height: 24px;
    display: inline-block;
    margin-right: 36px;
  }
  .demo-ruleForm {
    text-align: left;
    .el-input {
      margin: 10px 0 0 10px;
    }
  }
}
.expense {
  position: relative;
  padding: 10px;
  .header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    .header-lf {
      display: flex;
      justify-content: space-between;
      width: 50%;
      span {
        display: inline-block;
      }
    }
    .header-rl {
      line-height: 32px;
      .examine {
        margin-right: 10px;
        background: #006dcc;
        border: none;
      }
      .tong {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .pullDown {
        width: 32px;
        height: 32px;
        font-size: 20px;
        padding: 0px;
        /* border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
        vertical-align: bottom;*/
      }
      .down {
        position: absolute;
        right: 9px;
        top: 44px;
        z-index: 2002;
        box-sizing: border-box;
        /*padding: 10px; */
        border: solid 1px #aaaaaa;
        background: #fff;
        border-radius: 4px;
        margin-top: 10px;
        li {
          min-height: 26px;
          padding: 0 10px;
          font-size: 12px;
          padding: 0;
          text-align: center;
          line-height: 26px;
          color: #3e3e3c;
          width: 163px;
        }
        li:hover {
          background: #f3f2f2;
          color: #006dcc;
        }
      }
    }
  }
  .contenter {
    margin-top: 15px;
    display: flex;
    // justify-content: space-between;
    div {
      width: 30%;
      margin-right: 3%;
    }
    p {
      color: #015fb0;
      font-size: 16px;
      // width: 32%;
      margin-right: 2%;
      display: inline;
    }
    h3 {
      width: 32%;
      margin-right: 2%;
      line-height: 36px;
      font-size: 22px;
      font-weight: 400;
    }
  }
  .tableForm {
    // padding-top: 10px;
    // height: 160px;
    //display: flex;
    //justify-content: space-between;
    li {
      float: left;
      min-width: 30%;
      margin-right: 3%;
      .tableForm_p {
        line-height: 32px;
        font-size: 12px;
      }
      .tableForm_last {
        min-height: 22px;
        font-size: 14px;
        border-bottom: 1px solid #dedcda;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }
  .table {
    margin-top: 20px;
    .table_p {
      line-height: 12px;
      font-size: 12px;
      color: #3e3e3c;
    }
    .el-select {
      width: 110px;
    }
    .el-table {
      border: 1px solid #dddbda;
      border-radius: 5px;
    }
    // border-collapse: separate;
  }
}
.business {
  p {
    // margin-bottom: 10px;
  }
  .el-input {
    width: 104px;
    height: 32px;
    margin-right: 10px;
    // line-height: 32px;
    font-size: 12px;
    color: #999999;
  }

  .business-up {
    margin-bottom: 10px;
    .search-up {
      display: flex;
      justify-content: space-between;
      line-height: 32px;
      span {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        color: #080707;
        .icon {
          display: inline-block;
        }
      }
      .span-fr {
        // width: 223px;
        height: 32px;
        .el-button:nth-child(1) {
          width: 85px;
          float: right;
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .el-button:nth-child(2) {
          float: left;
          width: 138px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    .quick-up {
      margin-top: -6px;
    }
  }
}
</style>
