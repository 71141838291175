/*
 * @Author: your name
 * @Date: 2022-02-14 17:08:54
 * @LastEditTime: 2022-02-17 14:48:13
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\contract\api.js
 */
import axios from '@/config/httpConfig'

//查询docusign模板列表
export function getTemplatesList(data) {
    return axios.post('/docusign/getTemplatesList', data)
}
// 获取授权码
export function getAuthorizationCode() {
    return axios.get('/docusign/getAuthorizationCode')
}
// 获取访问令牌
export function getAccessToken(data) {
    return axios.post('/docusign/getAccessToken', data)
}
// 发起签署
export function envelopesCreate(data) {
    return axios.post('/docusign/envelopesCreate', data)
}
// 查询信封状态
export function envelopesStatus(data) {
    return axios.post('/docusign/envelopesStatus', data)
}
// 合同关联信封
export function updateEnvelopeidToContract(data) {
    return axios.post('/docusign/updateContractEnvelopeIdAndDocuments', data)
}
// 获取收件人签署状态
export function envelopesRecipients(data) {
    return axios.post('/docusign/envelopesRecipients', data)
}
// 获取合同pdf文件信息
export function contractDocuments(data) {
    return axios.post('/docusign/contractDocuments', data)
}
//查询合同字段
export function getContractField(data) {
    return axios.get('/docusign/getContractField', data)
}
// 获取docusign文本自定义字段
export function getTabDefinitions(data) {
    return axios.get('/docusign/getTabDefinitions', data)
}
// 获取已配置字段映射列表
export function getCustomField(data) {
    return axios.post('/docusign/getCustomField', data)
}

// 新增字段映射
export function createCustomField(data) {
    return axios.post('/docusign/createCustomField', data)
}

// 删除字段映射
export function deleteCustomField(data) {
    return axios.post('/docusign/deleteCustomField', data)
}

// 查询电子合同开通状态
export function getElectronicContractStatus(data) {
    return axios.get('/docusign/getElectronicContractStatus', data)
}

// 修改电子合同开通状态
export function updateElectronicContractStatus(data) {
    return axios.post('/docusign/updateElectronicContractStatus', data)
}
// 解除绑定
export function revokeAuthorization(data) {
    return axios.get('/docusign/revokeAuthorization', data)
}
// 获取授权账号信息 
export function getUserInfo(data) {
    return axios.post('/docusign/getUserInfo', data)
}

// 获取对象详细信息
export function getDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}
// 查询模板列表
export function getTempalteList(data) {
    return axios.get('/fadada/getTempalteList', data)
}
// 发起签署任务-签署模板
export function createWithSignTemplate(data) {
    return axios.post("/fadada/createWithSignTemplate", data);
}
// 确认发起签署任务
export function initiate(data) {
    return axios.post("/fadada/initiate", data);
}
// 获取签署任务详情
export function signTaskDetail(data) {
    return axios.post("/fadada/signTaskDetail", data);
}
// 撤销签署任务
export function cancelSignTask(data) {
    return axios.post("/fadada/cancelSignTask", data);
}
// 添加合同联系人
export function addContact(data) {
    return axios.post("/fadada/addContact", data);
}
// 获取联系人列表
export function queryContact(data) {
    return axios.post("/fadada/queryContact", data);
}
// 发起签署任务-文档模板
export function createWithDocTemplate(data) {
    return axios.post("/fadada/createWithDocTemplate", data);
}
//获取参与方信息
export function getParticipantsInfo(data) {
    return axios.post("/fadada/getParticipantsInfo", data);
}
//
export function getEnvelopeId(data) {
    return axios.post("/fadada/getEnvelopeid", data);
}
// 文件详情接口
export function contractDocumentsF(data) {
    return axios.post("/fadada/contractDocuments", data);
}