<template>
  <div class="add-contact">
    <el-dialog
      :title="$t('front-console-choose-contact')"
      :visible.sync="addContactDialogVisible"
      width="855px"
      :before-close="handleClose"
    >
      <div class="content">
        <div class="header">
          <div class="button-box">
            <div
              class="button first"
              @click="getList('1')"
              :style="{
                background: currentClick == '1' ? '#006DCC' : '',
                color: currentClick == '1' ? 'white' : '',
              }"
            >
              <!-- 最近联系人 -->
              {{ $t("c797") }}
            </div>
            <div
              class="button"
              @click="getList('2')"
              :style="{
                background: currentClick == '2' ? '#006DCC' : '',
                color: currentClick == '2' ? 'white' : '',
              }"
            >
              <!-- 内部联系人 -->
              {{ $t("c798") }}
            </div>
            <div
              class="button third"
              @click="getList('3')"
              :style="{
                background: currentClick == '3' ? '#006DCC' : '',
                color: currentClick == '3' ? 'white' : '',
              }"
            >
              <!-- 外部联系人 -->
              {{ $t("c799") }}
            </div>
          </div>
          <el-input
            :placeholder="$t('c800')"
            v-model="searchValue"
            @change="handleSelect(contactsType)"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
        <div class="table" v-show="currentClick == 1">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              prop="name"
              :label="$t('label.quote.xingming')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              :label="$t('label.mr.title.mobilePhone')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              :label="$t('label.description')"
              width="300"
            >
            </el-table-column>
            <el-table-column :label="$t('label.department.operation')">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >{{ $t("label.add") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table" v-show="currentClick == 2">
          <div class="add" @click="addContacts('within')">{{ $t("c801") }}</div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              prop="name"
              :label="$t('component_telerecord_label_name')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              :label="$t('label.mr.title.mobilePhone')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              :label="$t('label.description')"
              width="300"
            >
            </el-table-column>
            <el-table-column :label="$t('label.tabpage.operation')">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >{{ $t("label.add") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table" v-show="currentClick == 3">
          <div class="add" @click="addContacts('outside')">
            {{ $t("c801") }}
          </div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              prop="name"
              :label="$t('component_telerecord_label_name')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              :label="$t('label.mr.title.mobilePhone')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              :label="$t('label.description')"
              width="300"
            >
            </el-table-column>
            <el-table-column :label="$t('label.tabpage.operation')">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >{{ $t("label.add") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[5, 10, 15]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </span>
    </el-dialog>
    <el-dialog
      :title="addContact"
      :visible.sync="addDialogVisible"
      width="600px"
      :before-close="addhandleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item
          :label="$t('component_telerecord_label_name')"
          prop="name"
        >
          <el-input style="width: 320px" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('c819')" prop="phone">
          <el-input style="width: 320px" v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.weixin.group.description')" prop="text">
          <el-input
            style="width: 320px"
            type="textarea"
            :placeholder="$t('label.weixin.input.content')"
            v-model="ruleForm.text"
            maxlength="50"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{
          $t("label.cancel")
        }}</el-button>
        <!-- 确定 -->
        <el-button
          type="primary"
          @click="saveContact(contactsType)"
          :lodding="btLodding"
          >{{ $t("label.tabpage.ok") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as docusignApi from "../api.js";
export default {
  props: {
    //判断弹框开关
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addContactDialogVisible: true,
      searchValue: "",
      //当前点击按钮
      currentClick: "1",
      tableData: [],
      currentPage: 1,
      pageNum: 1,
      pageSize: 5,
      total: "",
      addDialogVisible: false, //新增联系人弹框
      // 新增联系人弹框校验
      rules: {
        name: [
          {
            required: true,
            message: this.$i18n.t("label.weixin.input.username"),
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            // message: '请输入手机号/邮箱',
            message: this.$i18n.t("c820"),
            trigger: "change",
          },
        ],
      },
      ruleForm: {
        name: "",
        phone: "",
        text: "",
      },
      contactsType: "", //联系人类型  0 内部联系人 1 外部联系人
      addData: "", //联系人添加到卡片上
      addContact: "",
    };
  },
  watch: {
    dialogVisible: {
      handler(nval) {
        this.addContactDialogVisible = nval;
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.addContactDialogVisible = false;
    },
    //新增联系人弹框关闭
    addhandleClose() {
      this.addDialogVisible = false;
    },
    getList(item) {
      this.currentClick = item;
      this.searchValue = ""; //切换tab搜索框清空
      if (item == "1") {
        this.queryContact();
        this.pageNum = 1;
        this.contactsType = "";
      } else if (item == "2") {
        this.pageNum = 1;
        this.queryContact("0");
        this.contactsType = "0";
      } else if (item == "3") {
        this.pageNum = 1;
        this.queryContact("1");
        this.contactsType = "1";
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryContact(this.contactsType);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.queryContact(this.contactsType);
    },
    handleSelect(val) {
      this.queryContact(val);
    },
    handleClick(row) {
      this.addData = row;
      this.$emit("closeContact");
      this.$emit("addContacts");
    },
    //点击新增联系人
    addContacts(val) {
      this.addDialogVisible = true;
      // 判断点击的是内部联系人的添加还是外部
      if (val == "within") {
        this.contactsType = "0";
        this.addContact = this.$i18n.t("c821");
      } else if (val == "outside") {
        this.addContact = this.$i18n.t("c822");
        this.contactsType = "1";
      }
    },
    // 添加联系人弹框确认
    saveContact(contactsType) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            name: this.ruleForm.name,
            phone: this.ruleForm.phone,
            remark: this.ruleForm.text,
            type: contactsType,
          };
          docusignApi.addContact(data).then(() => {
            this.addDialogVisible = false;
            this.queryContact(contactsType);
          });
        }
      });
    },
    // 获取联系人接口
    queryContact(contactsType) {
      let data = {
        name: this.searchValue,
        type: contactsType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      docusignApi.queryContact(data).then((res) => {
        this.tableData = res.data.tableList;
        this.total = res.data.totalCount;
      });
    },
  },
  computed: {},
};
</script>

<style lang='scss' scoped>
.add-contact {
  .content {
    .header {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      .button-box {
        .button {
          display: inline-block;
          padding: 0px 15px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border: 1px solid #e6e5e3;
          cursor: pointer;
        }
        .first {
          border-right: none;
          border-radius: 4px 0 0 4px;
        }
        .third {
          border-left: none;
          border-radius: 0 4px 4px 0;
        }
      }
      .el-input {
        width: 300px;
      }
      ::v-deep .el-input__inner {
        width: 300px;
        height: 36px;
      }
    }
    .table {
      .add {
        width: 92px;
        height: 36px;
        background-color: #006dcc;
        color: #fff;
        margin-bottom: 20px;
        text-align: center;
        line-height: 36px;
        border-radius: 4px;
        cursor: pointer;
      }
      ::v-deep .el-table th {
        padding: 8px 0;
      }
      ::v-deep .el-table td {
        padding: 4px 0;
      }
    }
  }
  ::v-deep .el-table thead {
    color: #333;
  }
  ::v-deep
    .el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label:after {
    content: "*";
    color: #f11b21;
    margin-right: 4px;
  }
  ::v-deep
    .el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label::before {
    content: "";
  }
  ::v-deep .el-form--label-left .el-form-item__label {
    width: 35% !important;
  }
}
</style>