<!--
 * @Author: 贾洁飞
-->
<template>
  <div class="quick">
    <el-table
      size="small"
      :data="EstimateList || InvoiceList || PaymentList"
      style="width: 100%"
      :header-cell-style="{
        'border-bottom': '1px solid #DDDBDA',
        'border-right': '1px solid #DDDBDA',
        background: '#FAFAF9',
      }"
    >
      <el-table-column :label="$t('label.partner.all.operate')">
        <template slot-scope="scope">
          <!-- <el-button
            v-if="searchFa===true||searchFu===true||searchGai===true"
            @click="relatedItem(scope.row)"
            type="text"
            size="small"
          >{{deleItem}}</el-button>
          <el-button
            v-else
            @click="deleteRelatedItem(scope.row)"
            type="text"
            size="small"
          >{{relevance}}</el-button>
          <span style="margin:0;color:#006DCC">|</span> -->
          <el-button @click="queryItems(scope.row)" type="text" size="small">{{
            $t("button_partner_viewdetail")
          }}</el-button>
        </template>
      </el-table-column>
      <!--DocNumber Balance TotalAmt-->
      <el-table-column
        :show-overflow-tooltip="true"
        :prop="fapiao ? 'invoiceDate' : 'paymentdate'"
        :label="$t('label.monitorsys.delete.date')"
      ></el-table-column>
      <!-- <el-table-column :show-overflow-tooltip="true" prop="" :label="$t('label.type')"></el-table-column> -->
      <el-table-column
        :show-overflow-tooltip="true"
        :prop="fapiao ? 'invoiceNumber' : ''"
        :label="$t('label.weixin.id')"
      ></el-table-column>
      <!-- <el-table-column prop="customername" :label="$t('label.quickbooks.searchresault.company')" width="80"></el-table-column> -->
      <el-table-column
        :show-overflow-tooltip="true"
        :prop="fapiao ? 'dueDate' : 'paymentdate'"
        :label="$t('label_chatter_maturiyDate')"
      ></el-table-column>
      <el-table-column
        v-if="fapiao"
        :show-overflow-tooltip="true"
        :prop="fapiao ? 'tobepaidamount' : ''"
        :label="$t('label.quickbooks.viewlist.balance')"
      ></el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        :prop="fapiao ? 'total' : 'amount'"
        :label="$t('label.reporttotal')"
      ></el-table-column>
      <el-table-column
        v-if="fapiao"
        :show-overflow-tooltip="true"
        prop="status"
        :label="$t('label_chatter_state')"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { relatedItem, deleteRelatedItem } from "./api";
export default {
  props: {
    fapiao: {
      type: String,
    },
    fukuan: {
      type: String,
    },
    InvoiceList: {
      type: Array,
    },
    PaymentList: {
      type: Array,
    },
    EstimateList: {
      type: Array,
    },
    searchFa: {
      type: Boolean,
    },
    searchFu: {
      type: Boolean,
    },
    searchGai: {
      type: Boolean,
    },
  },
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      // 业务机会数据
      tableData: this.EstimateList || this.InvoiceList || this.PaymentList,
      id: sessionStorage.getItem("id"), //当前点击id
      bind: this.$CCDK.CCToken.getToken(), //token
      relevance: this.$i18n.t("label.quickbooks.button.unlink"),
      deleItem: this.$i18n.t("label.icon.145"),
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 取消关联
    async deleteRelatedItem(obj) {
      var data;
      if (this.fapiao == "fapiao") {
        data = {
          id: this.id,
          qbid: obj.id,
          qbtype: "Invoice",
        };
        const res = await deleteRelatedItem(data);
        if (res.result === true) {
          this.$emit("correlation", this.fapiao);
        }
      } else if (this.fukuan == "fukuan") {
        data = {
          id: this.id,
          qbid: obj.id,
          qbtype: "Payment",
        };
        const res = await deleteRelatedItem(data);
        if (res.result === true) {
          this.$emit("correlation", this.fukuan);
        }
      } else {
        data = {
          id: this.id,
          qbid: obj.id,
          qbtype: "Estimate",
        };
        const res = await deleteRelatedItem(data);
        if (res.result === true) {
          this.$emit("correlation");
        }
      }
    },
    // 查看
    async queryItems(obj) {
      if (this.fapiao) {
        window.open(
          `https://qbo.intuit.com/app/invoice?txnId=${obj.invoiceId}`
        );
      } else if (this.fukuan) {
        window.open(
          `https://qbo.intuit.com/app/recvpayment?txnId=${obj.paymentId}`
        );
      }
    },
    // 关联
    async relatedItem(obj) {
      var data;
      if (this.fapiao == "fapiao") {
        data = {
          id: this.id,
          qbid: obj.id,
          qbtype: "Invoice",
        };
        const res = await relatedItem(data);
        if (res.result === true) {
          this.$emit("correlation", this.fapiao);
        }
      } else if (this.fukuan == "fukuan") {
        data = {
          id: this.id,
          qbid: obj.id,
          qbtype: "Payment",
        };
        const res = await relatedItem(data);
        if (res.result === true) {
          this.$emit("correlation", this.fukuan);
        }
      } else {
        data = {
          id: this.id,
          qbid: obj.id,
          qbtype: "Estimate",
        };
        const res = await relatedItem(data);
        if (res.result === true) {
          this.$emit("correlation");
        }
      }
    },
  },
  beforeCreate() {},

  beforeMount() {},

  beforeUpdate() {},

  updated() {},

  beforeDestroy() {},

  destroyed() {},

  activated() {},
};
</script>
<style lang='scss' scoped>
/* @import url(); 引入公共css类 */
.el-table {
  border: 1px solid #dddbda;
  border-radius: 5px;
  // margin-top: -6px;
}
.quick {
}
</style>
