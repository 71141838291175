<template>
  <div class="case-team" v-loading="loading">
    <div class="case-team-item">
      <!-- 更新个案团队成员 -->
      <div class="case-team-item-title">
        {{ $t("c1079") }}
      </div>
      <div class="case-team-data">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column :label="$t('c1077')">
            <template slot-scope="scope">
              <div class="col1">
                <!-- 必填 星号 -->
                <span style="color: red">*</span>
                <div class="case-team-user" @click="changeCaseUser(scope)">
                  <span>{{ scope.row.relatedname }}</span>
                  <i class="el-icon-search"></i>
                </div>
              </div>
            </template>
          </el-table-column>

          <!-- 成员角色 -->
          <el-table-column :label="$t('c1076')">
            <template slot-scope="scope">
              <!-- 必填 星号 -->
              <span style="color: red">*</span>
              <el-select
                v-model="scope.row.roleid"
                @change="handleSelect($event, scope)"
              >
                <el-option
                  v-for="item in rolelist"
                  :key="item.id"
                  :label="item.rolename"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="accesslevelname" :label="$t('c1070')">
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="$t('label.ems.action')"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleRemove(scope, 1)"
                style="color: #006dcc"
                type="text"
                size="small"
              >
                {{ $t("label.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="case-team-add">
        <el-button type="primary" size="mini" @click="addLine(1)">
          {{ $t("label.batchadd.newbatchpage.button.add") }}
        </el-button>
      </div>
    </div>
    <div class="case-team-item">
      <div class="case-team-item-title">
        {{ $t("c1074") }}
      </div>
      <div class="case-team-data">
        <el-table :data="tableData2" style="width: 100%">
          <el-table-column :label="$t('c1074')">
            <template slot-scope="scope">
              <el-select v-model="scope.row.relatedid">
                <el-option
                  v-for="item in teamlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="$t('label.ems.action')"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleRemove(scope, 2)"
                style="color: #006dcc"
                type="text"
                size="small"
              >
                {{ $t("label.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="case-team-add">
        <el-button type="primary" size="mini" @click="addLine(2)">
          {{ $t("label.batchadd.newbatchpage.button.add") }}
        </el-button>
      </div>
    </div>
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          relevant-objid="user"
          relevant-prefix="005"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Api from "./api";
import searchTable from "@/components/Form/search-table";

export default {
  name: "updateCaseTeam",
  components: {
    searchTable,
  },
  data() {
    return {
      // 加载状态
      loading: false,
      // 团队成员数据
      tableData: [],
      // 预设个案团队数据
      tableData2: [],
      //   角色列表
      rolelist: [],
      // 预设个案团队列表
      teamlist: [],
      // 当前更改索引
      currentIndex: "",
      // 查找/查找多选弹窗主体高度
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      //   查找弹窗
      showSearchTable: false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    /**
     * 初始化
     */
    init() {
      this.loading = true;
      this.getCaseTeam();
      Api.queryCaseTeamMember({
        id: this.$route.params.id || this.recordId,
      }).then((res) => {
        this.tableData = [];
        this.tableData2 = [];
        res.data.memberlist.forEach((item) => {
          if (item.type === "user") {
            this.tableData.push(item);
          } else {
            this.tableData2.push(item);
          }
        });
        this.addLine();
        this.rolelist = res.data.rolelist || [];
        let object = {
          rolename: this.$i18n.t("label_tabpage_nothingz"),
          id: "",
          accesslevel: "",
        };
        this.rolelist.unshift(object);
        this.loading = false;
      });
    },
    /**
     * 获取与定义个案团队列表
     */
    getCaseTeam() {
      Api.queryCaseTeamList().then((res) => {
        this.teamlist = res.data || [];
        let obj = {
          name: this.$i18n.t("label_tabpage_nothingz"),
          id: "",
        };
        this.teamlist.unshift(obj);
      });
    },
    /**
     * 新增一行
     */
    addLine(n) {
      if (n == 1) {
        let obj = {
          roleid: "",
          relatedid: "",
          relatedname: "",
          type: "user",
        };
        this.tableData.push(obj);
      } else if (n == 2) {
        let obj = {
          relatedid: "",
          type: "caseteam",
        };
        this.tableData2.push(obj);
      } else {
        let obj = {
          roleid: "",
          relatedid: "",
          relatedname: "",
          type: "user",
        };
        this.tableData.push(obj);
        let obj2 = {
          relatedid: "",
          type: "caseteam",
        };
        this.tableData2.push(obj2);
      }
    },

    /**
     * 下拉框更改
     */
    handleSelect(e, scope) {
      let access = this.rolelist.find((item) => {
        return item.id === e;
      });

      this.tableData[scope.$index].accesslevel = access.accesslevel;
      /**
       * 角色值赋值
       */
      this.tableData[scope.$index].rolename = access.rolename;
      this.tableData[scope.$index].accesslevelname =
        access.accesslevel === "Read"
          ? this.$i18n.t("label.readonly")
          : access.accesslevel === "Write"
          ? this.$i18n.t("label.readwrite")
          : "";
    },
    /**
     * 移除
     */
    handleRemove(item, flag) {
      if (flag == 1) {
        this.tableData.splice(item.$index, 1);
      } else {
        this.tableData2.splice(item.$index, 1);
      }
    },
    /**
     * 更改用户
     */
    changeCaseUser(item) {
      this.currentIndex = item.$index;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /*
     * 改变查找/查找多选的值
     */
    changeSelect(row) {
      if (row instanceof Array) {
        this.tableData[this.currentIndex].relatedname = "";
        this.tableData[this.currentIndex].relatedid = "";
      } else {
        this.tableData[this.currentIndex].relatedname = row.data.name;
        this.tableData[this.currentIndex].relatedid = row.data.id;
      }
      this.showSearchTable = false;
    },
    /*
     * 关闭查找弹框
     */
    beforeClose() {
      this.showSearchTable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.case-team {
  .case-team-item {
    margin-bottom: 40px;
    .case-team-item-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .case-team-data {
      margin-bottom: 8px;
      .case-team-user {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        padding: 0 15px;
        height: 32px;
        background-color: #fff;
      }
      .case-team-user:hover {
        background-color: #fff;
        border-color: #2d6cfc;
      }
    }
  }
}
::v-deep .el-select {
  padding-left: 0;
}
::v-deep .el-select .el-input__inner {
  height: 32px;
}
::v-deep .el-table {
  font-size: 12px;
}
::v-deep .el-table thead .el-table__cell {
  padding: 6px 0;
  background: #fafaf9;
  color: #666666;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #dedcda;
}
::v-deep .el-table thead .el-table__cell:first-child {
  border-left: 1px solid #dedcda;
}
::v-deep .el-table tbody .el-table__cell {
  padding: 5px 0;
}

.col1 {
  display: flex;
  width: 100%;
}
</style>