/*
 * @Author:贾洁飞
 * @Date: 2021-04-06 10:00:39
 * @FilePath: \lightning-web\src\api\quickbooks.js
 */
import axios from '@/config/httpConfig'
// 当前是否启用了quickbooks功能  查询QB权限
export function quickbookStart(data) {
    return axios.get('/quickbooks/getQBPermission', data)
  }
  // 搜索客户
  export function quickSearch(data){
    return axios.post('/quickbooks/searchCustomer',data)
  }
  // 新建用户
  export function newUser(data){
    return axios.post('/quickbooks/createCustomer',data)
  }
  // cloudcc同步到quickbooks
  export function cloudccToQuickBooks(data){
    return axios.post('/quickbooks/cloudccToQuickBooks',data)
  }
  // quickbooks同步到cloudcc
  export function quickBooksToCloudcc(data){
    return axios.post('/quickbooks/quickBooksToCloudcc',data)
  }
  // 关联客户或联系人
  export function relatedCustomer(data){
    return axios.post('/quickbooks/relatedCustomer',data)
  }
  // 客户联系人子选项卡
  export function quickBooksXiang(data){
    return axios.post("/quickbooks/customerDetail",data)
  }
  // 同步至quickbooks
  export function cloudccInvoicesToQBInvoices(data){
    return axios.post('/quickbooks/cloudccInvoicesToQBInvoices',data)
  }
  // 查找全部视图
  export function queryAllItems(data){
    return axios.post('/quickbooks/queryAllItems',data)
  }
  // 取消关联 deleteRelatedCustomer
  export function deleteRelatedCustomer(data){
    return axios.post('/quickbooks/deleteRelatedCustomer',data)
  }
  // 关联业务机会合同收款计划
  export function relatedItem(data){
    return axios.post('/quickbooks/relatedItem',data)
  }
  // 刷新业务机会合同收款计划
  export function refreshItems(data){
    return axios.post('/quickbooks/refreshItems',data)
  }
  // 查看业务机会合同收款计划
  export function queryItems(data){
    return axios.post('/quickbooks/queryItems',data)
  }
  // 搜索业务机会合同收款计划
  export function searchItems(data){
    return axios.post('/quickbooks/searchItems',data)
  }
  // 取消业务机会合同收款计划
  export function deleteRelatedItem(data){
    return axios.post('/quickbooks/deleteRelatedItem',data)
  }
  // quickbooks付款同步收款计划
  export function QBPaymentToCloudccSkjh(data){
    return axios.post('/quickbooks/QBPaymentToCloudccSkjh',data)
  }
  // 发票
  // 从quickbooks上同步发票信息
  export function sendInvoiceFromQB(data){
    return axios.post('/quickbooks/sendInvoiceFromQB',data)
  }
  // 从quickbooks上更新状态
  export function updateStatusFromQB(data){
    return axios.post('/quickbooks/updateStatusFromQB',data)
  }
  // 订单同步到QuickBooks
  export function cloudccOrderToQBEstimate(data){
      return axios.post('/quickbooks/cloudccOrderToQBEstimate',data)
    }
  // 回款同步到QuickBooks
  export function cloudccPaymentToQBPayment(data){
    return axios.post('/quickbooks/cloudccPaymentToQBPayment',data)
  }
   // 从QuickBooks同步发票
   export function syncInvoicesFromQB(data){
    return axios.post('/quickbooks/syncInvoicesFromQB',data)
  }
   // 从QuickBooks同步回款
   export function syncPaymentFromQB(data){
    return axios.post('/quickbooks/syncPaymentFromQB',data)
  }
   // 查询订单关联的客户信息
   export function queryRelatedCustomer(data){
    return axios.post('/quickbooks/queryRelatedCustomer',data)
  }
   // 查询客户已关联到QB的订单，发票，回款信息
   export function queryRelatedItemsLighting(data){
    return axios.post('quickbooks/queryRelatedItemsLighting',data)
  }
  

