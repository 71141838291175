<template>
  <div class="main">
    <div class="lf">
      <!-- 相关列表菜单"-->
      <div class="lf-top">
        <el-card id="box-main_top" class="box-main_top" shadow="never">
          <Wang-skeleton
            v-if="isSkeleton"
            type="custom"
            :childrenOption="relationListSkeletonOption"
            style="background: #fff; height: 100px"
          />
          <div class="related-list-box" v-else>
            <dl
              :style="{ width: relationNamePercent }"
              v-for="(item, i) in list"
              :key="i"
            >
              <dt>
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="width: 100%; height: 100%; cursor: default"
                >
                  <use
                    :href="
                      item.titImg ? '#icon-' + item.titImg : '#icon-hometab'
                    "
                  ></use>
                </svg>
              </dt>
              <!-- 鼠标悬浮弹窗 -->
              <!-- 批准历史、价格手册、股权关系不需要悬浮弹窗 -->
              <el-popover
                placement="bottom"
                popper-class="suspension_popover_list"
                trigger="hover"
                v-if="
                  item.relatedlistType !== 'approvalHistory' &&
                  item.relatedlistType !== 'caseteam' &&
                  item.relatedlistId !== 'pricebook' &&
                  item.relatedlistType !== 'stock' &&
                  item.relatedlistType !== 'ServiceEvaluation' &&
                  listFlag
                "
                :visible-arrow="false"
                :open-delay="openDelay"
                @show="moveBtn(i, item)"
              >
                <!-- 相关列表名称悬浮提示表格 -->
                <suspension-tabel
                  :itemArr="itemArr"
                  :id="id"
                  :historyData="historyData"
                  :pricebookArr="pricebookArr"
                  :bigTitle="bigTitle"
                  :tabName="tabNameData"
                  :list="list"
                  :titOffsetTop="titOffsetTop"
                  :objId="objId"
                  :pageObj="pageObj"
                  ref="sespen"
                  v-loading="item.loading"
                ></suspension-tabel>
                <!-- 相关列表名称 + 数量 -->
                <dd
                  slot="reference"
                  class="tit88 spot_s"
                  @click="menuBtn(item)"
                >
                  <span
                    :style="{
                      maxWidth: multiScreen ? '120px' : relationNameWidth,
                    }"
                    v-html="item.relationName"
                  >
                    <!-- {{ item.relationName }} -->
                  </span>
                  <span
                    v-if="
                      item.relationSize > 99 &&
                      item.relatedlistType !== 'approvalHistory'
                    "
                    >(99+)</span
                  >
                  <!-- 联系人角色 -->
                  <span
                    v-if="
                      item.relatedlistId == 'aae2016040617152997' &&
                      orgchartData.children
                    "
                  >
                    <span
                      v-if="
                        orgchartData.children.length > 99 &&
                        item.relatedlistType !== 'approvalHistory'
                      "
                      >(99+)</span
                    >
                    <span
                      v-else-if="
                        orgchartData.children.length <= 99 &&
                        item.relatedlistType !== 'approvalHistory'
                      "
                      >({{ orgchartData.children.length }})</span
                    >
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        item.relationSize > 99 &&
                        item.relatedlistType !== 'approvalHistory'
                      "
                      >(99+)</span
                    >
                    <span v-else-if="item.relatedlistType !== 'approvalHistory'"
                      >({{ item.relationSize }})</span
                    >
                  </span>
                </dd>
              </el-popover>
              <!-- 价格手册，批准历史均无悬浮展示和跳转列表页  wwwjjj-->
              <dd
                v-else-if="
                  (item.relatedlistType === 'approvalHistory' ||
                    item.relatedlistType === 'caseteam' ||
                    item.relatedlistId === 'pricebook' ||
                    item.relatedlistType === 'stock' ||
                    item.relatedlistType === 'ServiceEvaluation') &&
                  listFlag
                "
                class="tit88 spot_s"
                @click="menuBtn(item)"
              >
                <span
                  :style="{
                    maxWidth: multiScreen ? '120px' : relationNameWidth,
                  }"
                >
                  {{ item.relationName }}
                </span>
                <span
                  v-if="
                    item.relationSize > 99 && item.relatedlistId === 'pricebook'
                  "
                  >(99+)</span
                >
                <span v-else-if="item.relatedlistId === 'pricebook'"
                  >({{ item.relationSize }})</span
                >
                <!-- 客户和联系人下的服务评价数量  wwwjjj -->
                <span v-else-if="item.relatedlistType === 'ServiceEvaluation'"
                  >({{
                    accountNumberList > 10 ? "10+" : accountNumberList
                  }})</span
                >
              </dd>
              <!-- 用户无相关列表查看权限时不展示悬浮弹窗 -->
              <dd
                v-else-if="!listFlag"
                class="tit88 spot_s"
                @click="menuBtn(item)"
              >
                <span
                  :style="{
                    maxWidth: multiScreen ? '120px' : relationNameWidth,
                  }"
                >
                  {{ item.relationName }}
                </span>
                <span v-if="item.relationSize > 99">(99+)</span>
                <span v-else>({{ item.relationSize }})</span>
              </dd>
            </dl>
            <!--          开发人员：贺文娟-->
            <!--          营销渠道模块（非标准对象所以要单独写）-->
            <!--          勿删      -->
            <dl
              :style="{ width: relationNamePercent }"
              v-if="objectApiData === 'campaign'"
            >
              <dt>
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="width: 100%; height: 100%"
                >
                  <use href="#icon-hometab"></use>
                </svg>
              </dt>
              <dd class="tit88 spot_s" @click="menuBtn(item)">
                <span
                  :style="{
                    maxWidth: multiScreen ? '120px' : relationNameWidth,
                  }"
                >
                  <a @click="changeHash('#chnnel')">{{
                    $t("label.channel")
                  }}</a>
                </span>
                <!-- <span>(10+)</span> -->
                <span v-if="$store.state.channel.marketingListLength > 10"
                  >(10+)</span
                >
                <span v-else
                  >({{ $store.state.channel.marketingListLength }})</span
                >
              </dd>
            </dl>
            <!--          开发人员：张聿通-->
            <!--          市场活动新渠道模块（非标准对象所以要单独写）-->
            <!--          勿删      -->
            <dl
              :style="{ width: relationNamePercent }"
              v-if="objectApiData === 'campaign' && socialshow"
            >
              <dt>
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="width: 100%; height: 100%"
                >
                  <use href="#icon-markting_social"></use>
                </svg>
              </dt>
              <dd class="tit88 spot_s" @click="menuBtn(item)">
                <span
                  :style="{
                    maxWidth: multiScreen ? '120px' : relationNameWidth,
                  }"
                >
                  <a @click="changeHash('#social')">{{
                    $t("market.name.social.post")
                  }}</a>
                </span>
                <span v-if="$store.state.channel.socialLength > 10">(10+)</span>
                <span v-else>({{ $store.state.channel.socialLength }})</span>
              </dd>
            </dl>
            <!-- 非标准对象 海报 -->
            <dl
              :style="{ width: relationNamePercent }"
              v-if="objectApiData === 'campaign' && postershow"
            >
              <dt>
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="width: 100%; height: 100%"
                >
                  <use href="#icon-markting_poster"></use>
                </svg>
              </dt>
              <dd class="tit88 spot_s" @click="menuBtn(item)">
                <span :style="{ maxWidth: multiScreen ? '120px' : '180px' }">
                  <a @click="changeHash('#posters')">{{
                    $t("front-marketing-module-v1-share-poster")
                  }}</a>
                </span>
                <span v-if="$store.state.channel.posterLength > 10">(10+)</span>
                <span v-else>({{ $store.state.channel.posterLength }})</span>
              </dd>
            </dl>
            <!-- 非标准对象 员工邀约 -->
            <dl
              :style="{ width: relationNamePercent }"
              v-if="objectApiData === 'campaign' && invitshow"
            >
              <dt>
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="width: 100%; height: 100%"
                >
                  <use href="#icon-icon"></use>
                </svg>
              </dt>
              <dd class="tit88 spot_s" @click="menuBtn(item)">
                <span :style="{ maxWidth: multiScreen ? '120px' : '180px' }">
                  <a @click="changeHash('#invitation')">{{ $t("c62") }}</a>
                </span>
                <span v-if="$store.state.channel.invitationLength > 10"
                  >(10+)</span
                >
                <span v-else
                  >({{ $store.state.channel.invitationLength }})</span
                >
              </dd>
            </dl>
            <!-- 添加的关注者  正在关注  小组 -->
            <!-- 关注者 -->
            <div
              class="focusList"
              :style="{ width: relationNamePercent }"
              v-if="!isShowType"
            >
              <svg class="icon" aria-hidden="true">
                <use href="#icon-teampeople"></use>
              </svg>
              <div class @click="focusList">
                <!-- 关注者 -->
                {{ $t("vue_label_CCChat_followers") }}({{
                  this.followersMyCount
                }})
              </div>
            </div>
            <!-- 正在关注 -->
            <div
              class="focusListItemone"
              :style="{ width: relationNamePercent }"
              v-if="!isShowType"
            >
              <svg class="icon" aria-hidden="true">
                <use href="#icon-teampeople"></use>
              </svg>
              <div class="" @click="focusListItemone">
                {{ $t("label.chatter.following") }}({{ this.myfollowerCount }})
              </div>
            </div>
            <!-- 小组 -->
            <div
              class="focusListItemtwo"
              :style="{ width: relationNamePercent }"
              v-if="!isShowType"
            >
              <svg class="icon" aria-hidden="true">
                <use href="#icon-team"></use>
              </svg>
              <div class="" @click="focusListItemtwo">
                {{ $t("label.chat.micropost.a.group") }}({{
                  this.myGroupCount
                }})
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <!-- 详细信息-->
      <div class="lf-bottom">
        <el-card class="box-main_tit" shadow="never" v-if="showDetail">
          <!-- <i
            v-if="showDetailForm"
            class="el-icon-d-arrow-right controlDetail"
            @click="showDetailForm = !showDetailForm"
          ></i>
          <i
            v-if="!showDetailForm"
            class="el-icon-d-arrow-left controlDetail"
            @click="showDetailForm = !showDetailForm"
          ></i> -->
          <!-- 详细信息 -->
          <div class="detailTitle">
            <div class="detail">
              <!-- <div class="title_detail active">
                <a class="xiangqing">{{ $t("label.more.info") }}</a>
              </div>-->
              <!-- 详情tab -->
              <div
                :class="[
                  isBottom == true ? 'title_detail active' : 'title_detail',
                ]"
                @click="quickBottom('xiang')"
              >
                <a class="xiangqing">{{ $t("label.more.info") }} </a>
              </div>
              <!-- 报表图表模块
                  开发人员：张凤 -->
              <div
                style="line-height: 26px"
                v-if="chartsListData.length > 0"
                :class="[
                  isCharts == true ? 'title_detail active' : 'title_detail',
                ]"
                @click="quickBottom('charts')"
              >
                {{ $t("vue_label_commonobjects_view_chart") }}
              </div>
              <div
                :class="[isCheckQuick ? 'title_detail active' : 'title_detail']"
                @click="quickBottom('quick')"
                v-if="isquick"
              >
                <a>
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-quickbooks1"></use>
                  </svg>
                </a>
              </div>
              <!-- 工作订单下的quickbooks -->
              <!-- <div
                style="margin-left: 10px"
                :class="[
                  isBottom == false && isReport == false && isCharts == false
                    ? 'title_detail active'
                    : 'title_detail',
                ]"
                @click="quickBottom"
                v-if="prefix == '032'&& isquick"
              >
                <a>
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-quickbooks1"></use>
                  </svg>
                </a>
              </div> -->
              <div
                :class="[
                  isBottom == false && isshow == true
                    ? 'title_detail active'
                    : 'title_detail',
                ]"
                @click="quickBottom('isshow')"
                v-if="prefixData == 'k02'"
              >
                <!-- 版本 -->
                <a class="xiangqing">{{
                  $t("label.trigger.triggerVersion")
                }}</a>
              </div>
              <!-- 个案下的Knowledge -->
              <div
                style="margin-left: 10px"
                :class="[
                  isBottom == false && isshow == false && iscase == true
                    ? 'title_detail active'
                    : 'title_detail',
                ]"
                @click="quickBottom('iscase')"
                v-if="prefixData == '008' && isKnowledgeUser"
              >
                <a class="xiangqing">Knowledge</a>
              </div>
              <!-- 工作订单下的Knowledge -->
              <div
                style="margin-left: 10px"
                :class="[
                  isBottom == false && isshow == false && isWorkOrder == true
                    ? 'title_detail active'
                    : 'title_detail',
                ]"
                @click="quickBottom('isWorkOrder')"
                v-if="prefix == 's21' && isKnowledgeUser"
              >
                <a class="xiangqing">Knowledge</a>
              </div>
            </div>
            <el-tooltip
              popper-class="my-tooltip"
              effect="dark"
              :content="$t('label_switch_two_column_or_responsive_layout')"
              placement="top-start"
            >
              <ul
                class="changeColumnBtnGroup"
                v-if="detailLayoutMode === 'mode1' && isBottom == true"
              >
                <li
                  @click="changeColumns('twoColumns')"
                  style="border-right: 1px solid #dedcda"
                >
                  <svg class="icon setColumnsIcon" aria-hidden="true">
                    <use
                      :fill="
                        detailMode === 'twoColumns' ? '#006dcc' : '#f5f5f5'
                      "
                      href="#icon-baocunbuju"
                    ></use>
                  </svg>
                </li>
                <li @click="changeColumns('responsiveLayout')">
                  <svg
                    v-if="detailMode === 'responsiveLayout'"
                    class="icon setColumnsIcon autoColumnsIcon"
                    aria-hidden="true"
                  >
                    <use href="#icon-autoColumns-active"></use>
                  </svg>
                  <svg
                    v-else
                    class="icon setColumnsIcon autoColumnsIcon"
                    aria-hidden="true"
                  >
                    <use href="#icon-autoColumns"></use>
                  </svg>
                </li>
              </ul>
            </el-tooltip>
          </div>
          <div
            v-if="!loadingDetail && editableCell"
            v-show="isBottom"
            :class="
              detailLayoutMode === 'mode1'
                ? 'changeDetailBtns'
                : 'changeDetailBtns rightChangeDetailBtns'
            "
          >
            <el-button size="mini" @click="cancelChangeDetail">
              <!-- 取消 -->
              {{ $t("label.ems.cancel") }}
            </el-button>
            <el-button
              type="primary"
              size="mini"
              v-preventReClick
              @click="saveChange"
            >
              <!-- 保存 -->
              {{ $t("label.save") }}
            </el-button>
          </div>
          <transition name="detail">
            <div v-if="showDetailForm">
              <template v-if="loadingDetail">
                <!-- 骨架屏 -->
                <Wang-skeleton
                  type="custom"
                  :childrenOption="detailSkeletonOption"
                  v-for="i in (0, 8)"
                  :key="i"
                >
                </Wang-skeleton>
              </template>
              <!-- 显示时的表单信息 -->
              <info-form
                class="infoForm"
                ref="baseForm"
                v-if="!loadingDetail && !editableCell && isBottom == true"
                :objId="objId"
                :recordId="id"
                :operation="operation"
                :option-list="optionList"
                :inlineedit="inlineedit"
                :is-edit="isEdit"
                :can-edit-owner="canEditOwnerData"
                :autoRefresh="autoRefresh"
                :form-attr="formAttr"
                :label-position="labelPosition"
                :label-width="labelWidth"
                :isNotPersonaccount="isNotPersonaccount"
                :columnNum="detailLayoutMode === 'mode1' ? columnNum : 2"
                :oneColumnWidth="
                  detailLayoutMode === 'mode1' ? oneColumnWidth : '100%'
                "
                :twoColumnWidth="
                  detailLayoutMode === 'mode1' ? twoColumnWidth : '50%'
                "
                :status-icon="statusIcon"
                :objectApi="objectApiData"
                @changeSelect="changeSelect"
                @control="control"
                @lookupValue="lookupValue"
                @handleAction="handleAction"
                @saveEditableCell="saveEditableCell"
                @changeOwner="changeOwner"
                @editCloudcctag="editCloudcctag"
                @showEditableCell="showEditableCell"
                @positionEditableCell="positionEditableCell"
                @changeRecordtype="changeRecordtype"
                @ruleProcessing="ruleProcessing"
                @changeAddress="changeAddress"
              ></info-form>
              <!-- 报表图表 -->
              <div v-for="(item, index) in chartsListData" :key="index">
                <ReportChart
                  v-if="
                    isBottom == false &&
                    isReport == false &&
                    isCharts == true &&
                    chartsListData.length > 0
                  "
                  ref="reportChartRef"
                  :chartsData="item"
                  :id="id"
                >
                </ReportChart>
              </div>

              <!--quickbooks 组件-->
              <quickBooks
                v-if="isCheckQuick"
                :bind="bind"
                :userId="userId"
                :username="acconame"
                :isMenue="prefixData"
              ></quickBooks>

              <!-- 知识库版本 -->
              <Articleversion
                v-if="isBottom == false && isshow == true"
                :id="id"
              ></Articleversion>
              <!-- 个案Knowledge -->
              <Knowledgesearch
                ref="KnowledgesearchRef"
                v-if="
                  isBottom == false &&
                  isshow == false &&
                  (iscase == true || isWorkOrder == true)
                "
                :caseArticleId="id"
                @blockRefresh="blockRefresh"
              ></Knowledgesearch>

              <div
                v-if="!loadingDetail && editableCell"
                class="changeDetailBox"
              >
                <!-- 编辑状态的表单填写 -->
                <base-form
                  ref="baseForm"
                  class="baseForm"
                  :dependContainer="dependContainer"
                  :id="id"
                  :layoutId="layoutid"
                  :relatedlistIds="relatedlistIds"
                  :detail="true"
                  :option-list="optionList"
                  :form-attr="formAttr"
                  :special-objectapi="objectApiData"
                  :columnNum="columnNum"
                  :oneColumnWidth="oneColumnWidth"
                  :twoColumnWidth="twoColumnWidth"
                  :status-icon="statusIcon"
                  :autoRefresh="autoRefresh"
                  :objectApi="objectApiData"
                  :prefix="prefixData"
                  operationType="EDIT"
                  @addFieldOption="addFieldOption"
                  @addFile="addFile"
                  @removeFile="removeFile"
                  @remoteMethod="remoteMethod"
                  @changeSelect="changeSelect"
                  @control="control"
                  @lookupValue="lookupValue"
                  @handleAction="handleAction"
                  @changeCurrencyEdit="changeCurrencyEdit"
                  @newSearchableField="newSearchableField"
                  @ruleProcessing="ruleProcessing"
                  @getCity="getCity"
                  @getCounty="getCounty"
                  @changeEventEmitToParent="changeEventEmitToParent"
                ></base-form>
              </div>

              <!-- <div v-if="objId === 'lead' || objId === '个案'"
                      class="groupTitle">
                    <p>可选</p>
                    <el-checkbox v-model="validRule">
                      使用有效的分配规则进行分配
                    </el-checkbox>
              </div>-->
            </div>
          </transition>
          <!-- </el-tab-pane> -->
          <!-- </el-tabs> -->
        </el-card>
      </div>

      <!-- 商情发现模块 -->
      <Business></Business>

      <!-- 股权穿透图 -->
      <div v-for="each in list" :key="each.relatedlistIds">
        <el-card
          class="box-main_main"
          :id="each.relatedlistId"
          shadow="never"
          v-if="
            objId === 'account' && each.relatedlistType === 'stock' && !isCharts
          "
        >
          <EquityPenetrationRelationList
            ref="equityPenetrationRelationList"
            :objectName="tabNameData"
            :isEdit="isEdit"
          />
        </el-card>
      </div>
      <!--      海报列表-->
      <!--      勿删-->

      <!-- 相关列表 -->
      <div
        v-for="each in list"
        :key="each.relatedlistId"
        v-loading="each.loading"
      >
        <el-card
          class="box-main_main"
          :id="each.relatedlistId"
          shadow="never"
          v-if="each.relatedlistType !== 'stock' && !isCharts"
        >
          <relation-list-obj
            ref="relationListObj"
            :id="id"
            :obj="each"
            :bigTitle="bigTitle"
            :tabName="tabNameData"
            :objId="objId"
            :detailLayoutMode="detailLayoutMode"
            :isRlation="isRlation"
            :typedisabled="typedisabled"
            :isEdit="isEditDetail"
            :isLocked="isLocked"
            :isispermissionUpload="isispermissionUpload"
            :orgchartData="orgchartData"
            :viewId="$route.query.viewId"
            :isNotPersonaccount="isNotPersonaccount"
            :attendanceStaffData="attendanceStaffData"
            @historyBtn="showHistoryBtn"
            @offeres="offeres"
            @getRelationListButton="getRelationListButton"
            @buttonFun="buttonFun"
            @detailBtn="detailBtn"
            @everyEdit="everyEdit"
            @everyDelet="everyDelet"
            @getPricebook="getPricebook"
            @editPriceItem="editPriceItem"
            @deletPriceItem="deletPriceItem"
            @seeMove="seeMove"
            @SaveDropboxs="SaveDropboxs"
            @dropboxState="dropboxState"
            @editobjClick="editobjClick"
            @blockRefresh="blockRefresh"
            @searchRefresh="searchRefresh"
            @accountNumberToList="accountNumberToList"
            @changedefaultaddress="changedefaultaddress"
            @handleChangeKaoqin="handleChangeKaoqin"
            @setRelationSize="setRelationSize"
          ></relation-list-obj>
        </el-card>
      </div>

      <!-- 服务评价 联系人和客户对象下  wj   勿删勿删勿删-->
      <!-- <serviceEvaluation></serviceEvaluation> -->

      <marketingPostersList
        id="posters"
        v-if="objectApiData === 'campaign' && postershow"
      >
      </marketingPostersList>

      <maketingSocialList
        id="social"
        v-if="objectApiData === 'campaign' && socialshow"
      ></maketingSocialList>

      <maketingInvitation
        id="invitation"
        v-if="objectApiData === 'campaign' && invitshow"
      ></maketingInvitation>

      <!--      营销渠道列表-->
      <!--      勿删-->
      <marketingChannelList
        id="chnnel"
        v-if="objectApiData === 'campaign' && chnnelshow"
      >
      </marketingChannelList>

      <!-- 更改记录类型 -->
      <el-dialog
        :title="recordTypeDialogAttr.title"
        :width="recordTypeDialogAttr.dialogWidth"
        :visible.sync="recordTypeDialogAttr.isShow"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        :before-close="cancelRecordTypeDialog"
        top="15%"
      >
        <div v-if="recordOptions.length >= 1" style="margin-left: 15px">
          <!-- 选择记录类型 -->
          <span>{{ $t("label_tabpage_selrecordtypez") }}：</span>
          <!-- 请选择 -->
          <el-select
            v-model="recordType"
            filterable
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="item in recordOptions"
              :key="item.val"
              :label="item.key"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <div
          slot="footer"
          class="dialog-footer"
          style="text-align: right; margin-top: 10px"
        >
          <el-button size="mini" @click="cancelRecordTypeDialog">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}
          </el-button>
          <el-button size="mini" type="primary" @click="next">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </div>
      </el-dialog>

      <!-- 更改所有人 -->
      <transfer-owner-dialog
        :id="id"
        :objId="objId"
        ref="transferClientOwner"
        :transfer-deault="transferDeault"
        :owner-type-list="ownerTypeList"
        :owner-raletion-list="ownerRaletionList"
        :transfer-client-owner-dialog="transferClientOwnerDialog"
        @closeTransferOwnerDialog="closeTransferOwnerDialog"
        @saveTransferOwner="saveTransferOwner"
      ></transfer-owner-dialog>
      <!-- 展示选择地址弹框 -->
      <selectAccountAddress
        :showAddressDialog="showAddressDialog"
        :addressArr="addressArr"
        :name="accountName"
        @closedialog="closeAddressdialog"
        @addAdderss="addAdderss"
        @edit="editAddress"
        @change="changeAccountAddress"
      ></selectAccountAddress>
      <!-- 查找/查找多选 -->
      <!-- 搜索 -->
      <el-dialog
        :title="$t('label.quickbooks.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelectOwner"
        />
      </el-dialog>
      <!-- 考勤人员 -->
      <el-dialog
        :title="$t('label.quickbooks.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        :visible.sync="KaoqinDialog"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <search-table
          v-if="KaoqinDialog"
          v-loading="ChangeKaoqinLoading"
          ref="searchUserTable"
          :fieldId="fieldId"
          :checked="true"
          :isShowClearBtn="false"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          :optionCheckedArr="attendanceStaffDataCopy"
          @changeSelect="handleChangeKaoqin"
        />
      </el-dialog>

      <!--价格手册弹窗-->
      <!-- 新建价格手册条目 -->
      <el-dialog
        :title="$t('vue_label_pricebook_entry')"
        ref="priceItem"
        :visible.sync="pricebookdialogVisible"
        width="800px"
        :close-on-click-modal="false"
        class="diastyle"
      >
        <div style="font-size: 14px; display: flex" v-loading="loading">
          <div class="form">
            <el-form
              :rules="manualFormRules"
              ref="manualForm"
              :model="manualForm"
              label-position="left"
              label-width="110px"
            >
              <!-- 产品 -->
              <el-form-item :label="$t('label.product')" prop="product">
                <el-input
                  size="mini"
                  disabled
                  clearable
                  v-model="manualForm.product"
                  :placeholder="$t('label_tabpage_contentz')"
                ></el-input>
              </el-form-item>
              <!-- 价格手册 -->
              <el-form-item
                :label="$t('lable.opppdt.selPbk.pricebook')"
                prop="pricebooklist"
              >
                <el-input
                  :placeholder="$t('label_tabpage_contentz')"
                  v-model="manualForm.pricebooklist"
                  disabled
                  clearable
                  class="height_s"
                ></el-input>
              </el-form-item>
              <!-- 价目表价格 -->
              <el-form-item :label="$t('lable.product.plist')" prop="input8">
                <el-input
                  size="mini"
                  :placeholder="$t('label_tabpage_contentz')"
                  class="standardprice"
                  v-model="manualForm.input8"
                  @input="numbers"
                ></el-input>
              </el-form-item>
              <!-- 币种 -->
              <el-form-item :label="$t('label.currency')" prop="value8">
                <el-select
                  v-model="manualForm.value8"
                  filterable
                  clearable
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                  size="mini"
                >
                  <el-option
                    v-for="item in currencyList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div style="flex: 0.8">
            <div class="checkbox_style">
              <p>
                <el-checkbox v-model="checked3">
                  {{ $t("label.inusing") }}
                </el-checkbox>
              </p>
              <p>
                {{ $t("label.product.code") }}
                <span style="margin-left: 45px">{{ manualForm.cpdm }}</span>
              </p>
              <!-- 使用标准价格 -->
              <p>
                <el-checkbox v-model="checked6" disabled>
                  {{ $t("lable.product.pricebooks.ustand") }}
                </el-checkbox>
              </p>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closepricebookdialogVisible">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}
          </el-button>
          <el-button @click="addxinjian" style="width: 100px">
            <!-- 保存并新建 -->
            {{ $t("label.saveandnew") }}
          </el-button>
          <el-button type="primary" @click="savepricebook">
            <!-- 保存 -->
            {{ $t("label.save") }}
          </el-button>
        </span>
      </el-dialog>

      <!--编辑价格手册条目-->
      <el-dialog
        :title="$t('vue_label_pricebook_editdetail')"
        ref="priceItem"
        :visible.sync="editPricebookdialogVisible"
        width="800px"
        :close-on-click-modal="false"
        class="diastyle"
      >
        <div style="font-size: 14px; display: flex">
          <div class="form">
            <el-form
              :rules="editmanualFormRules"
              ref="editmanualForm"
              :model="itemInfo"
              label-position="left"
              label-width="110px"
            >
              <!-- 产品 -->
              <el-form-item :label="$t('label.product')" prop="productname">
                <el-input
                  size="mini"
                  disabled
                  clearable
                  v-model="itemInfo.productname"
                  :placeholder="$t('label_tabpage_contentz')"
                ></el-input>
              </el-form-item>
              <!-- 价格手册 -->
              <el-form-item
                :label="$t('lable.opppdt.selPbk.pricebook')"
                prop="pricebookname"
              >
                <el-autocomplete
                  size="mini"
                  class="inline-input"
                  v-model="itemInfo.pricebookname"
                  :fetch-suggestions="querySearch"
                  :placeholder="$t('label_tabpage_contentz')"
                  @select="handleSelect"
                  disabled
                  clearable
                ></el-autocomplete>
              </el-form-item>
              <!-- 价目表价格 -->
              <el-form-item
                :label="$t('lable.product.plist')"
                prop="standardprice"
              >
                <el-input
                  size="mini"
                  :placeholder="$t('label_tabpage_contentz')"
                  class="standardprice"
                  v-model="itemInfo.standardprice"
                  @input="numberes"
                  :disabled="disabled"
                ></el-input>
              </el-form-item>
              <!-- 币种 -->
              <el-form-item :label="$t('label.currency')">
                <el-input
                  size="mini"
                  :placeholder="$t('label_tabpage_contentz')"
                  v-model="itemInfo.currency"
                  disabled
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div style="flex: 0.8">
            <div class="checkbox_style">
              <p>
                <el-checkbox v-model="itemInfo.isenable">{{
                  $t("label.inusing")
                }}</el-checkbox>
              </p>
              <p
                class="two_p"
                v-if="
                  itemInfo.pricebookid !== '01s00000000000000001' &&
                  pricecurrencyList &&
                  pricecurrencyList.length > 0
                "
              >
                <span>{{ $t("lable.product.pricebooks.ustand") }}</span>
                <span class="checkboxs">
                  <el-checkbox-group
                    style="display: inline-flex; flex-direction: column"
                    v-model="checked5"
                    :min="0"
                    :max="1"
                  >
                    <el-checkbox
                      v-for="item in pricecurrencyList"
                      :label="item"
                      :key="item.currency"
                      @change="editChangestd"
                      >{{ item.currency }} {{ item.listPrice }}</el-checkbox
                    >
                  </el-checkbox-group>
                </span>
              </p>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closepricebookdialogVisible">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}
          </el-button>
          <el-button type="primary" @click="saveeditpricebook">
            <!-- 保存 -->
            {{ $t("label.save") }}
          </el-button>
        </span>
      </el-dialog>

      <!--添加到价格手册-->
      <el-dialog
        :title="$t('lable.product.pricebooks.add')"
        ref="priceItem"
        :visible.sync="addPricebookdialogVisible"
        width="800px"
        :close-on-click-modal="false"
        class="diastyle add_diastyle"
        :before-close="beforeClose"
      >
        <div style="font-size: 14px; display: flex">
          <div class="form">
            <el-form
              :rules="addmanualFormRules"
              ref="addmanualForm"
              :model="addmanualForm"
              label-position="left"
              label-width="110px"
            >
              <!-- 产品 -->
              <el-form-item :label="$t('label.product')" prop="name">
                <el-input
                  size="mini"
                  disabled
                  clearable
                  v-model="addmanualForm.name"
                  :placeholder="$t('label_tabpage_contentz')"
                ></el-input>
              </el-form-item>
              <!-- 价格手册 -->
              <el-form-item
                :label="$t('lable.opppdt.selPbk.pricebook')"
                prop="state2"
              >
                <el-select
                  v-model="addmanualForm.state2"
                  filterable
                  clearable
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                  size="mini"
                  @change="getJiaGe"
                >
                  <el-option
                    v-for="item in customPrice"
                    :key="item.pricebooklistid"
                    :label="item.value"
                    :value="item.pricebooklistid"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- 价目表价格 -->
              <el-form-item :label="$t('lable.product.plist')" prop="bookprice">
                <el-input
                  size="mini"
                  :placeholder="$t('label_tabpage_contentz')"
                  class="standardprice"
                  v-model="addmanualForm.bookprice"
                  @input="numberesTwo"
                  :disabled="bookpriceDisabled"
                ></el-input>
              </el-form-item>
              <!-- 币种 -->
              <el-form-item :label="$t('label.currency')" prop="pricecurrency">
                <el-select
                  v-model="addmanualForm.pricecurrency"
                  filterable
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                  size="mini"
                >
                  <el-option
                    v-for="item in currencyOption"
                    :key="item.currency"
                    :label="item.currency"
                    :value="item.currency"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div style="flex: 0.8">
            <div class="checkbox_style">
              <p>
                <!-- 已启用 -->
                <el-checkbox v-model="checked4">
                  {{ $t("label.inusing") }}
                </el-checkbox>
              </p>
              <!-- 产品代码 -->
              <p>
                {{ $t("label.product.code") }}
                <span style="margin-left: 45px">{{ addmanualForm.cpdm }}</span>
              </p>
              <p class="product_code">
                <!-- 使用标准价格 -->
                <span class="tit_label">{{
                  $t("lable.product.pricebooks.ustand")
                }}</span>
                <span class="checkbox">
                  <el-checkbox-group
                    style="display: inline-flex; flex-direction: column"
                    v-model="checked5"
                    :min="0"
                    :max="1"
                  >
                    <el-checkbox
                      v-for="item in pricecurrencyList"
                      :label="item"
                      :key="item.currency"
                      @change="changestd"
                      >{{ item.currency }} {{ item.listPrice }}</el-checkbox
                    >
                  </el-checkbox-group>
                </span>
              </p>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closepricebookdialogVisible">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}
          </el-button>
          <el-button type="primary" v-preventReClick @click="savepricebooklist">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>

      <!-- 添加市场活动成员 -->
      <add-campaign-members
        :show-add-campaign-members="showAddCampaignMembers"
        :big-title="bigTitle"
        @closeAddCampaignMembersDialog="closeAddCampaignMembersDialog"
      ></add-campaign-members>

      <!-- 更改记录类型后编辑记录 -->
      <create-edit-obj
        ref="editObj"
        :prefix="prefixData"
        :id="id"
        :object-api="objectApiData"
        :object-name="tabNameData"
        @save="save"
      ></create-edit-obj>
      <!-- 详情页复制/编辑相关列表记录 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="listPrefix"
        :id="dialogNewAttr.id"
        :objectApi="listObjectApi"
        :relation-field-id="relatedListObj.relationFieldId"
        :object-name="relatedListObj.objLabel"
        type="active"
        :relative-record-id="id"
        :realObjId="listObjectId"
        @editobjClick="editobjClick"
        @save="save"
      ></create-edit-obj>
      <!-- 订单发票详情页新建客户地址 -->
      <create-edit-obj
        ref="creatAddress"
        :prefix="'045'"
        :id="dialogNewAttr.id"
        :objectApi="'cloudccustomeraddress'"
        :relation-field-id="'ffe328customeradd012'"
        :object-name="$t('label.mr.title.customerAddress')"
        type="active"
        :relative-record-id="accountId"
        :realObjId="listObjectId"
        :orderInvoiceId="id"
        :orderInvoiceApi="objectApiData"
        @editobjClick="editobjClick"
        @save="save"
      ></create-edit-obj>
      <!--批量新增-->
      <el-dialog
        :visible.sync="batchAddDialog"
        top="10%"
        :title="batchAddDialogTitle"
        width="90%"
        :before-close="closeBatchAddDialog"
      >
        <batchAdd
          ref="batchAdd"
          :prefix="listPrefix"
          :objectApi="listObjectApi"
          :recordId="relatedListObj.recordId"
          :relation-field-id="relatedListObj.relationFieldId"
          :relatedListFlag="relatedListObj.relatedListFlag"
          @closeBatchAddDialog="closeBatchAddDialog"
          @getBatchLoading="getBatchLoading"
          :pageFlag="pageFlag"
        />
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button size="mini" @click="closeBatchAddDialog('cancel')">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="saveBatchAdd"
            :loading="isBatchLoading"
          >
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </div>
      </el-dialog>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('component_setup_tabs_label_delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <span style="font-size: 14px; color: #080707">
          <!-- 确认删除该条数据吗？ -->
          <!-- {{ $t("label.weixin.confirm.delete") }} -->
          {{ delContent }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}
          </el-button>
          <el-button @click="confirmDel" type="primary" size="mini">
            <!-- 删除 -->
            {{ $t("component_setup_tabs_label_delete") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 业务机会产品、联系人角色、业务机会小组弹窗 -->
      <pop-upteam
        :popTableVisible="popTableVisible"
        :manualVisible="manualVisible"
        :addmanualVisible="addmanualVisible"
        :recordId="id"
        :listData="listData"
        :pricebook2idMainProp="pricebook2idMainProp"
        :opportunityId="opportunityId"
        :productsVisible="productsVisible"
        :contactsVisible="contactsVisible"
        :addContact="addContact"
        :noDataTip="popNoDataTip"
        :DeletName="DeletName"
        :prefix="prefixData"
        :relatedListObj="relatedListObj"
        @successFun="successFun"
        @popTableClose="popTableClose"
        @manualClose="manualClose"
        @addmanualClose="addmanualClose"
        @contactsClose="contactsClose"
        @addmanualChange="addmanualChange"
        @productsChange="productsChange"
        @addContactClose="addContactClose"
        @confirmAddProduct="confirmAddProduct"
        @contactsVisibleOpen="contactsVisibleOpen"
        ref="popUpteam"
      />
      <!--报价单相关的弹窗-->
      <Quotation
        :manualVisible="singleManualVisible"
        :addmanualVisible="singleAddmanualVisible"
        :productsVisible="singleProductsVisible"
        :noDataTip="singleNoDataTip"
        :prefix="prefixData"
        @successFun="singleSuccessFun"
        @manualClose="singleManualClose"
        @addmanualClose="singleAddmanualClose"
        @singleManualChange="singleManualChange"
        @addmanualChange="singleAddmanualChange"
        @productsChange="singleProductsChange"
        ref="quotation"
      />
      <!-- 本地文件字段上传，支持一次上传多张 -->
      <el-dialog
        :title="$t('label.file.library.selectFile2Lib')"
        :visible.sync="batchAddFilesVisible"
        :lock-scroll="true"
        :close-on-click-modal="false"
        @before-close="closeLocalField"
        append-to-body
        destroy-on-close
      >
        <batch-add-files
          ref="batchaddfiles"
          :isUpload.sync="isUpload"
          :originLimit="
            addFieldInfo.length && Number(addFieldInfo.length)
              ? Number(addFieldInfo.length)
              : 10
          "
          @save="batchaddfilesSave"
        >
        </batch-add-files>
        <span slot="footer">
          <el-button size="mini" @click="batchAddFilesVisible = false">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            @click="localAddFile"
            :disabled="isUpload"
            type="primary"
            size="mini"
          >
            <!-- 附加 -->
            {{ $t("label.file.attached") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 添加文件 -->
      <add-files
        ref="addFile"
        :isFromFileCenter="false"
        :dialogAddVisible="addFileDialogVisible"
        @addSubmit="addFileSubmit"
        @close="addFileClose"
      ></add-files>

      <!-- 相关列表添加文件 -->
      <add-files
        ref="atoLib"
        :isFromFileCenter="false"
        :dialogAddVisible="dialogAddVisible"
        @addSubmit="addWorkSubmit"
        @close="addWorkClose"
      ></add-files>
    </div>

    <!-- 关注的相关列表(关注者,正在关注,小组) -->
    <template>
      <detailsTheListOf
        v-if="!isShowType"
        :followersMyData="followersMyData"
        :followersMyCount="followersMyCount"
        :myfollowersData="myfollowersData"
        :myfollowerCount="myfollowerCount"
        :myGroupCount="myGroupCount"
        :myGroupData="myGroupData"
      ></detailsTheListOf>
    </template>

    <!-- 批量发送邮件 -->
    <batch-send-email></batch-send-email>

    <!-- 群发短信弹框-->
    <shortMessageDialog
      :dialogTableVisible="dialogTableVisible"
      @confirmationMessage="confirmationMessage"
      @cancelMssage="cancelMssage"
    ></shortMessageDialog>

    <!-- 类似业务机会弹窗 -->
    <opportunities ref="opportunities"></opportunities>

    <!--表单营销推广新建编辑-->
    <formMarketingPromotion
      ref="formMarketingPromotion"
      :id="id"
      :listId="listId"
      :relation-field-id="dialogNewAttr.id"
      @close="closeFormMarketingPromotion"
    >
    </formMarketingPromotion>

    <!-- 业务机会/报价单/订单/发票/采购订单内联编辑切换价格手册提示 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      width="40%"
      top="5%"
      :visible.sync="pricIntro"
      append-to-body
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 更改已选取的价格手册，将删除全部现有的产品。是否更改为新的价格手册？ -->
        {{ $t("c731") }}
      </div>
      <span slot="footer">
        <el-button @click="cancelPricChange" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmPricChange" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 业务机会/报价单/订单/发票/采购订单内联编辑切换价格手册提示 -->
    <el-dialog
      :title="$t('c1078')"
      width="60%"
      top="5%"
      :visible.sync="caseTeamDialog"
      append-to-body
    >
      <div>
        <!-- 更新个案团队成员弹窗 by  -->
        <updateCaseTeam ref="updateCaseTeam"></updateCaseTeam>
      </div>
      <span slot="footer">
        <el-button @click="caseTeamDialog = false" size="mini">
          {{ $t("label.cancel") }}
        </el-button>
        <el-button type="primary" size="mini" @click="handleSaveCaseTeam">
          {{ $t("label.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Bus from "@/mixin/bus.js";
import * as Emailobject from "../api.js";
import {
  queryFollows, //关注列表相关数据
  queryUserGroups, //我的组（包含我创建的组和我加入的组）
  getApprovalHistoryList,
} from "./api.js";
import { consoleSaveTab } from "@/views/home/api.js";
import {
  InfoForm,
  BaseForm,
  AddFiles,
  TransferOwnerDialog,
  BatchAddFiles,
} from "@/components/index";
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import batchSendEmail from "@/views/Email/batchSendEmail.vue";
import shortMessageDialog from "@/views/noteSms/components/shortMessageDialog"; //群发短信弹框
import * as CommonObjApi from "./api";
import INPUTTYPE from "@/config/enumCode/inputType";
import {
  Bind,
  deleteContact,
  getUserSetup,
  getSimilarOpportunityDeleteBookmarksOppDetails,
  quickbookStart,
  getSystemPermission,
  masterRecordDetailInfo,
} from "./api";
import PopUpteam from "@/views/commonObjects/detailChild/Group/popUpTeam.vue";
import Quotation from "@/views/commonObjects/detailChild/Group/quotation.vue";
import Business from "./Business discovery/index";
import RelationListObj from "@/views/commonObjects/components/relation-list-obj.vue";
import EquityPenetrationRelationList from "@/views/commonObjects/components/EquityPenetrationRelationList.vue";
import createEditObj from "./../components/create-edit-obj.vue";
import addCampaignMembers from "./../components/add-campaign-members.vue";
import suspensionTabel from "@/views/commonObjects/detailChild/Group/suspensionTabel.vue";
import VueCookies from "vue-cookies";
import detailsTheListOf from "@/views/colleagueCircle/components/details/DetailsTheListOf.vue";
import opportunities from "@/components/Opportunities/index";
import detailSendCard from "@/utils/robotMessage.js";
import batchAdd from "@/components/ButtonWrapper/batchAdd";
import * as types from "@/store/mutations-types.js";
import quickBooks from "./quickbooks/quickBooks"; //引入QuickBooks组件
import Articleversion from "@/components/Articleversion/index.vue";
import marketingChannelList from "@/views/marketList/marketingChannel/marketingChannelList";
import marketingPostersList from "@/views/marketList/marketingPosters/marketingPostersList.vue";
import maketingSocialList from "@/views/marketList/marketingSocial/marketingSocialList.vue";
import maketingInvitation from "@/views/marketList/marketingInvitation/marketingInvitation.vue";
import Knowledgesearch from "@/components/knowledgesearch/Knowledgesearch.vue";
import ReportChart from "@/views/commonObjects/components/ReportChart.vue";
import selectAccountAddress from "@/components/SelectAddress/selectAccountAddress.vue"; //选择客户地址弹框
import formMarketingPromotion from "@/views/commonObjects/formMarketingPromotion/index.vue";
import * as FormMarkObjApi from "@/views/commonObjects/formMarketingPromotion/api.js";
import updateCaseTeam from "./CaseTeam/updateCaseTeam.vue";
import {
  listClientScript,
  myOnLoad,
  showDom,
} from "@/utils/ccdk/clientScript.js";
// 字段依赖性的混入文件
import dependFieldMixins from "@/views/commonObjects/components/dependField.vue";
export default {
  name: "ListChild",
  mixins: [TransferOwnerMixin, dependFieldMixins],
  components: {
    maketingInvitation,
    // marketingRoiList,
    // marketingFlowList,
    // marketingLandPageList,
    // maketingMailList,
    maketingSocialList,
    marketingPostersList,
    InfoForm,
    BaseForm,
    PopUpteam,
    Quotation,
    AddFiles,
    BatchAddFiles,
    addCampaignMembers,
    suspensionTabel,
    RelationListObj,
    createEditObj,
    detailsTheListOf,
    // serviceEvaluation,
    TransferOwnerDialog,
    batchSendEmail,
    shortMessageDialog,
    opportunities,
    Business,
    batchAdd,
    EquityPenetrationRelationList,
    quickBooks,
    marketingChannelList,
    Articleversion,
    Knowledgesearch,
    ReportChart,
    selectAccountAddress, //更改地址
    formMarketingPromotion,
    updateCaseTeam,
  },
  props: {
    detailLayoutMode: {
      type: String,
      default: "mode1",
    },
    isShowType: {
      type: Boolean,
      default: false,
    },
    detailGroupId: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
    fieldValue: {
      type: String,
      default: "",
    },
    bigTitle: {
      type: String,
      default: "",
    },
    tabName: {
      type: String,
      default: "",
    },
    isTag: {
      type: String,
      default: "",
    },
    typedisabled: {
      type: String,
      default: "",
    },
    objectApi: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    isEditDetail: {
      type: Boolean,
      default: false,
    },
    // 记录是否锁定
    isLocked: {
      type: Boolean,
      default: false,
    },
    // 更改所有人权限
    canEditOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 存放地址字段子级数组
      addrArr: [],
      // 页面id，快速新建时使用，方便回显新建后的数据
      quickCreatId: Date.now() + "",
      // 添加文件时文件字段信息
      addFieldInfo: {},
      // 是否正在上传过程中
      isUpload: false,
      // 本地文件上传dialog
      batchAddFilesVisible: false,
      // 业务机会产品相关列表数据
      listData: [],
      // 业务机会主记录价格手册id
      pricebook2idMainProp: "",
      // 添加到价格手册--价目表价格
      bookpriceDisabled: false,
      // 是否是二次删除
      isConfirmDel: "",
      // 删除弹窗文案
      delContent: this.$i18n.t("label.weixin.confirm.delete"),
      relatedlistIds: "",
      layoutid: "",
      orgchartData: {}, //详情页关系网数据
      accountNumberList: 0, //客户下的 联系人服务评价数量
      detailMode: "",
      columnNum: 2,
      oneColumnWidth: "100%",
      twoColumnWidth: "50%",
      relationNamePercent: "25%",
      relationNameWidth: "180px",
      inlineedit: false, // 全局内联编辑权限
      detailSkeletonOption: [
        {
          type: "line",
          options: {
            active: true,
            row: 2,
            column: 2,
            width: "30vw",
            height: "20px",
            justifyContent: "flex-start",
          },
        },
      ],
      personsName: "",
      usernameId: "",
      followersMyData: [], //追随我的数据--关注者
      followersMyCount: 1,
      myfollowersData: [], //我追随的数据--正在关注
      myfollowerCount: 1, //数量
      myGroupCount: 1, //小组数量
      myGroupData: [], //小组详细数据
      disabled: false,
      bookprice: "",
      pricebookid: "",
      currency: "",
      addmanualForm: {
        name: "",
        state2: "",
        bookprice: "",
        cpdm: "",
        pricecurrency: "",
      },
      addPricebookdialogVisible: false,
      itemid: "",
      state1: "",
      state2: "",
      itemInfo: {
        standardprice: "",
        productname: "",
      },
      customPrice: [],
      editPricebooklist: [],
      editPriceData: [],
      editPricebookdialogVisible: false,
      count: "",
      pricebooklistid: "",
      isenable: false,
      currencyList: [], // 标准价格手册币种列表
      pricecurrencyList: [], //价格手册需要币种列表
      manualForm: {
        product: "",
        pricebooklist: "",
        input8: "",
        cpdm: "",
        value8: "",
      },
      // 添加标准价格弹框loading
      loading: false,
      productId: "",
      checked5: [],
      checked4: true,
      checked3: true,
      checked6: false,
      checked1: "",
      options: [],
      input10: "",
      input9: "",
      pricebookdialogVisible: false,
      validRule: true,
      // controlContainer: null, //依赖关系
      actionRequireds: [], // 动态布局必填字段
      actionReadonlys: [], // 动态布局只读字段
      actionShowFields: [], // 动态布局显示字段
      actionShowSections: [], // 动态布局显示部分
      showDetailForm: true,
      buttonLength: 0, //按钮长度
      //附件归档
      dialogFormVisible: false,
      dialogAddVisible: false,
      addFileDialogVisible: false,
      formLabelWidth: "120px",
      activeName: "first",
      enclosure: "",
      folder: "",
      checkBox: "",
      layoutId: "", // 动态布局id
      relatedlistId: "",
      list: [], //相关列表数组
      //大数组
      objArr: [],
      relatedList: [],
      showBtn: false,
      id: this.$route.params.id || this.dataId, // 记录id
      objId: "",
      labelPosition: "top",
      editableCell: false,
      formAttr: null,
      labelWidth: 180,
      statusIcon: true,
      autoRefresh: false,
      //表格
      showKanban: false,
      tableHeight: "100",
      tableAttr: null,
      pageObj: {
        dataList: [],
      },
      noDataTip: false,
      currentIdc: "",
      type: this.$route.params.type,
      viewId: this.$route.query.viewId,
      fieldval: this.fieldValue,
      recordTypeDialogAttr: {
        // "选择记录类型"
        title: this.$i18n.t("label.recordtypeselect"),
        isShow: false,
        dialogWidth: "30%",
      },
      dialogNewAttr: {
        // 新建编辑
        title: "",
        type: "NEW",
        isShow: false,
        dialogWidth: "85%",
        id: "",
      },
      relatedArr: {},
      listObjectApi: "",
      listObjectId: "",
      listId: "", //相关列表id
      listPrefix: "",
      recordType: "",
      recordOptions: [],
      newid: "",
      showBaseForm: false,
      dialogVisible: false, // 删除
      //业务机会小组
      popTableVisible: false, //管理小组
      manualVisible: false, //选择价格手册
      addmanualVisible: false, //添加产品
      productsVisible: false, //编辑全部
      contactsVisible: false, //编辑业务机会联系人
      opportunityId: "",
      loadingDetail: true,
      seeMoveFlag: false, //查看更多
      //以下是报价单的添加产品及价格手册
      singleManualVisible: false,
      singleAddmanualVisible: false,
      singleProductsVisible: false,
      popNoDataTip: false, // 是否提示无更多数据
      singleNoDataTip: false,
      singlePageSize: 3,
      singlePage: 1,
      pageSize: 3,
      page: 1,
      transferClientOwnerDialog: false,
      optionList: {},
      ownerName: "",
      ownerId: "",
      isSendMail: false,
      checkList: [],
      ownerRaletionList: this.optional,
      optional: [
        {
          label: "转移其他人所有未处理业务机会",
          value: "otherUntreatedOpportunity",
        },
        {
          label: "转移客户所有人的已结束业务机会",
          value: "processedOpportunity",
        },
        {
          label: "转移客户所有人的全部个案",
          value: "ffe201100005108mj4GB",
        },
        {
          label: "转移客户所有人的全部发票",
          value: "ffe20181108invoice04",
        },
        {
          label: "转移客户所有人的全部报价单",
          value: "ffe201100006202iYyrO",
        },
      ],
      showSearchTable: false,
      // 考勤人员弹窗
      KaoqinDialog: false,
      fieldId: "",
      checked: false,
      relevantObjId: "",
      relevantPrefix: "",
      showAddCampaignMembers: false,
      //相关列表新建编辑
      relatedPrefix: "",
      data: {},
      bigArr: [],
      showDetail: false,
      operation: "DETAIL",
      headerBtn: [], // 传回父组件的审批按钮
      isSuspension: false,
      itemArr: {},
      pricebookArr: [],
      isEdit: null,
      isQuery: null,
      changeDetailBoxHeight: window.screen.height - 498,
      titOffsetTop: 0,
      listObjModifyAll: false,
      listObjModify: false,
      listObjDelete: false,
      openDelay: 500,
      newObjLevel: 0, // 新建查找字段标识
      fieldProp: "",
      visibleArrow: true,
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      systemManager: false,
      relatedListIndex: null, // 相关列表下标
      relatedListObj: {}, //按点击钮每个菜单数据
      ownerType: "",
      listFlag: false,
      listLoading: true,
      // 短信弹框的显示隐藏
      dialogTableVisible: false,
      relationListSkeletonOption: [
        // 列表
        {
          type: "list",
          options: {
            row: 3,
            column: 2,
            width: "30.5vw",
            height: "20px",
            backgroundColor: "#f6f6f6",
            justifyContent: "center",
            active: false,
            firstWidth: "30.5vw",
          },
        },
      ],
      isSkeleton: true,
      historyData: [],
      DeletName: "",
      Deletpricebook: [],
      multiScreen: false, //判断是否在分屏页面下
      //批量新增
      batchAddDialog: false,
      isBatchLoading: false,
      batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
        objName: this.tabNameData,
      }),
      pageFlag: "",
      //新建价格手册验证
      manualFormRules: {
        product: [
          //请输入产品名称
          {
            required: true,
            message: this.$i18n.t("vue_label_pricebook_enterproductname"),
            trigger: "blur",
          },
        ], //'请输入价格手册名称'
        pricebooklist: [
          {
            required: true,
            message: this.$i18n.t("vue_label_pricebook_enterbookname"),
            trigger: "change",
          },
        ], //请输入价目表价格
        input8: [
          {
            required: true,
            message: this.$i18n.t("label_please_input_price_list_price"),
            trigger: "blur",
          },
        ], //请输入币种
        value8: [
          {
            required: true,
            message: this.$i18n.t("label_please_enter_currency"),
            trigger: "change",
          },
        ],
      },
      //添加价格手册验证
      addmanualFormRules: {
        name: [
          {
            required: true,
            message: this.$i18n.t("vue_label_pricebook_enterproductname"),
            trigger: "blur",
          },
        ],
        state2: [
          //'请输入价格手册名称'
          {
            required: true,
            message: this.$i18n.t("vue_label_pricebook_enterbookname"),
            trigger: "change",
          },
        ],
        pricecurrency: [
          // 请选择币种
          {
            required: true,
            message: this.$i18n.t("label.cy.select"),
            trigger: "change",
          },
        ],
        bookprice: [
          {
            required: true,
            message: this.$i18n.t("label_please_input_price_list_price"),
            trigger: "blur",
          },
        ],
      },
      //添加价格手册验证
      editmanualFormRules: {
        productname: [
          {
            required: true,
            message: this.$i18n.t("vue_label_pricebook_enterproductname"),
            trigger: "blur",
          },
        ],
        pricebookname: [
          //'请输入价格手册名称'
          {
            required: true,
            message: this.$i18n.t("vue_label_pricebook_enterbookname"),
            trigger: "change",
          },
        ],
        standardprice: [
          {
            required: true,
            message: this.$i18n.t("label_please_input_price_list_price"),
            trigger: "blur",
          },
        ],
      },
      addContact: false,
      isDropboxState: null, //Dropbox功能是否开启
      attrIndex: -1, // 表单字段所在位置
      isRlation: "List", //是卡片还是列表
      isquick: null, //判断是否启用quickbooks
      isCheckQuick: false, // 是否选中quickbooks
      isBottom: true, //唯一标识
      isReport: true, //伙伴云报表图表标识
      bind: this.$CCDK.CCToken.getToken(),
      userId: this.$store.state.List.id,
      isshow: true, //知识文章标识
      iscase: true, // 个案knowledge标识
      isCharts: false, //图表标识
      chartsListData: [],
      isWorkOrder: false, // 工作订单knowledge标识
      konwrecordType: "", //知识文章记录类型
      approvalSubmitStatus: true, // 提交待审批状态
      currencyOption: [], //添加到价格手册的币种下拉框数据
      is_master_version: "",
      attrTemp: [], // 临时存储表单字段
      acconame: "",
      socialshow: false, //市场活动社交帖子显示
      postershow: false, //市场活动海报显示
      invitshow: false, //市场活动员工邀约显示
      chnnelshow: false, //市场活动营销渠道显示
      isKnowledgeUser: false, //判断是否有知识库用户
      lookupValueExpression: null, //查找带值规则
      showAddressDialog: false, //地址字段弹框展示
      accountId: "", //订单发票详情页客户id
      accountName: "", //客户名称
      addressArr: [], //客户地址数组
      isNew: false, //判断是否是新建
      addressType: "", //判断当前更改地址是账单地址还是收货地址
      pricIntro: false, // 内联编辑是否切换价格手册弹框
      priceRow: [], // 价格手册行数据
      priceFieldId: "", // 价格手册字段id
      priceFormData: {}, // 价格手册表单数据
      priceIsDelSon: false, // 修改价格手册是否触发确定按钮
      // props中的objectApi
      objectApiData: this.objectApi,
      // 不是个人客户
      isNotPersonaccount: false,
      prefixData: this.prefix,
      tabNameData: this.tabName,
      canEditOwnerData: this.canEditOwner,
      // 拥有从cloudcc中上传文件的权限
      isispermissionUpload: false,
      // 更新个案团队
      caseTeamDialog: false,
      // 考勤组 考勤人员
      attendanceStaffData: [],
      attendanceStaffDataCopy: [],
      // 考勤组 考勤人员 查找弹窗loading
      ChangeKaoqinLoading: false,
      // 区县列表
      citycountyContainer: [],
      // 城市/城镇选项列表
      provincecityContainer: [],
    };
  },
  // computed:{
  //   objectApiData() {
  //     return this.objectApi;
  //   }
  // },
  created() {
    if (this.$route.path && this.$route.path.split("/")[2] === "multi-screen") {
      this.multiScreen = true;
    } else {
      this.multiScreen = false;
    }
    this.userUrl = this.$cookies.get("domainName");
    this.getCurrentUser();
    this.getUserSetup();
    // 查询是否有使用cloudcc上传文件权限
    this.getSystemPermission("aab02017120502").then((res) => {
      this.isispermissionUpload = res;
    });
  },
  mounted() {
    // 快速新建接受返回值
    this.$bus.$on(this.quickCreatId, this.quickCreatIdCallback);
    this.$nextTick(() => {
      this.display();
    });
    // this.getRelationListButton()
    // 监听窗口变化
    Bus.$on("windowResize", this.windowResizeFnBus);
    // 监听编辑页面布局设置
    Bus.$on("setColumnsMode", this.changeColumns);
    // 恢复提交待审批状态
    this.$Bus.$on("recovery-submit-status", this.recoverySubmitStatusFn);

    // 关系网Node传递出事件,实现单个卡片编辑
    this.$bus.$on("editClickEvent", this.editClickEventFn);
    //  关系网 relation-list-obj 发射点击管理按钮事件
    this.$bus.$on("handleManageBusSend", this.handleManageBusSendFn);
    //响应式多列在修改宽度后需要动态的显示两列或多列
    this.$nextTick(() => {
      window.addEventListener("resize", this.windowResizeFn);
    });
    this.$bus.$on("followersTo", this.followers);
  },
  destroyed() {
    // 清空设置的接受快速新建传值问题
    this.$bus.$off(this.quickCreatId, this.quickCreatIdCallback);
    this.$bus.$off("followersTo", this.followers);
    this.$bus.$off("editClickEvent", this.editClickEventFn);
    this.$bus.$off("handleManageBusSend", this.handleManageBusSendFn);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeFn);
    Bus.$off("windowResize", this.windowResizeFnBus);
    Bus.$off("setColumnsMode", this.changeColumns);
    this.$Bus.$off("recovery-submit-status", this.recoverySubmitStatusFn);
  },
  methods: {
    windowResizeFnBus() {
      // 实时计算相关列表名称宽度
      this.setRelationNameWidth();
      if (
        this.detailLayoutMode === "mode1" &&
        this.detailMode !== "twoColumns"
      ) {
        this.calcDetailInfoWidth();
      } else {
        this.setColumnsMode("twoColumns");
      }
    },
    recoverySubmitStatusFn() {
      this.approvalSubmitStatus = true;
    },
    editClickEventFn(currentClickItemObj) {
      // 业务机会下管理点击
      //"管理"
      this.contactsVisible = true;
      this.$refs.popUpteam.getInit({
        isShowMaincontact: false,
        currentClickItemObj,
      });
    },
    handleManageBusSendFn() {
      // 业务机会下管理点击
      //"管理"
      this.$refs.popUpteam.getInit();
      this.contactsVisible = true;
    },
    windowResizeFn() {
      //监听浏览器窗口大小改变
      //浏览器变化执行动作
      if (
        this.detailLayoutMode === "mode1" &&
        this.detailMode !== "twoColumns"
      ) {
        if (document.getElementsByClassName("lf")[0] !== undefined) {
          this.calcDetailInfoWidth();
        }
      }
    },
    //弹框关闭前清空之前数据
    beforeClose() {
      this.closepricebookdialogVisible();
    },
    changeCurrencyEdit(result) {
      this.formAttr.forEach((item, index) => {
        if (item.apiname === "currency") {
          if (result.length > 0) {
            this.$refs.baseForm.formAttr[index].edit = false;
            this.$refs.baseForm.formAttr[index].showCurrencyIntro = false;
          } else {
            this.$refs.baseForm.formAttr[index].showCurrencyIntro = true;
          }
          return;
        } else {
          this.$refs.baseForm.formAttr[index].showCurrencyIntro = true;
        }
        this.$refs.baseForm.$forceUpdate();
      });
    },
    // 特殊相关列表进入主记录编辑页
    goToDetail(objectApi, objId) {
      this.$nextTick(() => {
        this.$refs.editObj.objectApi = objectApi;
        this.$refs.editObj.objId = objId;
        this.$refs.editObj.edit();
        // 操作按钮添加二级菜单
        // this.$CCDK.CCPage.openEditPage(
        //   {
        //     objectName: `${this.$i18n.t("label.department.user.edit")}${this.tabName}`,
        //     objId:objId,
        //     objectApi:objectApi,
        //   },
        //   this.id,//记录id
        //   {
        //     openPlace:'menu2',
        //     openMode:'_blank'
        //   }
        // )
      });
    },
    getJiaGe(val) {
      this.pricebooklistid = val;
    },
    // 客户和联系人下的服务评价的数量
    accountNumberToList(listNum) {
      this.accountNumberList = listNum;
    },
    // 获取报表id接口
    async queryReprtRecordtypeid() {
      let obj = {
        recordtypeid: this.konwrecordType,
      };
      let data = await CommonObjApi.queryReprtRecordtypeid(obj);
      this.chartsListData = data.data;
      // {recordname}的业务机会总金额,记录名为神州云动,则展示为“神州云动的业务机会总金额;
      this.chartsListData.map((item) => {
        if (item.title.indexOf("{recordname}") > -1) {
          item.title = item.title.replace("{recordname}", this.bigTitle);
        }
      });
    },
    /**
     * 获取业务机会所属数据
     * 联系人五星图上，每个联系人卡片右上角，根据是否有编辑权限，展示编辑按钮
     */
    async display() {
      function setKey(realData) {
        realData.isShowEditBtn = true;
        realData.children?.forEach((item) => setKey(item));
      }
      let [displayRes, getPermissionByIdRes] = await Promise.all([
        CommonObjApi.display({
          opportunityId: this.id,
        }),
        CommonObjApi.getPermissionById({ id: this.id }).catch(() => {}),
      ]);

      let realData = displayRes?.data ?? {};

      let getPermissionByIdResData = getPermissionByIdRes?.data ?? {};

      getPermissionByIdResData.isEdit && setKey(realData);
      if (
        !realData.name &&
        realData.children &&
        realData.children.length === 0
      ) {
        realData = {};
      }
      this.orgchartData = realData;
    },
    changeHash(str) {
      document.querySelector(str).scrollIntoView(true);
    },
    getPageDetail(group) {
      if (!group.pageUrl) {
        return;
      }
      let parameter = {
        pageApi: group.pageUrl,
      };
      this.$devHttp
        .postFormat(
          `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/detailCustomPage`,
          parameter
        )
        .then((res) => {
          let idx = this.formAttr.findIndex(
            (item) => item.selectionId === group.selectionId
          ); // 获取内嵌页面的新建部分title所在的位置
          let obj = {
            width: "100%",
            colNum: 1,
            type: "customPage",
            attrIndex: this.attrIndex,
            selectionId: group.selectionId,
            groupShow: true,
            hidden: false,
            componentData: JSON.parse(res.data.pageContent),
            canvasStyleData: JSON.parse(res.data.canvasStyleData),
          };
          // formAttr的数据内容title是一个对象，内容是一个对象；title对象的selectionId和内容对象的selectionId是一样的
          // 在这里判断去重，首先要判断当前的idx不是最后一项，因为如果当前是最后一项拿不到this.formAttr[idx + 1].selectionId
          if (
            idx !== -1 &&
            idx != this.formAttr.length - 1 &&
            this.formAttr[idx].selectionId == this.formAttr[idx + 1].selectionId
          ) {
            // 说明title下已经有对应的内嵌页面了,避免重复添加
            return;
          }
          this.formAttr.splice(idx + 1, 0, obj); // 新建部分title对应的idx的后一位没有内嵌页面则添加
        })
        .catch((err) => {
          this.message.error(err.returnInfo);
        });
    },
    // 判断显示哪一个选项卡
    quickBottom(val) {
      if (val === "xiang") {
        this.isBottom = true;
        this.isReport = false;
        this.isshow = false;
        this.iscase = false;
        this.isCharts = false;
        this.isWorkOrder = false;
        this.isCheckQuick = false;
      } else if (val === "isReport") {
        this.isBottom = false;
        this.isReport = true;
        this.isshow = false;
        this.iscase = false;
        this.isCharts = false;
        this.isWorkOrder = false;
        this.isCheckQuick = false;
      } else if (val === "isshow") {
        this.isBottom = false;
        this.isReport = false;
        this.isshow = true;
        this.iscase = false;
        this.isCharts = false;
        this.isWorkOrder = false;
        this.isCheckQuick = false;
      } else if (val === "iscase") {
        this.isBottom = false;
        this.isReport = false;
        this.isshow = false;
        this.iscase = true;
        this.isCharts = false;
        this.isWorkOrder = false;
        this.isCheckQuick = false;
        this.$nextTick(() => {
          this.$refs.KnowledgesearchRef.judgeAddArticleByCase();
        });
      } else if (val === "charts") {
        this.isBottom = false;
        this.isReport = false;
        this.isshow = false;
        this.iscase = false;
        this.isCharts = true;
        this.isWorkOrder = false;
        this.isCheckQuick = false;
      } else if (val === "isWorkOrder") {
        this.isBottom = false;
        this.isReport = false;
        this.isshow = false;
        this.iscase = false;
        this.isCharts = false;
        this.isWorkOrder = true;
        this.isCheckQuick = false;
      } else if (val === "quick") {
        this.isBottom = false;
        this.isReport = false;
        this.isshow = false;
        this.iscase = false;
        this.isCharts = false;
        this.isWorkOrder = false;
        this.isCheckQuick = true;
      }
    },
    // 判断是否启用quickbooks功能
    getQuickBooks() {
      //客户||联系人||收款计划||合同||业务机会 显示QuickBooks
      if (
        (this.prefix === "001" && this.objectApi === "Account") ||
        // (this.prefix === "003" && this.objectApi === "Contact") ||
        // (this.prefix === "002" && this.objectApi === "Opportunity") ||
        // (this.prefix === "019" && this.objectApi === "cloudccskjh") ||
        // (this.prefix === "007" && this.objectApi === "contract")
        this.prefix === "032"
      ) {
        quickbookStart().then((res) => {
          if (res.result && res.data && res.data == "1") {
            this.isquick = true;
          } else {
            this.isquick = false;
            //选项卡默认回详细信息
            this.isBottom = true;
          }
        });
      } else {
        this.isquick = false;
        //选项卡默认回详细信息
        this.isBottom = true;
      }
    },

    //获取是卡片还是列表
    getUserSetup() {
      getUserSetup().then((res) => {
        this.isRlation = res.data.relatedListStyle || 'List';
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      });
    },
    // 设置相关列表名称宽度
    setRelationNameWidth() {
      let cardWidth =
        document.getElementById("box-main_top") &&
        document.getElementById("box-main_top").clientWidth;
      if (cardWidth > 800) {
        this.relationNamePercent = "25%";
        this.relationNameWidth = "137px";
      } else if (cardWidth > 640) {
        this.relationNamePercent = "33.33%";
        this.relationNameWidth = "137px";
      } else {
        this.relationNamePercent = "50%";
        this.relationNameWidth = "180px";
      }
    },
    // 二列布局和响应式布局切换
    changeColumns(columns) {
      if (columns === "two" || columns === "twoColumns") {
        VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
        this.setColumnsMode("twoColumns");
      } else {
        VueCookies.set(`${this.objectApi}DetailMode`, "responsiveLayout", { sameSite: 'Strict' });
        this.calcDetailInfoWidth();
      }
      this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);
    },
    // 计算详细信息宽度
    calcDetailInfoWidth() {
      if (document.getElementsByClassName("lf")[0] !== undefined) {
        let width = document.getElementsByClassName("lf")[0].clientWidth;
        if (width <= 960) {
          this.setColumnsMode("twoColumns");
        } else if (960 < width && width <= 1440) {
          this.setColumnsMode("fourColumns");
        } else if (width > 1440) {
          this.setColumnsMode("sixColumns");
        }
      }
    },
    // 246列布局设置
    setColumnsMode(detailMode) {
      switch (detailMode) {
        case "twoColumns":
          this.columnNum = 2;
          this.oneColumnWidth = "100%";
          this.twoColumnWidth = "50%";
          break;
        case "fourColumns":
          this.columnNum = 4;
          this.oneColumnWidth = "50%";
          this.twoColumnWidth = "25%";
          break;
        case "sixColumns":
          this.columnNum = 6;
          this.oneColumnWidth = `${100 / 3}%`;
          this.twoColumnWidth = `${100 / 6}%`;
          break;
      }
    },
    focusList() {
      document.querySelector("#focusList").scrollIntoView(true);
    },
    focusListItemone() {
      document.querySelector("#focusListItemone").scrollIntoView(true);
    },
    focusListItemtwo() {
      document.querySelector("#focusListItemtwo").scrollIntoView(true);
    },
    // 获取关注相关列表的详细信息
    async followers() {
      //追随我的--关注者
      let followersMyRes = await queryFollows({
        keyword: "",
        userId: this.id,
        followType: "followme",
      });
      this.followersMyData = followersMyRes.data.list;
      this.followersMyCount = followersMyRes.data.totalCount;
      //我追随的--正在关注
      let myfollowerRes = await queryFollows({
        keyword: "",
        userId: this.id,
        followType: "mefollow",
      });
      this.myfollowersData = myfollowerRes.data.list;
      this.myfollowerCount = myfollowerRes.data.totalCount;
      //我的组（包含我创建的组和我加入的组）
      let myGroupRes = await queryUserGroups({
        keyword: "",
        groupType: "ug_myGroup",
      });
      this.myGroupCount = myGroupRes.data.totalSize;
      this.myGroupData = myGroupRes.data.list;
      this.$Bus.$emit("focusListLoading", false);
    },

    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      CommonObjApi.getUserInfo().then((res) => {
        // 管理员可编辑
        this.systemManager = res.data.profileId === "aaa000001" ? true : false;
        if (this.systemManager) {
          this.isEdit = true;
        }
      });
    },
    addFieldOption(prop, option) {
      if (this.optionList[prop]) {
        this.optionList[prop].push(option);
      } else {
        this.optionList[prop] = [option];
      }
    },
    // 编辑标签
    editCloudcctag() {
      this.$emit("customBtn");
    },
    /**
     * 定位到点击编辑的位置，并聚焦
     */
    positionEditableCell(obj) {
      let that = this;
      setTimeout(() => {
        if (obj && that.$refs.baseForm != undefined) {
          let apiname = obj.apiname;
          let dom = that.$refs.baseForm.$refs[apiname];
          if (dom !== undefined) {
            if (Object.prototype.toString.call(dom) !== "[object Object]") {
              //这里是针对遍历的情况（多个输入框），取值为数组
              dom = dom[0];
            }
            let scrollIntoViewOptions = {
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            };
            // 获取焦点
            if (
              dom.$el.lastElementChild.firstElementChild.firstElementChild
                .firstElementChild
            ) {
              dom.$el.lastElementChild.firstElementChild.firstElementChild.firstElementChild.focus();
            } else if (
              dom.$el.lastElementChild.firstElementChild.firstElementChild
            ) {
              dom.$el.lastElementChild.firstElementChild.firstElementChild.focus();
            }
            dom.$el.scrollIntoView(scrollIntoViewOptions);
          }
        }
      }, 2000);
    },
    // 编辑详细信息
    showEditableCell(val, type) {
      this.loadingDetail = true;
      this.operation = "EDIT";
      this.getFormAttr(val, type);
    },
    //获取cookies
    getCookie(name) {
      var prefix = name + "=";
      var start = document.cookie.indexOf(prefix);
      if (start == -1) {
        return null;
      }
      var end = document.cookie.indexOf(";", start + prefix.length);
      if (end == -1) {
        end = document.cookie.length;
      }
      var value = document.cookie.substring(start + prefix.length, end);
      return unescape(value);
    },
    // 远程搜索
    remoteMethod(params, item, formData) {
      //在项目管理系统下对负责人所属项目的判断
      if (
        item.apiname == "principal" ||
        item.apiname == "member" ||
        (window.location.href.substring(window.location.href.length - 3) ==
          "p04" &&
          (item.apiname == "milestone" ||
            item.apiname == "affected_milestones"))
      ) {
        if (
          formData.project_name == "" ||
          formData.their_project == "" ||
          formData.their_project_name == ""
        ) {
          this.$message.error("请选择项目");
          return;
        }
      }
      if (
        ((window.location.href.substring(window.location.href.length - 3) ==
          "p03" ||
          this.getCookie("parentObjectApi") == "cloudccTask") &&
          this.milestone == "their_milestone") ||
        (this.getCookie("parentObjectApi") == "cloudccTask" &&
          this.milestone == "their_task")
      ) {
        params.projectId = "";
      } else if (
        (window.location.href.substring(window.location.href.length - 3) ==
          "p05" ||
          this.getCookie("parentObjectApi") == "cloudccSubtask") &&
        this.milestone == "their_task"
      ) {
        params.projectId = "";
      } else {
        params.projectId =
          formData.their_project ||
          formData.project_name ||
          formData.their_project_name ||
          "";
      }
      // 查询是否有默认条件
      CommonObjApi.getLookupInfo({
        fieldId: params.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        // let lkdp = [""];
        // lkdp必须第一个是‘’，用于查找空的情况
        let values = [""];

        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          for (let reltaion in res.data.filterFieldIds) {
            let attr = this.formAttr.find((attrItem) => {
              return (
                attrItem.fieldId === res.data.filterFieldIds[reltaion].fieldid
              );
            });
            if (
              attr !== undefined &&
              formData[attr.prop] !== null &&
              Array.isArray(formData[attr.prop])
            ) {
              values.push(formData[attr.prop].join(";"));
            } else if (attr !== undefined && formData[attr.prop] !== null) {
              values.push(formData[attr.prop]);
            }
          }
        }
        params.lkdp = JSON.stringify(values);

        CommonObjApi.queryMoreDataByName(params).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname.replace(/&nbsp;/g, " ");
          });
          this.$set(this.optionList, item.prop, options);
        });
      });
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 获取对象api后进行页面跳转
      CommonObjApi.getLookupInfo({
        fieldId: item.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        // 判断快速新建搜索布局中的权限
        if (res.data.isquickcreated === "true") {
          this.$CCDK.CCPage.openCreatePage(
            {
              objectName: this.$i18n.t("label.ems.createTask", {
                taskName: item.fieldLabel,
              }),
              objId: item.objId,
              objectApi: res.data.objectApi,
              prefix: item.prefix,
              isQuickCreate: this.quickCreatId,
              isTableEdit: true,
            },
            { openPlace: "dialog", openMode: "_blank" }
          );
        } else {
          // 抱歉，您暂无该对象新建权限
          this.$message.warning(this.$i18n.t("message.nopermession"));
        }

        // let objectApi = res.data.objectApi;
        // window.open(
        //   `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
        // );
      });
    },
    /**
     * getSystemPermission:通用权限查找接口
     * @param {String} id:查询权限对应id
     */
    async getSystemPermission(id = "") {
      // 是否有权限
      let flag = null;
      let res = await getSystemPermission({ id });
      if (res.data && res.data.ispermission) {
        // 有权限
        flag = res.data.ispermission;
      }
      return flag;
    },

    /**
     * 文件上传成功后的回调
     */
    batchaddfilesSave() {
      // 获取上传文件列表
      let fieldList = this.$refs.batchaddfiles?.fileList || [];
      if (fieldList.length > 0) {
        this.batchAddFilesVisible = false;
        // 将上传的文件列表添加到对应字段中
        this.addFileSubmit(fieldList);
      }
    },
    /**
     * 本地上传确定按钮
     */
    localAddFile() {
      this.$refs.batchaddfiles.uploadSave();
    },
    /**
     * 本地上传文件弹窗关闭回调
     */
    closeLocalField() {
      this.isUpload = false;
    },
    /**
     * 打开添加文件弹窗
     * @param {Object} field 当前字段信息
     * @param {String} type 当前文件上传类型：1:本地上传；2:文件库上传
     */
    addFile(field, type) {
      this.fieldProp = field.prop;
      // this.fileInfo = JSON.parse(JSON.stringify(field));
      if (type === "1") {
        // 本地上传
        this.batchAddFilesVisible = true;
      } else {
        // 文件库上传
        this.$refs.addFile.initPage();
        this.dialogAddVisible = true;
      }
    },
    // 添加文件
    addFileSubmit(checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        ids.push(file.id);
      });
      // 文件上传个数超出时给提示
      this.formAttr.map((attr) => {
        if (attr.prop === this.fieldProp) {
          if (attr.length && ids.length > Number(attr.length)) {
            this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
            this.$refs.addFile.buttonLoading = false;
          } else {
            attr.data = checkedFileList;
            this.$set(
              this.$refs.baseForm.formData,
              this.fieldProp,
              ids.toString()
            );
            this.addFileClose();
          }
        }
      });
    },
    /**
     * 移除文件
     */
    removeFile(item, index) {
      let arr = item.data;
      arr.splice(index, 1);
      let ids = [];
      arr.length > 0 &&
        arr.forEach((file) => {
          ids.push(file.id);
        });
      this.formAttr.map((attr) => {
        if (attr.prop === item.prop) {
          attr.data = arr;
          ids = ids.length > 0 ? ids.toString() : "";
          this.$set(this.$refs.baseForm.formData, item.prop, ids);
        }
      });
    },
    // 关闭添加文件
    addFileClose() {
      this.addFileDialogVisible = false;
    },
    // 设置相关列表
    setRelationList() {
      this.GetRelationList(true);
    },
    getFormAttr(val, type) {
      this.loadingDetail = true;
      this.formAttr = [];
      this.attrTemp = [];
      this.optionList = {};
      this.attrIndex = -1;
      CommonObjApi.getDetail({
        id: this.id,
        objectApi: "",
        operation: this.operation,
        recordType: this.recordType,
      })
        .then((res) => {
          if (res.data && res.data.is_master_version) {
            this.is_master_version = res.data.is_master_version;
            this.$emit("getstate");
          }
          this.konwrecordType = res.data.recordType;
          if (this.konwrecordType) {
            //获取记录类型id，查询报表id接口
            this.queryReprtRecordtypeid();
          }
          // 不是个人客户
          if (res.data && res.data.ispersonaccount) {
            if (res.data.ispersonaccount === "false") {
              this.isNotPersonaccount = true;
            } else {
              this.isNotPersonaccount = false;
            }
          }
          this.objId = res.data.objId;
          this.objectApiData = res.data.objectApi;
          this.prefixData = res.data.prefix;
          this.layoutId = res.data.layoutId;
          this.$emit("dataListes", res.data);
          this.$emit("resetObjectInfo", res.data);
          this.editableCell = this.operation === "DETAIL" ? false : true;
          if (this.editableCell) {
            this.layoutid = res.data.layoutId;
          }
          // 246列布局设置
          if (VueCookies.get(`${this.objectApi}DetailMode`) === null) {
            VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
          }
          this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);
          if (
            this.detailLayoutMode === "mode1" &&
            this.detailMode !== "twoColumns"
          ) {
            this.calcDetailInfoWidth();
          }

          res.data.dataList.forEach((group) => {
            if (
              (this.operation === "DETAIL" && group.detailShow) ||
              (this.operation === "EDIT" && group.showWhenEdit)
            ) {
              this.attrIndex = -1;
            }
            if (group.selectionId === "adf201596551299p35Tq") {
              group.data.forEach((groupes) => {
                if (groupes.right.apiname === "isconvert") {
                  this.$emit("convert", groupes.right.value);
                }
              });
            }
            // 获取详情页用户对象跳转的用户名
            let gropDataOne = res.data.dataList[0].data;
            this.personsName =
              gropDataOne && gropDataOne[1] ? gropDataOne[1].left.value : "";
            // 将数据传给detail父
            if (!this.isShowType) {
              this.$emit("personsNameMethod", this.personsName);
            }

            // 获取详情页用户对象跳转的用户名
            let colNum = group.colNum;
            // 部分名称展示与否通过showWhenEdit或者detailShow控制
            if (
              this.operation === "DETAIL" ||
              (this.operation === "EDIT" && group.showWhenEdit)
            ) {
              if (this.getSelection(group.selectionId, this.attrTemp)) {
                // 详情页内嵌页面部分内容回显要根据部分标题回显位置，所以当详情页内嵌部分时，即使detailShow为false，也要添加进来
                let groupShow = true;
                if (this.operation === "DETAIL" && !group.detailShow) {
                  groupShow = group.detailShow;
                }
                this.attrTemp.push({
                  width: "100%",
                  colNum: 1,
                  type: "groupTitle",
                  title: group.title,
                  selectionId: group.selectionId,
                  groupShow: groupShow,
                  hidden: false,
                });
              }
            }

            // 非内嵌页面
            if (group.selectionType === "Field") {
              group.data &&
                group.data.length > 0 &&
                group.data.forEach((line) => {
                  // left/right是[]时是页面布局的空白，什么也没设置;left/right fieldType: "blank"时是页面布局设置的[空白]
                  if (
                    line.left &&
                    !(Array.isArray(line.left) && line.left.length === 0) &&
                    line.left.fieldType !== "blank"
                  ) {
                    let attr = this.attrTemp.find((item) => {
                      return item.fieldId === line.left.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr({
                        group,
                        selectionId: group.selectionId,
                        colNum,
                        attr: line.left,
                        isChildren: undefined,
                        position: "left",
                      });
                    }
                  } else {
                    if (line.left.fieldType == "blank") {
                      this.attrIndex += 1;
                      this.attrTemp.push({
                        width: "50%",
                        colNum: 2,
                        attrIndex: this.attrIndex,
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                        groupTitle: group.title,
                        selectionId: group.selectionId,
                        position: "left",
                      });
                    }
                  }
                  if (Number(colNum) !== 1) {
                    if (
                      !(Array.isArray(line.right) && line.right.length === 0) &&
                      line.right.fieldType !== "blank"
                    ) {
                      let attr = this.attrTemp.find((item) => {
                        return item.fieldId === line.right.fieldId;
                      });
                      if (attr === undefined) {
                        this.addFormAttr({
                          group,
                          selectionId: group.selectionId,
                          colNum,
                          attr: line.right,
                          isChildren: undefined,
                          position: "right",
                        });
                      }
                    } else {
                      if (line.right.fieldType == "blank") {
                        this.attrIndex += 1;
                        this.attrTemp.push({
                          width: "50%",
                          colNum: 2,
                          attrIndex: this.attrIndex,
                          type: "noData",
                          hidden: false,
                          groupShow: true,
                          groupTitle: group.title,
                          selectionId: group.selectionId,
                        });
                      }
                    }
                  }
                });
            } else {
              this.attrIndex += 1;
              // 新版本内嵌页面
              this.getPageDetail(group);
            }
          });
          // 任务事件名称相关项的处理
          if (this.objectApi === "Event" || this.objectApi === "Task") {
            this.getRelatedItem();
          }
          this.loadingDetail = false;
          this.formAttr = this.attrTemp;
          // 产品对象下详情页内联编辑币种不允许编辑
          if (this.operation === "EDIT" && this.objectApi === "product") {
            for (let i = 0; i < this.formAttr.length; i++) {
              if (this.formAttr[i].apiname === "currency") {
                this.formAttr[i].edit = false;
                break;
              }
            }
          }
          this.$nextTick(() => {
            let dependContainer = res.data.dependContainer
              ? JSON.parse(res.data.dependContainer)
              : [];
            // 强制刷新表单
            this.$refs.baseForm &&
              this.$refs.baseForm.filterFormData(true, dependContainer);

            // 编辑的时候走查找带值和字段依赖规则
            if (this.operation === "EDIT") {
              this.lookupValueExpression = res.data.lookupValueExpression;
              // 处理依赖关系，绑定事件
              if (res.data.controlContainer) {
                this.controlContainer = JSON.parse(res.data.controlContainer);
              }
              // 设置标识依赖字段属性
              if (res.data.dependContainer) {
                this.dependContainer = JSON.parse(res.data.dependContainer);
              }

              const { citycountyContainer, provincecityContainer } = res.data;
              // 城市列表
              this.provincecityContainer = JSON.parse(
                provincecityContainer || "[]"
              );
              // 县城
              this.citycountyContainer = JSON.parse(
                citycountyContainer || "[]"
              );
            }

            this.formAttr.forEach((res) => {
              if (res.apiname && res.apiname == "ownerid") {
                this.$Bus.$emit("deliver-group-strategy-ownerid", res.data[0]);
              }
              // 服务区域和服务区域成员 取操作时间id
              if (res.apiname === "operatinghoursid") {
                if (
                  this.objectApi === "ServiceTerritory" ||
                  this.objectApi === "ServiceTerritoryMember"
                ) {
                  this.$Bus.$emit("service-territory-data", res.data[0]);
                }
              }
            });
            if (val && type) {
              this.$nextTick(() => {
                if (type === "name") {
                  // 回填客户名
                  this.$refs.infoForm.setTianyanValue(val);
                } else if (type === "all") {
                  // 回填全部数据
                  this.$refs.infoForm.setTianyanValuees(val);
                }
                this.$message(this.$i18n.t("vue_label_visualize_data")); // 已回填天眼查数据，请保存。
              });
            }
            // 展示右侧活动组件
            showDom();
            if (val === "onLoad" && res?.data?.dataList) {
              // 保存详细信息数据到localStore中，并且执行onLoad
              if (window.$CCDK?.CCObject?.setObjectDetail) {
                window.$CCDK.CCObject.setObjectDetail({
                  id: this.id,
                  detail: res.data.dataList,
                });
              }

              // 调用onLoad客户端脚本
              let loadParam = {
                pageId: "detail",
                event: "onLoad",
                objId: this.objId,
                recordTypeId: res.data.recordType,
              };
              listClientScript(loadParam, myOnLoad);
            }
          });
          this.GetRelationList(true);
          //获取QuickBooks权限
          this.getQuickBooks();
        })
        .catch(() => {
          // 如果是编辑时请求详细信息接口报错，返回 展示详细信息
          if (this.operation === "EDIT") {
            this.cancelChangeDetail();
          }
        });
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0]) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则(初始化不走规则，bug2816)
            // this.getLookupValue(item, attr.value);
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 动态布局规则
    getDynamicLayoutListDetail(layoutId) {
      CommonObjApi.getDynamicLayoutListDetail({
        layoutId: layoutId,
      }).then((res) => {
        // 清空动态布局规则
        this.actionRequireds = [];
        this.actionShowFields = [];
        this.actionShowSections = [];
        this.actionReadonlys = [];
        this.actionConditions = res.data;

        res.data.forEach((action) => {
          // 重置规则控制字段
          action.mainConditions.forEach((mainCondition) => {
            // 主规则
            this.handleCondition(mainCondition, false);

            this.formAttr.forEach((attr, idx) => {
              // 地址和地理定位字段动态布局返回的是父字段id
              let fieldId = attr.fieldId;
              if (attr.parentType === "AD" || attr.parentType === "LT") {
                fieldId = attr.parentId;
              }
              if (fieldId === mainCondition.fieldId) {
                let changeEvent =
                  attr.changeEvent === undefined
                    ? "handleAction"
                    : `${attr.changeEvent},handleAction`;
                this.$set(this.formAttr[idx], "changeEvent", changeEvent);
              }
            });

            // 二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                this.handleCondition(dynamicPage, false);

                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  this.formAttr.forEach((attr, idx) => {
                    // 地址和地理定位字段动态布局返回的是父字段id
                    let fieldIdTwo = attr.fieldId;
                    if (attr.parentType === "AD" || attr.parentType === "LT") {
                      fieldIdTwo = attr.parentId;
                    }
                    if (fieldIdTwo === twoLevelCondition.fieldId) {
                      let changeEvent =
                        attr.changeEvent === undefined
                          ? "handleAction"
                          : `${attr.changeEvent},handleAction`;
                      this.$set(this.formAttr[idx], "changeEvent", changeEvent);
                    }
                  });
                });
              });
          });
        });

        // 走规则
        this.handleAction();
      });
    },
    // 组件内部处理完formData后表单才显示，父组件才能通过$refs获取到表单组件
    ruleProcessing() {
      this.$nextTick(() => {
        if (this.operation === "EDIT") {
          // 字段依赖
          this.setControlContainer();
          // 查找带值，绑定事件
          if (
            this.lookupValueExpression !== undefined &&
            this.lookupValueExpression !== "" &&
            this.lookupValueExpression !== null
          ) {
            this.setLookupValue(JSON.parse(this.lookupValueExpression));
          }
        }

        // 动态布局规则，绑定事件
        this.getDynamicLayoutListDetail(this.layoutId);
      });
    },
    // 判断部分名称是否已经存在
    getSelection(selectionId, formAttr) {
      let part = formAttr.find((item) => {
        return item.selectionId === selectionId;
      });
      // true 表示不存在该标题，可添加，false表示存在，不重复添加
      if (part !== undefined) {
        return false;
      } else {
        return true;
      }
    },
    // 查找带值
    lookupValue(selectVal, selectItem, formData) {
      this.lookupValues.forEach((lookupValue) => {
        if (lookupValue.expression.split(",")[0] === selectItem.apiname) {
          this.getLookupValue(lookupValue, selectVal, formData);
        }
      });
    },
    // 查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      // 查找字段值为空时不走带值规则
      if (id.toString() !== "") {
        fieldJson.push({
          fieldrelateid: lookupRule.id,
          id: id,
          expression: lookupRule.expression,
          iscover: lookupRule.iscover,
        });
        let param = {
          objId: this.prefix,
          fieldJson: JSON.stringify(fieldJson),
        };
        if (this.isZhucongfield) {
          // 从对象前缀
          param.objId = this.relatedObjPrefix;
        }
        CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
          carryValue =
            res.data && res.data.length > 0 ? res.data[0].value : null;
          // 主对象查找带值
          // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
          this.formAttr.forEach((attr) => {
            if (attr.fieldId === lookupRule.id) {
              // 编辑时
              if (formData !== undefined) {
                // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
                if (
                  formData[attr.prop] === "" ||
                  formData[attr.prop] === null ||
                  (formData[attr.prop] instanceof Array &&
                    formData[attr.prop].length === 0) ||
                  lookupRule.iscover === "true"
                ) {
                  // 查找、查找多选字段特殊处理
                  if (
                    (attr.fieldType === "Y" ||
                      attr.fieldType === "MR" ||
                      attr.fieldType === "M") &&
                    res.data[0].value &&
                    res.data[0].lkid &&
                    res.data[0].value !== "" &&
                    res.data[0].lkid !== ""
                  ) {
                    this.optionList[attr.prop] = [
                      {
                        value: res.data[0].lkid,
                        label: res.data[0].value,
                      },
                    ];
                    if (res.data[0].lkid !== "") {
                      formData[attr.prop] =
                        attr.fieldType === "Y" || attr.fieldType === "M"
                          ? res.data[0].lkid
                          : [res.data[0].lkid];
                    }
                  } else {
                    // 处理数字类型返回null时展示为0的问题
                    if (
                      (attr.fieldType === "P" ||
                        attr.fieldType === "N" ||
                        attr.fieldType === "c") &&
                      (carryValue === "" || carryValue === null)
                    ) {
                      formData[attr.prop] = undefined;
                    } else {
                      formData[attr.prop] = carryValue;
                    }
                  }
                }
              } else {
                // 如果要带值的字段值为空，带的值必须带过去 如果值不为空，iscover为‘true’时才带值
                if (
                  attr.value === undefined ||
                  attr.value === "" ||
                  attr.value === null ||
                  lookupRule.iscover === "true"
                ) {
                  if (
                    (attr.fieldType === "Y" ||
                      attr.fieldType === "MR" ||
                      attr.fieldType === "M") &&
                    res.data[0].value &&
                    res.data[0].lkid &&
                    res.data[0].value !== "" &&
                    res.data[0].lkid !== ""
                  ) {
                    this.optionList[attr.prop] = [
                      {
                        value: res.data[0].lkid,
                        label: res.data[0].value,
                      },
                    ];
                    if (res.data[0].lkid !== "") {
                      attr.value =
                        attr.fieldType === "Y" || attr.fieldType === "M"
                          ? res.data[0].lkid
                          : [res.data[0].lkid];
                      this.$refs.baseForm &&
                        this.$set(
                          this.$refs.baseForm.formData,
                          attr.apiname,
                          attr.fieldType === "Y" || attr.fieldType === "M"
                            ? res.data[0].lkid
                            : [res.data[0].lkid]
                        );
                    }
                  } else {
                    attr.value = carryValue;
                    this.$refs.baseForm &&
                      this.$set(
                        this.$refs.baseForm.formData,
                        attr.apiname,
                        carryValue
                      );
                  }
                }
              }

              // 带值字段如果有字段依赖、查找、动态布局规则，执行
              let changeEvent =
                attr.changeEvent !== undefined
                  ? attr.changeEvent.split(",")
                  : [];
              changeEvent.forEach((eventItem) => {
                if (eventItem === "control") {
                  this.control(
                    attr.value || this.$refs.baseForm.formData[attr.prop],
                    attr.fieldId,
                    this.$refs.baseForm.formData
                  );
                } else if (eventItem === "lookupValue") {
                  this.lookupValue(
                    attr.value || this.$refs.baseForm.formData[attr.prop],
                    attr,
                    this.$refs.baseForm.formData
                  );
                } else if (eventItem === "handleAction") {
                  this.handleAction();
                }
              });
            }
          });
        });
      }
    },
    // 使用ccdk快速新建，保存后带值
    quickCreatIdCallback(row = [{}]) {
      let obj = { data: row[0], field: {}, index: 1 };
      this.changeSelect(
        obj,
        this.$refs.baseForm.fieldId,
        this.$refs.baseForm.formData
      );
    },

    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      this.priceRow = row;
      this.priceFieldId = fieldId;
      this.priceFormData = formData;
      let options = [];
      let fieldVal = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }
      this.formAttr.forEach((e) => {
        // 内联编辑字段为修改价格手册时
        if (
          e.fieldId === fieldId &&
          e.apiname === "pricebook2id" &&
          (this.objectApi === "cloudccorder" ||
            this.objectApi === "quote" ||
            this.objectApi === "Invoices" ||
            this.objectApi === "cloudccpurchaseorder" ||
            this.objectApi === "Opportunity")
        ) {
          /**
           * 订单、报价单、发票、采购订单、业务机会等对象（详情页内联编辑）修改价格手册时，需查看相关子产品是否有数据，
           * 有则给出提示语，否则直接修改价格手册
           * @param {String} objId 对象id
           * @param {String} recordId 相关记录id
           */
          let params = {
            objId: this.objId,
            recordId: this.id,
          };
          CommonObjApi.existRelatedProductInfo(params).then((res) => {
            if (res.data == "true") {
              this.pricIntro = true;
            } else {
              // 防止清空时清空选项，再次编辑查找字段时显示数据Id
              if (options.length > 0) {
                this.$set(this.optionList, e.prop, options);
              }
              // attr.prop "pricebook2id"
              this.$set(this.optionList, e.prop, options);
              if (e.fieldType === "MR") {
                options.forEach((option) => {
                  fieldVal.push(option.value);
                });
                formData[e.apiname] = fieldVal;
              } else {
                formData[e.apiname] = row.data === undefined ? "" : row.data.id;
              }

              // 若有查找带值，将值带入
              this.lookupValues.forEach((lookupValue) => {
                if (e.apiname === lookupValue.expression.split(",")[0]) {
                  this.getLookupValue(
                    lookupValue,
                    formData[e.apiname],
                    formData
                  );
                }
              });
            }
          });
        }
      });
      this.formAttr.forEach((attr) => {
        if (attr.fieldId === fieldId && attr.apiname != "pricebook2id") {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          // attr.prop "pricebook2id"
          this.$set(this.optionList, attr.prop, options);
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((lookupValue) => {
            if (attr.apiname === lookupValue.expression.split(",")[0]) {
              this.getLookupValue(
                lookupValue,
                formData[attr.apiname],
                formData
              );
            }
          });
        }
      });
    },

    // 取消（内联编辑是否切换价格手册弹框）
    // 取消修改价格手册，价格手册赋回老值
    cancelPricChange() {
      this.pricIntro = false;
    },
    // 确定修改价格手册（内联编辑是否切换价格手册弹框）
    // 确定修改价格手册，价格手册赋新值，并且保存时调用删除子产品数据的接口（deleteRelatedProductInfo）
    confirmPricChange() {
      this.pricIntro = false;
      let options = [];
      let fieldVal = [];
      if (this.priceRow instanceof Array) {
        this.priceRow.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: this.priceRow.data.name,
          value: this.priceRow.data.id,
        });
      }
      this.formAttr.forEach((attr) => {
        if (attr.fieldId === this.priceFieldId) {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          // attr.prop "pricebook2id"
          this.$set(this.optionList, attr.prop, options);
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            this.priceFormData[attr.apiname] = fieldVal;
          } else {
            this.priceFormData[attr.apiname] =
              this.priceRow.data === undefined ? "" : this.priceRow.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((lookupValue) => {
            if (attr.apiname === lookupValue.expression.split(",")[0]) {
              this.getLookupValue(
                lookupValue,
                this.priceFormData[attr.apiname],
                this.priceFormData
              );
            }
          });
        }
      });
      this.priceIsDelSon = true; //修改价格手册弹框触发了确定按钮
    },

    // 依赖关系

    setControlContainer() {
      // 给依赖字段设置表示依赖字段身份的属性，清空依赖字段的下拉项数据
      this.setDependField(false, true);
      // 匹配控制字段，执行依赖方法
      this.setControlField(
        this.formAttr,
        this.controlContainer,
        this.$refs.baseForm?.formData,
        this.control
      );
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      if (formData === "$cancelDependField") {
        this.cancelDependField(selectVal, fieldId.fieldId);
      } else {
        // 执行依赖方法
        this.controlFun(selectVal, fieldId, formData, initControl);
      }
    },
    // 重置规则控制字段
    handleCondition(condition, flag) {
      // 设置字段/部分 是否显示/必填 注：部分下有必填字段时不隐藏该部分
      // 当flag为真时，规则中只要有一个条件满足，该字段显示/必填就为真
      // 每个控制字段变化时所有规则重新走一遍
      if (flag) {
        if (condition.action_requireds) {
          condition.action_requireds.forEach((requiredField) => {
            //  如果动态布局不是只读的，判断该字段动态布局是否是必填的，需要结合动态布局是否是必填的，如果是必填的，那么就是必填，只读为false。动态布局优先级永远是大于原布局
            this.actionRequireds.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.required = item.required || flag;
              }
            });
          });
        }
        if (condition.action_readonlys) {
          condition.action_readonlys.forEach((readonlysField) => {
            this.actionReadonlys.forEach((item) => {
              if (item.fieldId === readonlysField.field_id) {
                item.readonlys = item.readonlys || flag;
              }
            });
          });
        }
        if (condition.action_showFields) {
          condition.action_showFields.forEach((requiredField) => {
            this.actionShowFields.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.show = item.show || flag;
              }
            });
          });
        }
        if (condition.action_showSections) {
          condition.action_showSections.forEach((requiredField) => {
            this.actionShowSections.forEach((item) => {
              if (item.selectionId === requiredField.section_id) {
                item.showSection = item.showSection || flag;
              }
            });
          });
        }
      } else {
        // 初始化需要去重
        // 字段显示与否
        if (condition.action_showFields) {
          let showFields = [];
          condition.action_showFields.forEach((requiredField) => {
            if (!showFields.includes(requiredField.field_id)) {
              showFields.push(requiredField.field_id);
              this.actionShowFields.push({
                fieldId: requiredField.field_id,
                show: flag,
              });
            }
          });
        }
        // 部分显示与否
        if (condition.action_showSections) {
          let showSections = [];
          condition.action_showSections.forEach((requiredField) => {
            if (!showSections.includes(requiredField.section_id)) {
              showSections.push(requiredField.section_id);
              this.actionShowSections.push({
                sectionName: requiredField.sectionName,
                selectionId: requiredField.section_id,
                showSection: flag,
              });
            }
          });
        }
        // 字段必填与否
        if (condition.action_requireds) {
          let requireds = [];
          condition.action_requireds.forEach((requiredField) => {
            if (!requireds.includes(requiredField.field_id)) {
              requireds.push(requiredField.field_id);
              this.actionRequireds.push({
                fieldId: requiredField.field_id,
                required: flag,
              });
            }
          });
        }
        // 只读字段

        if (condition.action_readonlys) {
          let readonlys = [];
          condition.action_readonlys.forEach((readonlysField) => {
            if (!readonlys.includes(readonlysField.field_id)) {
              readonlys.push(readonlysField.field_id);
              this.actionReadonlys.push({
                fieldId: readonlysField.field_id,
                readonlys: flag,
              });
            }
          });
        }
      }
    },
    // 判断值
    judgValue(field, operator, value, fieldType) {
      if (
        this.$refs.baseForm &&
        (this.$refs.baseForm.formData[field] || this.$refs.baseForm.formData[field] === 0)
      ) {
        if (fieldType === "Q") {
          //  以后组件值是数组的都要走这里
          //  多选列表选中的值
          let valueAry = this.$refs.baseForm.formData[field];
          // 主字段设置的数组
          let actionAry = value.split(",");
          // 多选列表选中值中被主字段设置的数据包含的值
          let filterAry = actionAry.filter((item) => {
            return valueAry.indexOf(item) > -1;
          });
          switch (operator) {
            case "e":
              // 等于(全包含，并且数组长度都一样)
              return (
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );
            case "n":
              // 不等于
              return !(
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );

            case "c":
              // 包含（只要有其中一个条件就成立）
              return filterAry.length > 0;
            case "k":
              // 不包含
              return filterAry.length === 0;
          }
        } else {
          let fieldValue = this.$refs.baseForm.formData[field].toString();
          // 如果是币种字段，且值包含币种，去除币种之间的逗号，去除币种后再进行比较.例如将 AUD 20.000 转化为 20
          if (
            fieldType === "c" &&
            this.$refs.baseForm.formData[field].toString().indexOf(" ") !== -1
          ) {
            let fieldValueStr = "";
            fieldValue
              .split(" ")[1]
              .split(",")
              .forEach((item) => {
                fieldValueStr += item;
              });
            //  自动去小数位零
            fieldValue = Number(fieldValueStr).toString();
          }
          // 判断字段类型
          fieldType = fieldType ? INPUTTYPE[fieldType] : fieldType;

          if (fieldType === "date" || fieldType === "datetime") {
            // 处理日期、日期时间字段
            fieldValue = new Date(fieldValue).getTime().toString();
            value = new Date(value).getTime().toString();
          }

          if (fieldType === "time") {
            // 处理时间字段
            let formField = fieldValue.split(":");
            let valueField = value.split(":");
            fieldValue = (
              Number(formField[0] ? formField[0] : "") * 3600 +
              Number(formField[1] ? formField[1] : "") * 60 +
              Number(formField[2] ? formField[2] : "")
            ).toString();
            value = (
              Number(valueField[0] ? valueField[0] : "") * 3600 +
              Number(valueField[1] ? valueField[1] : "") * 60 +
              Number(valueField[2] ? valueField[2] : "")
            ).toString();
          }
          switch (operator) {
            case "e":
              // 等于
              return fieldValue === value;
            case "n":
              // 不等于
              return fieldValue !== value;
            case "l":
              // 小于
              return Number(fieldValue) < Number(value);
            case "g":
              // 大于
              return Number(fieldValue) > Number(value);
            case "m":
              // 小于或等于
              return Number(fieldValue) <= Number(value);
            case "h":
              // 大于或等于
              return Number(fieldValue) >= Number(value);
            case "c":
              // 包含
              return fieldValue.includes(value);
            case "k":
              // 不包含
              return !fieldValue.includes(value);
            case "s":
              // 起始字符
              return fieldValue.indexOf(value) === 0;
          }
        }
      } else {
        // 如果是不包含、不等于返回true，其他false
        let flag = operator === "n" || operator === "k";
        // 如果value为空，此时operator是e（等于）返回为true
        if (operator === "e" && !value && value !== 0) {
          flag = true;
        }
        return flag;
      }
    },
    /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
    // 走规则
    handleAction() {
      // 清空动态布局规则
      this.actionRequireds = [];
      this.actionShowFields = [];
      this.actionShowSections = [];
      this.actionReadonlys = [];

      this.actionConditions.forEach((action) => {
        // 重置规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          // 主规则
          this.handleCondition(mainCondition, false);

          // 二级规则
          mainCondition.dynamicPageConditions &&
            mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
              this.handleCondition(dynamicPage, false);
            });
        });
      });

      this.actionConditions.forEach((action) => {
        // 循环规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          let judgment = this.judgValue(
            mainCondition.fieldName,
            mainCondition.operator,
            mainCondition.value,
            mainCondition.fieldType
          );
          if (judgment) {
            this.handleCondition(mainCondition, true);
            // 二级规则 - 符合一级规则时才走二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                // 二级条件执行
                let judgment = [];
                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  let judfment2= this.judgValue(
                      twoLevelCondition.fieldName,
                      twoLevelCondition.operator,
                      twoLevelCondition.value,
                      twoLevelCondition.fieldType
                    );
                    
                  judgment.push(judfment2)
                });
                // 执行高级条件
                if (this.isJudgment(judgment,dynamicPage.label)) {
                  this.handleCondition(dynamicPage, true);
                }
              });
          }
        });
      });

      // 根据规则设置字段显示/隐藏
      this.actionShowFields.forEach((actionShowField) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionShowField.fieldId) {
            if (actionShowField.show) {
              attr.hidden = false;
            } else {
              attr.hidden = true;
            }
          }
        });
      });

      // 设置部分显示/隐藏
      this.actionShowSections.forEach((actionShowSection) => {
        if (actionShowSection.sectionName) {
          this.formAttr.forEach((attr) => {
            // 控制某部分标题显示/隐藏
            // if (attr.title === actionShowSection.sectionName || attr.groupTitle == actionShowSection.sectionName) {
            if (attr.selectionId === actionShowSection.selectionId) {
              if (actionShowSection.showSection) {
                attr.hidden = false;
                attr.groupShow = true;
              } else {
                // 动态布局下只能设置条件符合时部分显示、字段显示、字段只读、字段必填
                // 如果动态规则条件不成立，原布局部分下有必填字段时不隐藏该部分
                let requiredFields = this.formAttr.find((item) => {
                  return (
                    item.selectionId === actionShowSection.selectionId &&
                    item.required
                  );
                });
                if (requiredFields !== undefined) {
                  attr.hidden = false;
                  attr.groupShow = true;
                } else {
                  attr.hidden = true;
                  attr.groupShow = false;
                }
              }
            }
          });
        }
      });

      // 必须先走字段必填，在走字段只读
      // 根据规则设置字段必填
      this.actionRequireds.forEach((actionRequire) => {
        // 需要判断该字段动态布局是否只读,并且只读条件成立，或者原布局该字段只读
        // 该字段动态布局是否只读,并且只读条件成立
        let flag = this.actionReadonlys.find((item) => {
          return item.fieldId === actionRequire.fieldId && item.readonlys;
        });
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionRequire.fieldId) {
            // 判断该字段动态布局或者原布局是否只读
            if (flag == undefined && attr.editCopy) {
              // 只读不成立
              // 判断动态必填或者原布局必填是否成立
              if (attr.requiredCopy || actionRequire.required) {
                // 需求变更：不需要添加验证提示语句了
                //  message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                //   attr.fieldLabel
                // }`,
                // 详细信息展示不需要必填标识
                if (this.operation === "EDIT") {
                  // console.log("edit进来零零零零");
                  attr.rules = [
                    {
                      required: true,
                      message: " ",
                      trigger: "blur", // 请输入
                    },
                  ];


                }
                // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段无规则
                // 清空必填，设置可编辑
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      //                 控制字段只读
      this.actionReadonlys.forEach((actionReadonly) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionReadonly.fieldId) {
            // 原字段只读或者动态规则只读条件成立
            if (!attr.editCopy || actionReadonly.readonlys) {
              attr.edit = false;
              attr.rules = [];
            } else {
              // 只读不成立，判断原布局或动态布局必填
              let flag = this.actionRequireds.find((item) => {
                return item.fieldId === actionReadonly.fieldId && item.required;
              });
              if (attr.requiredCopy || flag !== undefined) {
                // 详细信息展示不需要必填标识
                if (this.operation === "EDIT") {
                  attr.rules = [
                    {
                      required: true,
                      message: " ",
                      trigger: "blur", // 请输入
                    },
                  ]; // 改变只读,设置可编辑
                }

                attr.edit = true;
              } else {
                // 该字段没有规则
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      this.$refs.baseForm && this.$refs.baseForm.resetRules();
    },
    // 表单添加属性
    addFormAttr({
      group,
      selectionId,
      colNum,
      attr,
      isChildren,
      position,
      parentAttr,
      type,
    }) {

      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      // 单独存储备用原布局中的required和edit,动态布局设置规则的时候使用
      attr.requiredCopy = attr.required;
      attr.editCopy = attr.edit;
      this.$set(attr, "selectionId", selectionId);
      // 详细信息展示无需显示必填校验标识
      if (this.operation === "EDIT" && attr.required && attr.edit) {
        attr.rules = [
          {
            required: attr.required,
            message: " ",
            trigger: "blur",
          },
        ];
      }

      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (
        parentAttr &&
        (parentAttr.fieldType == "AD" || parentAttr.fieldType == "LT")
      ) {
        // 如果父字段是地址和地理定位字段，存储父字段fieldId
        attr.parentId = parentAttr.fieldId;
        attr.parentType = parentAttr.fieldType;
      }
      if (attr.fieldType !== "AD" && attr.fieldType !== "LT") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 图片显示

        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.operation === "EDIT"
        ) {
          if (attr.value !== "") {
            // 这里不需要了，需求变更，做多图片预览 （王金龙）
            // attr.data = [
            //   {
            //     name: "",
            //     url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
            //   },
            // ];
          } else {
            attr.data = [];
          }
        }
        // 评分+1
        if (attr.type === "score") {
          attr.value =
            this.operation === "EDIT" ? Number(attr.value) : Number(attr.value);
        }
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });

          this.optionList[attr.prop] = options;
          // 记录类型选项特殊处理
          if (attr.prop === "recordtype") {
            this.recordType = attr.id;
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }
          // else {
          //   this.recordType = attr.value;
          //   // this.optionList[attr.prop] = this.recordOptions;
          // }

          if (attr.type === "multi-select") {
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              (!attr.value ? [] : attr.value.split(";"));
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.lookupmutidata;
            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index].name,
                value: labels[index].id,
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.data[0].value === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            if (
              attr.value !== "" &&
              attr.data &&
              attr.data.length > 0 &&
              attr.data[0].value !== ""
            ) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            } else {
              attr.value = "";
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value =
            attr.value === "true" || attr.value === "1" ? true : false;
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.colNum = colNum;
        attr.groupTitle = group.title;
        attr.selectionId = selectionId;
        attr.groupShow = true;
        if (
          this.operation === "DETAIL" ||
          (this.operation === "EDIT" && attr.apiname !== "cloudcctag")
        ) {
          this.attrTemp.push(attr);
        }


      } else if (this.operation === "DETAIL") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        attr.colNum = colNum
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.groupTitle = group.title;
        attr.selectionId = selectionId;
        attr.groupShow = true;
        this.attrTemp.push(attr);
      } else {
        /**
         * 判断是否为地址字段子级
         * 强制生成一个 客户详细地址 字段从地图选择的字段
         */
        if (type == "address") {
          this.addrArr.push(attr);
        } else {
          this.attrTemp.push(attr);
        }
        if (attr.fieldType === "AD") {
          /**
           * 地址和地理定位特殊处理
           * 这里产品找不到写写这个什么原因，并逻辑有问题，暂且注释掉的了
           */
          // let newAdress = {
          //   edit: attr.edit,
          //   fieldId: "",
          //   // label: this.$i18n.t("LT"), //地理定位
          //   label: "??????", //地理定位
          //   name: attr.prop,
          //   required: false,
          //   type: "S",
          //   isAdress: true,
          //   mapFlag: true,
          // };
          // attr.children.unshift(newAdress);
          this.addrArr = [];
          attr.groupShow = true;
          attr.label = attr.fieldLabel;

          attr.children.forEach((item) => {
            if (
              (item.name && item.name.slice(-9) === "longitude") ||
              (item.name && item.name.slice(-8) === "latitude")
            ) {
              this.$set(item, "type", "noData");
              this.$set(item, "hidden", true);
            }

            this.addFormAttr({
              group,
              selectionId,
              colNum,
              attr: item,
              isChildren: true,
              position,
              parentAttr: attr,
              type: "address",
            });
            // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
            if (item["name"].slice(item["name"].length - 2) === "00") {
              this.getCountrySelectValue(item["name"]);
            }
          });
           attr.children = this.addrArr;
        } else {
          attr.children.forEach((item) => {
            if (
              (item.name && item.name.slice(-9) === "longitude") ||
              (item.name && item.name.slice(-8) === "latitude")
            ) {
              this.$set(item, "type", "noData");
              this.$set(item, "hidden", true);
            }
            //订单发票新建时地址字段只读保存后自动赋值
            // if((this.objectApi == 'cloudccorder' || this.objectApi == 'Invoices') && this.dialogAttr.type == "NEW"){
            // this.$set(item, 'placeholder', this.$t('c566'));
            // this.$set(item, 'edit', false);
            // }
            this.addFormAttr(group, colNum, item, true, position, attr);
          });

          this.attrIndex -= attr.children.length - 1;
        }
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
      if (
        this.objId === "event" ||
        this.objId === "task" ||
        this.objectApi === "Event" ||
        this.objectApi === "Task"
      ) {
        // 针对lightning版本：
        // 1、针对任务、事件两个对象中的“主题”字段，遵循后台配置，字段类型保持选项列表类型，且可根据配置选择选项。样式使用选项两列表类型的展示与操作样式；
        // 2、针对任务、事件两个对象中的“主题”字段，变更为选项列表类型字段后，同时也支持直接输入内容，并将输入的内容保存。
        if (attr.prop === "subject") {
          attr.type = "specialTypeSubject";
        }
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codekey, // 对应的value
              key: item.codevalue, // 对应的label
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取记录类型
    getRecordType() {
      let recordobj = this.formAttr.find((item) => {
        return item.prop === "recordtype";
      });
      let currentRecord = {
        val: recordobj.id,
        key: recordobj.value,
      };
      this.recordType = this.$refs.baseForm.formData["recordtype"];
      this.recordOptions = [];
      CommonObjApi.getRecordType({ prefix: this.prefix }).then((res) => {
        res.data &&
          res.data.recordTypeList &&
          res.data.recordTypeList !== null &&
          res.data.recordTypeList.forEach((record) => {
            this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
        let isHas = this.recordOptions.some(
          (item) => item.val == this.recordType
        );
        if (!isHas) {
          this.recordOptions.push(currentRecord);
        }
      });
    },
    // 修改记录类型
    changeRecordtype() {
      this.getRecordType();
      this.recordTypeDialogAttr.isShow = true;
    },
    // 先弹窗编辑记录，后保存记录类型
    next() {
      this.cancelRecordTypeDialog();
      // 编辑
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.data.isEdit) {
          this.$nextTick(() => {
            this.$refs.editObj.editAfterChangeRecordType(this.recordType);
          });
        } else {
          // 抱歉，您无权编辑该条数据，请联系系统管理员。
          this.$message(this.$i18n.t("vue_label_notice_nopermission_view"));
        }
      });
    },
    // 关闭修改记录类型弹窗
    cancelRecordTypeDialog() {
      this.type = "";
      this.recordTypeDialogAttr.isShow = false;
    },
    // 取消编辑
    cancelChangeDetail() {
      this.loadingDetail = true;
      this.editableCell = false;
      this.operation = "DETAIL";
      this.getFormAttr();
    },
    // 定位到非必填位置
    verifyJumpFn(object) {
      let dom = null;
      for (let i in object) {
        dom = this.$refs.baseForm.$refs[i];
        if (Object.prototype.toString.call(dom) !== "[object Object]") {
          //这里是针对遍历的情况（多个输入框），取值为数组
          dom = dom[0];
          break;
        }
      }
      dom.$el.scrollIntoView(false);
    },
    // 保存编辑
    saveChange() {
      let that = this;
      function tab(date1, date2) {
        let oDate1 = new Date(date1);
        let oDate2 = new Date(date2);
        if (oDate1.getTime() > oDate2.getTime()) {
          // 结束日期不得小于开始日期
          that.$message.warning(that.$i18n.t("label.weixin.end.later.start"));
          return false;
        } else {
          return true;
        }
      }
      // 更新主记录的价格手册，删除原主记录子产品
      if (this.priceIsDelSon == true) {
        let params = {
          objId: this.objId,
          recordId: this.id,
        };
        CommonObjApi.deleteRelatedProductInfo(params).then(() => {});
      }
      this.$refs.baseForm.$refs.elForm.validate((valid, object) => {
        if (valid) {
          // 新建工作规则加校验
          if (this.objectApi == "WorkRule") {
            let data = this.$refs.baseForm.formData;
            if (data.resourcespropertyfield && data.fieldoperator) {
              let params = {
                data: [
                  {
                    fieldId: data.resourcespropertyfield,
                    op: data.fieldoperator,
                    val: data.resourcespropertyvalue,
                  },
                ],
                filter: "",
                mainObjId: "WorkRuletdobject2021",
              };
              CommonObjApi.checkCondition({
                conditionVals: JSON.stringify(params),
              }).then((res) => {
                if (!res.result) {
                  this.$message.warning(res.returnInfo);
                  return;
                }
              });
            }
          }
          let dataList = [];
          let data = Object.assign({}, this.$refs.baseForm.formData);
          // 数字字段特殊处理
          for (const key in data) {
            this.formAttr.forEach((attr) => {
              //数字类型保存传值（小数位不够时补0）
              if (attr.type === "number" && data[attr.apiname]) {
                data[attr.apiname] = parseFloat(data[attr.apiname]).toFixed(
                  attr.precision
                );
              }
              if (
                attr.prop === key &&
                data[key] === undefined &&
                (attr.type === "number" || attr.type === "percent-number")
              ) {
                data[key] = "";
              }
            });
          }
          delete data.undefined;

          // 使用有效的分配规则进行分配
          // "潜在客户"   "个案"
          if (
            this.tabName === this.$i18n.t("label.brief.lead") ||
            this.tabName === this.$i18n.t("label.case")
          ) {
            data.case_assiGNment = this.validRule ? "true" : "false";
          }
          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // "--无--"
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined && data[key] != null) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined) {
                data[key] = data[key].toString();
              } else {
                data[key] = "";
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          data.id = this.id;
          dataList.push(data);
          // 判断结束时间是否大于开始时间
          if (
            (dataList[0].begintime && dataList[0].endtime) ||
            (dataList[0].start_date && dataList[0].end_date)
          ) {
            if (
              !tab(dataList[0].begintime, dataList[0].endtime) ||
              !tab(dataList[0].start_date, dataList[0].end_date)
            ) {
              return false;
            }
          }
          // 项目管理系统下，fieldType为c即金额类添加验证
          if (
            this.$store.state.home.homeApplicationId === "ace2020D253B3984Or6Q"
          ) {
            let moneyFlag = true,
              moneyName = "";
            this.formAttr.forEach((item) => {
              if (item.fieldType && item.fieldType === "c") {
                if (
                  dataList[0][item.apiname] &&
                  dataList[0][item.apiname].length > 10
                ) {
                  moneyName = item.fieldLabel;
                  moneyFlag = false;
                }
              }
            });
            if (!moneyFlag) {
              // 最多输入10位
              this.$message.warning(
                `${moneyName}` +
                  this.$i18n.t("label.projectManagement.enter.up.to.10.digits")
              );
              return;
            }
          }
          // 知识文章保存添加记录类型
          if (this.objectApi == "CloudccKArticle") {
            dataList[0].recordtype = this.konwrecordType;
          }
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };

          CommonObjApi.save(params).then((res) => {
            if (
              res.data &&
              res.data[0] &&
              res.data[0].isSaveSuccess === "true"
            ) {
              this.loadingDetail = true;
              this.editableCell = false;
              this.operation = "DETAIL";
              this.$emit("refreshAll");
              // 工作规则-保存筛选条件
              if (this.objectApi == "WorkRule") {
                let data = this.$refs.baseForm.formData;
                if (data.resourcespropertyfield && data.fieldoperator) {
                  let conditionVals = {
                    data: [
                      {
                        fieldId: data.resourcespropertyfield,
                        op: data.fieldoperator,
                        val: data.resourcespropertyvalue,
                      },
                    ],
                    filter: "",
                    mainObjId: "WorkRuletdobject2021",
                  };
                  let params = {
                    id: res.data[0].id,
                    objId: "WorkRuletdobject2021",
                    conditionVals: JSON.stringify(conditionVals),
                  };
                  CommonObjApi.saveCondition(params).then((res) => {
                    if (res.result) {
                      this.$message.success(
                        this.$i18n.t("label.search.saveok")
                      );
                    } else {
                      this.$message.warning(res.returnInfo);
                    }
                  });
                }
              }
            } else {
              // 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
              if (
                res?.data &&
                res.data[0]?.errormessage.includes("illegal_field_apiname")
              ) {
                // 值不存在或与筛选条件不匹配
                this.$message.error(this.$i18n.t("c2420"));
              } else {
                this.$message.error(
                  res.data[0].errormessage || this.$i18n.t("chatter_save_f")
                );
              }
            }
            this.display();
          });
        } else {
          this.verifyJumpFn(object);
          // 存在验证错误，请检查填写的内容
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
        }
      });
    },
    // 保存详细信息
    saveEditableCell(isTianyanDataSave) {
      this.$refs.baseForm.$refs.elForm.validate((valid) => {
        if (valid || isTianyanDataSave) {
          let dataList = [];
          let data = this.$refs.baseForm.formData;
          delete data.undefined;

          // 使用有效的分配规则进行分配
          // if (this.tabName === "潜在客户" || this.tabName === "个案") {
          //   data.case_assiGNment = this.validRule ? 'true' : 'false';
          // }

          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // "--无--"
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined) {
                if (data[key]) {
                  data[key] = data[key].toString();
                } else {
                  data[key] = "";
                }
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          data.id = this.id;
          dataList.push(data);
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };
          CommonObjApi.save(params).then((res) => {
            if (
              res.data &&
              res.data[0] &&
              res.data[0].isSaveSuccess === "true"
            ) {
              // "修改成功"
              this.$message.success(this.$i18n.t("message.modify.success"));
              this.getFormAttr();
              this.$parent.getBrief();
            } else {
              // 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
              if (
                res?.data &&
                res.data[0]?.errormessage.includes("illegal_field_apiname")
              ) {
                // 值不存在或与筛选条件不匹配
                this.$message.error(this.$i18n.t("c2420"));
              } else {
                this.$message.error(
                  res.data[0].errormessage || this.$i18n.t("chatter_save_f")
                );
              }
            }
          });
        }
      });
    },
    // 更改所有人
    changeOwner() {
      this.ownerName = "";
      this.ownerId = "";
      this.isSendMail = false;
      this.initTransferOwner(this.objId, this.id);
      this.transferClientOwnerDialog = true;
    },
    //更改地址
    async changeAddress(accountid, apiname, accountName) {
      let type =
        apiname == "billingaddress" || apiname == "billing_address"
          ? "billing"
          : "shipping";
      this.addressType = type;
      let objName = this.objectApi == "cloudccorder" ? "order" : "invoice";
      this.accountId = accountid;
      this.accountName = accountName;
      CommonObjApi.getPermissionById({ id: this.accountId }).then(
        async (res) => {
          if (res.result && res.data.isQuery == true) {
            this.showAddressDialog = true;
            let result = await CommonObjApi.getCustomerAddress({
              customerId: accountid,
              recordId: this.id,
              type: type,
              objName: objName,
            });
            if (result.result == true) {
              if (result.data.length > 0) {
                //将当前地址字段更改为组件适用字段
                result.data[0].address = result.data[0].shippingaddress
                  ? result.data[0].shippingaddress
                  : result.data[0].billingaddress;
                // result.data[0].contactName = result.data[0].shippingcontact ? result.data[0].shippingcontact : result.data[0].billingcontact
                result.data[0].phonenumber = result.data[0].shippingcontactphone
                  ? result.data[0].shippingcontactphone
                  : result.data[0].billingcontactphone;
              }
              this.addressArr = result.data;
            }
          } else {
            this.$message.warning(this.$t("c657"));
          }
        }
      );
    },
    //关闭地址字段弹框
    closeAddressdialog() {
      this.showAddressDialog = false;
    },
    //添加新地址
    addAdderss() {
      CommonObjApi.getPermissionById({ id: this.accountId }).then((res) => {
        if (res.result && res.data.isAdd == true) {
          this.showAddressDialog = false;
          this.isNew = true;
          this.dialogNewAttr.id = "";
          //查询当前客户是否有默认地址
          CommonObjApi.isDefaultAddress({ customerId: this.accountId }).then(
            (res) => {
              let obj = res.data;
              obj.currentType = this.addressType;
              this.$nextTick(() => {
                this.$refs.creatAddress.add(
                  obj,
                  "cloudccustomeraddress",
                  "objNewBuild"
                );
              });
            }
          );
        } else {
          this.$message.warning(this.$t("c657"));
        }
      });
    },
    //编辑地址
    editAddress(val, type) {
      let id = type == "account" ? this.id : this.accountId;
      CommonObjApi.getPermissionById({ id: id }).then((res) => {
        if (res.result && res.data.isEdit == true) {
          this.showAddressDialog = false;
          this.isNew = false;
          if (!this.isNew) {
            this.dialogNewAttr.id = val.id;
          }
          //查询当前客户是否有默认地址
          CommonObjApi.isDefaultAddress({ customerId: id }).then((res) => {
            let obj = res.data;
            obj.currentType = this.addressType;
            this.$nextTick(() => {
              this.$refs.creatAddress.edit(
                obj,
                "cloudccustomeraddress",
                "objNewBuild"
              );
            });
          });
        } else {
          this.$message.warning(this.$t("c657"));
        }
      });
    },
    //更改地址
    changeAccountAddress(val) {
      let params = {};
      //地址字段赋值
      params = { ...val };
      if (this.objectApi === "cloudccorder") {
        params.orderid = this.id;
      } else if (this.objectApi === "Invoices") {
        params.invoiceid = this.id;
      }

      params.phone = params.phonenumber == null ? "" : params.phonenumber;
      params.contactname =
        params.contact_name == null ? "" : params.contact_name;
      delete params.phonenumber;
      delete params.contact_name;
      delete params.billingaddress;
      delete params.shippingaddress;
      let type =
        this.addressType == "billing" ? "billingaddress" : "shippingaddress";
      params[type] = "true";
      CommonObjApi.updateOrderOrInvoiceInfo(params).then((res) => {
        if (res.result) {
          this.showAddressDialog = false;
          this.$emit("refreshAll");
        }
      });
    },
    // 查找所有人
    remoteSearchOwner() {
      // 查找所有人数据写死
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      this.ownerName = row.data.name;
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    // 确认更改所有人
    saveTransferOwnerSuccess() {
      this.validRule = true;
      this.operation = "DETAIL";
      // 更改成功
      this.$message.success(this.$i18n.t("vue_label_notice_change_success")); //"修改记录类型成功"
      this.closeTransferOwnerDialog();
      // 更改所有人后重新查询记录权限
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          if (res.data.isQuery) {
            this.$emit("refreshAll");
          } else {
            this.$parent.isQuery = false;
            this.$parent.fullscreenLoading = false;
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    //获取相关列表对象信息
    //toText为false时只刷新相关列表菜单，不刷新每个相关列表对应数据
    GetRelationList(toText) {
      this.relatedlistIds = "";
      CommonObjApi.GetRelationList({ recordId: this.id }).then((res) => {
        if (res.result && res.returnCode === "1") {
          if (this.prefix === "021") {
            // 发票
            res.data.forEach((list) => {
              if (list.relationFieldId === "ffe20181109items04") {
                this.relatedlistIds = list.relatedlistId;
              }
            });
          } else if (this.prefix === "011") {
            // 报价单
            res.data.forEach((list) => {
              if (list.relationFieldId === "ffe2011000063307X4r9") {
                this.relatedlistIds = list.relatedlistId;
              }
            });
          } else if (this.prefix === "032") {
            // 订单
            res.data.forEach((list) => {
              if (list.relationFieldId === "ffe2021orderitem0056") {
                this.relatedlistIds = list.relatedlistId;
              }
            });
          } else if (this.prefix === "092") {
            // 采购订单
            res.data.forEach((list) => {
              if (list.relationFieldId === "ffe2021puorderitem12") {
                this.relatedlistIds = list.relatedlistId;
              }
            });
          } else if (this.prefix === "002") {
            // 业务机会
            res.data.forEach((list) => {
              if (list.relationFieldId === "ffe201300150058Vnr9C") {
                this.relatedlistIds = list.relatedlistId;
              }
            });
          }
          let data = [];
          data = res.data.filter(
            // "备注"
            (item) =>
              item.relatedlistType != "activityHistory" &&
              item.relatedlistType != "openActivities" &&
              item.relationName !=
                this.$i18n.t("label.weixin.group.description") &&
              item.relatedlistType != "campaign"
          );
          res.data.forEach((item) => {
            // 设置从记录数据条数属性，保证响应式
            item.relationSize = 0;
            if (item.prefix == "pos" && item.relatedlistType == "poster") {
              this.postershow = true;
            } else if (
              item.prefix == "inv" &&
              item.relatedlistType == "invited"
            ) {
              this.invitshow = true;
            } else if (
              item.prefix == "015" &&
              item.relatedlistType == "campaign"
            ) {
              this.chnnelshow = true;
            } else if (
              item.prefix == "soc" &&
              item.relatedlistType == "social"
            ) {
              this.socialshow = true;
            }
          });
          if (toText) {
            data.forEach((val) => {
              this.$set(val, "titImg", val.tabStyle ? val.tabStyle : "");
              // 股权关系不需要loading，默认值应该是false
              //设置每个相关列表loading
              this.$set(val, "loading", false);
            });
          }
          this.list = [];
          // 产品 价格手册
          if (this.prefix === "009") {
            this.list.push({
              relationName: this.$i18n.t("lable.opppdt.selPbk.pricebook"),
              relatedlistId: "pricebook",
              relationSize: 0,
            });
          }
          // 考勤组 考勤人员
          if (this.prefix === "s86") {
            this.list.push({
              relationName: this.$i18n.t("c1789"),
              relatedlistId: "attendanceStaff",
              relationSize: 0,
            });
            // 获取考勤人员
            this.getAttendanceStaff();
          }
          // else if (this.prefix === "006") {
          //   // 市场活动 影响业务机会
          //   // this.list.push({
          //   //   relationName: this.$i18n.t('vue_label_campaign_influencedopportunity'),'影响业务机会'
          //   //   relatedlistId: 'influenceBusinessOpportunities',
          //   //   relationSize: 0
          //   // })
          // } else if (this.prefix === "001") {
          //   this.list.push({
          //     relationName: this.$i18n.t(
          //       "vue_label_account_equityrelationship"
          //     ),
          //     relatedlistId: "stockRight",
          //     relationSize: 0,
          //   });
          // }
          this.list = this.list.concat(data);
          // 市场云员工邀约和海报屏蔽
          if (this.objectApi === "campaign") {
            this.list = this.list.filter((type) => {
              return type.prefix !== "inv" && type.prefix !== "pos";
            });
          }
          this.$emit("getObjArr", this.list);
          this.$nextTick(() => {
            if (toText) {
              this.$refs.relationListObj &&
                this.$refs.relationListObj.forEach((relationObj) => {
                  relationObj.init();
                });
            }
            // 初始化相关列表名称宽度
            this.setRelationNameWidth();
          });

          this.isSkeleton = false;

          // 用户详情页添加关注列表
          if (this.isShowType == false) {
            this.followers();
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取考勤人员
    async getAttendanceStaff() {
      let res = await CommonObjApi.getUserBYGroup({
        attendancegroupId: this.id,
      });
      if (res.result) {
        this.attendanceStaffData = res.data || [];
        this.attendanceStaffDataCopy = JSON.parse(
          JSON.stringify(this.attendanceStaffData)
        );
        this.list[0].relationSize = this.attendanceStaffData.length;
        this.$Bus.$emit("KaoqinLoading", false);
      }
    },
    searchRefresh() {
      this.$nextTick(() => {
        this.$refs.KnowledgesearchRef.getKnowResult();
      });
    },
    offeres(data) {
      this.$emit("offeres", data);
    },
    //获取相关列表数据及按钮和字段信息
    getDetailRelatedItems(toNext, obj) {
      if (this.list && this.list.length > 0) {
        let val = null;
        if (toNext) {
          val = this.list[this.relatedListIndex];
        } else {
          // 刷新局部相关列表时通过信息定位到某个相关列表
          val = this.list.filter((item) => {
            if (item.relatedlistId === obj.id) {
              return item;
            }
          })[0];
        }

        if (val) {
          let data = {
            recordId: this.id,
            layoutId: val.layoutId ? val.layoutId : "",
            relatedlistId: val.relatedlistId ? val.relatedlistId : "",
            perPage: this.perPage,
            attachementType: val.attachementType ? val.attachementType : "",
          };
          this.listLoading = true;
          CommonObjApi.GetDetailRelatedItems(data).then((res) => {
            if (res.result && res.returnCode === "1") {
              // 手动添加totalCount属性
              if (res.data && res.data.data) {
                if (res.data.data[0] && res.data.data[0].norecord) {
                  res.data.totalCount = 0;
                } else {
                  res.data.totalCount = res.data.data.length;
                }
              } else {
                res.data.totalCount = 0;
              }
              // "报价单明细"
              if (
                res.data &&
                res.data.objLabel &&
                res.data.objLabel === this.$i18n.t("label.quote.detail")
              ) {
                this.$emit("offeres", res.data);
              }
              if (res.data !== null) {
                if (toNext) {
                  this.objArr.push(res.data);
                  this.relatedListIndex = this.relatedListIndex + 1;
                  this.relatedListIndex < this.list.length &&
                    this.getDetailRelatedItems(toNext);
                } else {
                  // 如果是删除后局部刷新相关列表，通过下边将原相关列表删除，再将刷新后 的数据加上
                  this.objArr.forEach((item, idx) => {
                    if (item.id === res.data.id) {
                      this.relatedListIndex = idx;
                    }
                  });
                  this.objArr.splice(this.relatedListIndex, 1, res.data);
                }
                // res.data.objLabel !== '影响业务机会' ||
                if (
                  res.data.objLabel !==
                    this.$i18n.t("lable.opppdt.selPbk.pricebook") ||
                  res.data.objLabel ===
                    this.list[this.list.length - 1].relationName
                ) {
                  this.listLoading = false;
                }
              }
            } else {
              this.$message.error(res.returnInfo);
            }
          });
        }
      }
    },

    //相关列表新建、批量新增显示方法
    newDisplayFun(item) {
      let addBtn = item.buttons.find((btn) => {
        return btn.name === "New";
      });
      let batchAddBtn = item.buttons.find((btn) => {
        return btn.name === "Batch Added";
      });
      let productBtn = item.buttons.find((btn) => {
        return btn.name === "Product Family Settings";
      });
      // 产品 产品系列设置按钮
      if (item.objName === "product") {
        productBtn &&
          item.showButton.push({
            id: "",
            label: this.$i18n.t("label.partnerCloud.seriesSettings"), //"产品系列设置",
          });
      }
      // 订单对象 发票相关列表 新建按钮走订单生成发票按钮的展示逻辑
      if (
        item.objid === "invoices" &&
        item.recordObjId === "20210525cloudccorder"
      ) {
        CommonObjApi.orderStatus({ orderid: this.$route.params.id }).then(
          (res) => {
            if (
              res.data[0].invoicestatus !== "已开具发票" &&
              res.data[0].invoicestatus !== "All Invoiced"
            ) {
              addBtn &&
                item.showButton.push({
                  id: "",
                  label: this.$i18n.t("label.ems.create"), //"新建",
                });
            }
          }
        );
      } else {
        addBtn &&
          item.showButton.push({
            id: "",
            label: this.$i18n.t("label.ems.create"), //"新建",
          });
      }
      batchAddBtn &&
        item.showButton.push({
          id: "",
          label: this.$i18n.t("label.batchadd"), //"批量新增",
        });
    },
    //每个相关列表的按钮集合
    getRelationListButton(item) {
      if (
        item.objName === "opportunityteam" ||
        item.objName === "AccountTeam"
      ) {
        //业务机会小组、客户小组
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("vue_label_commonobjects_detail_team"), //"管理小组",
          },
        ];
      } else if (item.objName === "opportunitypdt") {
        //业务机会产品
        item.showButton = [];
        item.buttons?.forEach((btn) => {
          if (btn.name === "editall") {
            item.showButton.push({
              label: this.$i18n.t("lable.product.pricebooks.editall"), //编辑全部
              name: "editall",
            });
          } else if (btn.name === "selectpricebooks") {
            item.showButton.push({
              label: this.$i18n.t("lable.opppdt.selPbk.title"), //选择价格手册
              name: "selectpricebooks",
            });
          } else if (btn.name === "tjywjhcp") {
            item.showButton.push({
              label: this.$i18n.t("vue_label_opportunity_product_addproduct"), //添加产品
              name: "tjywjhcp",
            });
          }
        });
      } else if (item.objName === "campaign") {
        //市场活动历史
        item.showButton = [];
      } else if (item.objName === "quotedetail") {
        //报价单明细
        item.showButton = [];
        this.newDisplayFun(item);
      } else if (item.objName === "tp_sys_approval_instance_his") {
        item.showButton = [];
      } else if (item.objName === "campaignmember") {
        //市场活动成员
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label.add"), //"添加市场活动成员"
          },
          {
            id: "",
            label: this.$i18n.t("label.emailobject.groupemail"), //群发邮件
          },
          // {
          //   id: "",
          //   label: "群发短信",
          // },
        ];
      } else if (item.objName === "Attachement") {
        //文件
        if (this.isDropboxState === "1") {
          item.showButton = [
            {
              id: "dropbox",
              label: "", //添加文件
            },
            {
              id: "",
              label: this.$i18n.t("label.file.library.selectFile2Lib"), //添加文件
            },
            {
              id: "d01",
              label: this.$i18n.t("chatter.down"), //"下载"
            },
            // {
            //   id: "",
            //   label: "附件归档"
            // },
          ];
        } else {
          item.showButton = [
            {
              id: "",
              label: this.$i18n.t("label.file.library.selectFile2Lib"), //添加文件
            },
            {
              id: "d01",
              label: this.$i18n.t("chatter.down"), //"下载"
            },
            // {
            //   id: "",
            //   label: "附件归档"
            // },
          ];
        }
      } else if (
        item.objName === "tp_sys_contactrole" &&
        this.objectApi === "Opportunity"
      ) {
        //联系人角色（只有在业务机会下有关系网）
        item.showButton = [
          // {
          //   id: "",
          //   label: this.$i18n.t("label.add"), //"添加",
          // },
          {
            id: "",
            label: this.$i18n.t("button_partner_workspace_manage"), //"管理",
          },
          // {
          //   id: "",
          //   label: this.$i18n.t("vue_label_commonobjects_detail_network"), //关系网
          // },
        ];
      } else if (item.objName === "Contact" && this.objectApi === "Account") {
        //联系人（只有在客户下有关系网）
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("vue_label_commonobjects_detail_network"), //关系网
          },
        ];
        this.newDisplayFun(item);
      } else if (item.objName === "tp_sys_opportunity_bookmarks") {
        //类似业务机会
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label_tabpage_findz"), //"查找"
          },
        ];
      } else if (item.objName === "track" || item.objName === "Marketsea") {
        //字段跟踪历史和客户公海池字段跟踪
        item.showButton = [];
      } else if (item.objName === "blockprice") {
        //分块定价
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label.batcheditor"), //"批量编辑",
          },
        ];
        this.newDisplayFun(item);
      } else if (
        (item.objName === "cloudcccase" &&
          item.recordObjId === "cloudcc_k_article") ||
        (item.objName === "WorkOrder" &&
          item.recordObjId === "cloudcc_k_article") ||
        (item.objName === "CloudccKArticle" &&
          item.recordObjId === "WorkOrderstdobj02021") ||
        (item.objName === "CloudccKArticle" &&
          item.recordObjId === "201100000005024CxOSz")
      ) {
        // 知识文章下个案列表按钮和个案下知识文章列表右上角按钮隐藏
        // 知识文章下工作订单列表按钮和工作订单下知识文章列表右上角按钮隐藏
        item.showButton = [];
      } else if (
        item.objName === "ClouddCCSearchTerm" &&
        item.recordObjId === "cloudcc_k_article" &&
        (this.typedisabled == this.$i18n.t("label.knowledgebase.draft") ||
          this.typedisabled == "已归档")
      ) {
        // "草稿"
        //知识文章下搜索性能词列表右上角按钮隐藏
        item.showButton = [];
      } else if (item.objName === "ServiceEvaluation") {
        //服务评价
        item.showButton = [];
      } else if (item.objName === "campaignform") {
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label.ems.create"), //"新建",
          },
        ];
      } else {
        item.showButton = [];
        this.newDisplayFun(item);
      }

      // 自定义按钮
      item.buttons.forEach((btn) => {
        //0521先注掉客户下联系人架构图按钮
        if (
          btn.category === "CustomButton" &&
          btn.name !== "Architecture diagram"
        ) {
          item.showButton.push({
            id: btn.id,
            label: btn.label,
            category: btn.category,
            behavior: btn.behavior,
            event: btn.event,
            function_code: btn.function,
          });
        }
      });
    },
    // 相关列表按钮权限判断
    ifListButtonShow(label, each) {
      if (
        label === this.$i18n.t("pagecreator_page_button_edit") &&
        each.listObjModify
      ) {
        //编辑
        return true;
      } else if (
        label === this.$i18n.t("component_setup_tabs_label_delete") &&
        each.listObjDelete
      ) {
        //删除
        return true;
      } else if (
        label !== this.$i18n.t("pagecreator_page_button_edit") &&
        label !== this.$i18n.t("component_setup_tabs_label_delete") &&
        each.listObjModify
      ) {
        //编辑   删除
        return true;
      }
      return false;
    },
    getPricebook(pricebookItems) {
      this.pricebookArr = pricebookItems;
      this.$emit("getPricebook", pricebookItems);
      this.list[0].relationSize = pricebookItems.length;
    },
    //查看更多
    seeMove(val) {
      if (val.relationName) {
        if (this.$store.state.navigationStyle) {
          let menuObj = {
            name: val.objLabel,
            id: val.recordId,
            routerPath: `/commonObjects/console-multi-screen/console-commonObjects/suspension-list/${this.id}/${val.attachementType}`,
            query: {
              relatedlistId: val.relatedlistId,
              layoutId: val.layoutId,
              objId: this.objId,
              state: this.typedisabled,
            },
          };
          window.$CCDK.CCMenu.addMenu2(menuObj);
          this.$router.push({
            path: `/commonObjects/console-multi-screen/console-commonObjects/suspension-list/${this.id}/${val.attachementType}`,
            query: {
              relatedlistId: val.relatedlistId,
              layoutId: val.layoutId,
              objId: this.objId,
              state: this.typedisabled,
            },
          });
        } else {
          this.$router.push({
            path: `/commonObjects/suspension-list/${this.id}/${val.attachementType}`,
            query: {
              relatedlistId: val.relatedlistId,
              layoutId: val.layoutId,
              objId: this.objId,
              state: this.typedisabled,
            },
          });
        }
      } else {
        this.list.forEach((item) => {
          if (item.relatedlistId == val.id) {
            // 判断控制台样式情况下打开，点击相关列表打开二级菜单
            if (this.$store.state.navigationStyle) {
              let menuObj = {
                name: item.relationName,
                id: item.relatedlistId,
                routerPath: `/commonObjects/console-multi-screen/console-commonObjects/suspension-list/${this.id}/${item.attachementType}`,
                query: {
                  relatedlistId: item.relatedlistId,
                  layoutId: item.layoutId,
                  objId: this.objId,
                  relatedlistType: item.relatedlistType,
                  state: this.typedisabled,
                },
              };
              window.$CCDK.CCMenu.addMenu2(menuObj);
              this.$router.push({
                path: `/commonObjects/console-multi-screen/console-commonObjects/suspension-list/${this.id}/${item.attachementType}`,
                query: {
                  relatedlistId: item.relatedlistId,
                  layoutId: item.layoutId,
                  objId: this.objId,
                  relatedlistType: item.relatedlistType,
                  state: this.typedisabled,
                },
              });
            } else {
              this.$router.push({
                path: `/commonObjects/suspension-list/${this.id}/${item.attachementType}`,
                query: {
                  relatedlistId: item.relatedlistId,
                  layoutId: item.layoutId,
                  objId: this.objId,
                  relatedlistType: item.relatedlistType,
                  state: this.typedisabled,
                },
              });
            }
          }
        });
      }
    },
    //点击菜单
    menuBtn(item) {
      let PageId = document.querySelector(`#${item.relatedlistId || item.id}`);
      if (PageId && PageId.offsetTop !== 0) {
        this.$emit("setScroll", PageId.offsetTop);
      }
    },
    //转换悬浮列表类型
    conversionType(arr) {
      if (arr.fieldList) {
        arr.fieldList.forEach((item, idx) => {
          // 名称、查找、主详、查找多选均可跳转
          if (
            item.name === "name" ||
            item.type === "Y" ||
            item.type === "M" ||
            item.type === "MR" ||
            item.name === "subject" ||
            item.lookupObj === "user" ||
            item.name === "whoid" ||
            item.name === "relateid"
          ) {
            this.$set(arr.fieldList[idx], "click", "detail");
          }
          //文件字段转换字段名称
          if (item.type === "FL") {
            arr.data.forEach((childVal) => {
              this.$set(
                childVal,
                item.name + "List",
                childVal[item.name + "ccfilelist"]
              );
            });
          }
          //复选框值为1时是选中状态
          if (item.type === "B") {
            arr.data.forEach((childVal) => {
              if (childVal[item.name] == 1) {
                this.$set(childVal, item.name, "true");
              } else {
                this.$set(childVal, item.name, "false");
              }
            });
          }
          this.$set(arr.fieldList[idx], "sortByThis", false);
          this.$set(arr.fieldList[idx], "sortDir", "asc");
          this.$set(arr.fieldList[idx], "fixed", false);
          this.$set(arr.fieldList[idx], "locked", false);
          item.schemefieldType = item.type;
          item.schemefieldName = item.name;
          item.schemefieldLength = item.length;
          item.apiname = item.name;
          item.nameLabel = item.label;
        });
      }

      this.$set(arr.services, "modifyAll", false);
      this.$set(arr.services, "modify", false);
    },
    //获取悬浮列表信息
    moveBtn(i, item) {
      //获取相关列表 悬浮框位置
      let title_Id = document.querySelector(".tit88 ");
      let heightTop =
        document.documentElement.clientHeight - title_Id.offsetTop - 180;
      this.titOffsetTop = heightTop <= 120 ? 300 : heightTop;

      // if (this.objId === "account") {
      //   this.itemArr = this.$refs.relationListObj[i - 1].objectInfo;
      // } else {
      //   this.itemArr = this.$refs.relationListObj[i].objectInfo;
      // }
      this.itemArr = this.$refs?.relationListObj[i].objectInfo;
      this.conversionType(this.itemArr);
      this.pageObj.dataList =
        this.itemArr.data && this.itemArr.data.slice(0, 10);
      this.$nextTick(() => {
        this.$refs.sespen[i] &&
          this.$refs.sespen[i].$refs.tablePanel &&
          this.$refs.sespen[i].$refs.tablePanel.changeTableAttr(
            this.itemArr.fieldList
          );
      });
      if (item.relatedlistType === "approvalHistory") {
        getApprovalHistoryList({ relatedId: this.id }).then((res) => {
          this.historyData = res.data;
        });
      }
    },
    /**
     * 服务控制台跳转方法
     * @param {Object} tabObj {id:对象id,value:对象名称}
     *
     */
    consoleJump(tabObj) {
      let path = "";
      // 导航样式为控制台样式
      if (this.$store.state.navigationStyle) {
        // 是
        path = `/commonObjects/console-multi-screen/console-detail/${tabObj.id}/DETAIL?dataId=${tabObj.id}`;
        // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
        // 当前一级tab的对象
        let currentLevelTab = {};
        this.consoleData.data.list.forEach((level1Item) => {
          if (level1Item.id == this.consoleData.data.level1Id) {
            // 设置二级带单选中样式
            level1Item.level2Id = tabObj.id;
            // 当前一级tabid
            currentLevelTab.id = level1Item.id;
            // 当前一级tabname
            currentLevelTab.name = level1Item.name;
            // 当前一级菜单路由
            currentLevelTab.routerName = level1Item.routerName || "";
            currentLevelTab.routerPath = level1Item.routerPath || "";
            // 当前一级菜单路由参数
            currentLevelTab.params = level1Item.params || "";
            currentLevelTab.query = level1Item.query || "";
            level1Item.children = level1Item.children
              ? level1Item.children
              : [];
            // 判断children里是否存在此点击项 不存在再添加避免重复添加
            // 判断是否存在此点击项
            let res = level1Item.children?.some((cItem) => {
              return cItem.id == tabObj.id;
            });
            if (!res) {
              // 如果不存在再push
              level1Item.children.push({
                id: tabObj.id,
                name: tabObj.value,
              });
              // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则添加到第一项
              let flag = level1Item.children.some((cItem) => {
                return cItem.id == this.consoleData.data.level1Id;
              });
              if (!flag) {
                level1Item.children.unshift(currentLevelTab);
              }
            }
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      } else {
        path = `/commonObjects/detail/${tabObj.id}/DETAIL`;
      }
      return path;
    },
    // 详情
    detailBtn(each, item) {
      if (item.attachtype === "dropbox") {
        let tempwindow = window.open("_blank");
        tempwindow.location = item.url;
      } else {
        if (each.objName === "Attachement") {
          let baseURL = this.$baseConfig.baseURL;
          let token = this.$CCDK.CCToken.getToken();
          var link = document.createElement("a");
          link.setAttribute("download", "");
          link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${item.id}`;
          link.click();
        } else if (each.objName === "tp_sys_contactrole") {
          this.$router.push({
            path: `/commonObjects/detail/${item.contact}/DETAIL`,
            query: {
              viewId: this.viewId,
            },
          });
        } else if (
          item.objApi &&
          item.objApi === "Contact" &&
          each.objName === "Contact"
        ) {
          // 个人客户下的联系人相关列表(卡片式)，name字段跳转
          this.$router.push({
            path: `/commonObjects/detail/${each.recordId}/DETAIL`,
          });
        } else {
          let tabObj = {
            id: item.id,
            value: item.name,
          };
          let path = this.consoleJump(tabObj);
          this.$router.push({
            path: path,
            query: {
              viewId: this.viewId,
            },
          });
        }
      }
    },
    attachBtn(arr) {
      if (arr.data.length > 0) {
        let excelList = [];
        arr.data.forEach((val) => {
          if (val.attachChecked && val.attachChecked === true) {
            let baseURL = this.$baseConfig.baseURL;
            let token = this.$CCDK.CCToken.getToken();
            excelList.push(
              `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${val.id}`
            );
          }
        });
        //判断如果都没有选中
        let checked = arr.data.every((item) => {
          return item.attachChecked === undefined;
        });
        //都没有选中的时候提示
        if (checked === true) {
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.unselectfile"),
            type: "warning",
          });
        }
        for (let i = 0; i < excelList.length; i++) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none"; // 防止影响页面
          iframe.style.height = 0; // 防止影响页面
          iframe.src = excelList[i];
          document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
          // 5分钟之后删除
          setTimeout(() => {
            iframe.remove();
          }, 5 * 60 * 1000);
        }
      } else {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label.unselectfile"),
          type: "warning",
        });
        return false;
      }
    },
    // 标签点击事件
    handleClick() {},
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.isConfirmDel = "";
      this.delContent = this.$i18n.t("label.weixin.confirm.delete");
    },
    // 新建编辑弹窗关闭
    closeDialog() {
      this.dialogAttr.isShow = false;
    },
    // 父组件通过ref调用
    init() {
      this.objArr = [];
      this.validRule = true;
      this.showDetail = false;
      this.isSkeleton = true;
      // 先判断记录有没有编辑权限
      this.$nextTick(() => {
        // 非锁定且可编辑状态才可编辑
        this.isEdit = this.systemManager || this.$parent.isEdit;
        this.isQuery = this.$parent.isQuery || false;

        // 管理员或非锁定有更改所有人权限时显示 更改所有人按钮
        this.canEditOwnerData =
          this.systemManager || (!this.isLocked && this.canEditOwner) || false;
        if (this.isQuery) {
          this.showDetail = true;
        }
        this.operation = "DETAIL";
        this.getFormAttr("onLoad");
      });
      this.ownerRaletionList = this.optional;
      this.listFlag =
        localStorage.getItem("relatedList") === "true" ? true : false;
      this.inlineedit =
        localStorage.getItem("inlineedit") === "true" ? true : false;
      this.$parent.inlineedit = this.inlineedit;
    },
    cut() {
      this.$router.push({
        path: "/commonObjects/customer",
        query: {
          ids: this.$router.history.current.params.id,
          type: "Contact",
          content: this.objectApi,
        },
      });
    },
    //添加到价格手册
    addPrice(pricebookItems) {
      this.pricecurrencyList = [];
      this.currencyOption = [];
      // 标准价格手册条目
      pricebookItems.forEach((item) => {
        if (item.pricebookId === "01s00000000000000001") {
          this.pricecurrencyList.push({
            currency: item.currency,
            listPrice: item.listPrice,
          });
        }
      });
      let copyPricecurrencyList = JSON.parse(JSON.stringify(pricebookItems));
      //处理添加到价格手册的币种问题
      this.currencyOption = this.mapDataQu(copyPricecurrencyList);
      // 新建价格手册产品条目弹窗内 币种字段默认首选项
      if (
        this.currencyOption.length > 0 &&
        this.addmanualForm.pricecurrency === ""
      ) {
        this.addmanualForm.pricecurrency = this.currencyOption[0].currency;
      }
      CommonObjApi.addCustomPricebookItems({ productId: this.id }).then(
        (res) => {
          this.addmanualForm.name = res.data.product.name;
          this.addmanualForm.cpdm = res.data.product.cpdm;
          res.data.pricebooklist.forEach((item) => {
            this.customPrice.push({
              value: item.name,
              currency: item.currency,
              pricebooklistid: item.id,
            });
          });
        }
      );
    },
    // 审批操作项
    openApproval(i, detailGroupId, recordId) {
      if (i == 0) {
        if (this.approvalSubmitStatus) {
          this.approvalSubmitStatus = false;
          this.$refs.relationListObj &&
            this.$refs.relationListObj.forEach((relationObj) => {
              if (
                relationObj.objectInfo.relatedlistType === "approvalHistory"
              ) {
                relationObj.handleHistory(i, detailGroupId, recordId);
              }
            });
        } else {
          // this.$message.warning('等一下')
        }
      } else {
        this.$refs.relationListObj &&
          this.$refs.relationListObj.forEach((relationObj) => {
            if (relationObj.objectInfo.relatedlistType === "approvalHistory") {
              relationObj.handleHistory(i, detailGroupId, recordId);
            }
          });
      }
    },
    /**
     * 验证权限：verifyPermissions
     * @param {String} buttonType:按钮类型（或者是label）
     * @param {Object} objectInfo:相关列表权限详细信息
     * @return {Boolean} isPassed:校验权限是否通过
     */
    verifyPermissions(buttonType, objectInfo = {}) {
      let isPassed = true;
      if (objectInfo.constructor === Object) {
        // 新增、批量新增
        if (
          buttonType === this.$i18n.t("label.batchadd") ||
          buttonType === this.$i18n.t("label.ems.create")
        ) {
          isPassed = objectInfo.add;
          if (!objectInfo.add) {
            this.$message.warning(this.$i18n.t("c1035"));
          }
        }
      }

      return isPassed;
    },
    /**
     * 保存考勤人员
     */
    handleChangeKaoqin(data) {
      this.$Bus.$emit("KaoqinLoading", true);
      this.ChangeKaoqinLoading = true;
      let option = {
        users: data.map((item) => item.id).join(","),
        attendancegroupId: this.id,
      };
      CommonObjApi.saveUserInGroup(option).then((res) => {
        if (res.result) {
          this.getAttendanceStaff();
          this.ChangeKaoqinLoading = false;
          this.KaoqinDialog = false;
        }
      });
    },
    // 相关列表右上角按钮点击方法
    buttonFun(n = {}, objectInfo = {}, pricebookItems) {
      // 权限校验
      const isPassed = this.verifyPermissions(n.label, objectInfo.services);
      if (!isPassed) return;
      //相关列表每个对象的信息
      this.relatedListObj = objectInfo;
      this.DeletName = objectInfo.objName;
      this.listObjectId = objectInfo.objid;
      this.listId = objectInfo.id;
      // 相关列表匹配改为使用relationFieldId
      let objInfo = this.list.find((item) => {
        let flag = null;
        if (item.relationFieldId === "none" || !item.relationFieldId) {
          flag = item.relationName === objectInfo.objLabel;
        } else {
          flag = item.relationFieldId === objectInfo.relationFieldId;
        }
        return flag;
      });
      if (objInfo !== undefined) {
        this.$set(
          this.relatedListObj,
          "relationFieldId",
          objInfo.relationFieldId
        );
        this.$set(this.relatedListObj, "relatedListFlag", true);
      }
      if (
        objectInfo.objName === "product" &&
        n.label === this.$i18n.t("label.partnerCloud.seriesSettings")
      ) {
        // 产品系列设置 在新标签页打开lightning设置-平台设置-产品系列设置页面
        window.open("/#/systemSettings/setuppage/productSetting");
      } else if (
        objectInfo.objName === "opportunityteam" &&
        n.label === this.$i18n.t("vue_label_commonobjects_detail_team")
      ) {
        //"管理小组"
        this.popTableVisible = true;
        this.opportunityId = objectInfo.id;
        this.$refs.popUpteam.init();
      } else if (n.label === this.$i18n.t("UG_Account_05")) {
        //联系人架构图
        this.cut();
      } else if (
        objectInfo.objName === "opportunitypdt" &&
        n.name === "selectpricebooks" //"选择价格手册"按钮
      ) {
        this.listData = objectInfo.data;
        this.manualVisible = true;
        this.$refs.popUpteam.manualInit();
      } else if (
        objectInfo.objName === "opportunitypdt" &&
        n.name === "tjywjhcp" // "添加产品"按钮
      ) {
        masterRecordDetailInfo({
          objId: "opportunity",
          recordId: this.id,
        }).then((res) => {
          if (res?.data[0]?.pricebook2id) {
            this.pricebook2idMainProp = res.data[0].pricebook2id;
          } else {
            this.pricebook2idMainProp = "";
          }
          this.$nextTick(() => {
            this.addmanualChange();
          });
        });
        // // 业务机会相关列表子产品是否为空
        // if (this.relatedListObj.data.length === 0) {
        //   // 选择价格手册
        //   this.$refs.popUpteam.manualInit();
        // } else {
        //   // 判断有没有价格手册
        //   this.$refs.popUpteam.isManual();
        // }
        // return;
      } else if (
        objectInfo.objName === "opportunitypdt" &&
        n.name === "editall" // "编辑全部"按钮
      ) {
        this.$refs.popUpteam.productsInit();
        this.productsVisible = true;
      } else if (objectInfo.objName === "quotedetail") {
        // //"添加报价明细"
        // this.$refs.quotation.singleManualInit();
        // 只要是报价单明细相关列表  进入报价单编辑页
        // 编辑
        CommonObjApi.getPermissionById({ id: this.$route.params.id }).then(
          (res) => {
            if (res.data.isEdit) {
              // 如果是日程预约，不用标准弹窗。
              if (this.objId === "appointment") {
                this.$router.push({
                  path: "/scheduleappointment/bookingdetails",
                  query: {
                    id: this.id,
                  },
                });
              } else {
                this.goToDetail("quote", objectInfo.recordObjId);
              }
            } else {
              // 抱歉，您无权编辑该条数据，请联系系统管理员。
              this.$message(this.$i18n.t("label.notEdit.message"));
            }
          }
        );
      } else if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.manageAll.sta4")
      ) {
        //调回批准请求
        this.openApproval(1, this.detailGroupId, this.dataId);
      } else if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.submitforapproval")
      ) {
        //提交待审批
        this.openApproval(0, this.detailGroupId, this.dataId);
      } else if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.approveorrefuse")
      ) {
        //进行审批
        this.openApproval(2, this.detailGroupId, this.dataId);
      } else if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.redistribution")
      ) {
        //"重新分配"
        this.openApproval(3, this.detailGroupId, this.dataId);
      } else if (n.id === "relateToUser") {
        // 考勤人员
        CommonObjApi.getPermissionById({ id: this.$route.params.id }).then(
          (res) => {
            if (res.data.isEdit) {
              this.fieldId = "";
              this.relevantObjId = "user";
              this.relevantPrefix = "005";
              this.KaoqinDialog = true;
              this.$nextTick(() => {
                this.$refs.searchUserTable.page = 1;
                this.$refs.searchUserTable.pageObj.dataList = [];
                this.$refs.searchUserTable.searchConditions = "";
                this.$refs.searchUserTable.init();
              });
            } else {
              // 抱歉，您无权编辑该条数据，请联系系统管理员。
              this.$message(this.$i18n.t("label.notEdit.message"));
            }
          }
        );
      } else if (
        objectInfo.relatedlistType === "custom" &&
        n.label === this.$i18n.t("label.add")
      ) {
        //"添加市场活动成员"
        this.showAddCampaignMembers = true;
      } else if (n.label === this.$i18n.t("label.emailobject.groupemail")) {
        //群发邮件
        Emailobject.getEmailPageInfo({}).then((result) => {
          if (result.data && result.data.relatedActiveMailSetting == true) {
            if (objectInfo.data.length > 0) {
              let obj = JSON.parse(JSON.stringify(objectInfo.data));
              obj.forEach((res) => {
                res.type = res.contact == null ? "lead" : "contact";
              });
              this.$Bus.$emit("deliver-market-activity", obj);
            } else {
              this.$Bus.$emit("deliver-zero-array", objectInfo.data);
            }
          } else {
            this.$router.push({ path: "/relation" });
          }
        });
      } else if (n.label === this.$i18n.t("label.version.allsms")) {
        //群发短信
        this.dialogTableVisible = true;
      } else if (
        objectInfo.objName === "Attachement" &&
        n.label === this.$i18n.t("label.file.library.selectFile2Lib")
      ) {
        //添加文件
        // this.$refs.addwork.tabWorkFun();
        this.$refs.atoLib.initPage();
        this.dialogAddVisible = true;
      } else if (
        objectInfo.objName === "Attachement" &&
        n.label === this.$i18n.t("chatter.down")
      ) {
        //"下载"
        this.attachBtn(objectInfo);
      } else if (n.label === this.$i18n.t("label.ems.create")) {
        // 新建
        if (objectInfo.objid === "2021cloudccorderitem") {
          this.$nextTick(() => {
            this.$refs.editObj.objectApi = "cloudccorder";
            this.$refs.editObj.objId = "20210525cloudccorder";
            this.$refs.editObj.edit();
          });
        } else {
          if (
            objectInfo.objName === "cloudccquoteline" &&
            objectInfo.recordObjId === "201100000006130OosPK"
          ) {
            this.$parent.editProduct();
          } else if (
            objectInfo.objName === "quotelinegroup" &&
            objectInfo.recordObjId === "201100000006130OosPK"
          ) {
            this.$parent.editProduct();
          } else if (
            objectInfo.objName === "InvoicesItems" &&
            objectInfo.recordObjId === "invoices"
          ) {
            this.goToDetail("Invoices", objectInfo.recordObjId);
          } else if (
            objectInfo.objName === "purchaseorderitem" &&
            objectInfo.recordObjId === "2021127purchaseorder"
          ) {
            // 相关列表右上角新建
            this.goToDetail("cloudccpurchaseorder", objectInfo.recordObjId);
          } else if (
            objectInfo.objName === "Invoices" &&
            objectInfo.recordObjId === "20210525cloudccorder"
          ) {
            let obj = {
              name: "Generate Invoice",
            };
            this.$parent.recordOperationBtn(obj);
          } else if (
            objectInfo.recordObjId === "opportunity" &&
            objectInfo.prefix === "011"
          ) {
            // 业务机会新建报价单逻辑特殊,需要带值,走详情页右上角新建按钮方法
            this.$parent.recordOperationBtn({ name: "New Quote" });
          } else if (
            (objectInfo.recordObjId === "opportunity" ||
              objectInfo.recordObjId === "201100000006130OosPK") &&
            objectInfo.prefix === "032"
          ) {
            // 业务机会、报价单新建订单逻辑特殊,需要带值,走详情页右上角新建按钮方法
            this.$parent.recordOperationBtn({ name: "New Order" });
          } else if (
            (objectInfo.recordObjId === "opportunity" ||
              objectInfo.recordObjId === "201100000006130OosPK") &&
            objectInfo.prefix === "021"
          ) {
            // 业务机会、报价单新建发票逻辑特殊,需要带值,走详情页右上角新建按钮方法
            this.$parent.recordOperationBtn({ name: "New Invoice" });
          } else if (objectInfo.objName === "cloudccustomeraddress") {
            //新建客户地址
            //查询当前客户是否有默认地址
            CommonObjApi.isDefaultAddress({
              customerId: objectInfo.recordId,
            }).then((res) => {
              // defaultBilling: false
              // defaultShipping: false
              let obj = res.data;
              if (this.$store.state.navigationStyle) {
                // 如果是控制台样式应用程序，打开二级菜单新建
                this.$CCDK.CCPage.openCreatePage(
                  {
                    objectName: this.$i18n.t("label.ems.createTask", {
                      taskName: objectInfo.objLabel,
                    }),
                    objId: objectInfo.objid,
                    objectApi: objectInfo.objName,
                    prefix: objectInfo.prefix,
                    addParam: [obj, "cloudccustomeraddress"],
                  },
                  {
                    openPlace: "menu2",
                    openMode: "_blank",
                  }
                );
              } else {
                this.recordType = "";
                this.dialogNewAttr.id = "";
                this.listObjectApi = objectInfo.objName;
                this.listPrefix = objectInfo.prefix;
                this.$nextTick(() => {
                  this.$refs.createEditObj.add(obj, "cloudccustomeraddress");
                });
              }
            });
          } else if (objectInfo.objName === "campaignform") {
            //表单营销推广
            this.$refs.formMarketingPromotion.dialogFormVisible = true;
            this.dialogNewAttr.id = "";
            this.$nextTick(() => {
              this.$refs.formMarketingPromotion.add();
            });
          } else {
            if (this.$store.state.navigationStyle) {
              // 如果是控制台样式应用程序，打开二级菜单新建
              this.$CCDK.CCPage.openCreatePage(
                {
                  objectName: this.$i18n.t("label.ems.createTask", {
                    taskName: objectInfo.objLabel,
                  }),
                  objId: objectInfo.objid,
                  objectApi: objectInfo.objName,
                  prefix: objectInfo.prefix,
                  relativeRecordId: this.id,
                  relationFieldId: this.relatedListObj.relationFieldId,
                },
                {
                  openPlace: "menu2",
                  openMode: "_blank",
                }
              );
            } else {
              // "新建"
              this.recordType = "";
              this.dialogNewAttr.id = "";
              this.listObjectApi = objectInfo.objName;
              this.listPrefix = objectInfo.prefix;
              this.$nextTick(() => {
                this.$refs.createEditObj.add();
              });
            }
          }
        }
      } else if (n.label === this.$i18n.t("label.batchadd")) {
        // 批量新增
        if (objectInfo.objid === "2021cloudccorderitem") {
          this.$nextTick(() => {
            this.$refs.editObj.objectApi = "cloudccorder";
            this.$refs.editObj.objId = "20210525cloudccorder";
            this.$refs.editObj.edit();
          });
        } else {
          if (
            objectInfo.objName === "cloudccquoteline" &&
            objectInfo.recordObjId === "201100000006130OosPK"
          ) {
            this.$parent.editProduct();
          } else if (
            objectInfo.objName === "quotelinegroup" &&
            objectInfo.recordObjId === "201100000006130OosPK"
          ) {
            this.$parent.editProduct();
          } else if (
            objectInfo.objName === "InvoicesItems" &&
            objectInfo.recordObjId === "invoices"
          ) {
            this.goToDetail("Invoices", objectInfo.recordObjId);
          } else if (
            objectInfo.objName === "purchaseorderitem" &&
            objectInfo.recordObjId === "2021127purchaseorder"
          ) {
            this.goToDetail("cloudccpurchaseorder", objectInfo.recordObjId);
          } else {
            //批量新增
            this.listPrefix = objectInfo.prefix;
            this.listObjectApi = objectInfo.objName;
            this.batchAddDialog = true;
            this.$nextTick(() => {
              this.$refs.batchAdd && this.$refs.batchAdd.init();
              this.$refs.batchAdd && this.$refs.batchAdd.getRecordType();
            });
            this.batchAddDialogTitle = this.$i18n.t("label.object.batchadd", {
              objName: objectInfo.objLabel,
            });
          }
        }
      } else if (
        // "添加标准价格"
        objectInfo.objLabel === this.$i18n.t("lable.opppdt.selPbk.pricebook") &&
        n.label === this.$i18n.t("vue_label_pricebook_price")
      ) {
        this.pricebookdialogVisible = true;
        this.addStdPrice();
      } else if (
        objectInfo.objLabel === this.$i18n.t("lable.opppdt.selPbk.pricebook") &&
        n.label === this.$i18n.t("lable.product.pricebooks.add")
      ) {
        //添加到价格手册
        this.addPricebookdialogVisible = true;
        this.addPrice(pricebookItems);
      } else if (
        objectInfo.objName === "tp_sys_contactrole" &&
        n.label === this.$i18n.t("label.add")
      ) {
        this.addContact = true;
        this.$refs.popUpteam.addContactOpen();
        this.$refs.popUpteam.contactSelectArr = [];
      } else if (
        objectInfo.objName === "tp_sys_contactrole" &&
        n.label === this.$i18n.t("button_partner_workspace_manage")
      ) {
        //"管理"
        this.$refs.popUpteam.getInit();
        this.contactsVisible = true;
        // 业务机会 "关系网"
      } else if (
        objectInfo.objName === "tp_sys_contactrole" &&
        n.label === this.$i18n.t("vue_label_commonobjects_detail_network")
      ) {
        // 跳转到联系人架构图
        // 在点击关系网之前，保存当前的url，然后跳转到关系网，在关系网的面包屑中做返回到改对象使用
        this.$store.commit(
          types.SET_STRUCTURE_BEFORE_CLICK_SAVE_PATH,
          this.$route.fullPath
        );
        this.$router.push(
          `/structureChart/${this.id}/${this.bigTitle}/${this.objId}`
        );
      } else if (
        objectInfo.objName === "Contact" &&
        n.label === this.$i18n.t("vue_label_commonobjects_detail_network")
      ) {
        // 客户 "关系网"  跳转到联系人架构图
        // 在点击关系网之前，保存当前的url，然后跳转到关系网，在关系网的面包屑中做返回到改对象使用
        this.$store.commit(
          types.SET_STRUCTURE_BEFORE_CLICK_SAVE_PATH,
          this.$route.fullPath
        );
        this.$router.push(
          `/structureChart/${this.id}/${this.bigTitle}/${this.objId}`
        );
      } else if (
        objectInfo.objName === "tp_sys_opportunity_bookmarks" &&
        n.label === this.$i18n.t("label_tabpage_findz")
      ) {
        //"查找"
        this.$refs.opportunities.dialogVisibles();
        this.$refs.opportunities.each(objectInfo);
      } else if (
        objectInfo.objName === "AccountTeam" &&
        n.label === this.$i18n.t("vue_label_commonobjects_detail_team")
      ) {
        //"客户小组"
        this.popTableVisible = true;
        this.opportunityId = objectInfo.id;
        this.$refs.popUpteam.init();
      } else if (
        objectInfo.objName === "blockprice" &&
        n.label === this.$i18n.t("label.batcheditor")
      ) {
        //"批量编辑"
        this.$router.push({
          path: `/quotation/blockPricing`,
          query: {
            id: this.id,
          },
        });
      } else if (
        objectInfo.objName === "cloudccorderitem" &&
        n.label === this.$i18n.t("label.batcheditor")
      ) {
        this.$nextTick(() => {
          this.$refs.editObj.objectApi = "cloudccorder";
          this.$refs.editObj.objId = "20210525cloudccorder";
          this.$refs.editObj.edit();
        });
      } else if (
        objectInfo.relatedlistType === "caseteam" &&
        n.id === "updateCaseteam"
      ) {
        this.caseTeamDialog = true;
        this.$nextTick(() => {
          this.$refs.updateCaseTeam.init();
        });
      }
    },
    /**
     * 保存个案团队成员
     */
    async handleSaveCaseTeam() {
      let arr = this.$refs.updateCaseTeam.tableData.concat(
        this.$refs.updateCaseTeam.tableData2
      );
      /**
       * 必填项是否已经填写校验
       */
      let isComplete = true;
      arr.forEach((item) => {
        // 只检测类型是 成员的必填项
        if (item.type === "user") {
          if (item.relatedname && item.rolename) {
            // 通过
            isComplete = true;
          } else {
            isComplete = false;
          }
        }
      });

      if (!isComplete) {
        this.$message.error("请检查必填项");
        return;
      }

      let hash = {};
      const list = arr.reduce((preVal, curVal) => {
        hash[curVal.relatedid]
          ? ""
          : (hash[curVal.relatedid] = true && preVal.push(curVal));
        return preVal;
      }, []);

      let obj = {};
      let memberList = [];
      arr.forEach((item) => {
        if (obj[item.relatedid]) {
          memberList.push(item.relatedid);
        } else {
          obj[item.relatedid] = "has";
        }
      });
      if (memberList.length > 0) {
        this.$message.warning(this.$i18n.t("vue_label_normal_notice_repeated"));
        // this.$message.warning(this.$i18n.t("c2166"));
        return;
      }

      let finalList = [];
      list.forEach((item) => {
        let obj = {};
        if (item.type === "user" && item.relatedid && item.roleid) {
          obj = {
            roleid: item.roleid || "",
            relatedid: item.relatedid,
            type: item.type,
            accesslevel: item.accesslevel || "",
          };
          finalList.push(obj);
        } else if (item.type === "caseteam" && item.relatedid) {
          obj = {
            roleid: item.roleid || "",
            relatedid: item.relatedid,
            type: item.type,
            accesslevel: item.accesslevel || "",
          };
          finalList.push(obj);
        }
      });
      let params = {
        recordId: this.id,
        members: JSON.stringify(finalList),
      };
      const result = await CommonObjApi.saveCaseteamMember(params);
      if (result.result) {
        this.$message({
          showClose: true,
          type: "success",
          message: this.$i18n.t("savesuccess"), //保存成功
        });
        this.$Bus.$emit("freshCaseTeam");
        this.caseTeamDialog = false;
      }
    },
    //添加标准价格手册
    addStdPrice() {
      this.currencyList = [];
      this.loading = true;
      CommonObjApi.addStdPricebookItems({ productId: this.id }).then((res) => {
        let ret = res.data.currencyList;
        ret.forEach((item) => {
          var value = item.currencyCode + "-" + item.currencyName;
          this.currencyList.push({
            value: item.currencyName,
            label: value,
          });
        });
        this.manualForm.value8 = this.currencyList[0].label;
        res.data.pricebooklist.forEach((item) => {
          this.manualForm.pricebooklist = item.name;
          this.isenable = item.isenable;
          this.pricebooklistid = item.id;
        });
        this.manualForm.product = res.data.product.name;
        this.manualForm.cpdm = res.data.product.cpdm;
        // this.$emit('refreshAll')
        this.loading = false;
      });
    },
    // 数组去重
    mapDataQu(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].currency == arr[j].currency) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //添加到价格手册保存
    savepricebooklist() {
      this.$refs.addmanualForm.validate((valid) => {
        if (valid) {
          if (String(this.addmanualForm.bookprice).indexOf(".") + 1 !== 0) {
            if (
              this.addmanualForm.bookprice.replace(/\d+\.(\d*)/, "$1") === ""
            ) {
              this.addmanualForm.bookprice += "00";
            } else if (
              this.addmanualForm.bookprice.replace(/\d+\.(\d*)/, "$1")
                .length === 1
            ) {
              this.addmanualForm.bookprice += "0";
            }
          }
          let listPrice = "";
          let currency = "";
          let usestdPrice = "false";
          if (this.checked5.length == 1) {
            listPrice = this.checked5[0].listPrice;
            currency = this.checked5[0].currency;
            usestdPrice = "true";
          } else if (this.checked5.length == 0) {
            listPrice = this.addmanualForm.bookprice;
            currency = this.addmanualForm.pricecurrency;
            usestdPrice = "false";
          }
          // 当前选中币种的标准价格值
          let productstandardprice = "";
          this.pricecurrencyList.forEach((item) => {
            if (item.currency === currency) {
              productstandardprice = item.listPrice;
            }
          });
          let params = {
            isenable: this.checked4,
            pricebookId: this.pricebooklistid,
            itemid: "",
            productId: this.id,
            useStdPrice: usestdPrice,
            listPrice: listPrice,
            currency: currency,
            productstandardprice: productstandardprice,
          };
          CommonObjApi.saveProductPricebookItem(params).then(() => {
            this.closepricebookdialogVisible();
            this.blockRefresh();
            this.$refs.relationListObj &&
              this.$refs.relationListObj[0].querypricebooklist();
            this.bookpriceDisabled = false;
          });
        }
      });
    },
    //保存标准价格
    savepricebook() {
      this.$refs.manualForm.validate((valid) => {
        if (valid) {
          if (String(this.manualForm.input8).indexOf(".") + 1 !== 0) {
            if (this.manualForm.input8.replace(/\d+\.(\d*)/, "$1") === "") {
              this.manualForm.input8 += "00";
            } else if (
              this.manualForm.input8.replace(/\d+\.(\d*)/, "$1").length === 1
            ) {
              this.manualForm.input8 += "0";
            }
          }
          var str = this.manualForm.value8.split("-");
          var strr = JSON.parse(JSON.stringify(str[0]));
          let params = {
            isenable: this.checked3,
            pricebookId: this.pricebooklistid,
            itemid: "",
            productId: this.id,
            useStdPrice: this.checked6, //是否使用标准价格
            listPrice: this.manualForm.input8,
            currency: strr,
          };
          CommonObjApi.saveProductPricebookItem(params).then((res) => {
            if (res.result) {
              this.blockRefresh();
              this.$refs.relationListObj &&
                this.$refs.relationListObj[0].querypricebooklist();
            } else {
              this.$message.warning(res.returnInfo);
            }
            this.closepricebookdialogVisible();
          });
        }
      });
    },
    //删除价目表记录
    deletPriceItem(item, index1, row, pricebookItems, name) {
      this.DeletObj = item;
      this.DeletName = name;
      this.DeletItem1 = row;
      this.DeletId = row.id;
      this.Deletpricebook = pricebookItems;
      if (
        this.$store.state.userInfoObj.profileId === "aaa000001" ||
        name == "Attachement"
      ) {
        this.dialogVisible = true;
      } else {
        CommonObjApi.getPermissionById({ id: this.DeletId }).then((res) => {
          if (res.data.isDelete && !res.data.isLocked) {
            this.dialogVisible = true;
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_notice_nopermission_delete"),
              type: "warning",
            });
            //抱歉，您无权删除该条数据，请联系系统管理员。
          }
        });
      }
    },
    //产品详情页编辑价目表
    editPriceItem(item, item1, pricebookItems) {
      this.pricecurrencyList = [];
      CommonObjApi.getPermissionById({ id: item }).then((res) => {
        if (res.data.isEdit) {
          pricebookItems.forEach((ite) => {
            if (ite.pricebookId === "01s00000000000000001") {
              this.pricecurrencyList.push({
                currency: ite.currency,
                listPrice: ite.listPrice,
              });
            }
          });
          this.editPricebookdialogVisible = true;
          let params = {
            itemid: item,
          };
          CommonObjApi.editPricebookItem(params).then((res) => {
            this.editPriceData = res.data;
            this.itemInfo = res.data.itemInfo === null ? {} : res.data.itemInfo;
            // 重新存下原价目表价格
            this.$set(
              this.itemInfo,
              "oldStandardprice",
              this.itemInfo.standardprice
            );
            // 编辑时，"使用价格手册"状态启用
            if (this.itemInfo.usestdprice == "true") {
              this.pricecurrencyList = [];
              let obj = {
                currency: this.itemInfo.currency,
                listPrice: this.itemInfo.standardprice,
              };
              this.pricecurrencyList.push(obj);
              this.checked5 = this.pricecurrencyList;
              this.disabled = true;
            }
            // 字符串的布尔值换成布尔类型的
            if (this.itemInfo != null && this.itenInfo != {}) {
              this.itemInfo.isenable = JSON.parse(this.itemInfo.isenable);
            }
            this.itemid = res.data.itemInfo.itemid;
            if (res.data.itemInfo.pricebookid) {
              this.pricebookid = res.data.itemInfo.pricebookid;
            }
            this.currency = res.data.itemInfo.currency;
            this.pricebookname = res.data.itemInfo.pricebookname;
            res.data.pricebooklist.forEach((item) => {
              this.editPricebooklist.push({
                value: item.name,
                currency: item.currency,
                pricebooklistid: item.id,
              });
            });
          });
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t("report_label_norole"),
            type: "warning",
          });
          //"抱歉，您无权编辑该条数据，请联系系统管理员。"
        }
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    querySearch(queryString, cb) {
      var restaurants = this.editPricebooklist;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item) {
      this.pricebooklistid = item.pricebooklistid;
    },
    //添加到价格手册--使用标准价格
    changestd() {
      if (this.checked5.length == 1) {
        this.addmanualForm.bookprice = this.checked5[0].listPrice;
        this.bookpriceDisabled = true;
      } else if (this.checked5.length == 0) {
        this.addmanualForm.bookprice = null;
        this.bookpriceDisabled = false;
      }
    },
    // 编辑价格手册条目--使用标准价格
    editChangestd() {
      let oldStandardprice = this.itemInfo.oldStandardprice;
      if (this.checked5.length == 1) {
        this.itemInfo.standardprice = this.checked5[0].listPrice;
        this.disabled = true;
      } else if (this.checked5.length == 0) {
        this.itemInfo.standardprice = oldStandardprice;
        this.disabled = false;
      }
    },
    //产品详情页编辑价目表保存
    saveeditpricebook() {
      this.$refs.editmanualForm.validate((valid) => {
        if (valid) {
          if (String(this.itemInfo.standardprice).indexOf(".") + 1 !== 0) {
            if (
              this.itemInfo.standardprice.replace(/\d+\.(\d*)/, "$1") === ""
            ) {
              this.itemInfo.standardprice += "00";
            } else if (
              this.itemInfo.standardprice.replace(/\d+\.(\d*)/, "$1").length ===
              1
            ) {
              this.itemInfo.standardprice += "0";
            }
          }
          var currency = "";
          var pricebookId = "";
          var usestdPrice = "false";
          var listPrice = "";
          if (this.itemInfo.pricebookname === "Standard Price Book") {
            currency = this.currency;
            pricebookId = this.pricebookid;
            listPrice = this.itemInfo.standardprice;
          }
          if (
            this.itemInfo.pricebookname !== "Standard Price Book" &&
            this.checked5.length == 1
          ) {
            currency = this.checked5[0].currency;
            listPrice = this.checked5[0].listPrice;
            pricebookId = this.itemInfo.pricebookid;
            usestdPrice = "true";
          }
          if (
            this.itemInfo.pricebookname !== "Standard Price Book" &&
            this.checked5.length == 0
          ) {
            currency = this.itemInfo.currency;
            pricebookId = this.itemInfo.pricebookid;
            listPrice = this.itemInfo.standardprice;
            usestdPrice = "false";
          }
          // 当前选中币种的标准价格值
          let productstandardprice = "";
          this.pricecurrencyList.forEach((item) => {
            if (item.currency === currency) {
              productstandardprice = item.listPrice;
            }
          });
          let params = {
            isenable: JSON.stringify(this.itemInfo.isenable),
            pricebookId: pricebookId,
            itemid: this.itemid,
            productId: this.id,
            useStdPrice: usestdPrice,
            listPrice: listPrice,
            currency: currency,
            productstandardprice: productstandardprice,
          };
          CommonObjApi.saveProductPricebookItem(params).then(() => {
            this.closepricebookdialogVisible();
            this.blockRefresh();
            this.$refs.relationListObj &&
              this.$refs.relationListObj[0].querypricebooklist();
            this.disabled = false;
          });
        }
      });
    },
    //关闭添加标准价格
    closepricebookdialogVisible() {
      this.$refs.manualForm && this.$refs.manualForm.resetFields();
      this.$refs.addmanualForm && this.$refs.addmanualForm.resetFields();
      this.addPricebookdialogVisible = false;
      this.pricebookdialogVisible = false;
      this.editPricebookdialogVisible = false;
      this.addPricebookdialogVisible = false;
      this.pricecurrencyList = [];
      this.editPricebooklist = [];
      this.customPrice = [];
      this.currencyList = [];
      this.checked3 = true;
      this.checked4 = true;
      this.checked5 = [];
      this.checked6 = false;
      this.disabled = false;
      this.manualForm.value8 = "";
      this.manualForm.input8 = "";
    },
    //保存并新建
    addxinjian() {
      this.addStdPrice();
      this.savepricebook();
      setTimeout(() => {
        this.pricebookdialogVisible = true;
      }, 500);
    },
    closeAddCampaignMembersDialog() {
      this.showAddCampaignMembers = false;
      this.$emit("refreshAll");
    },
    /**
     * GetRelationList不在返回从记录数据条数，在getDetailRelatedItems中获取
     * 1、用于在getDetailRelatedItems接口中设置this.list中数据条数
     * @param {string} relatedlistId:从记录id
     * @param {number} relationSize:从记录RelationSize
     */
    setRelationSize(relatedlistId, relationSize) {
      relationSize = relationSize ? relationSize : 0;
      if (relatedlistId) {
        let target = this.list.find(
          (item) => item.relatedlistId === relatedlistId
        );
        if (target) {
          this.$set(target, "relationSize", relationSize);
        }
      }
    },
    // 展示审批历史的按钮--右上按钮
    showHistoryBtn() {
      // this.headerBtn = []
      // // showReassign  重新分配
      // // showApproval 进行审批
      // if (data.showSubmit) {
      //   ////提交待审批
      //   this.headerBtn.push({
      //     id: '',
      //     label: this.$i18n.t('label.submitforapproval'),
      //     hisId: '0',
      //   })
      // }
      // if (data.showApproval) {
      //   //进行审批
      //   this.headerBtn.push({
      //     id: '',
      //     label: this.$i18n.t('label.approveorrefuse'),
      //     hisId: '2',
      //   })
      // }
      // if (data.showReassign) {
      //   //重新分配
      //   this.headerBtn.push({
      //     id: '',
      //     label: this.$i18n.t('label.redistribution'),
      //     hisId: '3',
      //   })
      // }
      // if (data.showRecall) {
      //   //调回批准请求
      //   this.headerBtn.push({
      //     id: '',
      //     label: this.$i18n.t('label.manageAll.sta4'),
      //     hisId: '1',
      //   })
      // }
      // if (data.isAuto && data.showSubmit) {
      //   this.$emit('topHistorySubmit', true)
      // }
      // this.$emit('topHistoryBtn', this.headerBtn)
    },
    // 进入特殊新建编辑页
    goToEdit(objectApi, each) {
      this.tabNameData = each.recordObjName;
      this.$nextTick(() => {
        this.prefixData = "";
        // each.objName 采购订单 或 发票 或 订单
        this.objectApiData =
          each.objName == "purchaseorderitem" ||
          each.objName == "InvoicesItems" ||
          each.objName == "cloudccorderitem"
            ? objectApi
            : "";
        this.$refs.editObj.id = this.$route.params.id;
        this.$refs.editObj.objectApi = objectApi;
        this.$refs.editObj.objId = each.recordObjId;
        this.$refs.editObj.edit();
      });
    },
    // 编辑
    everyEdit(each, item) {
      // 个人客户下联系人相关列表（列表编辑或卡片编辑），只有此相关列表才有objApi字段
      if (
        (item.data?.objApi && item.data?.objApi === "Contact") ||
        (item.objApi && item.objApi === "Contact" && each.objid === "contact")
      ) {
        this.relatedListObj.objLabel = this.$i18n.t("label.account");
        this.listObjectId = "account";
        this.listObjectApi = "Account";
      } else {
        this.relatedListObj.objLabel = each.objLabel;
        this.listObjectId = each.objid;
        this.listObjectApi = each.objName;
      }
      this.listPrefix = each.prefix;
      //列表式编辑
      if (item.action) {
        if (
          each.recordObjId === "20210525cloudccorder" &&
          each.objName === "cloudccorderitem"
        ) {
          // 订单
          this.goToEdit("cloudccorder", each);
        } else if (
          each.recordObjId === "invoices" &&
          each.objName === "InvoicesItems"
        ) {
          // 发票
          this.goToEdit("Invoices", each);
        } else if (
          each.recordObjId === "2021127purchaseorder" &&
          each.objName === "purchaseorderitem"
        ) {
          // 列表式采购订单相关列表编辑
          this.goToEdit("cloudccpurchaseorder", each);
        } else if (each.objName === "campaignform") {
          //表单营销推广
          this.$refs.formMarketingPromotion.dialogFormVisible = true;
          this.$nextTick(() => {
            this.$refs.formMarketingPromotion.edit();
          });
        } else {
          // 个人客户下联系人相关列表（列表式编辑）
          if (item.data.objApi && item.data.objApi === "Contact") {
            this.dialogNewAttr.id = each.recordId;
          } else {
            this.dialogNewAttr.id = item.data.id;
          }
          //判断是否是编辑客户地址相关列表
          if (item.data.billingaddress || item.data.shippingaddress) {
            this.editAddress(item.data, "account");
          } else {
            this.$nextTick(() => {
              this.$refs.createEditObj.edit();
            });
          }
        }
      } else {
        CommonObjApi.getPermissionById({ id: item.id }).then((res) => {
          if (res.data.isEdit) {
            if (
              each.recordObjId === "20210525cloudccorder" &&
              each.objName === "cloudccorderitem"
            ) {
              this.goToEdit("cloudccorder", each);
            } else if (
              each.recordObjId === "invoices" &&
              each.objName === "InvoicesItems"
            ) {
              this.goToEdit("Invoices", each);
            } else if (
              each.recordObjId === "2021127purchaseorder" &&
              each.objName === "purchaseorderitem"
            ) {
              // 卡片式采购订单相关列表编辑
              this.goToEdit("cloudccpurchaseorder", each);
            } else {
              // 个人客户下联系人相关列表（卡片式编辑）
              if (item.objApi && item.objApi === "Contact") {
                this.dialogNewAttr.id = each.recordId;
              } else {
                this.dialogNewAttr.id = item.id;
              }
              if (item.billingaddress || item.shippingaddress) {
                this.editAddress(item, "account");
              } else {
                this.$nextTick(() => {
                  this.$refs.createEditObj.edit();
                });
              }
            }
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("report_label_norole"),
              type: "warning",
            });
            //"抱歉，您无权编辑该条数据，请联系系统管理员。"
          }
        });
      }
    },
    // 新增/修改保存
    save() {
      this.blockRefresh();
    },
    //相关列表分块刷新
    blockRefresh() {
      this.$emit("refreshAll");
      // this.GetRelationList(true)
      // this.list.forEach((val, index) => {
      //   if (val.relatedlistId === this.relatedListObj.id) {
      //     if (this.objId === 'account') {
      //       this.$refs.relationListObj[index - 1].getDetailRelatedItems(val)
      //     } else {
      //       this.$refs.relationListObj[index].getDetailRelatedItems(val)
      //     }
      //   }else if(val.relatedlistType=="cloudcc_k_article"){
      //     this.$refs.relationListObj[index].getDetailRelatedItems(val)
      //   }
      // });
    },
    //更改客户地址默认值
    changedefaultaddress(data, type) {
      let params = {
        customerId: this.id,
        addressId: type == "card" ? data.id : data.data.id,
      };

      if (data.action == "billing") {
        params.billingaddress = "1";
      } else if (data.action == "shipping") {
        params.shippingaddress = "1";
      }
      CommonObjApi.updateAddressStatus(params).then(() => {
        this.$emit("refreshAll");
      });
    },
    // 确认删除数据
    everyDelet(obj, name, id, item1) {
      //每个相关列表对象的信息
      this.relatedListObj = obj;
      //name.action是列表式删除
      if (name.action) {
        this.DeletName = obj.objName;
        this.DeletObj = obj;
        this.DeletId = name.data.id;
        this.dialogVisible = true;
      } else {
        this.DeletObj = obj;
        this.DeletName = name;
        this.DeletId = id;
        this.DeletItem1 = item1;
        if (
          this.$store.state.userInfoObj.profileId === "aaa000001" ||
          name == "Attachement"
        ) {
          this.dialogVisible = true;
        } else {
          CommonObjApi.getPermissionById({ id: this.DeletId }).then((res) => {
            if (res.data.isDelete && !res.data.isLocked) {
              this.dialogVisible = true;
            } else {
              this.$message(
                this.$i18n.t("vue_label_commonobjects_detail_the_administrator")
              );
            }
          });
        }
      }
    },
    // 确认删除数据
    confirmDel() {
      this.dialogVisible = false;
      //如果是联系人走单独删除接口
      if (this.DeletName === "tp_sys_contactrole") {
        deleteContact({ id: this.DeletId, opportunityId: this.id }).then(() => {
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.ems.delete.success"),
            type: "success",
          }); //删除成功
          this.blockRefresh();
        });
      } else if (this.DeletName === "tp_sys_opportunity_bookmarks") {
        getSimilarOpportunityDeleteBookmarksOppDetails({
          id: this.DeletItem1.bookmarksid,
        }).then(() => {
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.ems.delete.success"),
            type: "success",
          }); //删除成功
          this.blockRefresh();
        });
      } else if (
        this.DeletName === this.$i18n.t("lable.opppdt.selPbk.pricebook")
      ) {
        //價格手冊
        let params = {
          itemid: this.DeletObj,
        };
        var currencys = [];
        var str = "";
        var count = 0;
        this.Deletpricebook.forEach((ite) => {
          currencys.push(ite.currency);
          if (
            ite.pricebookname === "Standard Price Book" &&
            ite.id === this.DeletObj
          ) {
            str = ite.currency;
          }
        });
        for (var i = 0; i < currencys.length; i++) {
          if (str == currencys[i]) {
            count++;
          }
        }
        if (
          count == 1 &&
          this.DeletItem1.pricebookname === "Standard Price Book" &&
          this.DeletItem1.id === this.DeletObj
        ) {
          CommonObjApi.deleteItem(params).then(() => {
            this.blockRefresh();
            this.$refs.relationListObj &&
              this.$refs.relationListObj[0].querypricebooklist();
            this.$message.success(
              `${this.DeletItem1.name}${this.$i18n.t(
                "vue_label_pricebook_deleted"
              )}`
            ); //价格手册已删除
          });
        } else if (
          count > 1 &&
          this.DeletItem1.pricebookname === "Standard Price Book" &&
          this.DeletItem1.id === this.DeletObj
        ) {
          //价格手册条目无法删除，因为已有记录应用到该价格手册，您可以改为将价格手册条目标记为未启用。
          this.$message.warning(
            this.$i18n.t("vue_label_pricebook_notice_cantbedeleted")
          );
        } else if (this.DeletItem1.pricebookname !== "Standard Price Book") {
          CommonObjApi.deleteItem(params).then(() => {
            this.blockRefresh();
            this.$refs.relationListObj &&
              this.$refs.relationListObj[0].querypricebooklist();
            this.$message({
              showClose: true,
              message: this.$i18n.t("label.ems.delete.success"),
              type: "success",
            }); //删除成功
          });
        }
      } else if (this.DeletName === "campaignform") {
        //表单营销推广
        FormMarkObjApi.deleteCampaignForm({ id: this.DeletId }).then(() => {
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.ems.delete.success"),
            type: "success",
          }); //删除成功
          this.blockRefresh();
        });
      } else {
        let params = { id: this.DeletId, objectApi: this.DeletName };
        if (this.isConfirmDel === "true") {
          params.isConfirmDel = "true";
        }
        CommonObjApi.del(params).then((res) => {
          // 审批中的记录需要二次确认才能删除,returnCode返回isConfirmDel时，说明时删除正在审批中的记录
          if (res.returnCode === "isConfirmDel") {
            // 二次确认弹窗
            this.isConfirmDel = "true";
            this.dialogVisible = true;
            this.delContent = res.returnInfo;
          } else {
            this.dialogVisible = false;
            this.$message({
              showClose: true,
              message: this.$i18n.t("label.ems.delete.success"),
              type: "success",
            }); //删除成功
            this.blockRefresh();
          }
        });
      }
    },
    //管理小组 关闭弹窗
    popTableClose(n) {
      if (n) {
        this.blockRefresh();
        this.popTableVisible = false;
      } else {
        this.popTableVisible = false;
      }
    },
    //选择价格手册 关闭弹窗
    manualClose() {
      this.manualVisible = false;
    },
    //添加产品 关闭弹窗
    addmanualClose() {
      this.addmanualVisible = false;
    },
    //是否刷新相关列表
    successFun(n) {
      if (n) {
        this.blockRefresh();
        this.productsVisible = false;
      } else {
        this.productsVisible = false;
      }
    },
    //编辑业务机会联系人
    contactsClose(n) {
      if (n) {
        this.blockRefresh();
        this.contactsVisible = false;
        this.addContact = false;
        this.display();
      } else {
        this.contactsVisible = false;
        this.addContact = false;
      }
    },
    //业务机会产品选择价格手册 下一步
    addmanualChange() {
      // this.$refs.popUpteam.$refs.productFilter.page = 1;
      // this.$refs.popUpteam.$refs.productFilter.addmanualData = [];
      // // 添加产品时展示多搜索
      // this.$refs.popUpteam.$refs.productFilter.getLookupInfo();
      // // 过滤所有重复产品条目后的产品
      // this.$refs.popUpteam.$refs.productFilter.searchProducts();
      // // 产品系列数
      // this.$refs.popUpteam.$refs.productFilter.getProductTree();
      this.$refs.popUpteam.productFilterShow = true;
      this.$refs.popUpteam.addGetFieldList();
      // this.addmanualVisible = true;
      // this.$refs.popUpteam.searchProducts();
      // this.$refs.popUpteam.addmanualInit();
    },
    /**
     * 添加产品 (多选) -- 下一步关闭弹框
     */
    confirmAddProduct() {
      this.productsVisible = true;
    },
    //添加产品 下一步
    productsChange() {
      this.productsVisible = true;
    },
    //添加文件
    addWorkClose(n) {
      if (n) {
        this.$emit("refreshUpload");
        this.dialogAddVisible = false;
      } else {
        this.dialogAddVisible = false;
      }
    },
    addWorkSubmit(checkedFileList) {
      let data = [];
      checkedFileList.map((item) => {
        let dataItem = {};
        dataItem.recordid = this.id;
        dataItem.name = item.name;
        dataItem.type = item.fileType ? item.fileType : item.type;
        dataItem.fileContentId = item.fileContentId || item.id; //下载文件2021开头的是filecontentid
        dataItem.fileinfoid = item.fileInfoId || item.id; // fil开头的是fileinfoid
        dataItem.filesize = item.filesize;
        data.push(dataItem);
      });
      Bind(JSON.stringify(data)).then((res) => {
        let self = this;
        if (res.result === true) {
          //群策卡片
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].cardValue) {
              (function (i) {
                setTimeout(async () => {
                  let fieldsList = [];
                  res.data[i].cardValue.fieldsList.forEach((item) => {
                    if (
                      fieldsList.length < 5 &&
                      item.fieldValue != null &&
                      item.fieldValue != "true" &&
                      item.fieldValue != "false" &&
                      item.fieldValue != "0" &&
                      item.fieldName != "id" &&
                      item.fieldValue != "1"
                    ) {
                      fieldsList.push(item);
                    }
                  });
                  await detailSendCard(res.data[i].cardValue.groupId, {
                    title: self.$i18n.t("vue_label_newfile"),
                    recordId: res.data[i].cardValue.id,
                    type: "NORMAL_CARD",
                    body: fieldsList,
                    changer: localStorage.getItem("username"),
                  });
                }, 500 * i);
              })(i);
            }
          }
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("vue_label_notice_add_fail"),
          });
        }
      });
      this.addWorkClose(true);
    },
    //价格手册 关闭弹窗
    singleManualClose() {
      this.singleManualVisible = false;
    },
    //添加产品 关闭弹窗
    singleAddmanualClose() {
      this.singleAddmanualVisible = false;
    },
    //价格手册保存后打开 添加产品
    singleManualChange() {
      this.singleManualVisible = true;
    },
    //价格手册保存后打开 添加产品
    singleAddmanualChange() {
      this.singleAddmanualVisible = true;
    },
    //是否刷新相关列表
    singleSuccessFun(n) {
      if (n) {
        this.blockRefresh();
        this.singleProductsVisible = false;
      } else {
        this.singleProductsVisible = false;
      }
    },
    //添加产品 下一步
    singleProductsChange() {
      this.singleProductsVisible = true;
    },
    // 点击确认隐藏短信弹框
    confirmationMessage() {
      this.dialogTableVisible = false;
    },
    // 取消隐藏短信弹框
    cancelMssage() {
      this.dialogTableVisible = false;
    },
    routers() {
      if (this.objectApi === "Account") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Account",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "Contact") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Contact",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "campaign") {
        // this.isCustomer = true
        // this.content = '市场层级结构'
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Campaign",
            content: this.objectApi,
          },
        });
      } else {
        this.isCustomer = false;
      }
    },
    uploadRefresh() {
      this.$emit("refreshUpload");
    },
    numbers(val) {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      this.manualForm.input8 = val;
    },
    numberes(val) {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      this.itemInfo.standardprice = val;
    },
    numberesTwo(val) {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      this.addmanualForm.bookprice = val;
    },
    //联系人角色 添加
    addContactClose() {
      this.addContact = false;
    },
    contactsVisibleOpen() {
      this.contactsVisible = true;
    },
    //批量新增
    closeBatchAddDialog(isSave) {
      this.batchAddDialog = false;
      if (isSave === "save") {
        this.blockRefresh();
      }
    },
    //获取批量新增保存loading状态
    getBatchLoading(n) {
      this.isBatchLoading = n;
    },
    saveBatchAdd() {
      this.$refs.batchAdd.savedata();
    },
    SaveDropboxs() {
      this.$emit("refreshAll");
    },
    dropboxState(val) {
      this.isDropboxState = val;
    },
    // 解决个案下文章列表作为草稿编辑
    copy(val) {
      this.dialogNewAttr.id = val;
      this.listObjectApi = "CloudccKArticle";
      this.$nextTick(() => {
        this.$refs.createEditObj.objId = "cloudcc_k_article";
        this.$refs.createEditObj.copy(this.dialogNewAttr.id);
      });
    },
    editobjClick(val) {
      this.copy(val);
    },
    // 事件类型的相关项处理
    getRelatedItem() {
      // 获取相关项
      CommonObjApi.getDialigItem({ type: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let relatedItem = {
            reItem: res.data.relateobjList,
            naItem: res.data.whoobjInfoList,
          };
          this.formAttr.map((item) => {
            if (item.prop === "relateid") {
              item.type = "eventTypeB";
              item.optionItem = relatedItem.reItem;
              item.width = "100%";
            }
            if (item.prop === "whoid") {
              item.type = "eventTypeA";
              item.optionItem = relatedItem.naItem;
              item.width = "100%";
            }
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 判断是否有知识库用户
    getKnowledgePermissionById() {
      CommonObjApi.getKnowledgePermissionById().then((res) => {
        this.isKnowledgeUser = res.data.isKnowledgeUser;
      });
    },
    //表单营销推广关闭刷新
    closeFormMarketingPromotion() {
      this.blockRefresh();
    },
  },
  computed: {
    ...mapState(["consoleData"]),
  },
  watch: {
    fieldValue(nval, val) {
      if (nval !== val) {
        this.objArr = [];
      }
    },
    bigTitle() {
      this.acconame = this.bigTitle;
    },
  },
};
</script>

<style scoped lang="scss">
.detail-enter,
.detail-enter-to {
  height: 0;
}

.detail-enter-active,
.detail-leave-active {
  transition: all 0.4s ease;
}

// ::v-deep .el-input {
//   height: 30px;
//   width: 100%;
// }
// ::v-deep .el-input__inner {
//   height: 30px;
//   width: 100%;
// }

::v-deep .el-tabs__item {
  font-size: 16px;
}

::v-deep .el-card__body {
  padding: 0px;
}

::v-deep .el-input__icon {
  line-height: 30px;
}

.detailTitle {
  position: relative;
  border-bottom: 1px solid #dedc;

  .changeColumnBtnGroup {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    line-height: 30px;
    float: left;
    border: 1px solid #dedcda;
    border-radius: 3px;

    li {
      float: left;
      width: 30px;
      height: 30px;
      padding-top: 1px;
    }
  }

  .detail {
    display: flex;
    height: 36px;
    line-height: 20px;

    .title_detail {
      font-weight: 600;
      font-size: 16px;
      width: 84px;
      text-align: center;
      cursor: pointer;
      a {
        color: #080707;
        svg {
          width: 70px;
          height: 26px;
          line-height: 26px;
          cursor: pointer;
        }
      }
      .xiangqing {
        line-height: 26px;
      }
    }
    .active {
      border-bottom: 2px solid #006dcc;
    }
  }
}

.folder ::v-deep .el-dialog {
  width: 30%;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.infoForm {
  ::v-deep .groupTitle {
    margin: 16px 0px 16px 0px;
    // height: 36px;
    line-height: 36px;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 10px;
    background: #f3f7ff;
    color: #080707;
    font-weight: bold;
    border-radius: 4px;
    font {
      display: contents;
    }
  }
}
.changeDetailBox {
  position: relative;
  ::v-deep .el-form-item {
    // margin-bottom: -5px;
    display: flex;
    flex-direction: column;
  }
  ::v-deep .el-form-item__label {
    width: 100% !important;
  }
  ::v-deep .el-form-item__content {
    margin-left: 50px !important;
    width: 80%;
  }
  // 校验错误提示
  ::v-deep .el-form-item__error {
    // top: 30%;
    // left: 2%;
    padding-top: 0px;
  }
  ::v-deep .groupTitle {
    margin: 16px 0px 8px 0px;
    // height: 36px;
    line-height: 36px;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 10px;
    background: #f3f7ff;
    color: #080707;
    font-weight: bold;
    border-radius: 4px;
    font {
      display: contents;
    }
  }
}

.changeDetailBtns {
  width: 100%;
  text-align: center;
  position: sticky;
  top: 80px;
  height: 50px;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 120;
}

.rightChangeDetailBtns {
  width: calc(33.3% - 35px);
}

.main {
  .setColumnsIcon {
    width: 16px;
    // height: 28px;
    margin-left: 8px;
  }

  .autoColumnsIcon {
    width: 16px;
    margin-left: 7px;
  }

  // 用户对象详情列表的新样式
  .newMain {
    position: relative;
    width: 99%;
    margin-right: 1%;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 1px solid #dddbda;
    .newBox-main_tit {
      .newEl-icon-d-arrow-right {
        transform: rotate(90deg);
        margin-left: 50px;
        position: absolute;
        right: 20px;
        top: 16px;
        z-index: 100;
        cursor: pointer;
      }
    }
  }
  // 用户对象的详情相关列表样式
  .lf {
    .box-main_top {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      background: rgba(255, 255, 255, 1);
      border-radius: 3px;
      border: 1px solid rgba(221, 219, 218, 1);
      .related-list-box {
        display: flex;
        flex-wrap: wrap;
        padding: 12px 30px 8px 30px;
        box-sizing: border-box;
        dl {
          margin-top: 0;
          .spot_s {
            position: relative;
            span:first-child {
              max-width: 180px;
              overflow: hidden;
              word-break: keep-all;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
              border-bottom: 1px solid #006dcc;
              line-height: 18px;
              height: 18px;
            }
            span:nth-child(2) {
              position: absolute;
              border-bottom: 1px solid #006dcc;
              line-height: 18px;
              height: 18px;
              display: inline-block;
            }
          }
        }

        // 关注列表样式
        .focusList {
          padding: 0;
          // width: 25%;
          cursor: pointer;
          font-size: 12px;
          display: flex;
          align-items: center;
          .icon {
            width: 20px;
            height: 20px;
            margin-top: 4px;
            border-radius: 3px;
          }
          div {
            height: 25px;
            line-height: 26px;
            padding: 0;
            margin-left: 10px;
            color: #006dcc;
            border-bottom: solid 1px #006dcc;
          }
        }
        .focusListItemone {
          padding: 0;
          // width: 25%;
          cursor: pointer;
          font-size: 12px;
          display: flex;
          align-items: center;
          .icon {
            width: 20px;
            height: 20px;
            margin-top: 4px;
            border-radius: 3px;
          }
          div {
            height: 25px;
            line-height: 26px;
            padding: 0;
            margin-left: 10px;
            color: #006dcc;
            border-bottom: solid 1px #006dcc;
          }
        }
        .focusListItemtwo {
          padding: 0;
          // width: 25%;
          cursor: pointer;
          font-size: 12px;
          display: flex;
          align-items: center;
          .icon {
            width: 20px;
            height: 20px;
            margin-top: 4px;
            border-radius: 3px;
          }
          div {
            height: 25px;
            line-height: 26px;
            padding: 0;
            margin-left: 10px;
            color: #006dcc;
            border-bottom: solid 1px #006dcc;
          }
        }

        // @media screen and (min-width: 1200px) {
        //   dl {
        //     width: 25%;
        //   }
        //   .focusList {
        //     width: 25%;
        //   }
        //   .focusListItemone {
        //     width: 25%;
        //   }
        //   .focusListItemtwo {
        //     width: 25%;
        //   }
        // }
        // @media screen and (min-width: 960px) and (max-width: 1199px) {
        //   dl {
        //     width: 33%;
        //   }
        //   .focusList {
        //     width: 33%;
        //   }
        //   .focusListItemone {
        //     width: 33%;
        //   }
        //   .focusListItemtwo {
        //     width: 33%;
        //   }
        // }
        // @media screen and (min-width: 0) and (max-width: 959px) {
        //   dl {
        //     width: 50%;
        //   }
        //   .focusList {
        //     width: 50%;
        //   }
        //   .focusListItemone {
        //     width: 50%;
        //   }
        //   .focusListItemtwo {
        //     width: 50%;
        //   }
        // }
        dl {
          margin-bottom: 6px;
          display: flex;
          // min-height: 25px;
          // line-height: 25px;
          dt {
            width: 20px;
            height: 20px;
            margin: 0 10px 0 0;
            img {
              width: 100%;
            }
          }
          dd {
            height: 22px;
            padding-top: 2px;
            font-size: 12px;
            margin-bottom: 0;
            margin-left: 0;
            cursor: pointer;
            // border-bottom: 1px solid #006dcc;
            color: #006dcc;
          }
        }
      }
    }
    .box-main_tit {
      width: 100%;
      position: relative;
      background: rgba(255, 255, 255, 1);
      border-radius: 3px;
      border: 1px solid rgba(221, 219, 218, 1);
      // margin-top: 10px;
      padding: 10px;
      box-sizing: border-box;
      // 超出不可隐藏，防止下拉展示不全的情况
      overflow: visible;

      .controlDetail {
        transform: rotate(90deg);
        font-size: 16px !important;
        font-size: 16px !important;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 100;
        cursor: pointer;
      }
    }

    .box-main_main {
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border-radius: 3px;
      border: 1px solid #dddbda;
      margin-top: 10px;
    }
  }
}
.pricebox {
  height: 47px;
  display: flex;
}
.labels {
  display: inline-block;
  width: 12%;
  text-align: left;
}
.seclabels {
  display: inline-block;
  margin-left: 10%;
  width: 20%;
}
::v-deep .diastyle {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  .form {
    flex: 1.2;
    .el-form {
      .el-form-item {
        .el-form-item__label {
          padding: 0 5px 0 20px !important;
          font-size: 14px !important;
          word-break: break-word;
          height: auto !important;
        }
        .el-form-item__content {
          .el-select {
            width: 100%;
          }
          .el-autocomplete {
            width: 100%;
          }
        }
        ::v-deep .el-input__inner {
          height: 28px !important;
        }
      }
    }
  }

  .checkbox_style {
    margin-left: 50px;
    p {
      font-size: 14px;
      line-height: 47px !important;
      height: 63px;
      margin-bottom: 0;
      .el-checkbox {
        width: 90% !important;
        .el-checkbox__label {
          font-size: 14px !important;
          float: left !important;
          padding-left: 0px !important;
        }
        .el-checkbox__input {
          float: right !important;
          margin-right: 30px;
          margin-top: 4px;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background: #005fb2;
        }
      }
      .checkbox {
        .el-checkbox__label {
          font-size: 14px !important;
          padding-left: 0px !important;
          text-align: left !important;
        }
        .el-checkbox__input {
          float: left !important;
        }
      }
      .checkboxs {
        .el-checkbox {
          width: 90% !important;
        }
      }
    }
    .two_p {
      label {
        display: flex;
        .el-checkbox__input {
          margin: 0 10px;
          line-height: 0px;
        }
      }
      .el-checkbox__label {
        line-height: 33px;
        margin-top: -8px;
      }
    }
  }
}
.allstars {
  color: red;
}

::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #006dcc !important;
  border-color: #006dcc !important;
}

.standardprice::v-deep .el-input__suffix {
  position: absolute;
  top: -7px;
}

::v-deep .add_diastyle {
  .product_code {
    position: relative;
    .tit_label {
      width: 100px;
      display: inline-block;
    }
    .checkbox {
      position: absolute;
      top: 5px;
      left: 101px;
      .el-checkbox {
        width: 100px !important;
        display: flex;
        .el-checkbox__input {
          margin-right: 10px !important;
        }
      }
    }
  }
}

::v-deep .height_s {
  .el-input__inner {
    height: 28px !important;
    font-size: 12px;
  }
}
</style>
<style>
.suspension_popover_list {
  width: 95% !important;
  margin-left: 56px;
}
</style>
