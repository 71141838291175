<template>
  <div class="sequence-setting">
    <el-dialog
      :title="$t('c823')"
      :visible.sync="sequenceSettingDialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <div class="content">
        <!-- 填写部分 -->
        <div class="fill-in">
          <div class="header">
            <div class="title">{{ $t("c754") }}</div>
            <div class="operation">
              <span>{{ $t("c755") }}</span
              ><el-switch v-model="fillIsOpen"> </el-switch>
            </div>
          </div>
          <!-- 列表 -->
          <div class="list">
            <!-- 左侧数据条 -->
            <div class="left" v-if="fillIsOpen">
              <div class="box" v-for="(item, index) in fillArr" :key="index">
                <div class="item">{{ index + 1 }}</div>
                <div class="line" v-if="index != fillArr.length - 1"></div>
                <div class="sanjiao" v-if="index != fillArr.length - 1"></div>
              </div>
            </div>
            <!-- 右侧列表 -->
            <div
              class="right"
              :style="{ width: fillIsOpen ? 'calc(100% - 50px)' : '100%' }"
            >
              <vuedraggable v-model="fillArr" filter=".undraggable">
                <div
                  class="item"
                  v-for="(item, index) in fillArr"
                  :key="index"
                  :class="fillIsOpen ? '' : 'undraggable'"
                >
                  <!-- 企业与个人区分 -->
                  <div class="item-box" v-if="item.order == 'c'">
                    <div class="grey"></div>
                    <div class="img">
                      <svg
                        class="svgimg"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        viewBox="0,0,40,40"
                      >
                        <use href="#icon-qiye"></use>
                      </svg>
                    </div>
                    <div class="word">{{ $t("c825") }}</div>
                    <div class="name">{{ item.name }}</div>
                  </div>
                  <!-- 个人 -->
                  <div class="item-box" v-if="item.order == 'p'">
                    <div class="grey"></div>
                    <div class="img">
                      <svg
                        class="svgimg"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        viewBox="0,0,40,40"
                      >
                        <use href="#icon-geren"></use>
                      </svg>
                    </div>
                    <div class="word">{{ $t("label.chat.likeperson") }}</div>
                    <div class="name">{{ item.name }}</div>
                  </div>
                </div>
              </vuedraggable>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <!-- 签署部分 -->
        <div class="fill-in">
          <div class="header">
            <div class="title">{{ $t("c761") }}</div>
            <div class="operation">
              <span>{{ $t("c824") }}</span
              ><el-switch v-model="signIsOpen"> </el-switch>
            </div>
          </div>
          <!-- 列表 -->
          <div class="list">
            <!-- 左侧数据条 -->
            <div class="left" v-if="signIsOpen">
              <div class="box" v-for="(item, index) in fillArr" :key="index">
                <div class="item">{{ index + 1 }}</div>
                <div class="line" v-if="index != fillArr.length - 1"></div>
                <div class="sanjiao" v-if="index != fillArr.length - 1"></div>
              </div>
            </div>
            <!-- 右侧列表 -->
            <div
              class="right"
              :style="{ width: signIsOpen ? 'calc(100% - 50px)' : '100%' }"
            >
              <vuedraggable v-model="signArr" filter=".notdraggable">
                <div
                  class="item"
                  v-for="(item, index) in signArr"
                  :key="index"
                  :class="signIsOpen ? '' : 'notdraggable'"
                >
                  <!-- 企业与个人区分 -->
                  <div class="item-box" v-if="item.order == 'c'">
                    <div class="grey"></div>
                    <div class="img">
                      <svg
                        class="svgimg"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        viewBox="0,0,40,40"
                      >
                        <use href="#icon-qiye"></use>
                      </svg>
                    </div>
                    <div class="word">{{ $t("c825") }}</div>
                    <div class="name">{{ item.name }}</div>
                  </div>
                  <!-- 个人 -->
                  <div class="item-box" v-if="item.order == 'p'">
                    <div class="grey"></div>
                    <div class="img">
                      <svg
                        class="svgimg"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        viewBox="0,0,40,40"
                      >
                        <use href="#icon-geren"></use>
                      </svg>
                    </div>
                    <div class="word">{{ $t("chatter.p") }}</div>
                    <div class="name">{{ item.name }}</div>
                  </div>
                </div>
              </vuedraggable>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{
          $t("label_chatter_cancel")
        }}</el-button>
        <el-button type="primary" @click="handleClose">{{
          $t("label_tabpage_ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";

export default {
  props: {
    //判断弹框开关
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    card1: {
      type: Array,
      default: () => {
        return [];
      },
    },
    card2: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      sequenceSettingDialogVisible: false,
      //填写顺序填是否开启
      fillIsOpen: false,
      //签署顺序填是否开启
      signIsOpen: false,
      //填写顺序数组
      fillArr: [],
      //签署顺序数组
      signArr: [],
    };
  },
  components: {
    vuedraggable,
  },
  watch: {
    dialogVisible: {
      handler(nval) {
        this.sequenceSettingDialogVisible = nval;
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("closeSequence");
    },
  },
  mounted() {
    this.fillArr = [...this.card1, ...this.card2];
  },
};
</script>

<style lang='scss' scoped>
.sequence-setting {
  ::v-deep .el-button--primary {
    background-color: #006dcc;
  }
  .content {
    display: flex;
    height: 300px;
    .fill-in {
      width: 50%;
      .header {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .title {
          color: #000;
        }
      }
    }
    .list {
      display: flex;
      .left {
        width: 40px;
        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .item {
          width: 24px;
          height: 24px;
          background: #006dcc;
          border-radius: 50%;
          line-height: 24px;
          text-align: center;
          color: white;
          margin: 5px 0;
        }
        .line {
          width: 0px;
          height: 20px;
          border-right: 1px dashed #006dcc;
        }
        .sanjiao {
          border-width: 5px 5px 0;
          border-style: solid;
          border-color: #006dcc transparent transparent;
          margin-top: 3px;
        }
      }
      .right {
        width: calc(100% - 50px);
        margin-top: 0 !important;
        .item {
          width: 100%;
          margin-bottom: 20px;
          .item-box {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 10px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #dcdcdc;
            overflow: hidden;
          }
          .grey {
            width: 25px;
            height: 40px;
            background: #f8f8f8;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            .circle {
              width: 2px;
              height: 2px;
              background: #c4c4c4;
              border-radius: 50%;
              flex: 0 25%;
            }
          }
          .img {
            margin: 0 5px;
          }
          .word {
            color: #006dcc;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .line {
    width: 0px;
    height: 100%;
    opacity: 1;
    border-right: 1px dashed #dddbd9;
    margin: 0 30px;
  }
}
</style>