<!--
 * @Author: zhangyutong
 * @Date: 2021-11-16 19:49:52
 * @LastEditTime: 2022-02-17 14:47:50
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\contract\contractLabel.vue
-->
<!--  -->
<template>
  <div class="contractLabel" v-if="showContract" v-loading="loading">
    <div class="contractTitle">
      <div
        style="
          position: absolute;
          top: 30px;
          display: flex;
          align-items: center;
        "
      >
        <svg
          class="active-template-item-right-icon"
          aria-hidden="true"
          style="width: 25px; height: 30px; margin-top: 2px; margin-right: 5px"
        >
          <use href="#icon-a-bianzu9beifen"></use>
        </svg>
        <span>{{ $t("front-electronic-signature-explain-mainTitle") }}</span>
        <!-- 签署进度查询 -->
        <div class="sign_popover" v-show="envelopeData.envelopeid">
          <el-popover placement="bottom-start" width="502" trigger="hover">
            <div class="popup">
              <div class="t">
                <!-- 签署进度 -->
                {{ $t("c749") }}
              </div>
              <div class="title">
                <el-tooltip
                  popper-class="my-tooltip"
                  effect="dark"
                  :content="participants.company"
                  placement="top-start"
                >
                  <div class="tit_left people">
                    <!-- 发起方： -->
                    <span>{{ $t("c750") }}</span>
                    <span>{{ participants.company }}</span>
                  </div>
                </el-tooltip>
                <!-- 签署截止日期： -->
                <div class="tit_left date">
                  <span>{{ $t("c751") }}</span>
                  <span>{{ initiatTime }}</span>
                </div>
              </div>
              <div class="content" style="height: auto">
                <div class="line"></div>
                <div class="content_right">
                  <div class="right_first">
                    <div class="right_title">
                      <svg
                        class="active-template-item-right-icon"
                        aria-hidden="true"
                        style="width: 24px; height: 24px; margin-right: 6px"
                      >
                        <use href="#icon-a-initiatesigning"></use>
                      </svg>
                      <!-- 发起签署任务 -->
                      <span>{{ $t("c752") }}</span>
                    </div>
                    <div class="right_con">
                      <div class="right_con_left">
                        <svg
                          aria-hidden="true"
                          style="width: 20px; height: 15px; margin-right: 10px"
                        >
                          <use href="#icon-qiye"></use>
                        </svg>
                        <div>
                          <el-tooltip
                            popper-class="my-tooltip"
                            effect="dark"
                            :content="participants.company"
                            placement="top-start"
                          >
                            <div
                              style="margin-bottom: 10px; color: #080707"
                              class="font-change"
                            >
                              {{ participants.company }}
                            </div>
                          </el-tooltip>
                          <el-tooltip
                            popper-class="my-tooltip"
                            effect="dark"
                            :content="participants.khmc"
                            placement="top-start"
                          >
                            <div class="font-change">
                              {{ participants.khmc }}
                            </div>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="right_con_right">
                        <div>{{ curSignTaskStatus }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="right_second">
                    <div class="right_title">
                      <svg
                        class="active-template-item-right-icon"
                        aria-hidden="true"
                        style="width: 24px; height: 24px; margin-right: 5px"
                      >
                        <use href="#icon-a-Group121"></use>
                      </svg>
                      <!-- 参与方 -->
                      <span>{{ $t("c753") }}</span>
                    </div>
                    <div class="right_con" v-if="fillActorsData.length > 0">
                      <div class="right_con1_title">
                        <div class="spot"></div>
                        <!-- 填写 -->
                        <div>{{ $t("c754") }}</div>
                        <!-- 顺序填 -->
                        <div>
                          （{{
                            signData.fillInOrder ? $t("c755") : $t("c877")
                          }}）
                        </div>
                      </div>
                      <!-- 进度条 -->
                      <div
                        class="step-line"
                        v-for="(item, index) in fillActorsData"
                        :key="index"
                      >
                        <div class="left_icon">
                          <svg
                            style="width: 22px; height: 22px"
                            aria-hidden="true"
                            v-if="item.fillActorStatus == 'fill_completed'"
                          >
                            <use href="#icon-Finish"></use>
                          </svg>
                          <div v-else class="left_first">{{ index + 1 }}</div>
                          <div
                            class="line"
                            v-if="index != signData.fillActors.length - 1"
                          ></div>
                        </div>
                        <div class="right_con">
                          <div class="right_con_box">
                            <div class="right_con_left">
                              <svg
                                v-if="item.actorIdentType == 'corp'"
                                aria-hidden="true"
                                style="
                                  width: 20px;
                                  height: 15px;
                                  margin-right: 10px;
                                "
                              >
                                <use href="#icon-qiye"></use>
                              </svg>
                              <svg
                                v-else
                                class="active-template-item-right-icon"
                                aria-hidden="true"
                                style="
                                  width: 20px;
                                  height: 15px;
                                  margin-right: 10px;
                                "
                              >
                                <use href="#icon-geren"></use>
                              </svg>
                              <div>
                                <el-tooltip
                                  v-if="item.actorIdentType == 'corp'"
                                  popper-class="my-tooltip"
                                  effect="dark"
                                  :content="
                                    !item.corpName || item.corpName === ''
                                      ? '-'
                                      : item.corpName
                                  "
                                  placement="top-start"
                                >
                                  <div
                                    style="margin-bottom: 10px; color: #080707"
                                    class="font-change"
                                  >
                                    {{
                                      !item.corpName || item.corpName === ""
                                        ? "-"
                                        : item.corpName
                                    }}
                                  </div>
                                </el-tooltip>
                                <el-tooltip
                                  v-else
                                  popper-class="my-tooltip"
                                  effect="dark"
                                  :content="
                                    !item.userName || item.userName === ''
                                      ? '-'
                                      : item.userName
                                  "
                                  placement="top-start"
                                >
                                  <div
                                    style="margin-bottom: 10px; color: #080707"
                                    class="font-change"
                                  >
                                    {{
                                      !item.userName || item.userName === ""
                                        ? "-"
                                        : item.userName
                                    }}
                                  </div>
                                </el-tooltip>
                                <el-tooltip
                                  popper-class="my-tooltip"
                                  effect="dark"
                                  :content="
                                    item.userName + ' ' + item.notifyAddress
                                  "
                                  placement="top-start"
                                >
                                  <div class="font-change">
                                    {{
                                      item.actorIdentType == "corp"
                                        ? item.userName
                                          ? item.userName
                                          : "-"
                                        : ""
                                    }}
                                    ({{ item.notifyAddress }})
                                  </div>
                                </el-tooltip>
                              </div>
                            </div>

                            <div class="right_con_right">
                              <div>
                                {{
                                  item.fillActorStatus == "no_send"
                                    ? $t("c756")
                                    : item.fillActorStatus == "to_accept"
                                    ? $t("c757")
                                    : item.fillActorStatus == "accepted"
                                    ? $t("c758")
                                    : item.fillActorStatus == "to_fill"
                                    ? $t("c759")
                                    : item.fillActorStatus == "filled"
                                    ? $t("front-electronic-signature-writen")
                                    : $t("c760")
                                }}
                              </div>
                              <div
                                v-show="
                                  item.actionTime && item.actionTime !== ''
                                "
                                style="margin-top: 10px"
                              >
                                {{ transformTime(Number(item.actionTime)) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="right_con" v-if="signActorsData.length > 0">
                      <div class="right_con1_title">
                        <div class="spot"></div>
                        <div>{{ $t("c761") }}</div>
                        <div>
                          （{{
                            signData.signInOrder ? $t("c824") : $t("c876")
                          }}）
                        </div>
                      </div>
                      <!-- 进度条 -->
                      <div
                        class="step-line"
                        v-for="(item, index) in signActorsData"
                        :key="index"
                      >
                        <div class="left_icon">
                          <svg
                            style="width: 22px; height: 22px"
                            aria-hidden="true"
                            v-if="item.fillActorStatus == 'fill_completed'"
                          >
                            <use href="#icon-Finish"></use>
                          </svg>
                          <div v-else class="left_first">{{ index + 1 }}</div>
                          <div
                            class="line"
                            v-if="index != signData.signActors.length - 1"
                          ></div>
                        </div>
                        <div class="right_con">
                          <div class="right_con_box">
                            <div class="right_con_left">
                              <svg
                                v-if="item.actorIdentType == 'corp'"
                                aria-hidden="true"
                                style="
                                  width: 20px;
                                  height: 15px;
                                  margin-right: 10px;
                                "
                              >
                                <use href="#icon-qiye"></use>
                              </svg>
                              <svg
                                v-else
                                class="active-template-item-right-icon"
                                aria-hidden="true"
                                style="
                                  width: 20px;
                                  height: 15px;
                                  margin-right: 10px;
                                "
                              >
                                <use href="#icon-geren"></use>
                              </svg>
                              <div>
                                <el-tooltip
                                  v-if="item.actorIdentType == 'corp'"
                                  popper-class="my-tooltip"
                                  effect="dark"
                                  :content="
                                    !item.corpName || item.corpName === ''
                                      ? '-'
                                      : item.corpName
                                  "
                                  placement="top-start"
                                >
                                  <div
                                    style="margin-bottom: 10px; color: #080707"
                                    class="font-change"
                                  >
                                    {{
                                      !item.corpName || item.corpName === ""
                                        ? "-"
                                        : item.corpName
                                    }}
                                  </div>
                                </el-tooltip>
                                <el-tooltip
                                  v-else
                                  popper-class="my-tooltip"
                                  effect="dark"
                                  :content="
                                    !item.userName || item.userName === ''
                                      ? '-'
                                      : item.userName
                                  "
                                  placement="top-start"
                                >
                                  <div
                                    style="margin-bottom: 10px; color: #080707"
                                    class="font-change"
                                  >
                                    {{
                                      !item.userName || item.userName === ""
                                        ? "-"
                                        : item.userName
                                    }}
                                  </div>
                                </el-tooltip>
                                <el-tooltip
                                  popper-class="my-tooltip"
                                  effect="dark"
                                  :content="
                                    item.userName + ' ' + item.notifyAddress
                                  "
                                  placement="top-start"
                                >
                                  <div class="font-change">
                                    {{
                                      item.actorIdentType == "corp"
                                        ? item.userName
                                          ? item.userName
                                          : "-"
                                        : ""
                                    }}
                                    ({{ item.notifyAddress }})
                                  </div>
                                </el-tooltip>
                              </div>
                            </div>
                            <div class="right_con_right">
                              <div>
                                {{
                                  item.signActorStatus == "no_send"
                                    ? $t("c756")
                                    : item.signActorStatus == "to_accept"
                                    ? $t("c757")
                                    : item.signActorStatus == "accepted"
                                    ? $t("c758")
                                    : item.signActorStatus == "to_sign"
                                    ? $t("c762")
                                    : item.signActorStatus == "signed"
                                    ? $t("c763")
                                    : item.signActorStatus == "refused"
                                    ? $t("c764")
                                    : item.signActorStatus == "blocked"
                                    ? $t("c765")
                                    : ""
                                }}
                              </div>
                              <div
                                v-show="
                                  item.actionTime && item.actionTime !== ''
                                "
                                style="margin-top: 10px"
                              >
                                {{ transformTime(Number(item.actionTime)) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 签署进度查询 -->
            <span slot="reference" class="popup_text">{{ $t("c767") }}</span>
          </el-popover>
          <div
            class="revoke"
            @click="revokeBtn"
            v-show="
              signData.signTaskStatus == 'task_created' ||
              signData.signTaskStatus == 'task_lnitiated' ||
              signData.signTaskStatus == 'fill_progress' ||
              signData.signTaskStatus == 'fill_finalizing' ||
              signData.signTaskStatus == 'fill_finalized' ||
              signData.signTaskStatus == 'sign_progress'
            "
          >
            {{ $t("label.custom.revoke") }}
          </div>
        </div>
      </div>

      <!-- 签章进度 -->
      <div class="signature" style="display: none">
        <div
          class="signatureItem"
          v-for="(item, index) in fileList"
          :key="index"
        >
          <!-- 内容 -->
          <div class="itemContent">
            <!-- logo -->
            <div class="logo">
              <svg
                style="width: 100%; height: 100%"
                class="icon"
                aria-hidden="true"
              >
                <use :href="allIcon['pdf'] || defaultIcon"></use>
              </svg>
            </div>
            <!-- content -->
            <div class="content" @click="downloadSignFile(item)">
              {{ item.name }}
            </div>
            <!-- 状态 -->
            <!-- <div class="status">{{ item.status }}</div> -->
            <!-- 进度查询 -->
            <!-- <el-dropdown trigger="click" placement="bottom">      
              <div class="process">签署进度查询</div>
              <el-dropdown-menu slot="dropdown">
                <div style="padding: 20px">
                  <div class="menuItemTitle">WAITING FOR</div>
                  <div class="menuItemContent">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        margin: 20px 0px;
                      "
                    >
                      <div class="circle"></div>
                      <span>P1</span>
                    </div>
                    <div class="">Sent on 11/22/2021| 04；14：03 pm</div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown> -->
            <!-- 撤销 -->
            <!-- <div class="process">撤销</div> -->
          </div>
        </div>
      </div>
      <!-- 填写人 -->
      <div
        style="display: none"
        class="signPerson"
        v-show="
          signPersonList.inList.length > 0 || signPersonList.outList.length > 0
        "
      >
        <div class="personItem">
          <span class="personTitle">{{
            $t("label.weixin.appr.applicant")
          }}</span>
          <span class="personName">{{ initiator }}</span>
        </div>
        <div class="personItem">
          <span class="personTitle">{{
            $t("front-electronic-signature-innerSinger")
          }}</span>
          <div
            v-for="(item, index) in signPersonList.inList"
            :key="index"
            style="display: inline-block"
          >
            <span class="personName">{{ item.name }}</span>
            <span class="signStatus">{{ showSignStaus(item.status) }}</span>
          </div>
        </div>
        <div class="personItem">
          <span class="personTitle">{{
            $t("front-electronic-signature-outerSinger")
          }}</span>
          <div style="display: flex; flex-direction: column">
            <div
              v-for="(item, index) in signPersonList.outList"
              :key="index"
              style="display: inline-block"
            >
              <span class="personName">{{ item.name }}</span>
              <span class="signStatus">{{ showSignStaus(item.status) }}</span>
            </div>
          </div>
        </div>
      </div>

      <openSigned
        ref="openSigned"
        :dialogVisibleSigned="dialogVisibleSigned"
        @closeContractDialog="closeContractDialog"
        @setTemplateData="setTemplateData"
        @getDocuTemplate="getDocuTemplate"
        :fadadaOrdocusign="fadadaOrdocusign"
        @getFadadaDetail="getFadadaDetail"
      />
    </div>
    <!-- 1已创建 task_created 2已发起task_lnitiated 3填写进行中fill_progress 4已完成fill_completed  5定稿中fill_finalizing 6已定稿  fill_finalized 7签署进行中 sign_progress 8已完成 sign_completed 9已结束task_finished 10已撤销 task_canceled  11已终止task_stopped-->
    <!-- 文件 -->
    <!-- 未签署完成展示的文件 -->
    <div
      class="file"
      v-for="(item, index) in signData.docs"
      :key="index"
      v-show="envelopeData.envelopeid && fileLists.length == 0"
    >
      <svg
        class="active-template-item-right-icon"
        aria-hidden="true"
        style="width: 16px; height: 16px; margin-top: 2px; margin-right: 5px"
      >
        <use href="#icon-wenbenwendang-txt"></use>
      </svg>
      <div v-if="signData.signTaskStatus == 'task_finished'">
        <span>{{ item.docName }} 45</span>
      </div>
      <!-- 已发起的合同签署任务尚未完成，请在合同完成签署后进行文件下载。 -->
      <el-popover
        v-else
        placement="top-start"
        width="200"
        trigger="hover"
        :content="$t('c768')"
      >
        <span slot="reference">{{ item.docName }}</span>
      </el-popover>
    </div>
    <!-- 签署完成文件同步后展示的文件 -->
    <div
      class="file"
      v-for="(item, index) in fileLists"
      :key="index"
      v-show="envelopeData.envelopeid && fileLists.length > 0"
    >
      <svg
        class="active-template-item-right-icon"
        aria-hidden="true"
        style="width: 16px; height: 16px; margin-top: 2px; margin-right: 5px"
      >
        <use href="#icon-wenbenwendang-txt"></use>
      </svg>
      <div @click="downloadSignFile(item)">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <!-- 撤销签署任务弹框 -->
    <el-dialog
      :visible.sync="dialogRevoke"
      width="30%"
      :title="$t('label_popup_infoz')"
    >
      <!-- 撤销后，已发起的签署任务文件将被清空，是否继续撤销？ -->
      <span class="confirmInfo">{{ $t("c769") }}</span>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="dialogRevoke = false">{{
          $t("label.cancel")
        }}</el-button>
        <!-- 确认 -->
        <el-button
          :loading="confirmLoading"
          type="primary"
          @click="confirmBtn"
          >{{ $t("label.emailtocloudcc.button.sure") }}</el-button
        >
      </span>
    </el-dialog>
    <contractSign
      ref="contractSign"
      :templateData="templateData"
      :fadadaDetailData="fadadaDetailData"
      @getEnvelopeId="getEnvelopeId"
      @closeContractDialog="closeContractDialog"
    ></contractSign>
    <el-button class="contractButton" @click="openSigned">{{
      signButtonStatus
    }}</el-button>
  </div>
</template>

<script>
import openSigned from "@/views/contract/openSigned.vue";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import * as docusignApi from "./api.js";
import contractSign from "./contractSign.vue";

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: { openSigned, contractSign },
  data() {
    return {
      loading: false,
      //合同签署状态
      signStauts: "",
      setStatus: "",
      signPersonList: {
        inList: [],
        outList: [],
      },
      visible: false,
      allIcon: FILEICON, // 文件图标
      defaultIcon: FILEICON.default, // 文件缺省图标
      dialogVisibleSigned: false,
      fileList: [],
      templateData: {},
      userInfo: {},
      showContract: false,
      initiator: "",
      fadadaOrdocusign: 0, //0 法大大  1 docusign
      signData: [], //
      participants: {}, //参与方信息
      envelopeData: {},
      initiatTime: "", //签署截止时间
      dialogRevoke: false, //撤销弹框
      confirmLoading: false,
      curSignTaskStatus: "", //当前签署状态
      signActorsData: [], //签署人信息
      fillActorsData: [], //填写人息息
      fileLists: [], //法大大文件信息
    };
  },
  computed: {
    //签署按钮状态
    signButtonStatus() {
      return this.fileList.length > 0
        ? this.$i18n.t("front-electronic-signature-singed")
        : this.envelopeData.envelopeid
        ? this.$i18n.t("front-electronic-signature-singed")
        : this.$i18n.t("front-electronic-signature-openSign");
    },
  },
  mounted() {
    this.contractLabelInit();
    this.envelopesStatus();
    this.getEnvelopeId();
    // this.getParticipants();
  },
  //方法集合
  methods: {
    transformTime(dates) {
      let date = new Date(dates);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      let time = `${year}-${month}-${day} ${hours}:${minutes}`;
      return time;
    },
    // 文件下载
    downloadSignFile(file) {
      let baseURL = this.$baseConfig.baseURL;
      let token = this.$CCDK.CCToken.getToken();
      var link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${file.id}`;
      link.click();
    },
    //返回签署人状态
    showSignStaus(str) {
      switch (str) {
        case "sent":
          return this.$i18n.t("front-electronic-signature-sended");
        case "delivered":
          return this.$i18n.t("front-electronic-signature-given");
        case "completed":
          return this.$i18n.t("front-electronic-signature-writen");
      }
    },
    //合同签署
    contractLabelInit() {
      if (this.$route.query.envelopeId) {
        docusignApi
          .updateEnvelopeidToContract({
            envelopeId: this.$route.query.envelopeId,
            contractId: this.$route.params.id,
          })
          .then((res) => {
            if (res.result) {
              this.$message.success(
                this.$i18n.t("front-electronic-signature-sucessSign")
              );
              let newQuery = JSON.parse(JSON.stringify(this.$route.query));
              newQuery.envelopeId = null;
              newQuery.event = null;
              this.$router.replace({ query: newQuery });
              this.envelopesStatus();
            }
          });
      }
    },
    envelopesStatus() {
      // 获取签署设定状态  0未开通 1法大大 2 docusign
      docusignApi.getElectronicContractStatus().then((res) => {
        this.setStatus = res.data;
        if (
          this.setStatus.status == "1" &&
          this.setStatus.serviceProvider !== "0" &&
          this.setStatus.authstatus == "1"
        ) {
          this.showContract = true;
          // 获取文件信息
          docusignApi
            .contractDocuments({ contractId: this.$route.params.id })
            .then((res) => {
              if (res.data && res.data !== []) {
                this.fileList = res.data;
                if (this.fileList.length > 0) {
                  this.loading = true;
                  // 获取信封状态
                  docusignApi
                    .envelopesStatus({
                      contractId: this.$route.params.id,
                    })
                    .then((status) => {
                      if (status.result) {
                        this.signStauts =
                          status.data.status == "sent"
                            ? this.$i18n.t("front-electronic-signature-sended")
                            : this.$i18n.t("label.htyj.completed");
                        this.loading = false;
                      }
                    });
                  //获取收件人签署状态
                  docusignApi
                    .envelopesRecipientsF({ contractId: this.$route.params.id })
                    .then((res) => {
                      this.initiator = res.data.initiator
                        ? res.data.initiator
                        : "";
                      this.signPersonList = {
                        inList: [],
                        outList: [],
                      };
                      res.data.signers.forEach((item) => {
                        if (item.signerType === "0") {
                          this.signPersonList.inList.push(item);
                        } else {
                          this.signPersonList.outList.push(item);
                        }
                      });
                    });
                }
              }
            });
          // 法大大签署文件
          docusignApi
            .contractDocumentsF({
              contractId: this.$route.params.id,
            })
            .then((res) => {
              this.fileLists = res.data;
              this.fileLists.forEach((item) => {
                let arr = item.name.split(".");
                if (arr.length === 3) {
                  item.name = arr[0] + "." + arr[2];
                }
              });
            });
        } else {
          this.showContract = false;
        }
        if (this.setStatus.serviceProvider == "1") {
          this.fadadaOrdocusign = 0;
        } else if (this.setStatus.serviceProvider == "2") {
          this.fadadaOrdocusign = 1;
        }
      });
    },
    setTemplateData(row, userInfo) {
      this.templateData = row;
      this.userInfo = userInfo;
    },
    openSigned() {
      if (this.fileList.length > 0) {
        this.$message.error(
          this.$i18n.t("front-electronic-signature-signStatus", {
            signStauts: this.signStauts,
          })
        );
      } else if (this.fadadaOrdocusign == 0 && this.envelopeData.envelopeid) {
        //1已创建 task_created 2已发起task_lnitiated 3填写进行中fill_progress 4已完成fill_completed  5定稿中fill_finalizing 6已定稿  fill_finalized 7签署进行中 sign_progress 8已完成 sign_completed 9已结束task_finished 10已撤销 task_canceled  11已终止task_stopped
        let state =
          this.curSignTaskStatus == "task_created"
            ? this.$i18n.t("message.fieldtrack.operatecreate")
            : this.curSignTaskStatus == "task_lnitiated"
            ? this.$i18n.t("c770")
            : this.curSignTaskStatus == "fill_progress"
            ? this.$i18n.t("c771")
            : this.curSignTaskStatus == "fill_completed"
            ? this.$i18n.t("label.htyj.completed")
            : this.curSignTaskStatus == "fill_finalizing"
            ? this.$i18n.t("c772")
            : this.curSignTaskStatus == "fill_finalized"
            ? this.$i18n.t("c773")
            : this.curSignTaskStatus == "sign_progress"
            ? this.$i18n.t("c774")
            : this.curSignTaskStatus == "sign_completed"
            ? this.$i18n.t("label.htyj.completed")
            : this.curSignTaskStatus == "task_finished"
            ? this.$i18n.t("label.state.end")
            : this.$i18n.t("c775");
        this.$message.error(
          this.$i18n.t("front-electronic-signature-signStatus", {
            signStauts: state,
          })
        );
      } else if (
        this.setStatus.status === "0" ||
        this.setStatus.serviceProvider === "0"
      ) {
        this.$message.error(this.$$i18n.t("front-electronic-signature-nosign"));
      } else {
        this.dialogVisibleSigned = true;
      }
    },
    closeContractDialog() {
      this.dialogVisibleSigned = false;
    },
    // 获取docu 回填信息
    getDocuTemplate() {
      let templateRolesData = [];
      if (this.templateData.recipients.signers.length === 0) {
        // 设置第一个回填消息
        templateRolesData[0] = {
          name: this.userInfo.userName,
          email: this.userInfo.email,
          roleName: "roleName1",
        };
        let userId = "";
        docusignApi
          .getDetail({
            id: this.$route.params.id,
            objectApi: "",
            operation: "DETAIL",
            recordType: "",
          })
          .then((res) => {
            // 查找合同对象
            res.data.dataList.forEach((item) => {
              item.data.forEach((list) => {
                // 确定合同客户id
                if (list.left.fieldId === "ffe2011000049909E5PO") {
                  userId = list.left.data[0].id;
                  return;
                }
                if (list.right.fieldId === "ffe2011000049909E5PO") {
                  userId = list.right.data[0].id;
                  return;
                }
              });
            });
            if (userId !== "") {
              // 通过合同客户id获取信息
              docusignApi
                .getDetail({
                  id: userId,
                  objectApi: "",
                  operation: "MINI",
                  recordType: "",
                })
                .then((res) => {
                  // 设置第二个回填信息
                  templateRolesData[1] = {
                    name: res.data.data.name, //客户名
                    email: res.data.data.qyyx ? res.data.data.qyyx : "null", //企业邮箱
                    roleName: "roleName2",
                  };
                  this.getDocuUrl(templateRolesData);
                });
            } else {
              this.getDocuUrl(templateRolesData);
            }
          });
      } else {
        // 设置第一个回填消息
        templateRolesData[0] = {
          name: this.templateData.recipients.signers[0].name,
          email: this.templateData.recipients.signers[0].email,
          roleName: "roleName1",
        };

        let userId = "";
        docusignApi
          .getDetail({
            id: this.$route.params.id,
            objectApi: "",
            operation: "DETAIL",
            recordType: "",
          })
          .then((res) => {
            // 查找合同对象
            res.data.dataList.forEach((item) => {
              item.data.forEach((list) => {
                // 确定合同客户id
                if (list.left.fieldId === "ffe2011000049909E5PO") {
                  userId = list.left.data[0].id;
                  return;
                }
                if (list.right.fieldId === "ffe2011000049909E5PO") {
                  userId = list.right.data[0].id;
                  return;
                }
              });
            });
            if (userId !== "") {
              // 通过合同客户id获取信息
              docusignApi
                .getDetail({
                  id: userId,
                  objectApi: "",
                  operation: "MINI",
                  recordType: "",
                })
                .then((res) => {
                  // 设置第二个回填信息
                  templateRolesData[1] = {
                    name: res.data.data.name, //客户名
                    email: res.data.data.qyyx ? res.data.data.qyyx : "null", //企业邮箱
                    roleName: "roleName2",
                  };
                  this.getDocuUrl(templateRolesData);
                });
            } else {
              this.getDocuUrl(templateRolesData);
            }
          });
      }
    },
    // 获取docu url
    getDocuUrl(templateRolesData) {
      docusignApi
        .envelopesCreate({
          templateId: this.templateData.templateId,
          templateRoles: templateRolesData,
          returnUrl: window.location.href,
          contractId: this.$route.params.id,
          templateName: this.templateData.name,
        })
        .then((res) => {
          if (res.result) {
            window.location.href = res.data.url;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    // 参与方信息
    // getParticipants() {
    //   docusignApi.getParticipantsInfo({
    //     contractId: this.$route.params.id,
    //   }).then((res)=>{
    //     this.participants=res.data
    //   })
    // },
    //签署任务详情
    signTaskDetail() {
      docusignApi
        .signTaskDetail({ contractId: this.$route.params.id })
        .then((res) => {
          this.signData = res.data;
          // this.signData.docs
          let obj = {
            company: res.data.initiator.initiateCom,
            khmc: res.data.initiator.initiator,
          };
          this.participants = obj;
          this.signActorsData = res.data.signActors.sort(
            (a, b) => a.orderNo - b.orderNo
          ); //签署人顺序
          this.fillActorsData = res.data.fillActors.sort(
            (a, b) => a.orderNo - b.orderNo
          ); //填写人顺序
          this.initiatTime = res.data.initiator.expiresTime;
          this.curSignTaskStatus = res.data.signTaskStatus;
          let state =
            this.curSignTaskStatus == "task_created"
              ? this.$i18n.t("message.fieldtrack.operatecreate")
              : this.curSignTaskStatus == "task_lnitiated"
              ? this.$i18n.t("c770")
              : this.curSignTaskStatus == "fill_progress"
              ? this.$i18n.t("c771")
              : this.curSignTaskStatus == "fill_completed"
              ? this.$i18n.t("label.htyj.completed")
              : this.curSignTaskStatus == "fill_finalizing"
              ? this.$i18n.t("c772")
              : this.curSignTaskStatus == "fill_finalized"
              ? this.$i18n.t("c773")
              : this.curSignTaskStatus == "sign_progress"
              ? this.$i18n.t("c774")
              : this.curSignTaskStatus == "sign_completed"
              ? this.$i18n.t("label.htyj.completed")
              : this.curSignTaskStatus == "task_finished"
              ? this.$i18n.t("label.state.end")
              : this.$i18n.t("c775");
          this.curSignTaskStatus = state;
        });
    },
    getEnvelopeId() {
      docusignApi
        .getEnvelopeId({ contractId: this.$route.params.id || this.id })
        .then((res) => {
          this.envelopeData = res.data;
          if (res.data.envelopeid) {
            this.signTaskDetail();
          }
        });
    },
    //撤销签署任务
    revokeBtn() {
      this.dialogRevoke = true;
    },
    confirmBtn() {
      this.confirmLoading = true;
      docusignApi
        .cancelSignTask({ contractId: this.$route.params.id })
        .then((res) => {
          if (res.returnCode == "1") {
            this.confirmLoading = false;
            this.dialogRevoke = false;
            this.getEnvelopeId();
            this.$message.success(this.$t("c776"));
          }
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.contractLabel {
  width: 100%;
  min-height: 85px;
  margin-top: 10px;
  background: white;
  border-radius: 3px;
  border: 1px solid #dddbda;
  padding: 17px;
  position: relative;
  .contractTitle {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    margin-top: 55px;
    display: flex;
    .signature {
      margin-top: 45px;
      .signatureItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .itemContent {
          display: flex;
          align-items: center;
          .logo {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
          .content {
            font-size: 14px;
            font-weight: 400;
            color: #006dcc;
            cursor: pointer;
          }
          .status {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin: 0px 8px;
          }
          .process {
            font-size: 14px;
            font-weight: 400;
            color: #006dcc;
            cursor: pointer;
            margin-left: 18px;
          }
        }
        .deleteIcon {
          width: 15px;
          height: 15px;
        }
      }
    }
    .signPerson {
      border-top: 1px solid #dedcda;
      display: flex;
      flex-flow: wrap;
      .personItem {
        width: 30%;
        margin-top: 10px;
        display: flex;
        .personTitle {
          font-size: 14px;
          font-weight: 400;
          color: #006dcc;
        }
        .personName {
          font-size: 14px;
          font-weight: 400;
          color: #2c2c2c;
          margin: 0px 5px 0px 35px;
        }
        .signStatus {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .file {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .contractButton {
    position: absolute;
    top: 20px;
    right: 40px;
    height: 44px;
    background: #e7600e;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
    line-height: 19p;
  }
}
.menuItemTitle {
  padding-bottom: 5px;
  border-bottom: 1px solid #dddbd9;
  font-size: 12px;
  font-weight: bold;
  color: #333333;
}
.menuItemContent {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #006dcc;
    margin-right: 10px;
  }
}
.sign_popover {
  margin-left: 30px;
  display: flex;
  align-items: center;
  .revoke {
    width: 28px;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #2d6cfc;
    margin-left: 18px;
    cursor: pointer;
  }
}
::v-deep .el-popover {
  width: 502px;
  height: auto;
  background: #ffffff;
  opacity: 1;
}
.popup_text {
  width: 84px;
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #2d6cfc;
}
.popup {
  .t {
    width: 100%;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #dddbd9;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }
  .title {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    margin: 16px 20px 16px 0px;
    .tit_left {
      span:nth-child(1) {
        color: #5f5e5e;
      }
      span:nth-child(2) {
        color: #080707;
        cursor: pointer;
      }
    }
    .tit_left.date {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tit_left.people {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
  .content {
    display: flex;
    .line {
      height: auto;
      margin-left: 16px;
      border-left: 1px solid #e1e1e1;
    }
    .content_right {
      margin-left: -12px;
      .right_first {
        .right_title {
          display: flex;
          align-items: center;
          > span {
            height: 18px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            line-height: 16px;
          }
        }
        .right_con {
          width: 422px;
          height: 66px;
          background: #f3f7ff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          margin-left: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          font-size: 12px;
          font-weight: 400;
          color: #5f5e5e;
          .right_con_left {
            display: flex;
            align-items: center;
          }
          .right_con_right {
            text-align: right;
          }
        }
      }
      .right_second {
        margin-top: 10px;
        .right_title {
          display: flex;
          align-items: center;
          > span {
            height: 18px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            line-height: 16px;
          }
        }
        .right_con {
          .right_con1_title {
            display: flex;
            align-items: center;
            margin-left: -9px;
            margin-bottom: 16px;
            .spot {
              width: 8px;
              height: 8px;
              background: #2d6cfc;
              opacity: 1;
              margin: 0 10px 0 16px;
              border-radius: 50%;
            }
          }
          .step-line {
            margin-left: 26px;
            display: flex;
            .left_icon {
              display: flex;
              flex-direction: column;
              // justify-content:center;
              margin: 0px 10px 0 0;
              transform: translateY(20px);
              .left_first {
                width: 22px;
                height: 22px;
                background-color: #eeeeee;
                border-radius: 50%;
                text-align: center;
                line-height: 22px;
              }
              .line {
                height: 52px;
                opacity: 1;
                border-left: 1px solid #dddbd9;
                margin: 4px 0 4px 11px;
              }
            }
            .right_con {
              .right_con_box {
                width: 392px;
                height: 66px;
                background: #f3f7ff;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                margin-bottom: 16px;
                font-size: 12px;
                padding: 0 10px;
                font-weight: 400;
                color: #5f5e5e;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .right_con_left {
                  display: flex;
                  align-items: center;
                }
                .right_con_right {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}
.font-change {
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>