<!--
 * @Author: zhoumeng
 * @Date: 2021-11-18    14:50:21
 * @LastEditTime: 2022-06-28 10:51:27
 * @LastEditors: 张亚欣 18911148524@163.com
 * @FilePath: \lightning-web\src\views\contract\openSigned.vue
-->
<template>
  <div class="openSigned">
    <el-dialog
      :title="$t('front-electronic-signature-ContractTemplate')"
      :visible.sync="signedDialogVisible"
      width="45%"
      @close="closeDialog"
    >
      <div class="signedContent">
        <div class="fileList" v-show="fileList.length !== 0">
          <div class="fileItem" v-for="(item, index) in fileList" :key="index">
            <!-- 内容 -->
            <div class="itemContent">
              <!-- logo -->
              <div class="logo">
                <svg
                  style="width: 100%; height: 100%"
                  class="icon"
                  aria-hidden="true"
                >
                  <use :href="allIcon[item.type] || defaultIcon"></use>
                </svg>
              </div>
              <!-- content -->
              <div class="content">
                <div class="fileName">{{ item.fileName }}</div>
              </div>
            </div>
            <!-- 删除按钮  -->
            <div class="deleteIcon" @click="deleteFile(index)">
              <svg
                style="width: 100%; height: 100%"
                class="icon"
                aria-hidden="true"
              >
                <use href="#icon-shanchu1"></use>
              </svg>
            </div>
          </div>
        </div>
        <div class="uploadContent">
          <div class="content">
            <svg class="upload_icon" v-show="!checked" aria-hidden="true">
              <use xlink:href="#icon-shangchuanwenjian"></use>
            </svg>
            <el-button
              size="small"
              @click="openTemDialog"
              type="primary"
              class="uploadButton"
              >{{ $t("label.select.template") }}</el-button
            >
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="fadadaOrdocusign == 1">
        <el-button
          type="primary"
          @click="getDocuTemplate"
          :loading="setLoading"
          >{{ $t("front-electronic-signature-signerInfo") }}</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button
          type="primary"
          @click="getDocuTemplate"
          :loading="setLoading"
          >{{ $t("label_tabpage_next") }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <div class="serchArea">
        <div class="searchContent">
          <span>{{ $t("label.emailtemplate.name") }}</span>
          <el-input
            v-model="userInput"
            placeholder=""
            @keyup.enter.native="searchT"
          ></el-input>
        </div>
        <div class="buttonGroup">
          <el-button type="primary" size="small" @click="search">{{
            $t("label.searchs")
          }}</el-button>
          <el-button type="primary" size="small" @click="clear">{{
            $t("label.tabpage.emptyz")
          }}</el-button>
        </div>
      </div>
      <div class="tableData" v-loading="loading">
        <!-- 法大大合同模板列表弹框 -->
        <el-table
          :data="signTempalteList"
          style="width: 100%"
          height="358"
          v-if="fadadaOrdocusign == 0"
        >
          <el-table-column
            v-for="(item, index) in headLists"
            :key="index"
            :prop="item.fieldId"
            :label="item.label"
          >
            <template slot-scope="scope">
              <span
                class="allowClick"
                @click="choseTemplate(scope.row)"
                v-if="item.fieldId === 'name'"
              >
                <!-- {{scope.row}} -->
                {{ scope.row.signTemplateName }}
              </span>
              <span v-else>
                <span v-show="scope.row.docTemplateName">{{ $t("c793") }}</span>
                <span v-show="scope.row.signTemplateName">{{
                  $t("c794")
                }}</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="dataList" style="width: 100%" height="358" v-else>
          <el-table-column
            v-for="(item, index) in headList"
            :key="index"
            :prop="item.fieldId"
            :label="item.label"
          >
            <template slot-scope="scope">
              <span
                class="allowClick"
                @click="choseTemplate(scope.row)"
                v-if="item.fieldId === 'name'"
              >
                {{ scope.row[item.fieldId] }}
              </span>
              <span v-else>
                {{ scope.row[item.fieldId] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="height: 200px"></div>
    </el-dialog>
  </div>
</template>

<script>
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import * as docusignApi from "./api.js";
export default {
  components: {},
  props: {
    dialogVisibleSigned: {
      type: Boolean,
      default: false,
    },
    fadadaOrdocusign: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      signedDialogVisible: false,
      userInfo: {}, //获取用户列表
      setLoading: false,
      userInput: "",
      loading: false,
      fileList: [],
      allIcon: FILEICON, // 文件图标
      defaultIcon: FILEICON.default, // 文件缺省图标
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "ffe4102000032890C8YC",
      relevantObjId: "user",
      relevantObjApi: "User",
      relevantPrefix: "005",
      checked: false,
      headList: [
        { fieldId: "name", label: this.$i18n.t("label.partner.template.name") },
        {
          fieldId: "templateDocument",
          label: this.$i18n.t("label.emailmerge.newtemplate.file"),
        },
        {
          fieldId: "lastModified",
          label: this.$i18n.t("front-electronic-signature-newTime"),
        },
      ],
      dataList: [],
      headLists: [
        { fieldId: "name", label: this.$i18n.t("label.partner.template.name") },
        { fieldId: "templatetype", label: this.$t("label.emailtemplate.type") },
      ], //选择模板类型表头
      signTempalteList: [],
    };
  },
  watch: {
    dialogVisibleSigned(val) {
      if (val) {
        this.fileList = [];
        this.setLoading = false;
      }
      this.signedDialogVisible = val;
    },
  },
  //方法集合
  methods: {
    getDocuTemplate() {
      if (this.fadadaOrdocusign == 1) {
        if (this.fileList.length > 0) {
          this.setLoading = true;
          this.$emit("getDocuTemplate");
        } else {
          this.openTemDialog();
        }
      } else {
        if (this.fileList.length == 0) {
          this.$message.warning(this.$i18n.t("c795"));
        } else {
          this.$parent.$refs.contractSign.contractSignVisible = true;
        }
      }
    },
    searchT(e) {
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.search();
      }
    },
    search() {
      if (this.userInput) {
        if (this.fadadaOrdocusign == 0) {
          this.signTempalteList.map((item, index) => {
            if (item.fieldName.indexOf(this.userInput) === -1) {
              this.signTempalteList.splice(index, 1);
            }
          });
        } else {
          this.dataList.map((item, index) => {
            if (item.name.indexOf(this.userInput) === -1) {
              this.dataList.splice(index, 1);
            }
          });
        }
      }
    },
    clear() {
      this.userInput = "";
      this.getTemplateList();
    },
    choseTemplate(rowData) {
      if (this.fadadaOrdocusign == 0) {
        let array = [];
        rowData.docs.map((item) => {
          let obj = {};
          obj.fileName = item.docName.split(".")[0];
          obj.type = item.docName.split(".")[1];
          array.push(obj);
        });
        this.fileList = array;
        this.showSearchTable = false;
        this.$emit("setTemplateData", rowData, this.userInfo); //选择模板信息
        this.$bus.$emit("setTemplateData", rowData);
      } else {
        // 准备两个附件
        let array = [];
        rowData.templateDocuments.map((item) => {
          let obj = {};
          obj.fileName = item.name.split(".")[0];
          obj.type = item.name.split(".")[1];
          array.push(obj);
        });
        this.fileList = array;
        this.showSearchTable = false;
        this.$emit("setTemplateData", rowData, this.userInfo); //选择模板信息
      }
    },
    changeSelect() {
      this.$emit("fileList", this.fileList);
      this.showSearchTable = false;
    },
    async getTemplateList() {
      if (this.fadadaOrdocusign == 1) {
        this.loading = true;
        let result = await docusignApi.getTemplatesList({
          search_text: this.userInput,
        });
        this.loading = false;
        this.dataList = result.data.templates;
        this.userInfo = result.data.userInfo; //获取登录用户信息
        if (result.data.templates.length === 0) {
          this.$message.warning(
            this.$i18n.t("front-electronic-signature-prepareTemplate")
          );
          this.showSearchTable = false;
        }
        this.dataList.map((item) => {
          item.templateDocument = [];
          item.templateDocuments.map((fitem) => {
            item.templateDocument.push(fitem.name);
          });
          item.templateDocument = item.templateDocument.join(" , ");
        });
      } else if (this.fadadaOrdocusign == 0) {
        this.loading = true;
        //fadada 获取模板列表
        docusignApi.getTempalteList().then((res) => {
          if (res.data.signTempalteList.length === 0) {
            this.$message.warning(this.$i18n.t("c796"));
            this.showSearchTable = false;
          }
          this.loading = false;
          this.signTempalteList = res.data.signTempalteList;
        });
      }
    },
    openTemDialog() {
      this.showSearchTable = true;
      this.getTemplateList();
    },
    closeDialog() {
      this.$emit("closeContractDialog");
    },
    deleteFile(index) {
      this.fileList.splice(index, 1);
    },
  },
};
</script>
<style lang='scss' scoped>
.fileList {
  padding: 15px 0px;
  .fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .itemContent {
      display: flex;
      align-items: center;
      .logo {
        width: 32px;
        height: 36px;
        margin-right: 15px;
      }
      .content {
        .fileName {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
        }
        .fileOptions {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
        }
      }
    }
    .deleteIcon {
      width: 15px;
      height: 15px;
    }
  }
}
.uploadContent {
  width: 100%;
  min-height: 360px;
  background: #f5f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f5f7fa;
    .upload_icon {
      width: 100px;
      height: 84px;
    }
    .uploadButton {
      margin: 50px 0px 10px 0px;
    }
    .uploadTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
.uploadInfo {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
  margin-top: 10px;
}
::v-deep .el-upload {
  width: 100%;
  height: 360px;
}
::v-deep .el-upload-dragger {
  width: 100%;
  border: none;
  height: 360px;
  background: #f5f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
::v-deep .el-table-column--selection .cell {
  text-align: center;
}
// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}
// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}
// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}
.allowClick {
  color: #006dcc;
  text-decoration: solid;
  cursor: pointer;
}
.serchArea {
  width: 85%;
  margin: 0 auto;
  .searchContent {
    width: 100%;
    margin: 20px 0px;
    span {
      width: 10%;
      margin-right: 20px;
    }
    ::v-deep .el-input {
      width: 40%;
    }
    ::v-deep .el-input__inner {
      height: 25px;
    }
  }
  .buttonGroup {
    margin-bottom: 20px;
  }
}
.tableData {
  width: 85%;
  margin: 0 auto;
  border: 1px solid #dedcda;
}
</style>