<template>
  <el-card class="box-main_tit" v-show="showBusiness">
    <div class="detailTitle">
      <div class="title_detail" style="display: inline-block">商情发现</div>
      <button
        class="canGetBusiness"
        :class="{ canNotBusiness: canClick }"
        @click="getLists"
      >
        {{ showText ? "获取商情" : "更新商情" }}
      </button>
    </div>
    <div class="body" v-loading="loadDate">
      <!-- 空数据 -->
      <div v-show="noData" class="noData">
        <!-- 图片 -->
        <div>
          <svg class="icon" aria-hidden="true">
            <use href="#icon-nodata"></use>
          </svg>
        </div>
        <!-- 文字 -->
        <div class="title">
          商情可帮助销售更加了解客户动态，以此获得更多商机
        </div>
        <!-- 按钮 -->
        <div>
          <button class="canGetBusiness but" @click="getLists">获取商情</button>
        </div>
      </div>
      <!-- 有数据 -->
      <div v-show="!noData">
        <!-- 数据区域 -->
        <div class="hasData">
          <!-- 单独数据div -->
          <div class="singleData" v-for="(item, index) in busList" :key="index">
            <div
              style="float: left"
              :style="{
                float: 'left',
                width: item.imageurl !== '' ? '70%' : '75%',
              }"
            >
              <div style="display: flex; align-items: center">
                <!-- 图片 -->
                <div class="dataImg" v-show="item.imageurl !== ''">
                  <img :src="item.imageurl" alt="" />
                </div>
                <!-- 中间描述 -->
                <div class="desc">
                  <div class="title" @click="jumpDetail(item.url)">
                    {{ item.title }}
                  </div>
                  <div class="botTitle">
                    <div class="text">{{ item.website }}</div>
                    <div class="text">
                      {{ new Date(item.sourceTime * 1000).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右侧数量 -->
            <div class="numTip">
              <el-badge :value="item.sameCount" class="item"></el-badge>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { openBusiness, getBusList } from "../api.js";
export default {
  data() {
    return {
      // 商情的显示隐藏
      showBusiness: false,
      //  获取商情按钮的不同样式
      canClick: false,
      //  获取商情按钮的文字
      showText: true,
      //  数据是否为空
      noData: true,
      // 是否显示展开全部
      showOpenAll: true,
      //   商情列表数组
      busList: [],
      loadDate: false,
      // 存储一天的毫秒数
      oneDaySecond: 20000,
    };
  },
  created() {},
  mounted() {
    // this.oneDaySecond=1000*60*60*24;
    // 如果是客户  潜客  并且启用了商情  并且用户有权限才显示
    // if(this.$route.query.objId==='lead'|| this.$route.query.objId==='account'){
    //   this.getBusiness()
    // }
    // let businessList = localStorage.getItem('businessList');
    // if(!businessList){
    //     this.noDate=true;
    //     this.showText=true;
    // }else{
    //     this.busList=JSON.parse(businessList).params.list
    //     this.noData=false;
    //     let intervalSecond=new Date().getTime()-localStorage.getItem("businessDate");
    //     // 如果大于一天
    //     if(intervalSecond>this.oneDaySecond){
    //         // 可以获取商情点击按钮
    //         this.canClick=false;
    //         // 文字变为更新商情
    //         this.showText=false;
    //     }else{
    //         // 如果小于一天
    //         // 不可以获取商情点击按钮
    //         this.canClick=true;
    //         // 文字仍为获取商情
    //         this.showText=true;
    //     }
    // }
  },
  methods: {
        // 获取商情列表
    async getBusinessList() {
      // 每次获取商情列表  先将之前的缓存清除
      localStorage.removeItem("businessDate");
      localStorage.removeItem("businessList");
      let data = {
        searchWords: "23",
        pageSize: "6",
        pno: "1",
      };
      let result = await getBusList(data);
      if (!result.data == false) {
        let date = new Date().getTime();
        localStorage.setItem("businessDate", date);
        localStorage.setItem("businessList", result.data);
        let lists = localStorage.getItem("businessList");
        this.busList = JSON.parse(lists).params.list;
        this.noData = false;
        if (
          new Date().getTime() - localStorage.getItem("businessDate") >
          this.oneDaySecond
        ) {
          this.canClick = false;
          this.showText = false;
        }
      }
      this.loadDate = false;
    },
    getLists() {
      if (!this.canClick) {
        this.loadDate = true;
        this.canClick = true;
        this.getBusinessList();
      }
    },
    //是否可以获取商情
    async getBusiness() {
      if (!this.canClick) {
        let result = await openBusiness();
        if (!result.data == false) {
          if (result.data.businessEnable == "true") {
            this.showBusiness = true;
          }
        }
      } else {
        return;
      }
    },
    //   跳转详情页
    jumpDetail(website) {
      window.open(website);
    },
  },
};
</script>

<style lang="scss" scoped>
.detailTitle {
  border-bottom: 1px solid #dedc;
  .title_detail {
    font-weight: 600;
    font-size: 16px;
    color: #080707;
    padding-left: 10px;
    padding-right: 10px;
    // min-width: 84px;
    text-align: center;
    height: 36px;
    line-height: 32px;
    border-bottom: 2px solid #006dcc;
  }
}
.canGetBusiness {
  float: right;
  color: #006dcc;
  height: 30px;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid #dedcda;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  outline: none;
}
.canGetBusiness:focus {
  outline: none;
}
.canNotBusiness {
  background: #ffffff;
  color: #c8c6c6;
}
.body {
  width: 100%;
}
.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 90px;
}
.noData img {
  width: 206px;
  height: 113px;
}
.noData .title {
  margin-top: 10px;
  font-size: 12px;
  color: #333333;
}
.noData button {
  margin-top: 14px;
}
.but {
  background: #006dcc;
  color: #ffffff;
  border: none;
}
.hasData {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 20px;
}
.openALL {
  width: 100%;
  height: 28px;
  text-align: center;
  line-height: 33px;
  color: #006dcc;
  cursor: pointer;
  border-top: 1px solid #dddbda;
}
.singleData {
  margin-left: 10px;
  margin-top: 10px;
  border: 1px solid #dddbda;
  width: 48%;
  padding: 10px 20px 10px 10px;
  border-radius: 3px;
}
.dataImg img {
  width: 44px;
  height: 44px;
  margin-right: 16px;
}
.desc {
  width: 100%;
  // margin-left: 16px;
  // border: 1px solid #333333;
}
.numTip {
  float: right;
  width: 40px;
  // background-color: #006dcc;
}
.item {
  margin-top: 15px;
  margin-right: 20px;
}
.title {
  font-size: 14px;
  color: #006dcc;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}
.botTitle {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
::v-deep .el-badge__content {
  background-color: #c12218;
}
.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
