<template>
  <!-- 开发人员：李长兵 -->
  <div class="top" v-loading="fullscreenLoading || noClick">
    <div class="home" v-show="!isQuery && !fullscreenLoading">
      <div class="noPermissionBox">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-file404"></use>
        </svg>
        <p class="noPermissionTip">
          <!-- 抱歉，您无权限查看该条数据，请联系系统管理员。 -->
          {{ $t("vue_label_notice_nopermission_view") }}
        </p>
        <p style="text-align: center">
          <el-button type="primary" size="mini" @click="goBack">
            <!-- 返回上一级 -->
            {{ $t("label.goback") }}
          </el-button>
        </p>
      </div>
    </div>
    <div class="home" v-show="isQuery" id="contentMainBox" ref="home">
      <!-- 用户对象新增的头部 -->
      <detailsPersonal
        v-if="!isShowType"
        :id="id"
        :follow="follow"
        :fieldValue="userName"
      ></detailsPersonal>

      <div :class="routerName === 'DETAIL' ? 'contentBox' : 'contentBoxs'">
        <!-- <Wang-skeleton
          v-if="isStyleType"
          type="custom"
          :childrenOption="styleOption"
          style="background: #fff"
        />-->
        <!-- &&!isStyleType -->
        <div class="box_top" v-if="isShowType">
          <i
            class="iconfont iconjiantou-zuo left"
            @click="goBack"
            v-if="!$store.state.navigationStyle && historyLength > 1"
          ></i>
          <!-- 基本信息 -->
          <div>
            <svg class="icon box_header" aria-hidden="true">
              <use
                :href="tabStyle ? '#icon-' + tabStyle : '#icon-hometab'"
              ></use>
            </svg>
            <div class="title">
              <span class="title_top">{{ eventTap || tabName }}</span>
              <!-- <br /> -->
              <div class="title_box">
                <p class="title_main">
                  <span
                    v-html="bigTitle[0] ? bigTitle[0].fieldValue : ''"
                  ></span>
                  <el-tooltip
                    popper-class="my-tooltip"
                    class="item"
                    effect="dark"
                    :content="content"
                    placement="top"
                    v-if="isCustomer"
                  >
                    <i
                      style="cursor: pointer"
                      @contextmenu.prevent.stop="routers"
                      @click.prevent.stop="routers"
                    >
                      <svg
                        class="icon"
                        aria-hidden="true"
                        style="width: 18px; height: 18px"
                      >
                        <use href="#icon-cengji"></use>
                      </svg>
                    </i>
                  </el-tooltip>
                </p>
                <svg
                  class="icon lockedImage"
                  aria-hidden="true"
                  @click="lockBtn"
                  v-show="
                    bigTitle &&
                    bigTitle[0] &&
                    bigTitle[0].fieldValue &&
                    isLocked
                  "
                >
                  <use href="#icon-lockedImg"></use>
                </svg>
              </div>
            </div>
            <div class="header_right">
              <!-- 查看PDF视图 -->
              <div
                class="see-view-btn"
                v-if="
                  (objectApi == 'cloudccpurchaseorder' ||
                  objectApi == 'cloudccorder' ||
                  objectApi == 'quote' ||
                  objectApi == 'Invoices') &&
                  buttonList.find(item => item.name === 'pdfView')
                "
                @click="seeOldView"
              >
                {{ $t("c1022") }}
                <el-switch v-model="seeViewType"> </el-switch>
              </div>
              <!-- 付款按钮(只有在伙伴账户订单对象 且订单未支付时显示) -->

              <el-button
                size="mini"
                class="button edit_product"
                style="margin: 16px 10px 0 0; height: 28px"
                @click="goToPay"
                v-if="showPayButton"
                type="primary"
              >
                <span>
                  <!-- 付款 -->
                  {{ $t("label.quickbooks.payments") }}
                </span>
              </el-button>
              <!-- 编辑报价行(只有在报价单对象下才会有) -->
              <el-button
                size="mini"
                class="button edit_product"
                style="margin: 16px 10px 0 0; height: 28px"
                @click="editProduct"
                v-if="editQuoteline"
              >
                <span>
                  <!-- 编辑报价行 -->
                  {{ editQuoteline }}
                </span>
              </el-button>
              <!-- 编辑阶梯折扣(只有在折扣计划对象下才会有) -->
              <el-button
                size="mini"
                class="button edit_product"
                style="margin: 16px 10px 0 0; height: 28px"
                @click="editDiscount"
                v-if="editDiscountTiers"
              >
                <span>
                  <!-- 编辑阶梯折扣 -->
                  {{ editDiscountTiers }}
                </span>
              </el-button>
              <el-button-group
                style="margin-top: 16px; margin-right: 10px"
                v-if="isShowGanttButton"
              >
                <el-button
                  size="mini"
                  class="button"
                  @click="toGannt('isGantt')"
                  >{{ $t("label.projectManagement.gantt") }}</el-button
                >
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="hover"
                >
                  <div class="xiala" slot="reference">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-blue_sj"></use>
                    </svg>
                  </div>
                  <ul class="moreButton" style="width: 100px">
                    <li
                      style="cursor: pointer"
                      @click="toGannt('kanbanTaskPage')"
                    >
                      <!-- 任务看板 -->
                      {{ $t("label.projectManagement.kanban") }}
                    </li>
                    <li style="cursor: pointer" @click="toGannt('isGantt2')">
                      <!-- 资源使用视图 -->
                      {{ $t("label.projectManagement.Resource.usage.view") }}
                    </li>
                  </ul>
                </el-popover>
              </el-button-group>
              <el-button-group style="margin-right: 10px">
                <!-- <el-button
                  size="mini"
                  class="button"
                  v-if="isShowGanttButton"
                  @click="toGannt()"
                >
                  甘特图
                </el-button>-->
                <el-button
                  size="mini"
                  class="button"
                  @click="setFollow(follow)"
                >
                  <svg
                    class="icon"
                    style="width: 12px; margin-top: -1px; margin-right: 3px"
                    v-show="
                      this.follow === $t('label.chatter.follow') ||
                      this.follow === $t('label.chatter.followed')
                    "
                    aria-hidden="true"
                  >
                    <use
                      :href="
                        this.follow === $t('label.chatter.follow')
                          ? '#icon-gz'
                          : '#icon-yes'
                      "
                    ></use>
                  </svg>
                  <span @mouseover="mouseover" @mouseout="mouseout">
                    {{ follow }}
                  </span>
                </el-button>
                <!-- 群策 勿删 -->
                <el-button
                  size="mini"
                  class="button"
                  @click="judgeIsHas"
                  v-if="
                    isShowChat &&
                    (this.objId === 'account' || this.objId == 'opportunity') &&
                    //是否为伙伴云用户
                    !this.Partnercloud
                  "
                >
                  <svg
                    class="icon qcImg"
                    aria-hidden="true"
                    style="font-size: 14px; margin-top: -3px; margin-right: 3px"
                  >
                    <use :href="qcImg"></use>
                  </svg>
                  <span>
                    {{ $t("label_mobile_buttom_team") }}
                  </span>
                </el-button>
              </el-button-group>
              <!-- 自定义按钮区域 -->
              <el-button-group v-if="customButtonsShow.length > 0" style="margin-top: 16px; margin-right: 10px;">
                <el-button
                  class="button"
                  size="mini"
                  v-for="(item, index) in customButtonsShow"
                  :key="index"
                  :devid="item.id"
                  @click="recordOperationBtn(item)"
                  >{{ item.label }}</el-button
                >
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="click"
                  :visible-arrow="false"
                  v-if="customButtonsHide.length > 0"
                >
                  <div class="xiala" slot="reference">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-blue_sj"></use>
                    </svg>
                  </div>
                  <ul class="moreButton" :class="{ noClick: noClick }">
                    <li
                      class="li"
                      v-for="(item, index) in customButtonsHide"
                      :key="index"
                      :devid="item.id"
                      @click="recordOperationBtn(item, 'noclick')"
                      style="cursor: pointer"
                    >
                      <span
                        v-if="
                          item.name === 'manageExternalUsers' ? false : true
                        "
                        >{{ item.label }}</span
                      >
                      <span v-else>
                        <el-popover
                          placement="left-start"
                          width="150"
                          trigger="hover"
                          popper-class="external"
                        >
                          <ul class="moreButtons" style="font-size: 12px">
                            <li
                              v-for="(item, index) in partner"
                              :key="index"
                              :devid="item.id"
                              @click="external(item)"
                              style="cursor: pointer"
                            >
                              {{ item.label }}
                            </li>
                          </ul>
                          <span slot="reference" tyle="cursor:pointer">
                            {{ item.label }}
                          </span>
                        </el-popover>
                      </span>
                    </li>
                  </ul>
                </el-popover>
              </el-button-group>
              <el-button-group style="margin-top: 16px">
                <el-button
                  class="button"
                  size="mini"
                  v-for="(item, index) in showButton.filter((item)=>{return item.name != 'pdfView'})"
                  :key="index"
                  :devid="item.id"
                  @click="recordOperationBtn(item)"
                  >{{ item.label }}</el-button
                >
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="click"
                  :visible-arrow="false"
                  v-if="moreButton.length > 1 || (moreButton.length === 1 && moreButton[0].name != 'pdfView')"
                >
                  <div class="xiala" slot="reference">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-blue_sj"></use>
                    </svg>
                  </div>
                  <ul class="moreButton" :class="{ noClick: noClick }">
                    <li
                      class="li"
                      v-for="(item, index) in moreButton.filter((item)=>{return item.name != 'pdfView'})"
                      :key="index"
                      :devid="item.id"
                      @click="recordOperationBtn(item, 'noclick')"
                      style="cursor: pointer"
                    >
                      <span
                        v-if="
                          item.name === 'manageExternalUsers' ? false : true
                        "
                        >{{ item.label }}</span
                      >
                      <span v-else>
                        <el-popover
                          placement="left-start"
                          width="150"
                          trigger="hover"
                          popper-class="external"
                        >
                          <ul class="moreButtons" style="font-size: 12px">
                            <li
                              v-for="(item, index) in partner"
                              :key="index"
                              @click="external(item)"
                              style="cursor: pointer"
                            >
                              {{ item.label }}
                            </li>
                          </ul>
                          <span slot="reference" tyle="cursor:pointer">
                            {{ item.label }}
                          </span>
                        </el-popover>
                      </span>
                    </li>
                  </ul>
                </el-popover>
              </el-button-group>
              <!--审批-->
              <el-button-group style="margin-top: 16px">
                <el-button
                  class="button"
                  style="margin-left: 10px"
                  size="mini"
                  :loading="submitStatus"
                  v-for="(item, index) in showHistoryBtn"
                  :key="index"
                  :devid="item.id"
                  @click="historyDeal(item)"
                  >{{ item.label }}</el-button
                >
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="hover"
                  v-if="hideHistoryBtn.length > 0"
                >
                  <div class="xiala" slot="reference">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-blue_sj"></use>
                    </svg>
                  </div>
                  <ul class="moreButton" style="width: 100px">
                    <li
                      v-for="(item, index) in hideHistoryBtn"
                      :key="index"
                      :devid="item.id"
                      @click="historyDeal(item)"
                      style="cursor: pointer"
                    >
                      {{ item.label }}
                    </li>
                  </ul>
                </el-popover>
              </el-button-group>

              <div class="dayin">
                <svg class="icon" aria-hidden="true" @click="printDetail">
                  <use href="#icon-dayin_d"></use>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div style="clear: both"></div>
            <!-- 标签 -->
            <div class="label">
              <div class="tag" v-if="isTag === 'true' ? false : true">
                <el-tag
                  v-for="item in labelArr"
                  :key="item.id"
                  :type="item.type"
                  effect="plain"
                  style="cursor: pointer"
                  :style="{
                    background:
                      item.color === 'hong'
                        ? hong
                        : item.color === 'huang'
                        ? huang
                        : item.color === 'lan'
                        ? lan
                        : item.color === 'hui'
                        ? hui
                        : item.color === 'lv'
                        ? lv
                        : '',
                    color: color,
                  }"
                  v-html="item.name"
                >
                </el-tag>
              </div>
              <div
                v-if="isTag === 'true' ? true : false"
                style="width: 100%"
                class="detail-header-tag-box"
              >
                <svg class="icon detail-header-tag-svg" aria-hidden="true">
                  <use href="#icon-tag"></use>
                </svg>
                <div class="tag" style="margin-right: 5px">
                  <el-tag
                    v-for="item in labelArr"
                    :key="item.id"
                    :type="item.type"
                    effect="plain"
                    style="cursor: pointer"
                    @click="showAllTags = true"
                    :style="{
                      background:
                        item.color === 'hong'
                          ? hong
                          : item.color === 'huang'
                          ? huang
                          : item.color === 'lan'
                          ? lan
                          : item.color === 'hui'
                          ? hui
                          : item.color === 'lv'
                          ? lv
                          : '',
                      color: color,
                    }"
                    v-text="item.name"
                  >
                  </el-tag>
                </div>
                <el-link class="link" @click="customBtn">
                  <!-- 添加标签 -->
                  {{
                    labelArr.length > 0
                      ? $t("label.tag.edittags")
                      : $t("label.tag.addtags")
                  }}
                </el-link>
              </div>

              <add-label
                ref="addlabel"
                :ids="id"
                :detail="detail"
                :labelArr="labelArr"
                @updateDelLabelArr="getDelLabelArr"
              ></add-label>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- </el-card> -->
      <div
        :class="routerName === 'DETAIL' ? 'clears' : ''"
        v-if="isShowType"
      ></div>
      <el-card class="box-card" v-if="isShowType">
        <Wang-skeleton
          v-if="isSkeleton"
          type="custom"
          :childrenOption="childrenOptions"
          style="background: #fff"
        />
        <!-- 简介 -->
        <div class="introduce" v-else>
          <div
            class="introduce_content"
            v-for="(item, index) in heightLight"
            :key="index"
          >
            <div v-if="item.isShowMenus === true">
              <span class="introduce_title">
                {{ item.fieldLabel }}
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="content"
                  placement="top"
                  v-if="isCustomer"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="
                      item.fieldName === 'parent' || item.fieldName === 'zsss'
                        ? 'true'
                        : false
                    "
                    @contextmenu.prevent.stop="routers"
                    @click.prevent.stop="routers"
                  >
                    <use href="#icon-cengji"></use>
                  </svg>
                  <!-- <img
                    :src="chengjiImg"
                    alt
                    v-if="
                      item.fieldName === 'parent' || item.fieldName === 'zsss'
                        ? 'true'
                        : false
                    "
                    @contextmenu.prevent.stop="routers"
                    @click.prevent.stop="routers"
                  /> -->
                </el-tooltip>
              </span>
              <br />
              <!-- 不是005开头的就是队列 ,队列不跳转-->
              <span
                :class="[
                  'introduce_cet',
                  item.fieldType === 'Y' ||
                  item.fieldType === 'M' ||
                  item.fieldType === 'MR'
                    ? 'briefDetail_s'
                    : '',
                ]"
                @click="
                  item.fieldType === 'Y' ||
                  item.fieldType === 'M' ||
                  item.fieldType === 'MR'
                    ? briefDetail(item)
                    : ''
                "
              >
                <em
                  style="font-style: normal"
                  v-if="item.fieldType === 'F' && item.fieldValue"
                >
                  {{ item.fieldValue | datetimeFormat(countryCode) }}
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldType === 'D' && item.fieldValue"
                  >{{ item.fieldValue | dateFormat(countryCode) }}</em
                >
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldType === 'B'"
                >
                  <el-checkbox
                    :value="item.fieldValue === 'true' ? true : false"
                    disabled
                  >
                  </el-checkbox>
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldName === 'facebook'"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="item.fieldValue.indexOf('facebook.com') != -1"
                    style="font-size: 18px; height: 18px; cursor: pointer"
                    @click="jumpNew(item.fieldValue)"
                    @contextmenu.prevent="jumpNew(item.fieldValue)"
                  >
                    <use :href="facebookImgBlue"></use>
                  </svg>
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-else
                    style="font-size: 18px; height: 18px; cursor: pointer"
                  >
                    <use :href="facebookImg"></use>
                  </svg>
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldName === 'linkedin'"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="item.fieldValue.indexOf('linkedin.com') != -1"
                    style="font-size: 18px; height: 18px; cursor: pointer"
                    @click="jumpNew(item.fieldValue)"
                    @contextmenu.prevent="jumpNew(item.fieldValue)"
                  >
                    <use :href="linkedinImgBlue"></use>
                  </svg>
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-else
                    style="font-size: 18px; height: 18px; cursor: pointer"
                  >
                    <use :href="linkedinImg"></use>
                  </svg>
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldName === 'twitter'"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="item.fieldValue.indexOf('twitter.com') != -1"
                    style="font-size: 18px; height: 18px; cursor: pointer"
                    @click="jumpNew(item.fieldValue)"
                    @contextmenu.prevent="jumpNew(item.fieldValue)"
                  >
                    <use :href="twitterImgBlue"></use>
                  </svg>
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-else
                    style="font-size: 18px; height: 18px; cursor: pointer"
                  >
                    <use :href="twitterImg"></use>
                  </svg>
                </em>
                <!-- 潜在客户打分 -->
                <em
                  v-else-if="item.fieldName === 'leadscore'"
                  style="display: flex; padding-right: 7px"
                  class="cannotEdit"
                >
                  <span
                    style="
                      width: 25px;
                      font-style: normal;
                      display: inline-block;
                    "
                    >{{ item.fieldValue }}</span
                  >
                  <el-progress
                    :percentage="
                      item.fieldValue !== undefined && item.fieldValue !== null
                        ? Number(item.fieldValue)
                        : 0
                    "
                    :color="customColors"
                    :format="formatProgress"
                  >
                  </el-progress>
                </em>
                <!-- url -->
                <em
                  v-else-if="item.fieldType === 'U'"
                  @click="jumpTo(item.fieldValue)"
                  style="font-style: normal; color: #006dcc; cursor: pointer"
                >
                  {{ item.fieldValue }}
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="
                    (item.fieldType == 'Z' && item.expressionType == 'S') ||
                    item.fieldType == 'S' ||
                    item.fieldType == 'X' ||
                    item.fieldType == 'A' ||
                    item.fieldType == 'J'
                  "
                  v-html="item.fieldValue"
                >
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldType === 'H'"
                >
                  <svg
                    class="icon"
                    v-if="replyPhoneShow && item.value"
                    aria-hidden="true"
                    @click="callPhone(item.value)"
                    style="cursor: pointer; margin-left: 5px"
                  >
                    <use href="#icon-Call"></use>
                  </svg>
                  {{ item.fieldValue }}
                </em>
                <em style="font-style: normal" v-else>
                  {{ item.fieldValue }}
                </em>
              </span>
            </div>
          </div>
        </div>
      </el-card>
      <!-- ROI卡片 -->
      <roiCard v-show="objectApi === 'campaign'" ref="roiCard"></roiCard>

      <Contract-Label v-if="objectApi === 'contract'" :id="id"></Contract-Label>

      <!-- 进度条 -->
      <Progress
        v-if="isShowType"
        ref="Progress"
        :isconvert="isconvert"
        :viewId="viewId"
        :data-id="id"
        :isEdit="isEdit"
        :inlineedit="inlineedit"
        :canEditOwner="isCanEditOwner"
        :bigTitle="bigTitle"
        :prefixs="prefix"
        :objId="objId"
        :objectApi="objectApi"
      ></Progress>

      <div style="float: left; position: relative; width: 100%">
        <!-- 布局切换按钮 -->
        <!-- 邮件互动页面特殊处理 -->
        <div
          class="layoutSwitchBox"
          @click="switchLayout"
          :style="this.objId === 'cloudccMailActivity' ? 'display: none' : ''"
        >
          <svg aria-hidden="true" slot="reference">
            <use href="#icon-layoutSwitchImg2"></use>
          </svg>
        </div>
        <!-- 相关列表菜单及详细信息 -->

        <list-child
          ref="listChild"
          id="listChild"
          :class="detailLayoutMode === 'mode1' ? 'left' : 'right'"
          :detailGroupId="detailGroupId"
          :isShowType="isShowType"
          :tabName="tabName"
          :dataId="id"
          :fieldValue="fieldValue"
          :bigTitle="bigTitle[0] ? bigTitle[0].fieldValue : ''"
          :isTag="isTag"
          :detailLayoutMode="detailLayoutMode"
          :objectApi="objectApi"
          :prefix="prefix"
          :isEditDetail="isEdit"
          :isQueryDetail="isQuery"
          :locked="isLocked"
          :canEditOwner="isCanEditOwner"
          @refreshAll="refreshAll"
          @refreshUpload="refreshUpload"
          @topHistoryBtn="getHistoryBtn"
          @topHistorySubmit="getHistorySubmit"
          @setScroll="getScroll"
          @resetObjectInfo="resetObjectInfo"
          @convert="convert"
          @getPricebook="getPricebook"
          @customBtn="customBtn"
          @dataListes="dataListes"
          @personsNameMethod="personsNameMethod"
          :typedisabled="typedisabled"
          @getstate="getstate"
        ></list-child>
        <!-- 邮件互动页面特殊处理 -->
        <div
          :style="this.objId === 'cloudccMailActivity' ? 'display: none' : ''"
          id="activityAndDynamicPublics"
          :class="detailLayoutMode === 'mode1' ? 'right' : 'left'"
        >
          <el-card
            v-show="objectApi === 'CloudccKArticle'"
            class="box-card_left"
            :style="{ height: height, 'margin-bottom': '10px' }"
          >
            <!--知识库展示查看翻译-->
            <div style="min-height: 100px">
              <!-- 查看翻译 -->
              <h3 style="margin-left: 10px; margin-top: 20px">
                {{ $t("label.knowledgebase.view.translation") }}
              </h3>
              <!-- 请选择 -->
              <el-select
                v-model="langevalue"
                v-if="langeoptionslength > 0"
                :placeholder="$t('label.emailtocloudcc.select1')"
                style="width: 90%; margin-left: 10px"
                @change="langechange(langevalue)"
              >
                <el-option-group
                  v-for="group in langeoptions"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
              <!-- 没有已发布或草稿翻译版本 -->
              <el-select
                v-else
                v-model="blankValue"
                disabled
                :placeholder="
                  $t('label.knowledgebase.nopublished.draft.translations')
                "
                style="width: 90%; margin-left: 10px"
              >
              </el-select>
            </div>
          </el-card>
          <el-card
            v-if="
              isOpenService === 'true' &&
              (objectApi === 'WorkOrder' || objectApi === 'cloudcccase')
            "
            class="box-card_left"
            :style="{ height: height, 'margin-bottom': '10px' }"
          >
            <!--            工作订单下展示重大事件-->
            <div style="min-height: 100px">
              <important-event
                ref="importantEvent"
                :workOrderId="id"
              ></important-event>
            </div>
          </el-card>
          <el-card class="box-card_left" :style="{ height: height }">
            <span
              class="guanbi"
              v-show="
                objectApi !== 'ServiceTerritory' &&
                objectApi !== 'ServiceTerritoryMember'
              "
            >
              <!-- @click="guanbis" -->
              <i
                :class="icons"
                style="-webkit-transform: rotate(90deg); visibility: hidden"
              ></i>
            </span>
            <!--            服务区域及服务区成员对象 显示操作时间组件、隐藏活动相关-->
            <!--            服务区域--操作时间组件 -->
            <div
              style="min-height: 100px"
              v-show="
                objectApi === 'ServiceTerritory' ||
                objectApi === 'ServiceTerritoryMember'
              "
            >
              <operating-time
                :operationTime="operatingTimeObj"
              ></operating-time>
            </div>
            <!-- 活动 -->
            <el-tabs
              v-show="
                objectApi !== 'ServiceTerritory' &&
                objectApi !== 'ServiceTerritoryMember'
              "
              class="optiones"
              v-model="activeName"
              :class="{ newDongTai: dynamicType }"
              style="
                padding: 10px;
                box-sizing: border-box;
                position: relative;
                top: -29px;
              "
            >
              <!-- 活动  v-if=" isShowType === false ? false : (objectApi === 'Task' || objectApi === 'Event')? false : true " -->
              <el-tab-pane
                :label="$t('label.movable')"
                v-if="
                  objectApi === 'Task' || objectApi === 'Event' ? false : true
                "
                name="first"
              >
                <activity
                  v-if="'first' === activeName"
                  ref="activitys"
                  :key="id"
                  :objectApi="objectApi"
                  :tabName="tabName"
                  :bigTitle="bigTitle && bigTitle[0] ? bigTitle : [{}]"
                  :dataId="id"
                  :prefix="prefix"
                  :objId="objId"
                  :theme="theme"
                  :activeList="activeList"
                  @refreshUpload="refreshUpload"
                ></activity>
              </el-tab-pane>
              <!-- 动态   v-if="isShowType"  -->
              <el-tab-pane :label="$t('label.ccchat.2018')" name="second">
                <!-- fieldValue个人详情页到某人 -->
                <dynamicPublic
                  v-if="'second' === activeName"
                  v-show="isShowdynamicPublics == 'true'"
                  ref="dynamicPublics"
                  :isDetailType="''"
                  :recordName="bigTitle"
                  :postShowType="postShowType"
                  :tabheight="height"
                  :activeName="activeName"
                  :fieldValue="userName"
                  :isShowType="isShowType"
                  :dataId="id"
                ></dynamicPublic>
                <!-- 没有开启动态提示信息 -->
                <div
                  class="isEnableBox"
                  v-show="isShowdynamicPublics == 'false'"
                >
                  <svg
                    class="icon"
                    style="width: 200px; margin-bottom: 20px; height: 110px"
                    aria-hidden="true"
                  >
                    <use :href="'#icon-PostsNoEnable'"></use>
                  </svg>
                  <div>
                    {{ $t("setup.chatter.enabled") }}
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 共享弹窗 -->
    <Share ref="isShare" :shares="shares"></Share>

    <!-- pdf预览 -->
    <pdf-viewer
      :is-show="pdfViewerAttr.isShow"
      :top="3"
      :title="pdfViewerAttr.title"
      :pdfUrl="pdfViewerAttr.pdfUrl"
      :fullScreen="false"
      @cancel="closeViewerDialog"
      @saveToAppendix="saveToAppendix"
    >
      <!-- <div slot="others" style="text-align: right">
        <el-button size="mini" @click="closeViewerDialog"> 取消 </el-button>
        <el-button size="mini" type="primary" @click="saveToAppendix">
          保存到文件
        </el-button>
      </div>-->
    </pdf-viewer>
    <!-- 提交待审批弹窗 -->
    <nextApproval ref="nextApprovalref"></nextApproval>

    <!-- 共享弹窗 -->
    <synchro ref="synchro"></synchro>
    <!-- 详情页写死 type = active
    修改： 王钰祥 -->
    <!-- 详情页复制/编辑记录 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="prefix"
      :id="id"
      :object-api="objectApi"
      :object-name="tabName"
      :dataListPartner="dataList"
      :relatedlistIds="relatedlistIds"
      @Refreshes="Refreshes"
      @save="save"
      type="active"
    ></create-edit-obj>
    <!-- 企业微信联系人转联系人或潜在客户专用 -->
    <create-edit-obj
      ref="ewcreateEditObj"
      :prefix="ewprefix"
      :objectApi="ewobjectApi"
      :object-name="ewtabName"
      @Refreshes="Refreshes"
      @save="save"
      type="active"
      :ewId="ewId"
      :ewData="ewData"
    ></create-edit-obj>
    <!-- 客服云-访客/会话/语音专用 -->
    <create-edit-obj
      ref="ceateEditObjForCustomer"
      :prefix="prefixCustomer"
      :objectApi="objectApiCustomer"
      :object-name="objectNameCustomer"
      @Refreshes="Refreshes"
      @save="save"
      type="active"
    ></create-edit-obj>
    <!-- 发票详情页新建回款按钮对应的特殊新建页面 -->
    <create-edit-obj
      ref="invoiceCeateEditObj"
      :object-name="objectNameReceive"
      :prefix="invoicePrefix"
      :objectApi="invoiceApi"
      :isReceivable="true"
      :customId="customId"
      :invoiceInfo="invoiceInfo"
      @save="save"
    ></create-edit-obj>
    <!-- 企业微信联系人转联系人或潜在客户专用 -->
    <el-dialog
      :title="
        objId === 'account'
          ? $t('vue_label_commonobjects_detail_group_strategy')
          : $t('vue_label_commonobjects_detail_group_opportunitystrategy')
      "
      :visible.sync="isShowTag"
      width="40%"
      :append-to-body="true"
      custom-class="qunce-dialog"
    >
      <div>
        <!-- 加入后，该群策将显示在您的群策列表，群策动态会实时推送给您。 -->
        {{ $t("vue_label_commonobjects_detail_real_time") }}
      </div>
      <el-button @click="cancelDia" class="cancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="joinGroup">
        <!-- 加入 -->
        {{ $t("chatter.group.join") }}
      </el-button>
    </el-dialog>

    <!-- 删除数据和解除锁定 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogAttr.title"
      top="15%"
      width="27%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 16px; font-weight: bold">{{
        dialogAttr.content
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button @click="confirm" type="primary" size="mini">
          <!-- 确认 -->
          {{ $t("label_chatter_ok") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 订单详情页生成发票弹窗 -->
    <create-edit-obj
      ref="orderCeateEditObj"
      :object-name="orderNameRecive"
      :prefix="orderPrefix"
      :objectApi="orderApi"
      :isOrderPage="true"
      :customId="customId"
      :orderInfo="orderInfo"
      :opportunityInfo="opportunityInfo"
      @save="save"
    ></create-edit-obj>
    <!-- 伙伴云 -->
    <el-dialog
      custom-class="partnercloud"
      :title="$t('label_partner')"
      :visible.sync="isPartnerCloud"
      width="69.3%"
      :before-close="handleCloses"
    >
      <el-table :data="tableData" border style="width: 100%">
        <!-- 名称 -->
        <el-table-column
          prop="name"
          :label="$t('label_chatter_name')"
          width="180"
        ></el-table-column>
        <!-- 描述 -->
        <el-table-column
          prop="description"
          :label="$t('label.file.des')"
          width="180"
        ></el-table-column>
        <el-table-column label="URL">
          <template slot-scope="scope">
            <el-link type="primary" :href="scope.row.url" target="_blank">
              {{ scope.row.url }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isPartnerCloud = false">
          <!-- 取 消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 置顶按钮 -->
    <svg class="icon backtop" aria-hidden="true" @click="backtop">
      <use href="#icon-detail-top1"></use>
    </svg>
    <div style="height: 0; overflow: hidden">
      <ApprovalHistory
        v-show="true"
        ref="uponDetailHistory"
        :historyID="id"
        :itemTitle="fieldValue"
        @closeLoading="closeLoading"
      ></ApprovalHistory>
    </div>
    <!-- 回退公海池弹框-->
    <seas-pool
      :seaShow.sync="seaShow"
      :objid="valSea"
      @clientPoolCancle="clientPoolCancle"
      :objectApi="objectApi"
    ></seas-pool>
    <!-- 公告发布范围弹框 -->
    <ggReleaseRange
      :ggRecordid="id"
      :isShowggRecord="isShowggRecord"
      @closeGgRecord="closeGgRecord"
    ></ggReleaseRange>

    <!-- 迁移资产 -->
    <AssetsMoveDialogVisible
      ref="assetsBOM"
      :prefix="prefix"
      :id="id"
      :object-api="objectApi"
      :object-name="tabName"
      :dataListPartner="dataList"
      @updateDetail="refreshAll"
    />
    <!-- 删除文章、删除草稿、归档、还原、作为草稿编辑 -->
    <Dialog
      :dialogSign="dialogSign"
      :btnName="btnName"
      :titleType="titleType"
      :jumpsign="jumpsign"
      @dialogEdit="dialogEdit"
      @dialoghandleClose="dialoghandleClose"
    >
      <template v-slot:Dialog>
        <div>
          <div v-if="btnSign == 'draftdel'">
            <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
            <div class="dialog-type">
              {{ $t("label.knowledgebase.unable.restore.draft") }}
            </div>
            <div>
              {{
                $t(
                  "label.knowledgebase.delete.translation.associated.with.draft"
                )
              }}
            </div>
          </div>
          <div v-else-if="btnSign == 'articledel'" class="dialog-type">
            <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
            <div>
              {{ $t("label.knowledgebase.permanently.remove.articles") }}
            </div>
          </div>
          <div v-else-if="btnSign == 'file'" class="dialog-type">
            <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
            <div>{{ $t("label.knowledgebase.published.translations") }}</div>
          </div>
          <div v-else-if="btnSign == 'recovery'" class="dialog-type">
            <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
            <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
          </div>
          <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
            <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
            <div>{{ $t("label.knowledgebase.published.version.online") }}</div>
          </div>
        </div>
      </template>
    </Dialog>
    <!-- 知识库发布 -->
    <Release
      :id="id"
      :transfer-owner-dialog="releasedialogVisible"
      @releasehandleClose="releasehandleClose"
      :markNewVersion="markNewVersion"
      :affectTranslation="affectTranslation"
      :jumpsign="jumpsign"
      @refreshall="refreshall"
    ></Release>
    <!-- 销售云 报价表 -->
    <CreatedQuotationDialog
      ref="createdQuotationDialog"
      :id="id"
      :objId="objId"
      :type="printType"
      :title="printTitle"
      :templateList="templateList"
      :quotationDialogVisible="quotationDialogVisible"
      @closeQuotaionDialog="closeQuotaionDialog"
    ></CreatedQuotationDialog>
    <!-- 销售云 选择报价单模板   -->
    <SelectTemplate
      ref="selectTemplate"
      :id="id"
      :objId="objId"
      :templateList="templateList"
      :selectTemplateDialogVisible="selectTemplateDialogVisible"
      @closeSelectTemplateDialog="closeSelectTemplateDialog"
    ></SelectTemplate>
    <!-- 销售云 选择报价单文件 -->
    <selectquotation
      :id="id"
      :title="this.eventTap || this.tabName"
      ref="selectquotation"
      :dialogTableVisible="dialogTableVisible"
      @closedselectquotation="closedselectquotation"
      @opencreatedquo="opencreatedquo"
    >
    </selectquotation>
    <!-- 发送邮件页面 -->
    <SendEmailDetail
      :enclosurearr="enclosurearr"
      :dialogVisibleEmail="dialogVisibleEmail"
      @closeEmailDialog="closeEmailDialog"
      :emailIsOperation="emailIsOperation"
      :templateId="templateId"
      :recordId="id"
      :objId="objId"
      :invoiceReceiver="invoiceReceiver"
    ></SendEmailDetail>
    <!-- 线上支付 -->
    <InvoiceTemplate
      ref="invoiceTemplate"
      :id="id"
      :objId="objId"
      :title="this.eventTap || this.tabName"
      :templateList="templateList"
      :selectTemplateDialogVisible="invoiceTemplateDialogVisible"
      @closeSelectTemplateDialog="closeinvoiceTemplateDialog"
    ></InvoiceTemplate>
    <!--  -->
    <!--    条形码，二维码弹窗-->
    <code-dialog ref="codeStyleDialog"> </code-dialog>
    <div class="knowledge">
      <el-dialog
        title="编辑文章类别"
        :visible.sync="centerDialogVisible"
        width="50%"
        center
        :before-close="checkcancel"
      >
        <div class="type">
          <div class="typetree">
            <el-input placeholder="输入关键字进行过滤" v-model="filterText">
            </el-input>
            <el-tree
              style="border: 1px solid #e5e5e5; min-height: 280px"
              class="filter-tree"
              :data="fromData"
              :props="defaultProps"
              default-expand-all
              :show-checkbox="true"
              :indent="10"
              :check-strictly="true"
              node-key="id"
              :filter-node-method="filterNode"
              @check="handleCheckChange"
              ref="tree"
            >
            </el-tree>
          </div>
          <!-- <div style="width:45%">
        <div>已选类别</div>
        <div style="height:320px;border:1px solid #e5e5e5;width:100%">
          <el-tree :data="checkoutlist" :props="defaultProps" :indent="10" :default-expand-all="true"></el-tree>
        </div>
      </div>   -->
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取 消 -->
          <el-button @click="checkcancel">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <!-- 确 定 -->
          <el-button type="primary" @click="checksure">{{
            $t("component.button.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="QBslowCC" v-if="QBtfCC">
      <el-button
        :autofocus="true"
        :loading="true"
        style="background: none; border: none; color: #e6a23c"
        >{{ QBmessCC }}</el-button
      >
    </div>
    <!-- 发票对象下是否确认收获弹窗 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      :visible.sync="invoicesDialogVisible"
      width="35%"
    >
      <!-- {{$t('c65')}} -->
      <span style="font-size: 16px; font-weight: bold">{{ $t("c65") }}</span>
      <span slot="footer">
        <div>
          <!-- 取 消 -->
          <el-button @click="invoicesDialogVisible = false">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <!-- 确 定 -->
          <el-button type="primary" @click="updateStatus">{{
            $t("component.button.ok")
          }}</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 业务机会对象下  新建报价单 订单  发票弹窗 -->
    <create-edit-obj
      ref="opportunityCeateEditObj"
      :object-name="opportunityNameRecive"
      :prefix="opportunityPrefix"
      :objectApi="opportunityApi"
      :orignObj="orignObj"
      :isSpecialCreate="true"
      :opportunityInfo="opportunityInfo"
      :opportunitySecInfo="opportunitySecInfo"
    ></create-edit-obj>
    <!-- 展示所有标签的弹框 -->
    <el-dialog
      title="查看标签"
      :visible.sync="showAllTags"
      top="15%"
      width="373px"
      :before-close="handleCloseAllTags"
    >
      <div class="allTagDialog">
        <el-tag
          v-for="item in labelArr"
          :key="item.id"
          :type="item.type"
          effect="plain"
          style="cursor: pointer"
          :style="{
            background:
              item.color === 'hong'
                ? hong
                : item.color === 'huang'
                ? huang
                : item.color === 'lan'
                ? lan
                : item.color === 'hui'
                ? hui
                : item.color === 'lv'
                ? lv
                : '',
            color: color,
          }"
          >{{ item.name }}</el-tag
        >
      </div>
    </el-dialog>
    <!--报价单详情页新建发票按钮提示弹窗 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      width="40%"
      top="5%"
      :visible.sync="showQuoteIntro"
      append-to-body
      :close-on-click-modal="false"
      @close="cancelChange"
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        {{ $t("c835") }}
      </div>
      <span slot="footer">
        <el-button @click="cancelChange" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmChange" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 拒单原因 -->
    <el-dialog
      :title="$t('c2324')"
      width="600px"
      top="5%"
      style="padding:20px 30px"
      :visible.sync="utOrderRejectFlag"
      append-to-body
      :close-on-click-modal="false"
      @close="cancelOrderRejectFlag"
    >
      <div class="rejection-box">
        <!-- 拒单原因 -->
        <el-select
          popper-class="reason-rejection"
          v-model="reasonRejectionchoose" placeholder="请选择">
          <el-option
            v-for="item in reasonRejectionData"
            :key="item.codevalue"
            :label="item.codevaluelang"
            :value="item.codevalue">
          </el-option>
        </el-select>
        <el-input
          popper-class="reason-rejectioninput"
          type="textarea"
          :placeholder="$t('c2325')"
          v-model="reasonRejectionValue"
          maxlength="500"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer">
        <el-button @click="cancelOrderRejectFlag" size="mini">
          {{ $t("label.cancel") }}
        </el-button>
        <el-button  type="primary" @click="utOrderReject"  :loading="utOrderRejectLoading" size="mini">
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as detailApi from "./api.js";
import { consoleSaveTab } from "@/views/home/api.js";
import SendEmailDetail from "@/views/quotationEmail/components/sendEmailDetail.vue";
import SelectTemplate from "@/views/quotationEmail/components/selectTemplate.vue";
import InvoiceTemplate from "@/views/invoice/selectTemplate.vue";
import listChild from "./detailChild/list.vue";
import addLabel from "@/components/LabelView/addTag.vue";
import dynamicPublic from "../colleagueCircle/components/dynamic-public.vue";
import activity from "./activity.vue";
import ApprovalHistory from "@/views/approval/components/approval-history.vue";
import roiCard from "./detailChild/ROIcard.vue";
import seasPool from "./detailChild/seasPool.vue";
import {
  Share,
  Progress,
  PdfViewer,
  Release,
  Dialog,
} from "@/components/index.js";
import job from "@/views/chat/js/request.js";
import synchro from "../../components/synchro/index";
import ImportantEvent from "@/views/commonObjects/detailChild/ImportantEvent/ImportantEvent.vue";
import createEditObj from "./components/create-edit-obj.vue";
import operatingTime from "./components/OperatingTime.vue";
import detailsPersonal from "@/views/colleagueCircle/components/details/DetailsPersonal.vue";
import VueCookies from "vue-cookies";
import debounce from "lodash.debounce";
import * as Time from "@/utils/time";
import ggReleaseRange from "./components/ggReleaseRange.vue";
import AssetsMoveDialogVisible from "./components/Assets-move-dialog.vue"; //迁移资产
import CodeDialog from "./components/CodeDialog.vue"; //资产二维码
import CreatedQuotationDialog from "@/views/quotationEmail/components/createdQuotationDialog.vue";
import selectquotation from "@/views/quotationEmail/components/selectquotation.vue";
import ContractLabel from "@/views/contract/contractLabel.vue";
import DialogMixin from "@/mixin/Dialog.js";
import nextApproval from "@/views/approval/components/approval-dialog";
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
export default {
  mixins: [DialogMixin],
  name: "CommonDetail",
  components: {
    InvoiceTemplate,
    ContractLabel,
    SelectTemplate,
    CreatedQuotationDialog,
    selectquotation,
    listChild,
    activity,
    addLabel,
    Share,
    dynamicPublic,
    Progress,
    roiCard,
    PdfViewer,
    synchro,
    createEditObj,
    ApprovalHistory,
    detailsPersonal,
    operatingTime,
    ImportantEvent,
    seasPool, //回退公海池弹框
    ggReleaseRange, //公告发布范围弹框
    AssetsMoveDialogVisible, //迁移资产
    CodeDialog, //资产二维码
    Release, //发布
    SendEmailDetail, //发送邮件
    Dialog, //删除文章、删除草稿、归档、还原、作为草稿编辑
    nextApproval, //下个审批人弹窗
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      replyPhoneShow: false,
      // 当前是否有上级路由
      historyLength: 1,
      seeViewType: false, //查看PDF视图按钮
      // 禁止点击当前dom
      noClick: false,
      orignObj: "",
      showQuoteIntro: false, //报价单详情页新建发票按钮提示弹窗是否展示
      opportunityInfo: {},
      opportunitySecInfo: [],
      opportunityNameRecive: "",
      opportunityPrefix: "",
      opportunityApi: "",
      relatedlistIds: "",
      invoicesDialogVisible: false,
      orderInfo: {},
      //提交待审批loading
      submitStatus: false,
      //发票收件人邮箱
      invoiceReceiver: "",
      //发票客户名称
      accountReceiver: "",
      //发票币种
      invoiceCurrency: "",
      //发票id
      templateId: "",
      customId: "",
      invoiceInfo: {},
      invoicePrefix: "072",
      orderPrefix: "021",
      invoiceApi: "cloudccproceed",
      orderApi: "Invoices",
      //预览页面title
      printTitle: "报价单预览",
      //模板预览pdf类型 quotation：报价单 invoice：发票
      printType: "quotation",
      closeEmailDialogFlg: 0,
      templateList: [], //模板列表
      selectTemplateDialogVisible: false, //选择模板
      invoiceTemplateDialogVisible: false, //发票选择模板
      layoutid: "", //布局id
      isShowdynamicPublics: "", // 是否启用微贴
      showPayButton: false, //是否展示付款按钮
      isOpenAssetBom: false, // 资产BOM权限判断是否开启
      isShowggRecord: false, //公告菜单下  标准按钮更改成 发布范围按钮 wzj  勿删勿删勿删
      isOpenService: sessionStorage.getItem("isOpenService"),
      valSea: "",
      seaShow: false, //回退公海池
      theme: "", // 主题value
      detailLayoutMode: "mode1",
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      orgId: this.$store.state.userInfoObj.orgId,
      //群策按钮是否显示
      isShowChat:
        VueCookies.get("countryCode") == "CN" &&
        localStorage.getItem("chatOpen") == "true" &&
        localStorage.getItem("login") == "success",
      dialogAttr: {
        title: "",
        content: "",
      },
      inlineedit: false,
      dynamicType: false, //更改右侧动态tab
      isShowType: true, //判断用户详情页面
      qcImg: "#icon-qc",
      qunceType: "",
      //加入群策需要的数据
      qunceData: {},
      //显示加入群策弹框
      isShowTag: false,
      //当前群策的群ID
      detailGroupId: "",
      //客户群策所有人
      groupStrategyOwnerid: {}, //获取用户的环信ID
      information: {},
      detail: "detail",
      content: this.$i18n.t("vue_label_commonobjects_detail_company_hierarchy"), //公司层级结构
      isCustomer: false,
      isShowAssets: false, //查看资产BOM
      customFlag: false,
      icons: "el-icon-d-arrow-right",
      isHeight: false,
      height: "100%",
      active: 0,
      activeName: "first",
      isTrue: "active",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      labelName: [],
      labelArr: [],
      buttonList: [],
      heightLight: [],
      // showBtn: false,
      moreButton: [],
      showButton: [],
      // 自定义按钮集合
      customButtons: [],
      // 展示自定义按钮
      customButtonsShow: [],
      // 更多自定义按钮
      customButtonsHide: [],
      color: "#fff",
      hong: "#F6817B",
      huang: "#FEC366",
      lan: "#62A5F3",
      hui: "#C8C6C6",
      lv: "#7cedcc",
      id: this.$route.params.id || this.dataId || this.$router.query.dataId,
      objectApi: "",
      ewobjectApi: "", // 企业微信转联系人或潜在客户专用
      bigTitle: [],
      fieldValue: "",
      tabName: "",
      ewtabName: "", // 企业微信转联系人或潜在客户专用
      ewId: {}, // 企业微信转联系人或潜在客户专用
      ewData: {}, // 企业微信转联系人或潜在客户专用
      ewprefix: "", // 企业微信转联系人或潜在客户专用
      shares: [],
      follow: "",
      pdfViewerAttr: {
        title: this.$i18n.t("pagecreator_button_preview"), //"预览",
        isShow: false,
        pdfUrl: null,
      },
      objId: this.$route.query.objId || this.objid,
      prefix: "",
      // 右上角审批按钮
      showHistoryBtn: [],
      hideHistoryBtn: [],
      printVisible: false, //详情打印弹窗
      isconvert: "",
      viewId: "",
      objArr: [],
      pricebookArr: [],
      isQuery: null, // 记录可查权限
      isCanEditOwner: "", //判断是否有更改所以人权限
      isTag: "",
      routerName: "",
      activeList: [],
      isLocked: false, //是否锁定
      isEdit: false, // 编辑权限
      postShowType: "detailInfo", //查询帖子的类型
      recordName: "",
      copyType: "",
      userName: "",
      // 是否是删除审批中的记录二次删除
      isConfirmDel: "",
      dialogVisible: false, //删除弹窗给
      childrenOptions: [
        // 列表
        {
          type: "list",
          options: {
            row: 2,
            column: 3,
            width: "80px",
            height: "16px",
            backgroundColor: "#f3f2f2",
            justifyContent: "center",
            active: false,
            itemRowMargin: "8px 8px 8px 26px",
            firstWidth: "50px",
            lastWidth: "80px",
            alignItems: "center",
          },
        },
      ],
      isSkeleton: false,
      styleOption: [
        // 列表
        {
          type: "list",
          options: {
            row: 2,
            column: 2,
            width: "44.9vw",
            height: "20px",
            backgroundColor: "#f3f2f2",
            justifyContent: "center",
            active: false,
            itemRowMargin: "10px 28px",
            firstWidth: "44.9vw",
            lastWidth: "44.9vw",
            alignItems: "center",
          },
        },
      ],
      isStyleType: false,
      partner: [],
      ismanagePartnerUser: null,
      ispartner: null,
      dataList: [],
      isPartnerCloud: false,
      tableData: [],
      userId: "",
      tabStyle: "",
      isShowGanttButton: false, //控制显示甘特图按钮
      screenWidth: document.body.clientWidth,
      timer: false,
      myid: localStorage.getItem("myid"),
      realObjId: "", //对象的objId
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      operatingTimeObj: {
        id: "",
        value: "",
      }, // 服务区域下的操作时间
      twitterImg: "#icon-twitter",
      linkedinImg: "#icon-Linkedin",
      facebookImg: "#icon-facebook",
      twitterImgBlue: "#icon-twitter_blue",
      linkedinImgBlue: "#icon-Linkedin_blue",
      facebookImgBlue: "#icon-facebook_blue",
      underDetailSubmitStatus: true, //提交待审批状态
      langeoptions: [],
      langeoptionslength: "",
      langevalue: "",
      centerDialogVisible: false, //知识库分类
      fromData: [],
      fromcheck: [],
      toData: [],
      thumbsUpvalue: 0,
      thumbsDownvalue: 0,
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      jumpsign: true, //草稿删除标识
      releasedialogVisible: false, //知识文章发布
      affectTranslation: null,
      markNewVersion: null,
      editQuoteline: "", //编辑报价行按钮
      editDiscountTiers: "", //编辑阶梯折扣按钮
      filterText: "",
      checkoutlist: [],
      dataNum: 0,
      allon: false,
      briefHeight: 0, //简介的高度
      typedisabled: "",
      fullscreenLoading: false,
      quotationDialogVisible: false,
      dialogTableVisible: false,
      //发送邮件弹框是否显示
      dialogVisibleEmail: false,
      //发送邮件弹框是否展示操作项
      emailIsOperation: true,
      is_master_version: "", //文章是否为主版本
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      enclosurearr: [], //要发送的报价单文件
      // 客服云对象新增参数
      prefixCustomer: "",
      objectApiCustomer: "",
      objectNameCustomer: "",
      objectNameReceive: this.$i18n.t("label.mobile.phone.receivable"),
      orderNameRecive: "",
      QBtfCC: false,
      QBmessCC: "",
      isquick: null, //QB是否启用
      showAllTags: false, //展示所有标签的弹窗
      // 知识库展示查看翻译空值
      blankValue: "",
      // 记录类型id
      recordType: "",
      reasonRejectionData:[],//拒单原因选项
      reasonRejectionchoose:'',//拒单原因选项
      serviceterritoryId:'',//服务区域id
      reasonRejectionValue:'',//拒单原因500字
      utOrderRejectFlag:false,//拒单弹框
      utOrderRejectLoading:false,//拒单接口loading
      serviceAppointmentStatus:'',//服务预约状态status为0:不显示；1:显示接单拒单按钮；2:显示接单按钮；3:显示拒单按钮
    };
  },
  beforeRouteEnter(to, from, next) {
    if (VueCookies.get("detailLayoutMode") === null) {
      VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
    }
    next((vm) => {
      // 通过 `vm` 访问组件实例
      vm.detailLayoutMode = VueCookies.get("detailLayoutMode");
      // vm.$refs.listChild && vm.$refs.listChild.setRelationNameWidth()
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.$store.state.navigationStyle) {
      if (to.query.viewId) {
        this.viewId = to.query.viewId;
      }
      this.id = to.params.id;
      // this.findArticleIdTranslate()
      this.init();
      document.getElementById("contentMainBox").scrollTop = 0;
      this.$nextTick(() => {
        this.$refs.listChild.id = this.id;
        this.$refs.listChild.init();
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === "/console-dispatchingUnits"){
      to.meta.keepAlive = true;
      next();
    } else {
      next();
    }
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    // 当前页面的路由层级
    this.historyLength = window.history.length;
    this.isSkeleton = true;
    // this.isStyleType = true
    this.routerName = this.$route.params.oprateType;
    this.viewId = this.$router.history.current.query.viewId;
    if (this.id) {
      this.init();
    }
    this.$bus.$on("updateDetailRecordId", this.setDetailRecordId);
    // 保存详细信息数据到localStore中，并且执行onLoad
    if (window.$CCDK?.CCObject?.setObjectDetail) {
      window.$CCDK.CCObject.setObjectDetail({ id: this.id, detail: [] });
    }
  },
  beforeDestroy() {
    this.$bus.$off("windowResize", this.getWindowWidth);
    this.$Bus.$off("custombtnrefresh", this.refreshAll);
    this.$Bus.$off("approvalrefresh", this.refreshAll);
    this.$Bus.$off("recovery-submit-status", this.setRecoverySubmitstatus);
    this.$Bus.$off("service-territory-data", this.setServiceTerritoryData);
    this.$Bus.$off(
      "deliver-group-strategy-ownerid",
      this.setDeliverGroupStrategyOwnerid
    );
    this.$bus.$off("updateDetailRecordId", this.setDetailRecordId);
  },
  mounted() {
    //选择下个审批人弹窗
    if (this.$route.query.isManual) {
      this.$refs.nextApprovalref.IsShowdialog(true, this.$route.query.recordId);
      //提交待审批只执行一次，防止刷新再次执行，清除路由参数
      this.$router.push({ query: { objId: this.$route.query.objId } });
    }
    this.$CCDK.CCDetail.setDetailId(this.id) // 详情页记录ID   给自定义页面内嵌使用
    // 知识文章详情页查看翻译
    if (this.id.substring(0, 3) === "k02") {
      this.findArticleIdTranslate();
      this.queryDataCategorySelection();
    }
    this.getIsHasGroup();

    // 如果是资产，获取用户资产操作权限
    if (this.id.substring(0, 3) === "s42") {
      this.getAssetBomPermission();
    }

    if (VueCookies.get("detailLayoutMode") === null) {
      VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
    }
    this.detailLayoutMode = VueCookies.get("detailLayoutMode");
    this.$Bus.$on(
      "deliver-group-strategy-ownerid",
      this.setDeliverGroupStrategyOwnerid
    );
    // 服务区域下的操作时间
    this.$Bus.$on("service-territory-data", this.setServiceTerritoryData);
    // 恢复提交待审批状态
    this.$Bus.$on("recovery-submit-status", this.setRecoverySubmitstatus);
    // 获取窗口宽度
    this.$bus.$on("windowResize", this.getWindowWidth);
    // 提交待审批后局部刷新页面
    this.$Bus.$on("approvalrefresh", this.refreshAll);
    // 相关列表自定义按钮 刷新页面 wj
    this.$Bus.$on("custombtnrefresh", this.refreshAll);
    // 初始化图标
    this.replyPhoneShowClick();
    // 通过保存并新建跳转到详情页，需要打开新建
    this.ccdkCreat()
    document.getElementById("lightning-loading").style.display = "none";
  },
  methods: {
    // 接单规则是否开启和服务伙伴接单规则开启的情况下调oRButton接口查看显示按钮,否则不显示
    async getOrdersRule(){
      let res = await detailApi.getOrdersRule();
      if(res.data.ruletype=='partner'&& res.data.orderModel=='true'){
        this.oRButton();
      }
    },
    // 服务预约详情页显示接单和拒单按钮
    async oRButton(){
      let params={
        serviceappointmentId:this.id
      }
      let res = await detailApi.oRButton(params);
      if(res.result){
        this.serviceAppointmentStatus=res.data.status;
        // status为0:不显示；1:显示接单拒单按钮；2:显示接单按钮；3:显示拒单按钮
        // 当存在拒单按钮时，调取拒单原因接口
        // status==1,对服务区域id赋值，拒单时需要传服务区域id
        if(this.serviceAppointmentStatus=='1'){
          this.serviceterritoryId=res.data.serviceterritoryId;
        }
      }
    },
    // 拒单原因接口
    async getRejectReason(){
      let res = await detailApi.getRejectReason();
      if(res.result && res.data){
        this.reasonRejectionData=res.data;
      }
    },
    // 关闭拒单弹框
    cancelOrderRejectFlag(){
      this.utOrderRejectFlag=false;
    },
    // 接单接口
    async getOrderReceiving(){
      let params={
        serviceappointmentId:this.id,//服务预约id
      }
      let res = await detailApi.utOrderReceive(params);
      // result为true时returnCode为2则为接单失败，其他成功；result为false失败
      if(res.result&&res.returnCode!='2'){
        this.getDisplaybtn();
        this.$message.success(this.$i18n.t("c2175"))
        // 接单成功后刷新详细信息和活动
        this.$refs.listChild.getFormAttr();
        this.$refs.activitys.init();
      }else{
        // 接单失败后隐藏接单拒单按钮
        this.getDisplaybtn();
        this.$message.error(this.$i18n.t("c2176"))
        // 接单失败后刷新详细信息和活动
        this.$refs.listChild.getFormAttr();
        this.$refs.activitys.init();
      }
    },
    // 接单，拒单，成功或失败时隐藏接单拒单按钮
    getDisplaybtn(){
      this.showButton.map((item,index)=>{
        if(item.id=='orderReceiving'){
          this.showButton.splice(index,1)
        }
      })
      this.showButton.map((item,index)=>{
        if(item.id=='rejection'){
          this.showButton.splice(index,1)
        }
      })
    },
    // 点击拒单按钮
    getutOrderReject(){
      this.utOrderRejectFlag=true;
      this.reasonRejectionchoose=this.$i18n.t("c2323");
    },
    // 拒单接口
    async utOrderReject(){
      this.utOrderRejectLoading=true;
      let params={
        serviceappointmentId:this.id,//服务预约id
        rejectReason:this.reasonRejectionchoose,//拒单原因,
        rejectDetail:this.reasonRejectionValue,//详细原因
        serviceterritoryId:this.serviceterritoryId,//服务区域id
      }
      let res = await detailApi.utOrderReject(params);
      if(res.result&&res.returnCode!='2'){
        this.getDisplaybtn();
        this.$message.success(this.$i18n.t("c2178"))
        // 拒单成功后刷新详细信息
        // this.$refs.listChild.getFormAttr();
        this.utOrderRejectFlag=false;
        this.reasonRejectionchoose='';
      }else{
        this.getDisplaybtn();
        this.$message.error(this.$i18n.t("c2177"))
        this.utOrderRejectFlag=false;
      }
      this.utOrderRejectLoading=false;
    },
    // 电话回复图标判断
    replyPhoneShowClick() {
      let callcenterConf = JSON.parse(localStorage.getItem("callcenterConf"));
      if (callcenterConf.tooltype) {
        this.replyPhoneShow = true;
      } else {
        this.replyPhoneShow = false;
      }
    },
    //  电话回复
    callPhone(phone) {
      this.$bus.$emit("newCLick", phone);
    },
    /**
     * 通过保存并新建跳转到详情页，需要打开新建
     */
    ccdkCreat(){
      // 如果是控制台应用程序，并且记录id和存储的是一个，本地存储中有值，并且不过期，此时打开新建
      let saveAndRenew = localStorage.getItem('saveAndRenew')
      // 清空本地存储中的标识
      localStorage.removeItem('saveAndRenew')
      let flag = false
      if(saveAndRenew){
        let obj = JSON.parse(saveAndRenew)
        flag = !!obj.data && obj.expire > Date.now() && obj.data.id === this.$route.params.id && this.$store.state.navigationStyle
        if(flag){
          // 在当前菜单下打开二级新建菜单
          this.$CCDK.CCPage.openCreatePage(obj.data.data,
              {
                openPlace:'menu2',
                openMode:'_blank'
              }
            )
        }
      }
    },
    setDetailRecordId() {
      this.$CCDK.CCDetail.setDetailId(this.id)  // 详情页记录ID   给自定义页面内嵌使用
    },
    setDeliverGroupStrategyOwnerid(res) {
      this.groupStrategyOwnerid = res;
    },
    // 服务区域下的操作时间
    setServiceTerritoryData(res) {
      this.operatingTimeObj = res;
    },
    // 恢复提交待审批状态
    setRecoverySubmitstatus() {
      this.underDetailSubmitStatus = true;
    },
    //获取窗口宽度
    getWindowWidth(offsetHeight, offsetWidth) {
      this.screenWidth = offsetWidth;
    },
    // 切换PDF视图
    seeOldView() {
      let param = {
        obj: this.objectApi,
        enable: this.seeViewType.toString(),
      };
      detailApi.saveTemplateViewStatus(param).then(() => {
        // 切换PDF视图时，IsPdfViewStatus清空
        this.$store.commit("setIsPdfViewStatus", "true");
        // 切换跳转到PDF视图详情页
        // this.$router.push(`/commonObjects/pdfViewDetail/${this.objectApi}/${this.objId}/${this.id}/${this.prefix}/${this.viewId}`);
        // this.$router.push(`/commonObjects/pdfViewDetail/${this.id}`);
        this.$router.push({
          path: `/commonObjects/pdfViewDetail/${this.id}`,
          query: {
            objectApi: this.objectApi,
            objId: this.objId,
            prefix: this.prefix,
            viewId: this.viewId,
          },
        });
      });
    },
    // 报价单详情页新建发票按钮 带值方法
    quoteInitValue() {
      this.orignObj = "quote";
      // 报价单新建发票按钮 主录入带值问题
      this.getInitValue(
        [
          { oldField: "zongjia", newField: "total_amount" },
          {
            oldField: "totalcustomerdiscountamount",
            newField: "discountamount",
          },
          { oldField: "selectdiscount", newField: "selectdiscount" }, //主记录折扣方式回显
          { oldField: "customerdiscount", newField: "discount" },
          { oldField: "tax", newField: "tax" },
          { oldField: "ratetype", newField: "ratetype" },
          { oldField: "adjustment", newField: "adjustment" },
          { oldField: "totalamount", newField: "total" },
          { oldField: "khmc", newField: "account_name" },
          { oldField: "lxrxm", newField: "contact_name" },
          { oldField: "ywjhmc", newField: "opportunity" },
          { oldField: "pricebook2id", newField: "pricebook2id" },
          { oldField: "currency", newField: "currency" },
          { oldField: "name", newField: "quote" },
        ],
        "quoteToInvoices"
      );
      this.getObjInfo({ id: "invoices" });
    },
    // 报价单详情页新建发票按钮提示弹窗取消方法
    cancelChange() {
      this.showQuoteIntro = false;
    },
    // 报价单详情页新建发票按钮提示弹窗确定方法
    confirmChange() {
      this.showQuoteIntro = false;
      this.quoteInitValue();
    },
    /**
     * 业务机会/报价单等数据转换 根据原数据获取新数据带值方法
     * @param {Object} arrayFiled 要转换的数据信息,包含原字段和新字段 字段apiname
     * @param {String} type 是否是业务机会转换为报价单/订单
     */
    getInitValue(arrayFiled, type) {
      this.opportunityInfo = {};
      let promiseList = [];
      promiseList.push(
        detailApi
          .masterRecordDetailInfo({
            objId: this.objId,
            recordId: this.$route.params.id,
          })
          .then((res) => {
            localStorage.setItem(
              "masterRecordDetailInfoval",
              JSON.stringify(res.data[0])
            );
            return res;
          })
      );
      if (this.objectApi === "Opportunity") {
        // pengpeng待解决周一
        // 业务机会产品从录入数据获取值
        promiseList.push(
          detailApi
            .relatedOpportunityPdtInfo({
              currency: "",
              opportunityId: this.$route.params.id,
            })
            .then((res) => {
              res.data.forEach((item) => {
                for (var key in item) {
                  if (key === "optionalsku") {
                    item.product2 = item.optionalsku;
                  } else if (key === "productname") {
                    item.product2ccname = item.productname;
                  }
                }
              });
              return res;
            })
        );
      } else if (this.objectApi === "quote") {
        // 从报价单原记录获取数据
        let array = [];
        let objId = "";
        // 获取所有的报价单明细id
        this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
          if (ele.objectInfo.objName === "quotedetail") {
            objId = "2011000000062730EI25";
            ele.objectInfo.data.forEach((list) => {
              array.push(list.id);
            });
          }
        });
        // 获取所有的报价行id
        this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
          if (ele.objectInfo.objName === "cloudccquoteline") {
            objId = "20210607thequoteline";
            ele.objectInfo.data.forEach((list) => {
              array.push(list.id);
            });
          }
        });
        if (array.length > 0 && objId !== "") {
          // 报价单明细  报价行带值
          promiseList.push(
            detailApi
              .masterRecordDetailInfo({ objId, recordId: array.join(",") })
              .then((res) => {
                let quoteArray = [];
                res.data.forEach((dataList) => {
                  let quoteInfoobj = {};
                  for (var key in dataList) {
                    if (
                      objId === "2011000000062730EI25" &&
                      (key === "chanpin" ||
                        key === "chanpinccname" ||
                        key === "selectdiscount" ||
                        key === "cpdm" ||
                        key === "xsjg" ||
                        key === "shuliang" ||
                        key === "xiaoji" ||
                        key === "discountamount" ||
                        key === "zhekou" ||
                        key === "tax" ||
                        key === "ratetype" ||
                        key === "zongjia")
                    ) {
                      quoteInfoobj[key] = dataList[key];
                    } else if (
                      objId === "20210607thequoteline" &&
                      (key === "product" ||
                        key === "productccname" ||
                        key === "originalprice" ||
                        key === "quantity" ||
                        key === "additionaldiscountamount" ||
                        key === "discount" ||
                        key === "productcode")
                    ) {
                      quoteInfoobj[key] = dataList[key];
                    }
                  }
                  quoteArray.push(quoteInfoobj);
                });
                return quoteArray;
              })
          );
        }
      }
      // 业务机会新建报价单  新建发票需要获取当前记录客户的关联信息
      if (type === "specialQuote" || type === "specialInvoice") {
        promiseList.push(
          detailApi
            .getRelatedAccountInfo({
              id: this.$route.params.id,
              keyword: "Opportunity",
            })
            .then((result) => {
              return result;
            })
        );
      }
      Promise.all(promiseList).then((allres) => {
        allres.forEach((list, index) => {
          if (index === 0) {
            let infoObj = list.data[0];
            arrayFiled.forEach((name) => {
              for (var key in infoObj) {
                if (key === name.oldField && name.oldField !== "name") {
                  if (infoObj[`${key}ccname`]) {
                    this.opportunityInfo[name.newField] = [
                      { id: infoObj[key], value: infoObj[`${key}ccname`] },
                    ];
                  } else {
                    this.opportunityInfo[name.newField] = [
                      { id: infoObj[key] },
                    ];
                  }
                } else if (key === name.oldField && name.oldField === "name") {
                  this.opportunityInfo[name.newField] = [
                    { id: this.$route.params.id, value: infoObj.name },
                  ];
                }
                if (key === "createbyid") {
                  let str = infoObj.createdate.split(" ")[0];
                  this.opportunityInfo.createbyid = [{ id: str }];
                }
              }
            });
            if (type === "specialOrder") {
              let arrayInfo = this.opportunityInfo.pricebook2id;
              if (arrayInfo && arrayInfo[0].id && arrayInfo[0].id !== "") {
                // 获取已启用的价格手册
                detailApi.getEnablePriceBook().then((res) => {
                  let isOnPriceBook = false;
                  res.data.forEach((list) => {
                    if (arrayInfo[0].id === list.id) {
                      isOnPriceBook = true;
                    }
                  });
                  if (isOnPriceBook) {
                    this.getObjInfo({ id: "20210525cloudccorder" });
                  } else {
                    this.$message.warning(this.$i18n.t("c788"));
                  }
                });
              } else {
                // arrayInfo为null，不区分价格手册是否为空也可新建订单
                this.getObjInfo({ id: "20210525cloudccorder" });
              }
            }
          } else if (index === 2) {
            let infoObj = list.data[0];
            if (type === "specialQuote") {
              if (infoObj?.contactId && infoObj?.contactId !== "") {
                this.opportunityInfo.lxrxm = [
                  { id: infoObj.contactId, value: infoObj.contactName },
                ];
              }
              // 传真
              this.opportunityInfo.dianhua = [{ id: infoObj.dianhua }];
              // 电话
              this.opportunityInfo.chuanzhen = [{ id: infoObj.fax }];
            } else if (type === "specialInvoice") {
              if (infoObj?.contactId && infoObj?.contactId !== "") {
                this.opportunityInfo.contact_name = [
                  { id: infoObj.contactId, value: infoObj.contactName },
                ];
              }
            }
          } else if (index === 1) {
            this.opportunitySecInfo = list.data ? list.data : list;
          }
        });
      });
    },
    async getObjInfo(params) {
      let res = await detailApi.getObjectGetObjectInfo(params);
      this.opportunityNameRecive = res.data.labelname;
      this.opportunityPrefix = res.data.prefix;
      this.opportunityApi = res.data.objectapi;
      this.$refs.opportunityCeateEditObj.objId = res.data.id;
      this.$nextTick(() => {
        this.$refs.opportunityCeateEditObj.add();
      });
    },
    //暂无文件列表时生成
    opencreatedquo() {
      this.generate();
    },
    //获取对象详细信息
    getDetail() {
      let params = {
        id: this.id,
        objectApi: "",
        operation: "DETAIL",
        recordType: "",
      };
      detailApi.getDetail(params).then((res) => {
        this.recordType = res?.data?.recordType;
      });
    },
    //标准模板生成功能
    generate() {
      //模板标准生成功能
      this.printTitle = this.$i18n.t("c111", {
        name: this.eventTap || this.tabName,
      });
      this.printType = "quotation";
      this.emailIsOperation = true;
      detailApi.getObjTemplateList({ objId: this.objId }).then((res) => {
        if (res.result) {
          let flg = true;
          res.data.forEach((element) => {
            if (element.isenable === "1") {
              flg = false;
            }
          });
          if (flg) {
            this.$message.error(
              this.$i18n.t("c112", { name: this.eventTap || this.tabName })
            );
          } else {
            if (this.recordType) {
              this.templateList = res.data.filter((item) => {
                return item.recordtype == this.recordType;
              });
            } else {
              this.templateList = res.data;
            }
            // 模板时间排序
            this.templateList.sort((a, b) => {
              let dateOne = new Date(a.lastmodifydate);
              let dateTow = new Date(b.lastmodifydate);
              if (dateOne === dateTow) {
                return 0;
              } else if (dateOne < dateTow) {
                return 1;
              } else {
                return -1;
              }
            });
            this.templateList.sort((a, b) => {
              if (b.isenable === "0") {
                return -1;
              } else {
                return 1;
              }
            });

            this.invoiceTemplateDialogVisible = true;
            this.closeEmailDialogFlg = 1;
            this.$refs.invoiceTemplate.init(
              this.fieldValue,
              this.templateList[0].templateid
            );
          }
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    // 确认改变发票的产品运输状态
    updateStatus() {
      this.invoicesDialogVisible = false;
      detailApi.updateShippingstatus({ invoicesid: this.id }).then((res) => {
        if (res.returnCode === "10000") {
          this.refreshAll();
          this.$message.success(this.$i18n.t("c66"));
        }
      });
    },
    //关闭提交待审批按钮loading
    closeLoading() {
      this.submitStatus = false;
    },
    //QB是否启用
    startQB() {
      detailApi.quickbookStart().then((res) => {
        if (res.result && res.data && res.data == "1") {
          this.isquick = true;
        } else {
          this.isquick = false;
        }
      });
    },
    // 文章分类判断是否为主版本
    getstate() {
      this.is_master_version = this.$refs.listChild.is_master_version;
    },
    closeSelectTemplateDialog(flg, data) {
      this.selectTemplateDialogVisible = false;
      if (flg) {
        this.$refs.createdQuotationDialog.init(data);
        this.quotationDialogVisible = true;
      }
    },
    //关闭发票选择模板弹框
    closeinvoiceTemplateDialog(flg, data) {
      this.invoiceTemplateDialogVisible = false;
      if (flg) {
        this.$refs.createdQuotationDialog.init(data);
        this.templateId = data.templateId;
        this.quotationDialogVisible = true;
      }
    },
    // 关闭生成报价单弹窗
    closeQuotaionDialog(val, item) {
      this.quotationDialogVisible = false;
      if (item) {
        this.dialogVisibleEmail = true;
        this.enclosurearr = item;
      } else {
        this.dialogVisibleEmail = false;
        this.refreshUpload();
      }
    },
    //关闭选择报价单弹框
    closedselectquotation(val, selectval) {
      this.dialogTableVisible = val;
      if (selectval) {
        this.enclosurearr = selectval;
        this.dialogVisibleEmail = true;
      } else {
        this.dialogVisibleEmail = false;
      }
    },
    //关闭邮件弹框
    closeEmailDialog(flg) {
      if (!flg && this.closeEmailDialogFlg === 1) {
        this.dialogVisibleEmail = false;
        this.quotationDialogVisible = true;
      } else if (!flg && this.closeEmailDialogFlg === 2) {
        this.dialogVisibleEmail = false;
        this.enclosurearr = [];
        this.dialogTableVisible = true;
        this.$refs.selectquotation.getFilesdata();
      } else if (flg && this.closeEmailDialogFlg === 1) {
        this.dialogVisibleEmail = false;
        this.enclosurearr = [];
        this.refreshUpload();
      } else {
        this.dialogVisibleEmail = false;
        this.enclosurearr = [];
      }
    },
    // 获取CCChat后台设置信息
    async getSetupInfo() {
      let res = await detailApi.getSetupInfo();
      this.isShowdynamicPublics = res.data.isEnable;
      if (res.data.isEnable == "true") {
        this.$refs.dynamicPublics.second();
      }
    },
    goToPay() {
      let totalPrice = "";
      this.heightLight.map((item) => {
        if (item.fieldName == "totalamount") {
          totalPrice = item.fieldValue.split(" ")[1];
        }
      });
      totalPrice = totalPrice.replace(",", "");
      this.$router.push({
        path: `/orderPay/${totalPrice}/${this.id}`,
      });
    },
    // 是否展示付款按钮
    async isPayButton() {
      let result = await detailApi.getPaymentStatus({ orderid: this.id });
      let res = await detailApi.getPayMethod({});
      if (
        (result.data.paymentstatus == "未回款" ||
          result.data.paymentstatus == "部分回款" ||
          result.data.paymentstatus == "Unpaid" ||
          result.data.paymentstatus == "Partially Paid") &&
        (res.data.isOnlinePayment === "true" ||
          res.data.avaliable !== "" ||
          res.data.isUsingPartnerFund == "true")
      ) {
        this.showPayButton = true;
      } else {
        this.showPayButton = false;
      }
    },
    // 如果是资产，获取用户资产操作权限
    getAssetBomPermission() {
      detailApi.isOpenAsset({}).then((res) => {
        if (res.result) {
          this.isOpenAssetBom = res.data || false;
        }
      });
    },
    // 关闭公告发布范围弹窗
    closeGgRecord(ggdata) {
      this.isShowggRecord = ggdata;
    },
    // 查看翻译
    findArticleIdTranslate() {
      let params = {
        articleId: this.id,
      };
      detailApi.findArticleIdTranslate(params).then((res) => {
        if (res.data !== null && res.data.langevalue !== null) {
          this.langevalue = res.data.langevalue;
          this.langeoptions = res.data.langeoptions;
          this.langeoptionslength = res.data.langeoptions.length;
        }
      });
    },
    // 点击跳转新页面
    jumpNew(newHref) {
      window.open(newHref);
      // location.replace(newHref)
    },
    // 左右布局切换
    switchLayout() {
      this.detailLayoutMode =
        this.detailLayoutMode === "mode1" ? "mode2" : "mode1";
      VueCookies.set("detailLayoutMode", this.detailLayoutMode, { sameSite: 'Strict' });
      this.$nextTick(() => {
        this.$refs.listChild && this.$refs.listChild.setRelationNameWidth();
      });
    },
    //根据视口宽度计算简介内容展示
    meunsFilter() {
      let itemWidth = 0;
      let intLen = document.getElementsByClassName("introduce_content");
      for (var i = 0; i <= intLen.length; i++) {
        if (intLen[i]) {
          itemWidth = intLen[i].offsetLeft + 300;
        }
        if (itemWidth >= this.screenWidth) {
          if (this.heightLight[i]) {
            this.heightLight[i].isShowMenus = false;
          }
        } else {
          if (this.heightLight[i]) {
            this.heightLight[i].isShowMenus = true;
          }
        }
      }
    },
    // 获取从子组件list传过来的详情页跳转用户名
    personsNameMethod(name) {
      this.userName = name;
      document.title = this.$setTitle(`${name}`);
    },
    // 刷新整个页面
    refreshAll() {
      this.init();
      document.getElementById("contentMainBox").scrollTop = 0;
      this.$nextTick(() => {
        //获取roi卡片数据
        if (this.objectApi && this.objectApi === "campaign") {
          this.$refs.roiCard && this.$refs.roiCard.init();
        }
        this.$refs.uponDetailHistory &&
          this.$refs.uponDetailHistory.getApprovalPreview();
        if (
          this.isOpenService === "true" &&
          (this.objectApi === "WorkOrder" || this.objectApi === "cloudcccase")
        ) {
          this.$refs.importantEvent.renderPages();
        }
      });
    },
    //刷新文件列表
    refreshUpload() {
      this.$nextTick(() => {
        this.$refs.listChild.init();
        //获取roi卡片数据
        if (this.objectApi && this.objectApi === "campaign") {
          this.$refs.roiCard && this.$refs.roiCard.init();
        }
        this.$refs.uponDetailHistory &&
          this.$refs.uponDetailHistory.getApprovalPreview();
        if (
          this.isOpenService === "true" &&
          (this.objectApi === "WorkOrder" || this.objectApi === "cloudcccase")
        ) {
          this.$refs.importantEvent.renderPages();
        }
        this.$refs.listChild.menuBtn({
          relatedlistId: "aeethequote2747f3905",
        });
      });
    },
    joinGroup() {
      if (this.qunceType == "exist") {
        this.$Bus.$emit("create-strategy-groupexist", this.qunceData);
        this.isShowTag = false;
      } else if (this.qunceType == "notexist") {
        if (Object.keys(this.groupStrategyOwnerid).length > 0) {
          if (this.groupStrategyOwnerid.value == "") {
            this.$message({
              showClose: true,
              type: "warning",
              message: this.$i18n.t(
                "vue_label_commonobjects_detail_a_group_strategy"
              ), //该条数据缺少所有人,无法创建群策
            });
            return false;
          }
          if (this.groupStrategyOwnerid.id == localStorage.getItem("myid")) {
            this.qunceData.groupIdArr = [
              JSON.parse(localStorage.getItem("userInfo")).userId,
            ];
            this.qunceData.groupName =
              this.bigTitle && this.bigTitle[0].fieldValue;
            this.qunceData.groupNames = localStorage.getItem("username");
            this.qunceData.userIdArr = localStorage.getItem("myid");
            this.$Bus.$emit(
              "create-strategy-allnotexist",
              this.qunceData,
              this.id
            );
          } else {
            this.qunceData.groupIdArr = [
              JSON.parse(localStorage.getItem("userInfo")).userId,
            ];
            this.qunceData.groupName =
              this.bigTitle && this.bigTitle[0].fieldValue;
            this.qunceData.userIdArr = [localStorage.getItem("myid")];
            this.getUserInfor(this.groupStrategyOwnerid.id, this.qunceData);
          }
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_customer_details"
            ), //正在加载客户详情
          });
          return false;
        }
        this.isShowTag = false;
      }
    },
    cancelDia() {
      this.isShowTag = false;
    },
    //判断业务机会或客户是否创建了群策
    async getIsHasGroup() {
      let result = await job.judgeIsHasStrategy(this.id);
      if (
        result.result == true &&
        result.data &&
        result.data.strategyGroupId &&
        result.data.strategyGroupId != ""
      ) {
        this.detailGroupId = result.data.strategyGroupId;
      }
    },
    //群策获取客户信息
    judgeIsHas: debounce(
      async function () {
        let result = await job.judgeIsHasStrategy(this.id);
        if (result.result == true && result.data) {
          let item = {};
          item.strategyType = result.data.objid;
          if (
            result.data.strategyGroupId &&
            result.data.strategyGroupId != ""
          ) {
            let groupMessage = await job.getgroupmessage(
              result.data.strategyGroupId
            );
            if (groupMessage.result == true && groupMessage.data) {
              item.name = groupMessage.data.name;
              item.id = result.data.strategyGroupId;
            }
          } //存在群策，并且已经加入
          if (
            result.data.existStrategyGroup == "true" &&
            result.data.joinStrategyGroup == "true"
          ) {
            this.$Bus.$emit("create-strategy-allexist", item);
          } //存在群策，但没有加入
          if (
            result.data.existStrategyGroup == "true" &&
            result.data.joinStrategyGroup == "false"
          ) {
            //打开群策对应的群
            this.isShowTag = true;
            this.qunceType = "exist";
            this.qunceData = item;
          } //不存在群策
          if (
            result.data.existStrategyGroup == "false" &&
            result.data.joinStrategyGroup == "false"
          ) {
            this.isShowTag = true;
            this.qunceType = "notexist";
            this.qunceData = item;
          }
        }
      },
      3000,
      { leading: true, trailing: false }
    ),
    async getUserInfor(id, item) {
      let userId = await job.getUserInformation(id);
      if (userId.result == true && userId.data) {
        item.groupIdArr.push(userId.data[0].imUserId);
        item.groupNames = userId.data[0].name;
        item.userIdArr.push(userId.data[0].id);
        item.userIdArr = item.userIdArr.join();
        this.$Bus.$emit("create-strategy-allnotexist", item, this.id);
      }
    },
   init() {
      this.isQuery = null;
      this.fullscreenLoading = true;
      this.isFollow();
      sessionStorage.setItem("id", this.$route.params.id);

      // 获取记录查看权限，无权限时给提示，可返回上一级
      detailApi
        .getPermissionById({ id: this.id })
        .then((res) => {
          if (res.returnCode === "1" && res.result) {
            this.objectApi = res.data.objectApi;
            // 服务预约记录详情页下判断是否显示接单和拒单按钮
            if(this.objectApi=='ServiceAppointment'){
              this.oRButton();
            }
            this.prefix = res.data.prefix;
            this.isEdit = res.data.isEdit;
            this.realObjId = res.data.objId;
            this.isLocked = res.data.isLocked;
            // this.isDelete = res.data.isDelete
            this.isCanEditOwner = res.data.canEditOwner;
            this.fullscreenLoading = false;
            if (this.objectApi == "cloudcccase" || this.objectApi === "WorkOrder") {
              this.$refs.listChild.getKnowledgePermissionById();
            }
            this.$nextTick(() => {});
            if (res.data.isQuery) {
              this.isQuery = true;
              this.isShowType = true;
              if (res.data.objId === "user") {
                // this.getCurrentUser();
                this.isShowType = false;
                this.isQuery = true;
                this.dynamicType = true;
                this.activeName = "second";
                this.$refs.dynamicPublics?.personDetailPage();
                this.$refs.listChild.init();
              } else {
                this.getBrief();
                this.getTagsByRecordId();
                this.$refs.listChild && this.$refs.listChild.init();
              }
            } else {
              this.isQuery = false;
            }
          } else {
            this.isQuery = false;
            // 无权限，返回上一步
            // this.$router.go(-1);
          }
        })
        .catch((err) => {
          // 数据不存在返回上一步
          if (err.returnCode === "10905") {
            this.$message.error(err.returnInfo);
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } else {
            this.fullscreenLoading = false;
          }
        });
    },
    // 鼠标悬浮
    mouseover() {
      // 当已关注后鼠标悬浮变成取消关注
      if (this.follow === this.$i18n.t("label.chatter.followed")) {
        this.follow = this.$i18n.t("vue_label_CCChat_unfollow");
      }
    },
    // 当按钮为取消关注时鼠标离开变成已关注
    mouseout() {
      if (this.follow === this.$i18n.t("vue_label_CCChat_unfollow")) {
        this.follow = this.$i18n.t("label.chatter.followed");
      }
    },
    // 判断是否关注
    async isFollow() {
      let params = {
        id: this.id,
      };
      let res = await detailApi.isFollow(params);
      if (res.data.isFollow) {
        // 已关注
        this.follow = this.$i18n.t("label.chatter.followed");
      } else {
        //关注
        this.follow = this.$i18n.t("label.chatter.follow");
      }
    },
    // 点击关注 取消关注
    async setFollow(val) {
      let params = {};
      if (val == this.$i18n.t("label.chatter.follow")) {
        params = {
          id: this.id,
          followType: "record",
          followStatus: "1", //关注
        };
      } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
        params = {
          id: this.id,
          followType: "record",
          followStatus: "0", //取消关注
        };
      }

      let res = await detailApi.setFollow(params);
      if (res.result) {
        if (val == this.$i18n.t("label.chatter.follow")) {
          // 按钮为关注时点击后变成已关注
          this.follow = this.$i18n.t("label.chatter.followed");
        } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
          // 按钮为取消关注时点击后变成关注
          this.follow = this.$i18n.t("label.chatter.follow");
        }
      }
    },
    //关闭添加标签
    closeAddBtn(n) {
      this.customFlag = n;
    },
    // 获取新添加的标签
    determineFun() {
      this.customFlag = false;
      this.getTagsByRecordId();
    },
    getDelLabelArr(msg) {
      this.labelArr = msg;
      this.$refs.listChild.loadingDetail = true;
      this.$refs.listChild.operation = "DETAIL";
      this.$refs.listChild.getFormAttr();
    },
    customBtn() {
      // this.customFlag = true;
      if (this.labelArr.length !== 0) {
        this.$refs.addlabel.labelArrs(this.labelArr);
      }
      this.$refs.addlabel.tagApi(this.objId, this.objectApi);
      this.$refs.addlabel.isShows();
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    getTagsByRecordId() {
      detailApi
        .GetTagsByRecordId({
          recordId: this.id,
        })
        .then((res) => {
          this.labelArr = res.data;
        });
    },
    resetObjectInfo(data) {
      this.objId = data.objId ? data.objId : this.objId;
      // this.objectApi = data.objectApi;
      this.prefix = data.prefix ? data.prefix : this.prefix;
      if (data.objectApi === "Account") {
        // 个人客户不显示层级图标 -- ispersonaccount为true (个人客户)
        this.isCustomer = data.ispersonaccount === "false";
        //客户层级结构
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_customer_hierarchy"
        );
      } else if (data.objectApi === "Contact") {
        // 个人客户不显示层级图标 -- ispersonaccount为true (个人客户)
        this.isCustomer = data.ispersonaccount === "false";
        // 联系人层级结构
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_contact_hierarchy"
        );
      } else if (data.objectApi === "campaign") {
        this.isCustomer = true;
        //"市场层级结构";
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_market_hierarchy"
        );
      } else if (data.objectApi === "Asset") {
        this.isCustomer = true;
        // 资产层级结构
        this.content = this.$i18n.t("front-asset-module-v1-view-asset-bom");
        this.isShowAssets = true; //显示按钮查看资产BOM
      } else {
        this.isCustomer = false;
      }
    },
    //获取标签
    getBrief() {
      this.bigTitle = [];
      this.tabName = "";
      this.tabStyle = "";
      this.editDiscountTiers = "";
      this.editQuoteline = "";
      detailApi.GetBrief({ id: this.id }).then((res) => {
        //如果父对象存在展示菜单中，则为父对象选项卡加选种样式，否则清除所有选中样式
        this.$Bus.$emit("change-detail-style", res.data.objid);
        if (res.result && res.returnCode === "1") {
          if (res.data) {
            this.objectApi = res.data.objapi;
            this.objId = res.data.objid;
            this.$cookies.set("parentObjectApi", res.data.objapi, { sameSite: 'Strict' }); //存储父对象的ObjectApi、详情关联列表-实际工作清单新建使用
            this.buttonList = [];
            // 判断是否返回提交待审批
            let result = res.data.button.some((item) => {
              if (item.name === "Submit") {
                return true;
              }
            });
            this.showSubmitBtn(result, res.data.approvalButtonMap);
            this.customButtons = res.data.button.filter(item => {
              return item.iscustom === "1"
            })
            this.customButtonsShow = this.customButtons.slice(0, 1);
            this.customButtonsHide = this.customButtons.slice(1);
            let standardButtons = res.data.button.filter(item => {
              return item.iscustom !== "1"
            })
            standardButtons.forEach((item) => {
              //改517问题（当出现自定义按钮从业务机会同步、同步到业务机会，先隐藏，用标准按钮功能）
              //('startsyncing '自定义按钮带空格，标准按钮不带，如果改，需要改四块地方)
              if (
                item.name === "fromopportunity" ||
                item.name === "startsyncing "
              ) {
                delete item.iscustom;
              }
              // 后端进行了权限校验，前端直接展示
              // if (item.name == "Share" && this.isCanEditOwner) {
              if (item.name == "Share") {
                //共享
                this.buttonList.push(item);
              } else if (item.name === "Generate Invoice") {
                if (res.data.objapi === "cloudccorder") {
                  detailApi
                    .orderStatus({ orderid: this.$route.params.id })
                    .then((res) => {
                      if (
                        res.data[0] &&
                        res.data[0].invoicestatus &&
                        res.data[0].invoicestatus !== "已开具发票" &&
                        res.data[0].invoicestatus !== "All Invoiced"
                      ) {
                        this.buttonList.push(item);
                      }
                      this.showButton = this.buttonList.slice(0, 3);
                      this.moreButton = this.buttonList.slice(3);
                    });
                } else {
                  this.buttonList.push(item);
                }
              } else if (
                item.name === "unlock" ||
                item.name === "Edit" ||
                item.name === "Delete" ||
                item.name === "PrintQuote" ||
                item.name === "fromopportunity" ||
                item.name === "startsyncing " ||
                item.name === "Lead_Convers" ||
                item.name === "Lead Convers" ||
                item.name === "Clone" ||
                item.name === "Return Pool" ||
                item.name === "Save and Sync to QB" ||
                item.name === "Print Asset Code" ||
                item.name === "View Asset BOM" ||
                item.name === "Copy Asset BOM" ||
                item.name === "Delete Asset BOM" ||
                item.name === "Transfer Asset BOM" ||
                item.name === "Move Asset BOM" ||
                item.name === "Product To Asset BOM" ||
                item.name === "Order To Asset BOM" ||
                item.name === "fabu" || //公告菜单下  标准按钮更改成 发布范围按钮
                item.iscustom === "1" || // 自定义按钮
                item.name === "Edit Draft" ||
                item.name === "Publish" ||
                item.name === "Change Record Type" ||
                item.name === "Edit as Draft" ||
                item.name === "Archive" ||
                item.name === "Restore" ||
                item.name === "Delete Draft" ||
                item.name === "Synchronize Opportunity" ||
                item.name === "Generate Order" ||
                item.name === "Generate Contract" ||
                item.name === "Payment" ||
                item.name === "Create Contact" || //创建联系人
                item.name === "Create Lead" || //创建潜在客户
                item.name === "CreateWorkOrder" || //新建工作订单
                item.name === "CreateContact" || //新建联系人
                item.name === "CreateCase" || //新建个案
                item.name === "CreateAccount" || //新建客户
                item.name === "Request Pay Online" || //发起线上付款
                item.name === "Send Invoice" || //发送发票
                item.name === "send" || //发送报价单文件
                item.name === "generate" || //生成报价单文件
                // item.name === "Generate Invoice" //生成发票
                item.name === "New Quote" || //新建报价单
                item.name === "New Order" || //新建订单
                item.name === "New Invoice" || //新建发票
                item.name === "Generate Invoice" ||
                item.name === "New Contract" ||
                item.name === "pdfView" // 查看PDF视图
              ) {
                // item.iscustom === "1"
                // 解锁、编辑、删除、报价单打印、从业务机会同步、同步到业务机会、潜在客户转化、复制、自定义按钮、回退公海池
                //查看资产BOM、复制资产BOM、删除资产BOM、转移资产BOM、迁移资产BOM、同步报价行至业务机会
                this.buttonList.push(item);
              } else if (item.name === "Edit Quoteline") {
                this.editQuoteline = item.label;
              } else if (item.name === "Edit Discount Tiers") {
                this.editDiscountTiers = item.label;
              } else if (item.name === "Received") {
                if (this.objectApi === "Invoices") {
                  // 获取是否展示确定收获按钮
                  detailApi
                    .getShippingstatus({ invoicesid: this.id })
                    .then((res) => {
                      if (res.returnCode === "10000") {
                        this.buttonList.push(item);
                        this.showButton = this.buttonList.slice(0, 3);
                        this.moreButton = this.buttonList.slice(3);
                      } else {
                        return;
                      }
                    });
                }
              }
            });
            if (this.objectApi === "Invoices") {
              if (this.isquick) {
                this.buttonList = [
                  ...this.buttonList,
                  {
                    behavior: "self",
                    btn_type: "detailBtn",
                    event: "URL",
                    function_code: null,
                    id: "CCtoQB",
                    iscustom: null,
                    label: this.$i18n.t("front-sales-button-toQB"),
                    menubar: null,
                    name: "CCtoQB",
                    url: null,
                  },
                  {
                    behavior: "self",
                    btn_type: "detailBtn",
                    event: "URL",
                    function_code: null,
                    id: "QBtoCC",
                    iscustom: null,
                    label: this.$i18n.t("front-sales-button-fromQB"),
                    menubar: null,
                    name: "QBtoCC",
                    url: null,
                  },
                ];
                this.buttonList.forEach((res, index) => {
                  if (res.id == "adc2018saveqb39KmL") {
                    this.buttonList.splice(index, 1);
                  }
                });
              }
            } else if (this.objectApi === "cloudccproceed" && this.isquick) {
              this.buttonList = [
                ...this.buttonList,
                {
                  behavior: "self",
                  btn_type: "detailBtn",
                  event: "URL",
                  function_code: null,
                  id: "CCtoQB",
                  iscustom: null,
                  label: this.$i18n.t("front-sales-button-toQB"),
                  menubar: null,
                  name: "CCtoQB",
                  url: null,
                },
                {
                  behavior: "self",
                  btn_type: "detailBtn",
                  event: "URL",
                  function_code: null,
                  id: "QBtoCC",
                  iscustom: null,
                  label: this.$i18n.t("front-sales-button-fromQB"),
                  menubar: null,
                  name: "QBtoCC",
                  url: null,
                },
              ];
            } else if (this.objectApi === "cloudccorder" && this.isquick) {
              this.buttonList = [
                ...this.buttonList,
                {
                  behavior: "self",
                  btn_type: "detailBtn",
                  event: "URL",
                  function_code: null,
                  id: "CCtoQB",
                  iscustom: null,
                  label: this.$i18n.t("front-sales-button-toQB"),
                  menubar: null,
                  name: "CCtoQB",
                  url: null,
                },
              ];
            }
            //编辑 删除 共享 转换 打印报价单 从业务机会同步 同步至业务机会 提交待审批 -调回批准请求
            //项目管理系统
            // if (
            //   sessionStorage.getItem('application') === 'ace2020D253B3984Or6Q'
            // ) {
            //   this.showButton = this.buttonList.slice(0, 2);
            // } else {
            this.showButton = this.buttonList.slice(0, 3);
            // }
            this.moreButton = this.buttonList.slice(3);

            // 公告菜单下  标准按钮更改成 发布范围按钮
            if (this.objId === "Announcement20210520") {
              this.moreButton.forEach((item) => {
                if (item.name === "Share") {
                  item.name = "fabu";
                  item.label = this.$i18n.t("label.ggReleaseRange.release"); //发布范围
                  item.id = "dfsgdgdfgdfg";
                }
              });
              this.showButton.forEach((item) => {
                if (item.name === "Share") {
                  item.name = "fabu";
                  item.label = this.$i18n.t("label.ggReleaseRange.release"); //发布范围
                  item.id = "dfsgdgdfgdfg";
                }
              });
            }
            // 公告菜单下  标准按钮更改成 发布范围按钮 wzj

            if (
              res.data.objapi === "Account" ||
              res.data.objapi === "Contact"
            ) {
              this.getPartnerGetPartnerPermissions(res.data.objapi);
            }
            this.heightLight = JSON.parse(JSON.stringify(res.data.heigthLight));
            if (res.data.objapi == "CloudccKArticle") {
              this.typedisabled = this.heightLight[2].fieldValue;
            }
            //  获取主题值
            res.data.heigthLight.forEach((item) => {
              if (item.fieldName === "zhuti") {
                this.theme = item.fieldValue;
              }
            });
            this.heightLight.forEach((item) => {
              this.$set(item, "isShowMenus", true);
            });
            //相关列表定位所需
            if (this.heightLight && this.heightLight.length > 0) {
              this.briefHeight = 60;
            } else {
              this.briefHeight = 0;
            }
            this.bigTitle = res.data.title;
            this.tabName = res.data.label;
            this.tabStyle = res.data.tabStyle ? res.data.tabStyle : "";
            this.fieldValue = this.bigTitle[0].fieldValue;
            // 勿删
            this.$store.commit("changeBigTitle", this.fieldValue);
            this.$store.commit("changeTabName", this.tabName);
            this.$nextTick(() => {
              this.meunsFilter();
            });
            // 设置title标题
            if (this.fieldValue) {
              document.title = this.$setTitle(`${this.fieldValue}`);
            }
          }
          // 服务预约详情页下根据接口返回值判断是否添加接单拒单按钮
          if(this.objectApi=='ServiceAppointment'){
            if(this.serviceAppointmentStatus=='1'){
              this.showButton.unshift({
                label:this.$i18n.t("c2321"),
                id:'orderReceiving',
              },{
                label:this.$i18n.t("c2322"),
                id:'rejection',
              })
            }
          }
          this.isSkeleton = false;
          // this.isStyleType = false
          //获取roi卡片数据
          if (this.objectApi && this.objectApi === "campaign") {
            this.$refs.roiCard && this.$refs.roiCard.init();
          }
        } else {
          this.$message.error(res.returnInfo);
          // 让进度条隐藏
        }
        // 如果是伙伴账户 请求订单是否支付接口
        if (
          this.$store.state.userInfoObj.ispartner &&
          res.data.objapi == "cloudccorder"
        ) {
          this.isPayButton();
        }
      });
    },
    getHistorySubmit() {
      let obj = {
        id: "",
        label: this.$i18n.t("label.submitforapproval"),
        hisId: "0",
      };
      if (this.$route.query.saveandapproval === "true") {
        this.historyDeal(obj);
      }
    },
    // 展示提交按钮
    showSubmitBtn(flag, approvalStatus) {
      if (flag) {
        let tempObj = {};
        if (
          approvalStatus.isApproval ||
          approvalStatus.isReassign ||
          approvalStatus.isRecall
        ) {
          if (approvalStatus.isApproval) {
            tempObj.showApproval = true;
          }
          if (approvalStatus.isReassign) {
            tempObj.showReassign = true;
          }
          if (approvalStatus.isRecall) {
            tempObj.showRecall = true;
          }
        } else {
          tempObj.showSubmit = true;
        }

        this.showHeaderHistoryBtn(tempObj);
      } else {
        let tempObj = {};
        if (approvalStatus.isApproval) {
          tempObj.showApproval = true;
        }
        if (approvalStatus.isReassign) {
          tempObj.showReassign = true;
        }
        if (approvalStatus.isRecall) {
          tempObj.showRecall = true;
        }

        this.showHeaderHistoryBtn(tempObj);
      }
    },
    showHeaderHistoryBtn(data) {
      this.showHistoryBtn = [];
      this.hideHistoryBtn = [];
      let btnArr = [];
      if (data.showSubmit) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.submitforapproval"),
          hisId: "0",
        }); //"提交待审批"
      }
      if (data.showApproval) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.approveorrefuse"),
          hisId: "2",
        }); //进行审批
      }
      if (data.showReassign) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.redistribution"),
          hisId: "3",
        }); //重新分配
      }
      if (data.showRecall) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.manageAll.sta4"),
          hisId: "1",
        }); //调回批准请求
      }
      let hasShenpi = false;
      btnArr.forEach((item) => {
        if (item.hisId === "2") {
          hasShenpi = true;
        }
      });
      if (btnArr.length < 2) {
        this.showHistoryBtn = btnArr;
        this.hideHistoryBtn = [];
      } else {
        if (hasShenpi) {
          btnArr.forEach((item) => {
            if (item.hisId === "2") {
              this.showHistoryBtn.push(item);
            } else {
              this.hideHistoryBtn.push(item);
            }
          });
        } else {
          this.showHistoryBtn = btnArr.slice(0, 1);
          this.hideHistoryBtn = btnArr.splice(1);
        }
      }
    },
    // 审批历史右上角展示的按钮
    getHistoryBtn(arr) {
      this.showHistoryBtn = [];
      this.hideHistoryBtn = [];
      let hasShenpi = false;
      arr.forEach((item) => {
        if (item.hisId === "2") {
          hasShenpi = true;
        }
      });
      if (arr.length < 2) {
        this.showHistoryBtn = arr;
        this.hideHistoryBtn = [];
      } else {
        if (hasShenpi) {
          arr.forEach((item) => {
            if (item.hisId === "2") {
              this.showHistoryBtn.push(item);
            } else {
              this.hideHistoryBtn.push(item);
            }
          });
        } else {
          this.showHistoryBtn = arr.slice(0, 1);
          this.hideHistoryBtn = arr.splice(1);
        }
      }
    },
    historyDeal(item) {
      if (item.hisId == 0) {
        if (this.underDetailSubmitStatus) {
          this.underDetailSubmitStatus = false;
          //loading启用
          this.submitStatus = true;
          this.$refs.uponDetailHistory.parentClickHistory(
            item.hisId,
            this.detailGroupId,
            this.id
          );
        }
      } else {
        this.$refs.uponDetailHistory.parentClickHistory(
          item.hisId,
          this.detailGroupId,
          this.id
        );
      }
    },
    // 刷新知识文章详情页面
    refreshall() {
      this.init();
      document.getElementById("contentMainBox").scrollTop = 0;
      this.$nextTick(() => {
        this.$refs.listChild.init();
        //获取roi卡片数据
        if (this.objectApi && this.objectApi === "campaign") {
          this.$refs.roiCard && this.$refs.roiCard.init();
        }
        this.$refs.uponDetailHistory &&
          this.$refs.uponDetailHistory.getApprovalPreview();
      });
    },
    //知识库发布关闭弹窗
    releasehandleClose() {
      this.releasedialogVisible = false;
    },
    copy() {
      this.dialogSign = false;
      this.$nextTick(() => {
        this.$refs.createEditObj.objId = "cloudcc_k_article";
        this.$refs.createEditObj.copy(this.id);
      });
    },
    /**
     * recordOperationBtn:右上角按钮点击方法,添加防抖
     * @param {Object} item
     * @param {String} flag:如果flag==='noclick',接口加loading
     */
    recordOperationBtn: debounce(
      function (item, flag) {
        // 服务预约详情页点击接单和拒单按钮
        if(item.id=='orderReceiving'){
          this.getOrderReceiving();//点击接单
          return;
        }else if(item.id=='rejection'){
          this.getRejectReason();//获取拒单原因
          this.getutOrderReject();//点击拒单
          return;
        }
        // 如果标准按钮如果设置了自定义事件，执行自定义事件
        // "转换"这个按钮有标准功能需要特殊处理（王荟清）
        if (
          item.function_code !== "null" &&
          item.function_code !== "" &&
          item.function_code !== undefined &&
          item.function_code !== null &&
          item.name !== "Lead_Convers" &&
          item.name !== "Lead Convers" &&
          item.iscustom !== "1"
        ) {
          let funText = item.function_code
            ? item.function_code
            : item.functionCode;
          let newFun = Function(funText);
          newFun();
          return false;
        }
        // 自定义按钮
        if (
          item.iscustom === "1" &&
          item.name !== "Lead_Convers" &&
          item.name !== "Lead Convers"
        ) {
          // lightning版本javescript类型自定义按钮 调用特定方法执行服务
          if (item.event === "lightning") {
            // 替换id
            if (item.function_code.indexOf("{!id}") !== -1) {
              item.function_code = item.function_code.replace("{!id}", this.id);
            }
            if(item.function_code.indexOf("{!binding}") !== -1) {
              item.function_code = item.function_code.replace("{!binding}", this.$CCDK.CCToken.getToken());
            }
            let param = JSON.parse(item.function_code);
            param.id = this.id;
            param.token = this.$CCDK.CCToken.getToken()
            this.noClick = flag === "noclick";
            // 加loading
         this.fullscreenLoading = true
            detailApi.openCall(param).then((res) => {
                this.fullscreenLoading = false
              this.noClick = false;
              if (res.data && res.data.action) {
                // 刷新页面
                if (res.data.action === "refresh") {
                  this.refreshAll();
                } else if (res.data.action === "redirect") {
                  // 重定向 需判断当前窗口打开还是新窗口打开
                  if (res.data.mode === "new") {
                    window.open(res.data.url);
                  } else if (res.data.mode === "now") {
                    window.location.href = res.data.url;
                  }
                } else if (res.data.action === "alert") {
                  // 不同类型的消息提示   wj
                  this.$message({
                    message: res.data.message,
                    type: res.data.messageType || "info",
                  });
                }
                //先弹窗，在当前页面跳转
                else if (res.data.action === "alert_refresh") {
                  // 不同类型的消息提示   wj
                  this.$message({
                    message: res.data.message,
                    type: res.data.messageType || "info",
                  });
                  setTimeout(() => {
                    this.refreshAll();
                  }, 1000);
                }
                //先弹窗,在新标签页跳转
                else if (res.data.action === "alert_redirect") {
                  // 不同类型的消息提示   wj
                  this.$message({
                    message: res.data.message,
                    type: res.data.messageType || "info",
                  });
                  setTimeout(() => {
                    if (res.data.mode === "new") {
                      window.open(res.data.url);
                    } else if (res.data.mode === "now") {
                      if (`/${window.location.hash}` === res.data.url) {
                        this.refreshAll();
                      } else {
                        window.location.href = res.data.url;
                      }
                    }
                  }, 1000);
                }
              }
            }).catch(()=>{
              this.fullscreenLoading = false
            })
          }
          // 自定义按钮lightning-script
          if (item.event === "lightning-script") {
            let funText = item.function_code
              ? item.function_code
              : item.functionCode;
            let newFun = Function(funText);
            newFun();
          }
          if (item.event === "lightning-url") {
            // 替换id
            if (item.function_code.indexOf("{!id}") !== -1) {
              item.function_code = item.function_code.replace("{!id}", this.id);
            }
            // 当前窗口打开
            if (item.behavior === "self") {
              // window.location.href = item.function_code
              if (item.function_code.substring(0, 7) == "cloudcc") {
                let searchUrl = item.function_code.split("?")[1];
                let id = item.function_code
                        .split("?")[1]
                        .split("&")[0]
                        .split("=")[1];
                let page = item.function_code
                        .split("?")[1]
                        .split("&")[1]
                        .split("=")[1];
                // 判断是否显示菜单栏
                let routeData = "";
                if (item.menubar == "show") {
                  // 展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent",
                    query: { id: id, page: page, button: "Home" },
                  });
                } else {
                  // 不展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: { id: id, page: page },
                  });
                }
                if (item.function_code.indexOf("target") !== -1) {
                  // 例如自定义按钮链接为这种的时候 cloudcc-page?id={!id}&pageApi=idtest&target=window&dialogTitle=Create Survey&dialogWidth=600px
                  //在本页面打开一个新的窗口
                  let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
                  if (paramsObj.target.indexOf("dialog") !== -1) {
                    let obj = {
                      title: paramsObj.dialogTitle || "详情",
                      width: paramsObj.dialogWidth || "50%",
                      height: paramsObj.dialogHeight || "",
                      pageApi: page,
                    };
                    this.$bus.$emit("openDialog", obj);
                  } else if (paramsObj.target.indexOf("window") !== -1) {
                    // 自定义按钮链接为这种的时候 cloudcc-page?id={!id}&pageApi=idtest&target=window
                    window.open(
                            routeData.href,
                            "newWindow",
                            "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                    );
                  } else if (paramsObj.target.indexOf("menu2") !== -1 && this.$store.state.navigationStyle) {
                    // 自定义按钮链接为这种的时候 cloudcc-page?id={!id}&pageApi=idtest&target=menu2
                    let menuName = item.label;
                    let menuId = Math.random().toString(16).slice(2);
                    // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
                    // 当前一级tab的对象
                    let currentLevelTab = {};
                    this.consoleData.data.list.forEach((leve1Item) => {
                      if (leve1Item.id == this.consoleData.data.level1Id) {
                        // 设置二级带单选中样式
                        leve1Item.level2Id = menuId;
                        // 当前一级tabid
                        currentLevelTab.id = leve1Item.id;
                        // 当前一级tabname
                        currentLevelTab.name = leve1Item.name;
                        // 当前一级菜单路由  有可能是通过ccdk生成的一级菜单
                        currentLevelTab.routerName = leve1Item.routerName || "";
                        currentLevelTab.routerPath = leve1Item.routerPath || "";
                        // 当前一级菜单路由参数
                        currentLevelTab.params = leve1Item.params || "";
                        currentLevelTab.query = leve1Item.query || "";
                        leve1Item.children = leve1Item.children ? leve1Item.children : [];
                        // 判断children里是否存在此点击项 不存在再添加避免重复添加
                        // 判断是否存在此点击项
                        let res = leve1Item.children?.some((cItem) => {
                          return cItem.id == menuId;
                        });
                        if (!res) {
                          // 如果不存在再push
                          leve1Item.children.push({
                            id: menuId,
                            name: menuName,
                            customPage: "1", // 自定义页面标识
                            pageApi: page,
                          });
                          // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则将当前的一级Tab添加到第一项
                          let flag = leve1Item.children.some((cItem) => {
                            return cItem.id == this.consoleData.data.level1Id;
                          });
                          if (!flag) {
                            leve1Item.children.unshift(currentLevelTab);
                          }
                        }
                        this.$router.push({
                          path: `/commonObjects/console-multi-screen/console-injection-component/${page}/${menuId}`,
                        });
                      }
                    });
                    this.$store.commit("setConsoleData", this.consoleData);
                    // 将数据保存到后端
                    consoleSaveTab({
                      appid: this.$store.state.home.homeApplicationId,
                      opentab: JSON.stringify(this.consoleData),
                    });
                  }
                } else {
                  // 例如自定义按钮链接为这种的时候 cloudcc-page?id={!id}&pageApi=idtest
                  // 没有target 覆盖
                  window.open(routeData.href, "_self");
                }
              } else {
                window.location.href = item.function_code;
              }
            } else {
              // 新窗口打开
              // window.open(item.function_code);
              if (item.function_code.substring(0, 7) == "cloudcc") {
                let id = item.function_code
                        .split("?")[1]
                        .split("&")[0]
                        .split("=")[1];
                let page = item.function_code
                        .split("?")[1]
                        .split("&")[1]
                        .split("=")[1];
                // 判断是否显示菜单栏
                let routeData = "";
                if (item.menubar == "show") {
                  // 展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent",
                    query: { id: id, page: page, button: "Home" },
                  });
                } else {
                  // 不展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: { id: id, page: page },
                  });
                }
                window.open(routeData.href, "_blank");
              } else {
                window.open(item.function_code);
              }
            }
          } else if (
            (item.event === "JavaScript" &&
              item.id !== "adc201300153022ukrN5" &&
              item.id !== "adc201596326104TADlh") ||
            item.event === "URL"
          ) {
            // JavaScript和URL类型暂时打开接口返回新页面处理
            // item.url != null && window.open(item.url)
            this.$message.warning(this.$i18n.t("lable.Please.switch"));
          }
        } else if (item.name === "unlock") {
          // 解除锁定
          this.dialogVisible = true;
          this.dialogAttr.title = this.$i18n.t("label.unlock");
          this.dialogAttr.content = this.$i18n.t(
            "vue_label_commonobjects_detail_unlockconfirm"
          ); //'确认解除锁定？'
        } else if (item.name === "Delete") {
          // 删除
          if (this.$store.state.userInfoObj.profileId === "aaa000001") {
            this.dialogVisible = true;
            this.dialogAttr.title = this.$i18n.t(
              "component_setup_tabs_label_delete"
            );

            if (this.objId === "cloudcc_project") {
              //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
              this.dialogAttr.content = this.$i18n.t(
                "label.projectManagement.delete.item"
              );
            } else if (this.objId === "cloudccMilestone") {
              //删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
              this.dialogAttr.content = this.$i18n.t(
                "label.projectManagement.delete.milestone"
              );
            } else if (this.objId === "cloudcc_problem") {
              //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
              this.dialogAttr.content = this.$i18n.t(
                "label.projectManagement.delete.question"
              );
            } else if (this.objId === "cloudccTask") {
              //删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？
              this.dialogAttr.content = this.$i18n.t(
                "label.projectManagement.delete.task"
              );
            } else if (this.objId === "cloudccSubtask") {
              // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
              this.dialogAttr.content = this.$i18n.t(
                "label.projectManagement.delete.subtasks"
              );
            } else {
              this.dialogAttr.content = this.$i18n.t(
                "label.weixin.confirm.delete"
              ); //'确认解除锁定？'
            }
          } else {
            this.noClick = flag === "noclick";
            detailApi.getPermissionById({ id: this.id }).then((res) => {
              this.noClick = false;
              if (res.data.isDelete && !res.data.isLocked) {
                this.dialogVisible = true;
                this.dialogAttr.title = this.$i18n.t(
                  "component_setup_tabs_label_delete"
                );
                if (this.objId === "cloudcc_project") {
                  //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
                  this.dialogAttr.content = this.$i18n.t(
                    "label.projectManagement.delete.item"
                  );
                } else if (this.objId === "cloudccMilestone") {
                  //删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
                  this.dialogAttr.content = this.$i18n.t(
                    "label.projectManagement.delete.milestone"
                  );
                } else if (this.objId === "cloudcc_problem") {
                  //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
                  this.dialogAttr.content = this.$i18n.t(
                    "label.projectManagement.delete.question"
                  );
                } else if (this.objId === "cloudccTask") {
                  // 删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？
                  this.dialogAttr.content = this.$i18n.t(
                    "label.projectManagement.delete.task"
                  );
                } else if (this.objId === "cloudccSubtask") {
                  // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
                  this.dialogAttr.content = this.$i18n.t(
                    "label.projectManagement.delete.subtasks"
                  );
                } else {
                  this.dialogAttr.content = this.$i18n.t(
                    "label.weixin.confirm.delete"
                  ); //'确认解除锁定？'
                }
              } else {
                // 该记录已锁定，请联系管理员
                this.$message(
                  this.$i18n.t(
                    "vue_label_commonobjects_detail_the_administrator"
                  )
                );
              }
            });
          }
        } else if (item.name === "Share") {
          //分享
          this.Getrules();
          this.$refs.isShare.open();
        } else if (item.name === "fabu") {
          //公告菜单下  标准按钮更改成 发布范围按钮 wzj  勿删勿删勿删
          this.isShowggRecord = true;
        } else if (item.name === "PrintQuote") {
          // 打印报价单
          this.previewPdf();
        } else if (
          item.name === "Lead_Convers" ||
          item.name === "Lead Convers"
        ) {
          // 转换
          this.$refs.Progress.Boxs();
        } else if (item.name === "fromopportunity") {
          this.$refs.synchro.gettitle(item);
        } else if (item.name === "startsyncing ") {
          this.$refs.synchro.gettitle(item);
        } else if (item.name === "Edit") {
          // 编辑
          this.noClick = flag === "noclick";
          detailApi.getPermissionById({ id: this.id }).then((res) => {
            this.noClick = false;
            if (res.data.isEdit) {
              // 如果是日程预约，不用标准弹窗。
              if (this.objId === "appointment") {
                this.$router.push({
                  path: "/scheduleappointment/bookingdetails",
                  query: {
                    id: this.id,
                  },
                });
              } else {
                if (
                  this.prefix === "021" ||
                  this.prefix === "011" ||
                  this.prefix === "032" ||
                  this.prefix === "092" ||
                  this.prefix === "002"
                ) {
                  this.relatedlistIds = this.$refs.listChild.relatedlistIds;
                }
                this.$nextTick(() => {
                  this.$refs.createEditObj.objId = this.objId;
                  this.$refs.createEditObj.edit();
                });
              }
            } else {
              let messageText = this.$i18n.t("label.notEdit.message");
              if (this.isLocked) {
                // 抱歉，记录锁定，您无权编辑该条数据，请联系系统管理员。
                messageText = this.$i18n.t("c1967");
              }
              // 抱歉，您无权编辑该条数据，请联系系统管理员。
              this.$message(messageText);
            }
          });
        } else if (item.name === "Clone") {
          // 复制
          this.copyType = item.name;
          this.$nextTick(() => {
            this.$refs.createEditObj.objId = this.objId;
            this.$refs.createEditObj.copy();
          });
        } else if (item.name === "Submit") {
          //提交
          this.$refs.listChild.openApproval(0);
        } else if (item.name === "Return Pool") {
          // 退回公海池
          this.seaShow = true;
          // location.reload()
          if (this.objId === "account") {
            // this.selMarketsea("account");
            this.valSea = "account";
          } else if (this.objId === "lead") {
            // this.selMarketsea("lead");
            this.valSea = "lead";
          }
        } else if (item.name === "Save and Sync to QB") {
          this.Synchronization();
        } else if (item.name === "Print Asset Code") {
          // 服务云资产对象下打印标签
          this.$refs.codeStyleDialog.openDialog(this.id);
        } else if (item.name === "QBtoCC") {
          this.QBmessCC = this.$i18n.t("front-QBtoCC-changing");
          this.QBtfCC = true;
          if (this.objectApi === "cloudccproceed") {
            this.noClick = flag === "noclick";
            detailApi.syncPaymentFromQB({ id: this.id }).then((res) => {
              this.noClick = false;
              this.QBtfCC = false;
              this.refreshAll();
              this.QBmessCC = "";
              if (res.result) {
                if (res.data == "") {
                  this.$message.success(
                    this.$i18n.t("label.projectManagement.update.succeeded")
                  );
                } else {
                  if (res.data.length > 0) {
                    let lastobj = res.data[res.data.length - 1];
                    let lastarr = lastobj.split(",");
                    let succmess = Number(
                      lastarr[0].substring(2, lastarr[0].length - 1)
                    );
                    let errmess = Number(
                      lastarr[1].substring(3, lastarr[0].length - 1)
                    );
                    if (succmess > 0 && errmess == 0) {
                      this.$message.success(
                        this.$i18n.t("label.projectManagement.update.succeeded")
                      );
                    } else {
                      this.$message.error(
                        this.$i18n.t("front-verkoop-bywerking-error")
                      );
                    }
                  }
                }
              }
            });
          } else if (this.objectApi === "Invoices") {
            this.noClick = flag === "noclick";
            detailApi.syncInvoicesFromQB({ id: this.id }).then((res) => {
              this.noClick = false;
              this.QBtfCC = false;
              this.refreshAll();
              this.QBmessCC = "";
              if (res.result) {
                if (res.data == "") {
                  this.$message.success(
                    this.$i18n.t("label.projectManagement.update.succeeded")
                  );
                } else {
                  if (res.data.length > 0) {
                    let lastobj = res.data[res.data.length - 1];
                    let lastarr = lastobj.split(",");
                    let succmess = Number(
                      lastarr[0].substring(2, lastarr[0].length - 1)
                    );
                    let errmess = Number(
                      lastarr[1].substring(3, lastarr[0].length - 1)
                    );
                    if (succmess > 0 && errmess == 0) {
                      this.$message.success(
                        this.$i18n.t("label.projectManagement.update.succeeded")
                      );
                    } else {
                      this.$message.error(
                        this.$i18n.t("front-verkoop-bywerking-error")
                      );
                    }
                  }
                }
              }
            });
          }
        } else if (item.name === "CCtoQB") {
          this.QBmessCC = this.$i18n.t("front-QBtoCC-sinchronies");
          this.QBtfCC = true;
          if (this.objectApi === "cloudccproceed") {
            this.noClick = flag === "noclick";
            detailApi.cloudccPaymentToQBPayment({ id: this.id }).then((res) => {
              this.noClick = false;
              this.QBtfCC = false;
              this.QBmessCC = "";
              if (res.result) {
                if (res.data == "") {
                  this.$message.success(
                    this.$i18n.t("label.companyprofile.syncsuccess")
                  );
                } else {
                  if (res.data.length > 0) {
                    let lastobj = res.data[res.data.length - 1];
                    let lastarr = lastobj.split(",");
                    let succmess = Number(
                      lastarr[0].substring(2, lastarr[0].length - 1)
                    );
                    let errmess = Number(
                      lastarr[1].substring(3, lastarr[0].length - 1)
                    );
                    if (succmess > 0 && errmess == 0) {
                      this.$message.success(
                        this.$i18n.t("label.companyprofile.syncsuccess")
                      );
                    } else {
                      this.$message.error(
                        this.$i18n.t("lable.Synchronization.failure")
                      );
                    }
                  }
                }
              }
            });
          } else if (this.objectApi === "Invoices") {
            this.noClick = flag === "noclick";
            detailApi
              .cloudccInvoicesToQBInvoices({ id: this.id })
              .then((res) => {
                this.noClick = false;
                this.QBtfCC = false;
                this.QBmessCC = "";
                if (res.result) {
                  if (res.data == "") {
                    this.$message.success(
                      this.$i18n.t("label.companyprofile.syncsuccess")
                    );
                  } else {
                    if (res.data.length > 0) {
                      let lastobj = res.data[res.data.length - 1];
                      let lastarr = lastobj.split(",");
                      let succmess = Number(
                        lastarr[0].substring(2, lastarr[0].length - 1)
                      );
                      let errmess = Number(
                        lastarr[1].substring(3, lastarr[0].length - 1)
                      );
                      if (succmess > 0 && errmess == 0) {
                        this.$message.success(
                          this.$i18n.t("label.companyprofile.syncsuccess")
                        );
                      } else {
                        this.$message.error(
                          this.$i18n.t("lable.Synchronization.failure")
                        );
                      }
                    }
                  }
                }
              });
          } else if (this.objectApi === "cloudccorder") {
            this.noClick = flag === "noclick";
            detailApi.cloudccOrderToQBEstimate({ id: this.id }).then((res) => {
              this.noClick = false;
              this.QBtfCC = false;
              this.QBmessCC = "";
              if (res.result) {
                if (res.data == "") {
                  this.$message.success(
                    this.$i18n.t("label.companyprofile.syncsuccess")
                  );
                } else {
                  if (res.data.length > 0) {
                    let lastobj = res.data[res.data.length - 1];
                    let lastarr = lastobj.split(",");
                    let succmess = Number(
                      lastarr[0].substring(2, lastarr[0].length - 1)
                    );
                    let errmess = Number(
                      lastarr[1].substring(3, lastarr[0].length - 1)
                    );
                    if (succmess > 0 && errmess == 0) {
                      this.$message.success(
                        this.$i18n.t("label.companyprofile.syncsuccess")
                      );
                    } else {
                      this.$message.error(
                        this.$i18n.t("lable.Synchronization.failure")
                      );
                    }
                  }
                }
              }
            });
          }
        }
        //查看资产BOM
        else if (item.name === "View Asset BOM") {
          this.routers();
          //复制资产BOM
        } else if (item.name === "Copy Asset BOM") {
          if (this.isOpenAssetBom) {
            this.handleAssetBOM("copy");
          } else {
            // this.$message.warning('请联系管理员，您不具有复制资产BOM的权限')
            this.$message.warning(this.$i18n.t("assetbom.copy.notpermission"));
          }
          //删除资产BOM
        } else if (item.name === "Delete Asset BOM") {
          if (this.isOpenAssetBom) {
            this.handleAssetBOM("del");
          } else {
            // this.$message.warning('请联系管理员，您不具有删除资产BOM的权限')
            this.$message.warning(
              this.$i18n.t("assetbom.delete.notpermission")
            );
          }
          //转移资产BOM
        } else if (item.name === "Transfer Asset BOM") {
          if (this.isOpenAssetBom) {
            this.$refs.assetsBOM.assetsTransfer();
          } else {
            // this.$message.warning('请联系管理员，您不具有转移资产BOM的权限')
            this.$message.warning(
              this.$i18n.t("assetbom.transferowner.notpermission")
            );
          }
          //迁移资产BOM
        } else if (item.name === "Move Asset BOM") {
          if (this.isOpenAssetBom) {
            this.$refs.assetsBOM.assetsMove();
          } else {
            // this.$message.warning('请联系管理员，您不具有迁移资产BOM的权限')
            this.$message.warning(
              this.$i18n.t("assetbom.transfer.notpermission")
            );
          }
          //订单产品
        } else if (item.name === "Order To Asset BOM") {
          this.$refs.assetsBOM.orderProduct(this.heightLight, this.layoutid);
          //产品
        } else if (item.name === "Product To Asset BOM") {
          this.$refs.assetsBOM.assetsProduct();
        } else if (item.name === "Delete Draft") {
          this.noClick = flag === "noclick";
          detailApi.getPermissionById({ id: this.id }).then((res) => {
            this.noClick = false;
            if (res.data.isDelete) {
              // 删除草稿
              this.dialogAssembly(this.id, "draftdel");
            } else {
              // 抱歉，您无权编辑该条数据，请联系系统管理员。
              this.$message(
                this.$i18n.t("vue_label_notice_nopermission_delete")
              );
            }
          });
        } else if (item.name === "Publish") {
          // 发布
          let params = {
            id: this.id,
          };
          this.noClick = flag === "noclick";
          detailApi.ismasterVersion(params).then((res) => {
            this.noClick = false;
            this.releasedialogVisible = true;
            this.affectTranslation = res.data.affectTranslation;
            this.markNewVersion = res.data.markNewVersion;
          });
        } else if (item.name === "Change Record Type") {
          this.$nextTick(() => {
            // 更改记录类型
            this.$refs.createEditObj.objId = this.objId;
            this.$refs.createEditObj.edit(
              {},
              this.$refs.listChild.konwrecordType
            );
          });
        } else if (item.name === "Edit as Draft") {
          // 作为草稿编辑
          this.dialogAssembly(this.id, "draftedit");
        } else if (item.name === "Archive") {
          // 归档
          this.dialogAssembly(this.id, "file");
        } else if (item.name === "Restore") {
          // 还原
          this.dialogAssembly(this.id, "recovery");
        } else if (item.name === "Synchronize Opportunity") {
          // 同步报价行至业务机会
          this.fullscreenLoading = true;
          this.noClick = flag === "noclick";
          detailApi
            .syncQuoteToOpportunity({ quoteid: this.id })
            .then(() => {
              this.noClick = false;
              this.$message.success(
                this.$i18n.t("label.companyprofile.syncsuccess")
              );
              this.fullscreenLoading = false;
            })
            .catch(() => {
              this.fullscreenLoading = false;
            });
        } else if (item.name === "Generate Order") {
          this.fullscreenLoading = true;
          this.noClick = flag === "noclick";
          detailApi.generateOrder({ id: this.id }).then((res) => {
            this.noClick = false;
            this.fullscreenLoading = false;
            if (res.returnCode === "1") {
              this.$router.push(`/commonObjects/detail/${res.data}/DETAIL`);
            } else {
              this.$message.error(res.returnInfo);
            }
          });
        } else if (
          item.name === "New Order" &&
          this.objectApi === "Opportunity"
        ) {
          // 业务机会新建订单 准备带值 客户名称  业务机会  价格手册  币种  创建记录时间
          this.getInitValue(
            [
              { oldField: "khmc", newField: "accountid" },
              { oldField: "currency", newField: "currency" },
              { oldField: "pricebook2id", newField: "pricebook2id" },
              { oldField: "name", newField: "opportunityid" },
            ],
            "specialOrder"
          );
        } else if (item.name === "New Order" && this.objectApi === "quote") {
          // 报价单详情页新建订单,客户名称、业务机会名称、报价单、客户折扣总计、折扣、客户折扣、税率、调整、总计、价格手册、币种字段带值
          this.orignObj = "quote";
          this.getInitValue([
            { oldField: "khmc", newField: "accountid" },
            { oldField: "ywjhmc", newField: "opportunityid" },
            { oldField: "name", newField: "quote" },
            {
              oldField: "totalcustomerdiscountamount",
              newField: "discountamount",
            },
            { oldField: "selectdiscount", newField: "selectdiscount" }, //主记录折扣方式回显
            { oldField: "customerdiscount", newField: "discount" },
            { oldField: "tax", newField: "tax" },
            { oldField: "ratetype", newField: "ratetype" },
            { oldField: "adjustment", newField: "adjustment" },
            { oldField: "totalamount", newField: "totalamount" },
            { oldField: "pricebook2id", newField: "pricebook2id" },
            { oldField: "currency", newField: "currency" },
          ]);
          this.getObjInfo({ id: "20210525cloudccorder" });
        } else if (
          item.name === "New Contract" &&
          this.objectApi === "cloudccorder"
        ) {
          this.getObjInfo({ id: "201100000004911Amewy" });
        } else if (
          item.name === "New Quote" &&
          this.objectApi === "Opportunity"
        ) {
          // 业务机会新建报价单带值  客户  币种 价格手册 业务机会
          this.getInitValue(
            [
              { oldField: "khmc", newField: "khmc" },
              { oldField: "currency", newField: "currency" },
              { oldField: "pricebook2id", newField: "pricebook2id" },
              { oldField: "name", newField: "ywjhmc" },
            ],
            "specialQuote"
          );
          this.getObjInfo({ id: "201100000006130OosPK" });
        } else if (
          item.name === "New Invoice" &&
          (this.objectApi === "Opportunity" || this.objectApi === "quote")
        ) {
          // 业务机会新建发票
          if (this.objectApi === "Opportunity") {
            // 从录入带值
            this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
              if (ele.objectInfo.objName === "opportunitypdt") {
                this.opportunitySecInfo = ele.objectInfo.data;
              }
            });
            // 业务机会新建发票按钮 带值问题
            this.getInitValue(
              [
                { oldField: "khmc", newField: "account_name" },
                { oldField: "currency", newField: "currency" },
                { oldField: "pricebook2id", newField: "pricebook2id" },
                { oldField: "name", newField: "opportunity" },
                { oldField: "cloudccorder", newField: "cloudccorder" },
              ],
              "specialInvoice"
            );
            // 获取 业务机会关联的客户的联系人
            this.noClick = flag === "noclick";
            detailApi
              .getRelatedAccountInfo({
                id: this.$route.params.id,
                keyword: "Opportunity",
              })
              .then((res) => {
                this.noClick = false;
                if (res?.data[0]?.contactId && res?.data[0]?.contactId !== "") {
                  this.opportunityInfo.contact_name = [
                    {
                      id: res.data[0].contactId,
                      value: res.data[0].contactName,
                    },
                  ];
                }
                this.getObjInfo({ id: "invoices" });
              });
          } else {
            // 报价单新建发票
            let res = true;
            //获取发票的相关列表
            this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
              if (ele.objectInfo.objName === "Invoices") {
                res = false;
                // 判断发票相关列表是否有记录
                if (ele.objectInfo.data.length > 0) {
                  this.showQuoteIntro = true;
                } else {
                  // 准备带值
                  this.quoteInitValue();
                }
              }
            });
            if (res) {
              // 准备带值
              this.quoteInitValue();
            }
          }
        } else if (item.name === "confirmReceipt") {
          this.invoicesDialogVisible = true;
        } else if (item.name === "Generate Contract") {
          this.fullscreenLoading = true;
          this.noClick = flag === "noclick";
          detailApi.generateContract({ id: this.id }).then((res) => {
            this.noClick = false;
            this.fullscreenLoading = false;
            if (res.returnCode === "1") {
              this.$router.push(`/commonObjects/detail/${res.data}/DETAIL`);
            } else {
              this.$message.error(res.returnInfo);
            }
          });
        } else if (
          item.name === "Generate Invoice" &&
          this.objId === "20210525cloudccorder"
        ) {
          // 订单新建发票
          // 获取发票对象信息
          this.noClick = flag === "noclick";
          this.getInitValue([
            { oldField: "discountamount", newField: "discountamount" },
            { oldField: "discount", newField: "discount" },
            { oldField: "tax", newField: "tax" },
            { oldField: "ratetype", newField: "ratetype" },
            { oldField: "adjustment", newField: "adjustment" },
          ]);
          detailApi.getObjectGetObjectInfo({ id: "invoices" }).then((res) => {
            this.noClick = false;
            this.orderNameRecive = res.data.labelname;
            let orderid = this.$route.params.id;
            // 判断全部订单是否为激活状态
            detailApi.isActiveOrder({ orderid }).then((res) => {
              if (res.result) {
                this.orderInfo.orderlabel = this.bigTitle[0].fieldValue
                  ? this.bigTitle[0].fieldValue
                  : "";
                this.activeList.map((item) => {
                  item.data.forEach((ditem) => {
                    if (ditem.left.apiname === "accountid") {
                      this.orderInfo.contract = ditem.left.data[0];
                    }
                    if (ditem.right.apiname === "accountid") {
                      this.orderInfo.contract = ditem.right.data[0];
                    }
                    // 价格手册
                    if (ditem.left.apiname === "pricebook2id") {
                      this.orderInfo.pricebook2id = ditem.left.data[0];
                    }
                    if (ditem.right.apiname === "pricebook2id") {
                      this.orderInfo.pricebook2id = ditem.right.data[0];
                    }
                    // billingaddress  账单地址
                    if (ditem.left.apiname === "billingaddress") {
                      this.orderInfo.billingaddress = ditem.left.children;
                    }
                    if (ditem.right.apiname === "billingaddress") {
                      this.orderInfo.billingaddress = ditem.right.children;
                    }
                    // shippingaddress  收货地址
                    if (ditem.left.apiname === "shippingaddress") {
                      this.orderInfo.shippingaddress = ditem.left.children;
                    }
                    if (ditem.right.apiname === "shippingaddress") {
                      this.orderInfo.shippingaddress = ditem.right.children;
                    }
                  });
                });
                this.$refs.orderCeateEditObj.add();
              }
            });
          });
        } else if (item.name === "Payment") {
          this.activeList.map((item) => {
            item.data.forEach((ditem) => {
              if (ditem.left.apiname === "account_name") {
                this.customId = ditem.left.data[0].id;
                this.invoiceInfo.customer = ditem.left.data[0];
              }
              if (ditem.right.apiname === "account_name") {
                this.customId = ditem.right.data[0].id;
                this.invoiceInfo.customer = ditem.right.data[0];
              }
              if (ditem.left.apiname === "tobepaidamount") {
                this.invoiceInfo.tobepaidamount = ditem.left.value;
              }
              if (ditem.right.apiname === "tobepaidamount") {
                this.invoiceInfo.tobepaidamount = ditem.right.value;
              }
              if (ditem.left.apiname === "contract") {
                this.invoiceInfo.contract = ditem.left.data[0];
              }
              if (ditem.right.apiname === "contract") {
                this.invoiceInfo.contract = ditem.right.data[0];
              }
              if (ditem.left.apiname === "cloudccorder") {
                this.invoiceInfo.order = ditem.left.data[0];
              }
              if (ditem.right.apiname === "cloudccorder") {
                this.invoiceInfo.order = ditem.right.data[0];
              }
            });
          });
          this.$refs.invoiceCeateEditObj.add();
        } else if (item.name === "Request Pay Online") {
          if (this.accountReceiver == "") {
            this.$message({
              type: "warning",
              message: this.$i18n.t("c131"),
            });
            return false;
          }
          //发起线上付款
          this.noClick = flag === "noclick";
          detailApi
            .getEmailObjectGetEmailPageInfo({ recordId: this.id })
            .then((email) => {
              this.noClick = false;
              if (email.result) {
                if (email.data.relatedActiveMailSetting) {
                  detailApi
                    .getMessageByNum({ invoiceid: this.id })
                    .then((detail) => {
                      if (detail.result) {
                        if (
                          detail.data.isemail == "0" &&
                          detail.data.status == "0"
                        ) {
                          if (
                            detail.data.total == null ||
                            Number(detail.data.total) <= 0
                          ) {
                            this.$message({
                              type: "warning",
                              message: this.$i18n.t("front-email-payment-tips"),
                            });
                            return false;
                          } else {
                            this.printType = "invoice";
                            this.emailIsOperation = false;
                            this.printTitle = this.$i18n.t(
                              "front-email-payment-Invoice-preview"
                            );
                            detailApi
                              .getEmailOnlinePaySetUp()
                              .then((status) => {
                                if (
                                  status.result &&
                                  this.invoiceCurrency != "CNY" &&
                                  status.data.ispaypol == "false" &&
                                  status.data.isvisa == "false"
                                ) {
                                  this.$message({
                                    type: "warning",
                                    message: this.$i18n.t(
                                      "front-email-payment-Invoice-Tips"
                                    ),
                                  });
                                  return false;
                                }
                                if (
                                  status.result &&
                                  status.data.isonlinepay == "true" &&
                                  (status.data.isalipay == "true" ||
                                    status.data.iswxpay == "true" ||
                                    status.data.ispaypol == "true" ||
                                    status.data.isvisa == "true")
                                ) {
                                  detailApi
                                    .getObjTemplateList({ objId: this.objId })
                                    .then((res) => {
                                      if (res.result) {
                                        let flg = true;
                                        res.data.forEach((element) => {
                                          if (element.isenable === "1") {
                                            flg = false;
                                          }
                                        });
                                        if (flg) {
                                          this.$message.error(
                                            this.$i18n.t(
                                              "front-email-payment-Invoice-preview-tips"
                                            )
                                          );
                                        } else {
                                          this.templateList = res.data;
                                          // 模板时间排序
                                          this.templateList.sort((a, b) => {
                                            let dateOne = new Date(
                                              a.lastmodifydate
                                            );
                                            let dateTow = new Date(
                                              b.lastmodifydate
                                            );
                                            if (dateOne === dateTow) {
                                              return 0;
                                            } else if (dateOne < dateTow) {
                                              return 1;
                                            } else {
                                              return -1;
                                            }
                                          });
                                          this.templateList.sort((a, b) => {
                                            if (b.isenable === "0") {
                                              return -1;
                                            } else {
                                              return 1;
                                            }
                                          });
                                          this.invoiceTemplateDialogVisible = true;
                                          this.closeEmailDialogFlg = 1;
                                          this.$refs.invoiceTemplate.init(
                                            this.fieldValue,
                                            this.templateList[0].templateid
                                          );
                                        }
                                      } else {
                                        this.$message({
                                          showClose: true,
                                          type: "error",
                                          message: res.returnInfo,
                                        });
                                      }
                                    });
                                } else {
                                  this.$message.warning(
                                    this.$i18n.t(
                                      "front-email-payment-Invoice-preview-opening"
                                    )
                                  );
                                }
                              });
                          }
                        } else {
                          this.$message({
                            type: "warning",
                            message: this.$i18n.t(
                              "front-invoice-online-pay-again"
                            ),
                          });
                        }
                      }
                    });
                } else {
                  this.$message.warning(
                    this.$i18n.t(
                      "vue_label_email_You_have_not_activated_the_mailbox"
                    )
                  ); //您还未启用邮箱
                }
              }
            });
        } else if (item.name === "Create Contact") {
          // 创建联系人
          this.ewobjectApi = "Contact";
          this.ewtabName = "联系人";
          this.ewprefix = "003";

          // 调接口把值带过去
          this.noClick = flag === "noclick";
          detailApi
            .epWechatToContactAndLead({
              id: this.id,
            })
            .then((res) => {
              this.noClick = false;
              if (100 == res.returnCode) {
                this.ewId = {
                  label: this.bigTitle && this.bigTitle[0].fieldValue,
                  id: this.$route.params.id,
                };
                this.ewData = res.data.contactshowinfo;

                this.$refs.ewcreateEditObj.add();
              }
            });
        } else if (item.name === "Create Lead") {
          // 创建潜在客户
          this.ewobjectApi = "cloudcclead";
          this.ewtabName = "潜在客户";
          this.ewprefix = "004";

          // 调接口把值带过去
          this.noClick = flag === "noclick";
          detailApi
            .epWechatToContactAndLead({
              id: this.id,
            })
            .then((res) => {
              this.noClick = false;
              if (100 == res.returnCode) {
                this.ewId = {
                  label: this.bigTitle && this.bigTitle[0].fieldValue,
                  id: this.$route.params.id,
                };
                this.ewData = res.data.leadshowinfo;

                this.$refs.ewcreateEditObj.add();
              }
            });
        } else if (item.name === "CreateWorkOrder") {
          // 工作订单
          this.prefixCustomer = "s21";
          this.objectApiCustomer = "WorkOrder";
          this.objectNameCustomer = this.$i18n.t(
            "lable.PowerProcess.Workorder"
          );
          this.$nextTick(() => {
            this.$refs.ceateEditObjForCustomer.add();
          });
        } else if (item.name === "CreateContact") {
          // 联系人 Contact 003 label.contact
          this.prefixCustomer = "003";
          this.objectApiCustomer = "Contact";
          this.objectNameCustomer = this.$i18n.t("label.contact");
          this.$nextTick(() => {
            this.$refs.ceateEditObjForCustomer.add();
          });
        } else if (item.name === "CreateCase") {
          // 个案 cloudcccase 008 label.case
          this.prefixCustomer = "008";
          this.objectApiCustomer = "cloudcccase";
          this.objectNameCustomer = this.$i18n.t("label.case");
          this.$nextTick(() => {
            this.$refs.ceateEditObjForCustomer.add();
          });
        } else if (item.name === "CreateAccount") {
          // 客户 Account 001 label.account
          this.prefixCustomer = "001";
          this.objectApiCustomer = "Account";
          this.objectNameCustomer = this.$i18n.t("label.account");
          this.$nextTick(() => {
            this.$refs.ceateEditObjForCustomer.add();
          });
        } else if (item.name === "generate") {
          this.getDetail();
          this.generate();
        } else if (item.name === "send") {
          //模板标准发送功能
          this.printType = "quotation";
          this.emailIsOperation = true;
          this.closeEmailDialogFlg = 2;
          this.$refs.selectquotation.getFilesdata();
        }
        // 指定在延迟开始前调用
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    ),
    // 发票页面同步至quickbooks
    async Synchronization() {
      var data = {
        accessToken: this.$CCDK.CCToken.getToken(),
        id: sessionStorage.getItem("id"),
      };
      const res = await detailApi.cloudccInvoicesToQBInvoices(data);
      if (res.result == true) {
        this.$message({
          type: "success",
          message: this.$i18n.t("label.companyprofile.syncsuccess"),
        });
        history.go(0);
      } else {
        this.$message({
          type: "warning",
          message: this.$i18n.t("lable.Synchronization.failure"),
        });
      }
    },
    // 控制公海池弹框隐藏
    clientPoolCancle() {
      this.seaShow = false;
    },
    // 新增/修改保存
    save() {
      //如果是保存刷新当前页面也用，跳转不需要
      if (this.copyType === "Clone") {
        this.$message.success(this.$i18n.t("label.search.saveok"));
      } else {
        // 局部刷新简要信息、相关列表和详细信息
        this.getBrief();
        this.$refs.listChild.init();
        this.$refs.Progress && this.$refs.Progress.$refs.Bars.getMilestones();
      }
    },
    // 打印
    printDetail() {
      localStorage.getItem("openWater") == "true";
      window.open(
        `#/print-detail/${this.id}/${this.prefix}`,
        "_parent - URL",
        "height=700, width=1300, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
    },
    closeDialog() {
      this.printVisible = false;
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
      // this.$router.push({
      //     path:
      //       `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
      //   });
    },
    //获取价格手册数据
    getPricebook(n) {
      this.pricebookArr = n;
    },
    previewPdf() {
      let token = this.$CCDK.CCToken.getToken();
      this.pdfViewerAttr.pdfUrl = `api/quote/previewPdf?accessToken=${token}&quoteId=${this.id}`;
      this.pdfViewerAttr.isShow = true;
    },
    // 保存到文件
    saveToAppendix() {
      detailApi
        .SavePdfToFile({
          quoteId: this.id,
        })
        .then((res) => {
          // 保存成功
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label.search.saveok"),
            });
          } else {
            // 保存失败
            this.$message({
              showClose: true,
              type: "error",
              message: this.$i18n.t("savefail"),
            });
          }
          this.pdfViewerAttr.isShow = false;
        });
    },
    closeViewerDialog() {
      this.pdfViewerAttr.isShow = false;
    },
    // 确认
    confirm() {
      this.dialogVisible = false;
      if (
        this.dialogAttr.title ===
        this.$i18n.t("component_setup_tabs_label_delete")
      ) {
        const params = {
          id: this.$route.params.id,
          objectApi: this.objectApi,
        };
        // 审批中的记录二次确认删除
        if (this.isConfirmDel) {
          params.isConfirmDel = "true";
        }
        detailApi.GetDelete(params).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            });
            // this.$router.go(-1);
            this.$router.push({
              path: `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
            });
          } else {
            // 审批中的记录需要二次确认才能删除,returnCode返回isConfirmDel时，说明时删除正在审批中的记录
            if (res.returnCode === "isConfirmDel") {
              // 二次确认弹窗
              this.isConfirmDel = "true";
              this.dialogVisible = true;
              this.dialogAttr.content = res.returnInfo;
              this.dialogAttr.title = this.$i18n.t(
                "component_setup_tabs_label_delete"
              );
            } else {
              this.isConfirmDel = "";
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          }
        });
      } else {
        // 解除锁定
        detailApi.recordUnLocked({ id: this.$route.params.id }).then(() => {
          // 解除锁定成功
          this.$message.success(
            this.$i18n.t("vue_label_commonobjects_detail_unlocksuccess")
          );
          this.refreshAll();
        });
      }
    },
    //相关列表定位
    getScroll(msg) {
      //进度条的高度
      let pbh = this.$refs.Progress ? this.$refs.Progress.$el.offsetHeight : 0;
      //边框高度
      let borderHeight = pbh ? 10 : 0;
      //市场活动下roi卡片高度
      let roiHeight = this.$refs.roiCard
        ? this.$refs.roiCard.$el.offsetHeight
        : 0;
      if (this.isShowType) {
        this.$refs.home.scrollTop =
          Number(msg) +
          this.briefHeight +
          Number(pbh) +
          borderHeight +
          Number(roiHeight);
      } else {
        this.$refs.home.scrollTop = Number(msg) + 98;
      }
    },
    guanbis() {
      if (this.isHeight === false) {
        this.height = "60px";
        this.isHeight = true;
        this.icons = "el-icon-d-arrow-left";
      } else {
        this.height = "100%";
        this.isHeight = false;
        this.icons = "el-icon-d-arrow-right";
      }
    },
    routers() {
      if (this.objectApi === "Account") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Account",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "Contact") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Contact",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "campaign") {
        // this.isCustomer = true
        // this.content = '市场层级结构'
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Campaign",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "Asset") {
        // 资产层级结构
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Asset",
            content: this.objectApi,
          },
        });
      } else {
        this.isCustomer = false;
      }
    },
    async Getrules() {
      let params = {
        id: this.$router.history.current.params.id,
      };
      let res = await detailApi.GetSharegetShareRuleList(params);
      this.shares = res.data;
    },
    //进度条需要
    shuaxin() {
      this.$refs.listChild.getFormAttr();
    },
    convert(val) {
      this.isconvert = val;
    },
    async getObjectGetObjectInfos(val) {
      let params = {
        id: val,
      };
      let res = await detailApi.getObjectGetObjectInfo(params);
      if (res.result) {
        this.isTag = res.data.istag;
      }
    },
    dataListes(val) {
      this.layoutid = val.layoutId;
      this.activeList = val.dataList;
      if (this.activeList) return;
      this.activeList[0].data &&
        this.activeList[0].data.forEach((res) => {
          if (res.left.apiname == "email") {
            this.invoiceReceiver = res.left.value;
            return;
          }
          if (res.right.apiname == "email") {
            this.invoiceReceiver = res.right.value;
            return;
          }
        });
      this.activeList[0].data &&
        this.activeList[0].data.forEach((res) => {
          if (res.left.apiname == "account_name") {
            this.accountReceiver = res.left.value;
            return;
          }
          if (res.right.apiname == "account_name") {
            this.accountReceiver = res.right.value;
            return;
          }
        });
      this.activeList[0].data &&
        this.activeList[0].data.forEach((res) => {
          if (res.left.apiname == "total") {
            this.invoiceCurrency = res.left.value.slice(0, 3);
            return;
          }
          if (res.right.apiname == "total") {
            this.invoiceCurrency = res.right.value.slice(0, 3);
            return;
          }
        });
    },
    // 关闭弹窗
    handleClose() {
      this.isConfirmDel = "";
      this.dialogVisible = false;
    },
    handleCloses() {
      this.isPartnerCloud = false;
    },
    /**
     * 服务控制台跳转方法
     * @param {Object} tabObj {id:对象id,value:对象名称}
     *
     */
    consoleJump(row) {
      let path = "";
      // 导航样式为控制台样式
      if (this.$store.state.navigationStyle) {
        path = `/commonObjects/console-multi-screen/console-detail/${row.fieldValueId}/DETAIL?dataId=${row.fieldValueId}`;
        // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
        // 当前一级tab的对象
        let currentLevelTab = {};
        this.consoleData.data.list.forEach((item) => {
          if (item.id == this.consoleData.data.level1Id) {
            // 设置二级带单选中样式
            item.level2Id = row.fieldValueId;
            // 当前一级tabid
            currentLevelTab.id = item.id;
            // 当前一级tabname
            currentLevelTab.name = item.name;
            // 当前一级菜单路由  有可能是通过ccdk生成的一级菜单
            currentLevelTab.routerName = item.routerName || "";
            currentLevelTab.routerPath = item.routerPath || "";
            // 当前一级菜单路由参数
            currentLevelTab.params = item.params || "";
            currentLevelTab.query = item.query || "";
            item.children = item.children ? item.children : [];
            // 判断children里是否存在此点击项 不存在再添加避免重复添加
            // 判断是否存在此点击项
            let res = item.children?.some((cItem) => {
              return cItem.id == row.fieldValueId;
            });
            if (!res) {
              // 如果不存在再push
              item.children.push({
                id: row.fieldValueId,
                name: row.fieldValue,
              });
              // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则添加到第一项
              let flag = item.children.some((cItem) => {
                return cItem.id == this.consoleData.data.level1Id;
              });
              if (!flag) {
                item.children.unshift(currentLevelTab);
              }
            }
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      } else {
        path = `/commonObjects/detail/${row.fieldValueId}/DETAIL`;
      }
      return path;
    },
    //详情简介跳转详情
    briefDetail(row) {
      let path = this.consoleJump(row);
      if (row.fieldName === "ownerid") {
        // 所有人非队列可跳转
        if (row.fieldValueId && row.fieldValueId.slice(0, 3) === "005") {
          this.$router.push({
            path: path,
            query: {
              viewId: "",
            },
          });
        } else {
          // 所有人队列不可跳转
          return false;
        }
      } else {
        detailApi
          .getPermissionById({
            id: row.fieldValueId,
          })
          .then((res) => {
            if (res.data.isQuery === true) {
              this.$router.push({
                path: path,
                query: {
                  viewId: "",
                },
              });
            } else {
              this.$message.warning(
                this.$i18n.t("vue_label_notice_nopermission_view")
                //"抱歉，您无权查看该条数据，请联系系统管理员。"
              );
            }
          });
      }
    },
    async getPartnerGetPartnerPermissions(val) {
      let params = {
        id: this.id,
      };
      let res = await detailApi.getPartnerGetPartnerPermission(params);
      if (res.result) {
        this.ismanagePartnerUser = res.data.managePartnerUser;
        this.ispartner = res.data.ispartner;
        this.userId = res.data.userId;
        if (this.ismanagePartnerUser) {
          let obj = {
            label: this.$i18n.t("label.query.detail.manageexuser"),
            name: "manageExternalUsers",
            //管理外部用户
          };
          this.moreButton.push(obj);
          this.unique(this.moreButton);
        }
        if (res.data.ispartner) {
          if (val === "Contact") {
            let buttons = [
              {
                label: this.$i18n.t("label.query.detail.viewpartneruser"),
                name: "viewPartners",
                // 查看合作伙伴用户
              },
              {
                label: this.$i18n.t("label.query.detail.editpartneruser"),
                name: "editPartner",
                // 编辑合作伙伴用户
              },
              {
                label: this.$i18n.t("label.query.detail.forbidpartneruser"),
                name: "disablePartner",
                // 禁用合作伙伴用户
              },
              {
                label: this.$i18n.t(
                  "vue_label_commonobjects_detail_cloud_as_a_user"
                ), //"以用户身份登陆伙伴云",
                name: "userPartner",
                // 以用户身份登陆伙伴云
              },
            ];
            this.partner = buttons;
          } else {
            this.partner = [
              {
                // 禁用合作伙伴
                label: this.$i18n.t("label.query.detail.closepartner"),
                name: "disablePartner",
              },
            ];
          }
        } else {
          if (val === "Account") {
            this.partner = [
              {
                // 启用合作伙伴用户
                label: this.$i18n.t("label.query.detail.openpartner"),
                name: "enablePartne",
              },
            ];
          } else {
            this.partner = [
              {
                // 启用合作伙伴用户
                label: this.$i18n.t("label.query.detail.openpartneruser"),
                name: "enablePartne",
              },
            ];
          }
        }
      }
    },
    external(val) {
      if (val.name === "enablePartne") {
        if (this.objectApi === "Account") {
          this.$confirm(
            // " 一旦将客户作为合作伙伴启用，它就会出现在isPartner字段上筛选过的报表和列表视图中。另外，合作伙伴用户可以从与此客户关联的联系人进行创建。是否确定？",
            this.$i18n.t("label.query.detail.openpartnermessage"),
            // "提示",
            this.$i18n.t("label.ccchat.message.new.error4"),
            {
              // 确定
              confirmButtonText: this.$i18n.t("label.ems.confirm"),
              // 取消
              cancelButtonText: this.$i18n.t("button_partner_cancel"),
              type: "warning",
            }
          ).then(() => {
            this.getPartnerPartnerEnableAccounts();
            this.freshen();
          });
        } else {
          this.getPartnerPartnerEnableContacts();
          this.freshen();
        }
      } else if (val.name === "disablePartner") {
        this.$confirm(
          this.$i18n.t("vue_label_commonobjects_detail_customers"),
          //"永久禁用外部用户的客户将禁止所有与客户关联的外部用户，同时从与他们关联的所有社区、组、小组、权限集和共享规则中删除。同时，将永久删除与该账户关联的角色和组，同时不会出现在回收站中。是否确定？",
          // "提示",
          this.$i18n.t("label.ccchat.message.new.error4"),
          {
            // 确定
            confirmButtonText: this.$i18n.t("label.ems.confirm"),
            // 取消
            cancelButtonText: this.$i18n.t("button_partner_cancel"),
            type: "warning",
          }
        ).then(() => {
          if (this.objectApi === "Account") {
            this.getPartnerPartnerEnableAccounts();
            this.freshen();
          } else if (this.objectApi === "Contact") {
            this.getPartnerPartnerEnableContacts();
            this.freshen();
          }
        });
      } else if (val.name === "viewPartners") {
        this.$router.push({
          path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
        });
      } else if (val.name === "editPartner") {
        this.$router.push({
          path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
          query: {
            edit: false,
          },
        });
      } else if (val.name === "userPartner") {
        this.getPartnerQueryAllPartnerByLogins();
      }
    },
    //客户下启用禁用合作伙伴
    async getPartnerPartnerEnableAccounts() {
      let params = {
        id: this.id,
      };
      await detailApi.getPartnerPartnerEnableAccount(params);
    },
    //联系人下启用禁用合作伙伴
    async getPartnerPartnerEnableContacts() {
      let params = {
        id: this.id,
      };
      let res = await detailApi.getPartnerPartnerEnableContact(params);
      if (res.result) {
        if (res.data.isfirst !== null && res.data.isfirst !== false) {
          this.dataList = res.data;
          this.$router.push({
            path: `/commonObjects/externalUsers/${this.id}/${this.prefix}/${this.objectApi}`,
            query: {
              newBuild: true,
            },
          });
          // this.$nextTick(() => {
          //   this.$refs.createEditObj.objId = this.objId
          //   this.$refs.createEditObj.contacts()
          // })
        } else {
          // 启用成功
          this.$message.success(this.$i18n.t("label.group.isusing.success"));
          this.freshen();
        }
      }
    },
    //获取所有能登录的伙伴云链接
    async getPartnerQueryAllPartnerByLogins() {
      let params = {
        id: this.id,
      };
      let res = await detailApi.getPartnerQueryAllPartnerByLogin(params);
      if (res.result) {
        this.tableData = res.data;
        this.isPartnerCloud = true;
      }
    },
    freshen() {
      this.getBrief();
      this.$refs.addlabel.tagApi(this.objId, this.objectApi);
      this.$refs.listChild.setRelationList();
      this.$refs.listChild.getFormAttr();
    },
    Refreshes(val) {
      if (val === "true") {
        this.freshen();
      }
    },
    //togantt
    toGannt(type) {
      if (type === "kanbanTaskPage") {
        // 修改看板任务本地存储值的问题，默认第一次进入看板是里程碑页面
        let a = "ffe20201207task11";
        localStorage.setItem("key", a);
      }
      this.$router.push({
        path: "/gantt",
        query: {
          proId: this.id,
          proName: this.fieldValue,
          isGantt: type,
        },
      });
    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].name == arr[j].name) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //回到顶部
    backtop() {
      document.getElementById("contentMainBox").scrollTop = 0;
    },
    // 复制、删除资产BOM
    handleAssetBOM(flag) {
      this.$refs.assetsBOM.handleAsset(flag);
    },
    //编辑报价行
    editProduct() {
      this.$router.push(`/quotation/editQuotation/${this.id}`);
    },
    //编辑阶梯折扣
    editDiscount() {
      this.$router.push({
        path: `/quotation/ladderDiscount`,
        query: {
          id: this.id,
        },
      });
    },
    typeedit() {
      this.centerDialogVisible = true;
      this.queryDataCategory();
    },
    thumbsUp(type) {
      if (type == "up") {
        this.thumbsUpvalue = this.thumbsUpvalue + 1;
      } else {
        this.thumbsDownvalue = this.thumbsDownvalue + 1;
      }
    },
    langechange(id) {
      this.$router.push({
        path: `/commonObjects/detail/${id}/DETAIL`,
      });
      this.findArticleIdTranslate();
    },

    //新建文章
    addarticle() {
      this.$refs.createEditObj.add();
    },
    // 两个数组中不同项输出
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 数组扁平化
    treeToList(tree) {
      var queen = [];
      var out = [];
      queen = queen.concat(tree);
      while (queen.length) {
        var first = queen.shift();
        if (first.children) {
          queen = queen.concat(first.children);
          delete first["children"];
        }
        out.push(first);
      }
      return out;
    },
    // 数组转成树状结构
    transformTree(list) {
      const tree = [];
      for (let i = 0, len = list.length; i < len; i++) {
        if (!list[i].pid) {
          const item = this.queryChildren(list[i], list);
          tree.push(item);
        }
      }
      return tree;
    },
    queryChildren(parent, list) {
      const children = [];
      for (let i = 0, len = list.length; i < len; i++) {
        if (list[i].pid === parent.id) {
          const item = this.queryChildren(list[i], list);
          children.push(item);
        }
      }
      if (children.length) {
        parent.children = children;
      }
      return parent;
    },
    // 知识文章分类保存
    checksure() {
      var arr = this.treeToList(this.$refs.tree.children);
      arr.map((o) => {
        return Object.assign(o, { categoryId: o.id });
      });
      let params = {
        dataCategoryId: JSON.stringify(arr),
        articleId: this.id,
      };

      detailApi.saveDataCategory(params).then(() => {
        this.$refs.tree.setCheckedKeys([]);
        this.fromData = [];
        this.centerDialogVisible = false;
        this.queryDataCategorySelection();
      });
    },
    //取消分类弹窗
    checkcancel() {
      this.$refs.tree.setCheckedKeys([]);
      this.fromData = [];
      this.centerDialogVisible = false;
    },
    // 查询已选择数据分类
    queryDataCategorySelection() {
      let params = {
        articleId: this.id,
      };
      detailApi.queryDataCategorySelection(params).then((res) => {
        this.data = res.data;
        this.dataNum = res.data.length;
        for (var i = 0; i < this.data.length; i++) {
          this.$set(this.data[i], "openBool", true);
        }
      });
    },

    // 查询数据分类和数据分类组
    queryDataCategory() {
      detailApi.queryDataCategory({ articleId: this.id }).then((res) => {
        this.fromData = res.data;
        var checkList = [];
        this.forEachObj({ children: res.data }, (item) => {
          if (item.selected === true) {
            checkList.push(item.id);
          }
        });
        this.$refs.tree.setCheckedKeys(checkList);
      });
    },

    // 遍历子节点
    forEachObj({ children }, func) {
      children?.forEach((item) => {
        func(item);
        this.forEachObj(item, func);
      });
    },

    // 知识文章分类选中操作
    handleCheckChange(data, node) {
      const checkBool = node.checkedKeys.includes(data.id);
      data.selected = checkBool;
      this.forEachObj(data, (item) => {
        this.$refs.tree.setChecked(item.id, false);
        item.selected = false;
        item.disabled = checkBool;
      });
    },
    // 改变添加属性的布尔值
    changeopenBool(val) {
      this.data[val].openBool = !this.data[val].openBool;
      if (this.data.every(this.checkOpen)) {
        this.allon = false;
      } else {
        this.allon = true;
      }
    },
    // 检测openBool函数
    checkOpen(val) {
      return val.openBool === true;
    },
    // 全部展开
    openBoolall() {
      this.allon = false;
      this.data.forEach((item) => {
        item.openBool = true;
      });
    },
    // 全部折叠
    closeBoolall() {
      this.allon = true;
      this.data.forEach((item) => {
        item.openBool = false;
      });
    },
    // 进度条格式化
    formatProgress() {
      return "";
    },
    //简介为url跳转
    jumpTo(url) {
      if (url.indexOf("http://") !== -1) {
        window.open(url);
      } else {
        window.open("http://" + url);
      }
    },
    lockBtn() {
      //判断是否有解除锁定按钮
      let isLock = this.buttonList.some((val) => {
        return val.name === "unlock";
      });
      //当锁定状态为true,并且有解除锁定按钮时才会有解除按钮功能
      if (this.isLocked && isLock) {
        // 解除锁定
        this.dialogVisible = true;
        this.dialogAttr.title = this.$i18n.t("label.unlock");
        this.dialogAttr.content = this.$i18n.t(
          "vue_label_commonobjects_detail_unlockconfirm"
        ); //'确认解除锁定？'
      } else {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label.you.do.not.have.permission.to.operate."), // '您没有权限进行操作'
        });
      }
    },
    // 关闭展示所有标签的弹窗
    handleCloseAllTags() {
      this.showAllTags = false;
    },
    /**
     * @description: 分屏视图切换数据刷新活动组件
     */

    multiTask() {
      this.$refs.activitys.init();
      this.getSetupInfo();
    },
  },
  watch: {
    realObjId(val) {
      // 控制甘特图按钮显隐
      if (val && val === "cloudcc_project") {
        this.isShowGanttButton = true;
      } else {
        this.isShowGanttButton = false;
      }
    },
    screenWidth: {
      immediate: true,
      handler(newValue) {
        if (!this.timer) {
          this.screenWidth = newValue;
          this.meunsFilter();
          this.timer = true;
          let that = this;
          setTimeout(function () {
            that.timer = false;
          }, 200);
        }
        this.$nextTick(() => {
          this.$refs.listChild?.setRelationNameWidth();
        });
      },
    },
    id(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getIsHasGroup();
        this.$CCDK.CCDetail.setDetailId(this.id) // 详情页记录ID   给自定义页面内嵌使用  id变化更新localStorage
      }
    },
    labelName() {},
    // 监听分屏视图数据变化
    dataId(nval) {
      this.id = nval;
      this.$refs.listChild.id = nval;
      this.init();
      // this.$refs.listChild.init();
    },
    tabName(nval) {
      // 联系人
      if (nval === this.$i18n.t("label_tabpage_contactsz")) {
        let obj = {
          objectApi: "Contact",
        };
        this.resetObjectInfo(obj);
      }
    },
    objId(val) {
      if (val !== "" && val !== undefined) {
        this.getObjectGetObjectInfos(val);
      }
      if (val === "task" || val === "event") {
        this.activeName = "second";
      } else if (this.isShowType === false) {
        this.activeName = "second";
      } else {
        this.activeName = "first";
      }
    },
    objectApi(val) {
      if (
        this.objectApi === "Invoices" ||
        this.objectApi === "cloudccproceed" ||
        this.objectApi === "cloudccorder"
      ) {
        this.startQB();
      }
      if (val === "Task" || val === "Event") {
        this.activeName = "second";
      } else if (this.isShowType === false) {
        this.activeName = "second";
      } else {
        this.activeName = "first";
      }
      // 公告菜单下  标准按钮更改成 发布范围按钮 wzj     勿删勿删勿删
      // if (val === 'Announcement') {
      //   this.moreButton.forEach((item) => {
      //     if (item.name === 'Share') {
      //       item.name = 'fabu'
      //       item.label = '发布范围'
      //       item.id = 'dfsgdgdfgdfg'
      //     }
      //   })
      //   this.showButton.forEach((item) => {
      //     if (item.name === 'Share') {
      //       item.name = 'fabu'
      //       item.label = '发布范围'
      //       item.id = 'dfsgdgdfgdfg'
      //     }
      //   })
      // }
    },
    activeName(val) {
      if (val === "first") {
        // this.$refs.activitys.init();
      } else if (val === "second" && this.isShowType === true) {
        this.getSetupInfo();
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    ...mapState(["consoleData"]),
    eventTap() {
      let str = "";
      if (this.objectApi === "Event") {
        str = this.$i18n.t("label.event");
      }
      return str;
    },
  },
  destroyed() {
    localStorage.removeItem("detailRecordId");
    this.$bus.$off("updateDetailRecordId");
  },
};
</script>

<style scoped lang="scss">
.noClick {
  pointer-events: none;
}
.downXiala {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  float: right;
}
.li {
  padding: 0px 10px !important;
}

.layoutSwitchBox {
  position: absolute;
  left: calc(66.7% - 26px);
  top: 0;
  text-align: center;
  // width: 16px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  z-index: 100;

  svg {
    width: 41px;
    height: 49px;
  }
}

::v-deep .el-dialog.qunce-dialog {
  padding: 20px;
  border-radius: 8px;
  .el-dialog__header {
    border-bottom: 1px solid #dcdcdc;
    padding: 0 0 10px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 900;
    }
  }
  .el-dialog__body {
    padding: 0;
    padding-top: 17px;
  }
  .el-button {
    margin-top: 35px;
    padding: 10px 15px;
  }
  .cancel {
    margin-left: calc(50% - 70px);
  }
}
.top {
  /* display: none; */
  width: 100%;
  height: 100%;
  background: #f2f2f5;
  padding: 10px 0px 0px 10px !important;
}
.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}
.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
  color: #3e3e3c;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.moreButtons li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
  color: #3e3e3c;
}
.moreButtons li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
<style scoped lang="scss">
.isEnableBox {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 400px;
}
::v-deep .dynamic {
  padding: 0 10px;
  .Post {
    margin-right: 0;
    margin-top: 20px;
  }
}

::v-deep .dynamic-public .detail-box {
  // padding: 10px 0px 13px;
  padding: 5px 0px 5px 0;
}
.home {
  height: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
  height: calc(100% - 10px);
  // text-align: center;
  // background: #fff;
  border-radius: 3px;

  .noPermissionBox {
    // width: 100%;
    text-align: center;
    position: absolute;
    top: calc(50% - 136px);
    left: calc(50% - 176.5px);
    svg {
      width: 353px;
      height: 194px;
      margin: 0 auto;
    }
    .noPermissionTip {
      margin: 10px 0;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .contentBox {
    position: sticky;
    left: 0px;
    right: 0px;
    z-index: 999;
    top: 0.1px;
    width: 100%;
    // margin-left: 10px;
    // margin-right: 10px;
    // width: calc(100% - 20px);
    // min-width: 1270px;
  }
  .contentBoxs {
    z-index: 999;
    top: 104px;
    // min-width: 1270px;
  }
}

::v-deep .el-card__body {
  padding: 0px;
}

.box-card {
  width: 100%;
  border: 1px solid rgba(221, 219, 218, 1);
  border-radius: 0 !important;
  ul {
    padding: 10px;
    box-sizing: border-box;

    li {
      font-size: 12px;
      line-height: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .label_span {
        width: 80px;
        display: inline-block;
        margin-right: 20px;
      }

      .color_s span {
        width: 20px;
        height: 20px;
        background: green;
        margin: 5px;
        display: inline-block;
      }
    }
  }

  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}

.box-card {
  width: 100%;
  border: 1px solid rgba(221, 219, 218, 1);
  ::v-deep .el-card__body {
    padding: 0;
  }
  border-radius: 0 !important;
  .introduce {
    width: 100%;
    overflow-x: hidden;
    height: auto;
    display: flex;
    .introduce_title {
      font-size: 12px;
      color: #3e3e3c;
      letter-spacing: 0;
      text-align: justify;
      line-height: 22px;
      min-width: 80px;
      display: inline-block;
      white-space: nowrap;
      svg {
        margin-left: 1px;
        margin-top: -2px;
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }

    .introduce_cet {
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      text-align: justify;
      line-height: 18px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
      ::v-deep .is-checked {
        .el-checkbox__input {
          .el-checkbox__inner {
            background-color: #006dcc !important;
            border-color: #006dcc !important;
          }
        }
      }
      ::v-deep .el-progress-bar {
        padding-right: 0;
        width: 50px;
        margin-right: 0;
      }
    }

    .briefDetail_s {
      color: #006dcc;
      cursor: pointer;
    }

    .introduce_content {
      float: left;
      margin: 10px 0 10px 35px;
      // min-width: 20px;
    }
  }
}

.guanbi {
  font-size: 16px;
  position: relative;
  top: 15px;
  left: 93%;
  // cursor: pointer;
  z-index: 1;
}
// .newDongTai {
//   ::v-deep .el-tabs__active-bar {
//     left: 10px;
//   }
// }

.left {
  width: calc(66.7% - 10px);
  margin: 10px 10px 10px 0;
  float: left;
  position: relative;
}

::v-deep .right {
  float: right;
  margin-top: 10px;
  width: 33.3%;
  position: relative;
}

::v-deep #activityAndDynamicPublics {
  .optiones {
    .el-tabs__item {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold;
      color: #080707;
      min-width: 70px;
      text-align: center;
      font-weight: bold;
    }

    .is-active {
      font-weight: bold;
    }

    .el-tabs__active-bar {
      background: #006dcc;
    }
  }
}

.box_top {
  background: #f3f2f2;
  border: 1px solid #dddbda;
  border-bottom: 1px solid rgba(221, 219, 218, 1);
  min-height: 75px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  .left {
    width: 7px;
    height: 12px;
    margin-left: 11px;
    margin-top: 20px;
    margin-right: 12px;
    float: left;
    cursor: pointer;
  }

  .box_header {
    float: left;
    width: 36px;
    height: 36px;
    margin-top: 10px;
  }

  .title {
    height: 36px;
    float: left;
    margin-top: 8px;
    margin-left: 8px;
    max-width: calc(100% - 585px);
    min-width: 25%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .title_top {
      height: 12px;
      font-size: 12px;
      color: #333333;
      line-height: 18px;
    }

    .title_box {
      max-width: 100%;
      height: 20px;
      line-height: 18px;
      display: flex;
      .title_main {
        font-size: 16px;
        font-weight: 600;
        color: #080707;
        margin-top: -3px;
        overflow: hidden;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 20px;
        line-height: 18px;
      }
      .lockedImage {
        min-width: 14px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .header_right {
    float: right;
    display: flex;
    padding-right: 10px;
    .see-view-btn {
      margin: 20px 10px 0 0;
      font-size: 12px;
      font-weight: 400;
      color: #393939;
    }
    .dayin {
      width: 28px;
      height: 28px;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 3px;
      cursor: pointer;
      margin: 16px 0 0 10px;
      svg {
        margin: 5px;
        width: 16px;
        height: 16px;
      }
    }
    ::v-deep .el-button-group {
      margin-top: 16px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      .moreButton {
        margin-bottom: 0;
        li {
          height: 30px;
          line-height: 30px;

          &:hover {
            color: #006dcc;
          }
        }
      }
      ::v-deep .button {
        height: 30px;
        text-align: center;
        padding: 0 15px;
        color: #006dcc;
        border: 1px solid #dedcda;
      }
    }
    .xiala {
      width: 28px;
      height: 28px;
      border: 1px solid #dedcda;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .daying {
      width: 28px;
      height: 28px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #ccc;
      position: relative;
      left: 24px;
    }
  }
  .el-tag {
    margin-right: 5px;
    height: 18px;
    padding: 0 4px;
    line-height: 18px;
    border: 0;
  }
  .label {
    margin-left: 40px;
    margin-bottom: 8px;
    margin-right: 40px;
    svg {
      float: left;
      width: 14px;
      display: inline-block;
      margin: 5px 5px 0 0;
    }
  }

  .tag {
    display: flex;
    align-items: center;
    float: left;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .link {
    min-width: 48px;
    font-size: 12px;

    font-weight: 400;
    color: rgba(0, 109, 204, 1);
    position: relative;
  }
  .detail-header-tag-box {
    display: flex;
    align-items: center;
    padding-top: 5px;
    .detail-header-tag-svg {
      margin: 0 10px 0 0;
    }
  }
}
::v-deep .el-card.is-always-shadow {
  box-shadow: none;
  border: 1px solid #dddbda;
}
::v-deep .partnercloud {
  .el-dialog__header {
    font-size: 14px;
    color: #080707;
  }
  .el-dialog__body {
    padding: 30px 36px 30px 33px;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px !important;
  background-color: #dedcda !important;
}
.backtop {
  width: 40px;
  height: 39px;
  position: fixed;
  bottom: 55px;
  right: -5px;
  cursor: pointer;
  // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.knowledge ::v-deep .el-dialog--center .el-dialog__body {
  min-height: 380px;
  max-height: 380px;
}
.QBslowCC {
  z-index: 999999;
  height: 40px;
  background: #fdf6ec;
  width: 24%;
  position: absolute;
  top: 2%;
  left: 38%;
  display: flex;
  justify-content: left;
  align-items: center;
  ::v-deep .el-button.is-loading:before {
    background: none;
  }
}
.rejection-box{
  ::v-deep .el-select{
    width:360px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-bottom:16px;
    .el-input__inner{
      height: 32px;
      line-height:32px;
    }
  }
  ::v-deep .el-textarea{
    width: 540px;
    height: 222px;
    background: #FAFAFA;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .el-textarea__inner{
      height: 222px;
    }
  }
}



</style>
<style>
.external {
  padding: 12px 0px 12px 0px !important;
}
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.type {
  display: flex;
}
.typetree {
  width: 80%;
  margin-left: 10%;
}
.dialog-type {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
}
.allTagDialog span {
  margin: 3px;
}
</style>
