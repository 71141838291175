<!--
 * @Author: 张亚欣
 * @Date: 2021-07-05 16:19:20
 * @LastEditTime: 2022-02-18 16:55:11
 * @LastEditors: Please set LastEditors
 * @Description: 股权穿透图
 * @FilePath: \lightning-web\src\views\commonObjects\components\EquityPenetrationRelationList.vue
-->
<template>
  <div class="relation-list-obj">
    <div class="tit">
      <div class="tit_s">
        <svg class="icon hometabIcon" aria-hidden="true">
          <use href="#icon-hometab"></use>
        </svg>
        <p>
          {{ relationName }}
        </p>
      </div>
      <el-button-group style="position: relative">
        <el-button
          class="button"
          size="small"
          v-if="isEdit"
          :style="{
            backgroundColor: '#fff',
            color: '#006dcc',
          }"
          style="position: relative"
          @click="manageEquityRelationships"
        >
          <span>{{ btnLabel }}</span>
        </el-button>
        <el-button
          class="button"
          size="small"
          :style="{
            backgroundColor: '#fff',
            color: '#006dcc',
          }"
          style="position: relative"
          @click="showInAllScreen"
        >
          <span>{{ $t("vue_label_fllscreen") }}</span>
        </el-button>
      </el-button-group>
    </div>

    <!-- 股权穿透图 -->
    <EquityPenetrationChart ref="equityPenetrationChart" />

    <!-- 管理股权关系 -->
    <el-dialog
      :title="manageEquityRelationshipDialog.title"
      :width="manageEquityRelationshipDialog.dialogWidth"
      :visible.sync="manageEquityRelationshipDialog.isShow"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="cancelManageEquityRelationshipDialog"
      top="10%"
    >
      <div class="tabName">
        <span
          :class="tabName === 'shareholder' ? 'curTab' : ''"
          style="margin-right: 40px"
          @click="handleClick('shareholder')"
          >{{ $t("vue_label_account_shareholder") }}</span
        >
        <span
          :class="tabName !== 'shareholder' ? 'curTab' : ''"
          @click="handleClick('jointStockCompany')"
          >{{ $t("vue_label_account_subsidiary") }}</span
        >
      </div>

      <!-- 股东 -->
      <div class="shareholderList" v-if="tabName === 'shareholder'">
        <div class="listHeaderBox">
          <span
            class="listHeader"
            style="width: 50%; border-right: 1px solid #dddbda"
          >
            <span style="color: red">*</span>{{ objectName }}
          </span>
          <span
            class="listHeader"
            style="width: 36%; border-right: 1px solid #dddbda"
          >
            <span style="color: red">*</span
            >{{ $t("vue_labe_account_shareholdingratio") }}
          </span>
          <!-- 操作   wj -->
          <span class="listHeader" style="width: calc(14% - 2px)">
            <span>{{$t("label.operate")}}</span>
          </span>
        </div>
        <ul>
          <li v-for="(shareholder, num) in shareholderList" :key="num">
            <div
              style="
                width: 50%;
                float: left;
                position: relative;
                line-height: 38px;
                padding-right: 4px;
              "
            >
              <EditableCell
                class="editableCell"
                input-type="remote-select"
                :line-index="num"
                :value="shareholder.stockHolderId"
                :options="shareholder.options || []"
                @remoteSearch="remoteSearch('shareholder', num)"
              ></EditableCell>
            </div>
            <div style="width: 36%; float: left; padding-left: 4px">
              <EditableCell
                class="editableCell"
                input-type="number"
                :line-index="num"
                :min="0"
                :max="100"
                field-name="shareholder"
                :value="shareholder.holdingRatio"
                :precision="2"
                @editValueChange="editValueChange"
              ></EditableCell>
            </div>
            <div class="operationButtonBox">
              <span
                class="operationButton"
                style="margin-right: 8px"
                @click="del('shareholder', num)"
                v-if="shareholderList.length > 1"
                >-</span
              >
              <span
                class="operationButton"
                v-if="
                  num === shareholderList.length - 1 &&
                  shareholderList.length < 8
                "
                @click="add('shareholder')"
                >+</span
              >
            </div>
          </li>
        </ul>
      </div>

      <!-- 子公司 -->
      <div class="shareholderList" v-if="tabName !== 'shareholder'">
        <div class="listHeaderBox">
          <span
            class="listHeader"
            style="width: 50%; border-right: 1px solid #dddbda"
          >
            <span style="color: red">*</span>{{ objectName }}
          </span>
          <span
            class="listHeader"
            style="width: 36%; border-right: 1px solid #dddbda"
          >
            <span style="color: red">*</span
            >{{ $t("vue_labe_account_shareholdingratio") }}
          </span>
           <!-- 操作   wj -->
          <span class="listHeader" style="width: calc(14% - 2px)">
            <span>{{$t("label.operate")}}</span>
          </span>
        </div>
        <ul>
          <li
            v-for="(jointStockCompany, num) in jointStockCompanyList"
            :key="num"
          >
            <div
              style="
                width: 50%;
                float: left;
                position: relative;
                line-height: 38px;
                padding-right: 4px;
              "
            >
              <EditableCell
                class="editableCell"
                input-type="remote-select"
                :line-index="num"
                :value="jointStockCompany.companyId"
                :options="jointStockCompany.options || []"
                @remoteSearch="remoteSearch('jointStockCompany', num)"
              ></EditableCell>
            </div>
            <div style="width: 36%; float: left; padding-left: 4px">
              <EditableCell
                class="editableCell"
                input-type="percent-number"
                :line-index="num"
                :min="0"
                :max="100"
                field-name="jointStockCompany"
                :value="jointStockCompany.holdingRatio"
                :precision="2"
                @editValueChange="editValueChange"
              ></EditableCell>
            </div>
            <div class="operationButtonBox">
              <span
                class="operationButton"
                style="margin-right: 8px"
                @click="del('jointStockCompany', num)"
                v-if="jointStockCompanyList.length > 1"
                >-</span
              >
              <span
                class="operationButton"
                v-if="
                  num === jointStockCompanyList.length - 1 &&
                  jointStockCompanyList.length < 8
                "
                @click="add('jointStockCompany')"
                >+</span
              >
            </div>
          </li>
        </ul>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
        style="text-align: right; margin-top: 10px"
      >
        <el-button size="mini" @click="cancelManageEquityRelationshipDialog">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="save">
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelect"
      />
    </el-dialog>
  </div>
</template>

<script>
import * as StockApi from './api.js'
import EquityPenetrationChart from '../../equityPenetrationChart/EquityPenetrationChart.vue'
import EditableCell from '@/components/TablePanel/EditableCell'

export default {
  name: 'EquityPenetrationRelationList',
  components: { EquityPenetrationChart, EditableCell },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    objectName: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      relationName: this.$i18n.t('vue_label_account_equityrelationship'),
      btnLabel: this.$i18n.t('vue_label_account_equitymanage'),
      manageEquityRelationshipDialog: {
        title: this.$i18n.t('vue_label_account_equitymanage'),
        dialogWidth: '45%',
        isShow: false,
      },
      tabName: 'shareholder',
      shareholderList: [
        {
          name: { value: '' },
          proportion: { value: '' },
        },
      ],
      jointStockCompanyList: [],
      showSearchTable: false,
      fieldId: '',
      relevantObjId: '',
      relevantPrefix: '',
      recordId: this.$route.params.id,
      lineIndex: '',
      remoteSearchFlag: '',
    }
  },
  mounted () { },
  created () {
    // 
  },
  methods: {
    // 管理股权关系
    manageEquityRelationships () {
      this.shareholderList = []
      this.jointStockCompanyList = []

      this.manageEquityRelationshipDialog.isShow = true
      let treeData = JSON.parse(
        JSON.stringify(this.$refs.equityPenetrationChart.tree)
      )
      // 股东
      if (JSON.stringify(treeData) !== '{}' && treeData.parents) {
        treeData.parents.forEach((item) => {
          this.shareholderList.push({
            stockHolderId: { value: item.id },
            holdingRatio: { value: item.Holding.toString() },
            options: [{ label: item.name, value: item.id }],
          })
        })
      } else {
        this.shareholderList = [
          {
            stockHolderId: { value: '' },
            holdingRatio: { value: '0' },
            options: [],
          },
        ]
      }
      // 控股公司
      if (JSON.stringify(treeData) !== '{}' && treeData.children) {
        treeData.children.forEach((item) => {
          this.jointStockCompanyList.push({
            companyId: { value: item.id },
            holdingRatio: { value: item.Holding.toString() },
            options: [{ label: item.name, value: item.id }],
          })
        })
      } else {
        this.jointStockCompanyList = [
          {
            companyId: { value: '' },
            holdingRatio: { value: '0' },
            options: [],
          },
        ]
      }
    },
    // 关闭管理股权关系弹窗
    cancelManageEquityRelationshipDialog () {
      this.tabName = 'shareholder'
      this.manageEquityRelationshipDialog.isShow = false
    },
    // 切换股东/参股公司
    handleClick (tabName) {
      this.tabName = tabName
    },
    // 确定
    save () {
      let stocks = []
      let validRequire = true
      // 必填校验 每行数据只要有一个不为空时,另一个也不能为空, 其中控股比例不能为0
      // 错误提示内容
      let errorText = this.$i18n.t('vue_label_lead_required_fields')
      // 股东数据必填验证
      this.shareholderList.length > 0 &&
        this.shareholderList.forEach((shareholder) => {
          if (
            (shareholder.stockHolderId.value  &&
              (!shareholder.holdingRatio.value  ||
                shareholder.holdingRatio.value === '0')) ||
            (shareholder.holdingRatio.value !== '0' &&
              shareholder.holdingRatio.value  &&
              !shareholder.stockHolderId.value )
          ) {
            validRequire = validRequire && false
            if(shareholder.holdingRatio.value === '0'){
              // 控股比例不能为0!
              errorText = 'c1980'
            }else {
              errorText = 'vue_label_lead_required_fields'
            }
          } else {
            stocks.push({
              stockHolderId: shareholder.stockHolderId.value,
              holdingRatio: shareholder.holdingRatio.value.toString(),
            })
          }
        })
      // 子公司数据必填验证
        this.jointStockCompanyList.length > 0 &&
        this.jointStockCompanyList.forEach((jointStockCompany) => {
          if (
            (jointStockCompany.companyId.value &&
              (!jointStockCompany.holdingRatio.value ||
                jointStockCompany.holdingRatio.value === '0')) ||
            (jointStockCompany.holdingRatio.value !== '0' &&
              jointStockCompany.holdingRatio.value  &&
              !jointStockCompany.companyId.value )
          ) {
            validRequire =  false
            if(jointStockCompany.holdingRatio.value === '0'){
              // 控股比例不能为0!
              errorText = 'c1980'
            }else {
              errorText = 'vue_label_lead_required_fields'
            }
          } else {
            stocks.push({
              companyId: jointStockCompany.companyId.value,
              holdingRatio: jointStockCompany.holdingRatio.value.toString(),
            })
          }
        })

      if (!validRequire) {
        this.$message.warning(this.$i18n.t(errorText))
      } else {
        StockApi.save({
          id: this.recordId,
          stocks: JSON.stringify(stocks),
        }).then(() => {
          // 刷新股权穿透图
          this.$refs.equityPenetrationChart.getTreeData()
          this.cancelManageEquityRelationshipDialog()
        })
      }
    },
    /**
     * 查找/查找多选
     * flag: 股东还是控股公司
     * num: 所在行数
     */
    remoteSearch (flag, num) {
      // this.entityFieldName = entityFieldName
      // this.fieldName = item.name
      // this.rowIndex = num + 1

      this.lineIndex = num
      this.remoteSearchFlag = flag
      this.fieldId = 'ffe2011000033512VJnE'
      this.relevantObjId = 'account'
      this.relevantPrefix = '001'
      this.showSearchTable = true
      // this.checked = item.type === 'remote-multi-select' ? true : false

      this.$nextTick(() => {
        this.$refs.searchTable.page = 1
        this.$refs.searchTable.pageObj.dataList = []
        this.$refs.searchTable.searchConditions = ''
        this.$refs.searchTable.init()
      })
    },
    // 改变查找/查找多选的值
    changeSelect (row) {
      // 股东与子公司不可以相同
      if (row.data.id === this.recordId) {
        this.$message.warning(this.$i18n.t('label_relation_shareholder'))
      } else {
        // 股东
        if (this.remoteSearchFlag === 'shareholder') {
          let dataList = this.shareholderList.find((item) => {
            return item.stockHolderId.value === row.data.id
          })
          if (dataList === undefined) {
            this.shareholderList[this.lineIndex].stockHolderId = {
              value: row.data.id,
            }
            this.shareholderList[this.lineIndex].options = [
              {
                label: row.data.name,
                value: row.data.id,
              },
            ]
          } else {
            this.$message.warning(
              this.$i18n.t('vue_label_normal_notice_repeated')
            )
          }
        } else {
          let dataList = this.jointStockCompanyList.find((item) => {
            return item.companyId.value === row.data.id
          })
          if (dataList === undefined) {
            this.jointStockCompanyList[this.lineIndex].companyId = {
              value: row.data.id,
            }
            this.jointStockCompanyList[this.lineIndex].options = [
              {
                label: row.data.name,
                value: row.data.id,
              },
            ]
          } else {
            this.$message.warning(
              this.$i18n.t('vue_label_normal_notice_repeated')
            )
          }
        }
        this.showSearchTable = false
      }
    },
    // 编辑值改变
    editValueChange (val, lineIndex, field) {
      if(!val && val !== 0) return 
      if (field === 'shareholder') {
        this.shareholderList[lineIndex].holdingRatio = {
          value: val.toString(),
        }
      } else {
        this.jointStockCompanyList[lineIndex].holdingRatio = {
          value: val.toString(),
        }
      }
    },
    // 删除一行
    del (list, num) {
      //股东
      if (list === 'shareholder') {
        this.shareholderList.splice(num, 1)
      } else {
        this.jointStockCompanyList.splice(num, 1)
      }
    },
    // 增加一行
    add (list) {
      if (list === 'shareholder') {
        this.shareholderList.push({
          stockHolderId: { value: '' },
          holdingRatio: { value: '0' },
          options: [],
        })
      } else {
        this.jointStockCompanyList.push({
          companyId: { value: '' },
          holdingRatio: { value: '0' },
          options: [],
        })
      }
    },
    // 全屏查看
    showInAllScreen () {
      this.$router.push(`/EquityPenetrationRelation/${this.recordId}`)
    },
  },
  watch: {
    $route: {
      handler (val) {
        this.recordId = val.params.id
        this.$refs.equityPenetrationChart.recordId = val.params.id
        this.$nextTick(() => {
          this.$refs.equityPenetrationChart.getTreeData()
        })
      },
      // 深度观察监听
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
.tit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 10px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;

  .hometabIcon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }

  .upload-demo {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .el-upload .el-upload--text {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }
  .tit_s {
    display: flex;
    img {
      margin-right: 7px;
      //   float: left;
      width: 20px;
      height: 20px;
    }
    p {
      margin: 0;
      //   float: right;
      height: 20px;
      font-size: 12px;
      color: #080707;
      line-height: 20px;
    }
    .hover_p:hover {
      border-bottom: 1px solid #006dcc;
      cursor: pointer;
      color: #006dcc;
    }
  }
  .xiala {
    width: 28px;
    height: 28px;
    float: right;
    text-align: center;
    position: relative;
    img {
      cursor: pointer;
    }
    li {
      cursor: pointer;
    }
  }
  .button {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}

.tabName {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 20px;

  .curTab {
    border-bottom: 2px solid #006dcc;
  }

  span {
    cursor: pointer;
    padding: 0 1px;
  }
}

.listHeaderBox {
  font-size: 12px;
  border: 1px solid #dddbda;
  border-radius: 3px;
  // text-align: center;

  .listHeader {
    height: 32px;
    line-height: 32px;
    display: inline-block;
    span {
      display: inline-block;     //wj
      margin-left: 6px;
    }
  }
}

.editableCell {
  height: 40px;
}

::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 100%;
  padding: 0 15px;
}

::v-deep .el-input-number {      //wj
  width: 100%;
  line-height: 40px;
}
::v-deep .remoteBtn1 {
  top: 0;
}
.operationButtonBox {
  width: 14%;
  height: 40px;
  float: left;
  padding-left: 18px;
  font-size: 14px;
  line-height: 40px;

  .operationButton {
    cursor: pointer;
    border: 1px solid #080707;
    width: 13px;
    height: 13px;
    display: inline-block;
    border-radius: 50%;
    line-height: 10px;
    padding: 0;
    text-align: center;
  }
}
</style>
