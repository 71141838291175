<!--
 * @Author: zhangyutong
 * @Date: 2021-11-18 14:30:31
 * @LastEditTime: 2022-06-28 10:37:34
 * @LastEditors: 张亚欣 18911148524@163.com
 * @FilePath: \lightning-web\src\views\contract\contractSign.vue
-->
<!--  -->
<template>
  <div class="">
    <el-dialog
      :title="$t('c777')"
      ref="priceItem"
      :visible.sync="contractSign"
      width="1300px"
      top="10%"
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <el-form :model="dialogData" ref="ruleForm">
        <div class="top">
          <div class="baseTitle">
            <span class="bule"></span>{{ $t("page.layout.binfo") }}
          </div>
          <!-- 基本信息input -->
          <div class="baseInput">
            <div class="inputCell">
              <span class="secTitle">{{ $t("c778") }}</span>
              <el-input
                style="width: 450px; margin-left: 10px"
                :disabled="true"
                v-model="fadadaDetailData.company"
              ></el-input>
            </div>
            <div class="inputCell">
              <!-- 签署截止日期 -->
              <span class="secTitle">{{ $t("c779") }}</span>
              <el-date-picker
                v-model="expirationTime"
                style="width: 450px; margin-left: 10px"
                type="date"
                :placeholder="$t('label_tabpage_selectdate')"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <!-- 流程签署 -->
          <div class="panTitle">
            <span class="bule"></span>
            <!-- 签署流程 -->
            {{ $t("c780") }}
            <!-- <span class="solt" @click="soltSet">顺序设置</span> -->
          </div>
          <!-- 提示 -->
          <div class="tips">
            <svg
              class="active-template-item-right-icon"
              aria-hidden="true"
              style="width: 15px; height: 10px"
            >
              <use href="#icon-prompt1"></use>
            </svg>
            <!-- 企业：请输入工商营业执照上的企业名称。签署时将会进行校验。 -->
            <span> {{ $t("c781") }}</span>
          </div>
          <!-- 表头 -->
          <div class="labelHead">
            <div class="labelLeft"></div>
            <div class="labelRight">
              <span style="left: 142px">{{ $t("c753") }}</span>
              <span style="left: 692px">{{ $t("c782") }}</span>
              <span style="left: 918px">{{ $t("c783") }}</span>
            </div>
          </div>
          <!-- 主要信息 企业卡片-->
          <el-form-item>
            <div class="main" v-for="(item, index) in corpData" :key="index">
              <div class="mainLeft" :style="'height:128px'">
                <div class="circle"></div>
              </div>
              <div class="mainRight">
                <div class="logo">
                  <svg
                    class="active-template-item-right-icon"
                    aria-hidden="true"
                    style="width: 20px; height: 15px"
                  >
                    <use href="#icon-qiye"></use>
                  </svg>
                  <el-tooltip
                    popper-class="my-tooltip"
                    effect="dark"
                    :content="item.actorId"
                    placement="top-start"
                  >
                    <span>{{ item.actorId }}</span>
                  </el-tooltip>
                </div>
                <div class="contant">
                  <div class="first">
                    <el-input
                      :placeholder="$t('c784')"
                      size="small"
                      v-model="item.remark"
                      class="businessInput"
                    >
                    </el-input>
                    <i
                      @click="getContact('card1', index)"
                      class="el-icon-search iconSearch"
                    ></i>
                  </div>
                  <div class="second">
                    <el-input
                      :placeholder="$t('c785')"
                      size="small"
                      v-model="item.name"
                      class="samllInput mr-10 mt-20"
                    >
                    </el-input>
                    <el-input
                      :placeholder="$t('c786')"
                      size="small"
                      v-model="item.phone"
                      class="samllInput mr-10 mt-20"
                    >
                    </el-input>
                  </div>
                </div>
                <div class="join" style="margin: auto 23px">
                  <div
                    :class="[
                      item.actorType == 'fillActor'
                        ? 'checked_fill'
                        : item.actorType == 'fillActorAndSignActor'
                        ? 'checked_fill'
                        : 'fill',
                    ]"
                  >
                    {{ $t("c754") }}
                  </div>
                  <div
                    :class="[
                      item.actorType == 'signActor'
                        ? 'checked_fill'
                        : item.actorType == 'fillActorAndSignActor'
                        ? 'checked_fill'
                        : 'fill',
                    ]"
                  >
                    {{ $t("c761") }}
                  </div>
                </div>
                <div class="res">
                  <el-checkbox-group
                    v-model="item.signerSignMethod"
                    size="mini"
                    style="width: 130px"
                  >
                    <el-checkbox label="unlimited" disabled>{{
                      $t("c787")
                    }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- 个人 -->
          <el-form-item>
            <div class="main" v-for="(item, index) in personData" :key="index">
              <div class="mainLeft" :style="'height:128px'">
                <div class="circle"></div>
              </div>
              <div class="mainRight">
                <div class="logo">
                  <svg
                    class="active-template-item-right-icon"
                    aria-hidden="true"
                    style="width: 20px; height: 15px"
                  >
                    <use href="#icon-geren"></use>
                  </svg>
                  <el-tooltip
                    popper-class="my-tooltip"
                    effect="dark"
                    :content="item.actorId"
                    placement="top-start"
                  >
                    <span>{{ item.actorId }}</span>
                  </el-tooltip>
                </div>
                <div class="contant">
                  <div class="second" style="margin-top: 28px">
                    <el-input
                      :placeholder="$t('c785')"
                      size="small"
                      v-model="item.name"
                      class="samllInput mr-10 mt-20"
                    >
                    </el-input>
                    <el-input
                      :placeholder="$t('c786')"
                      size="small"
                      v-model="item.phone"
                      class="samllInput mr-10 mt-20"
                    >
                    </el-input>
                    <i
                      @click="getContact('card2', index)"
                      class="el-icon-search iconSearch"
                    ></i>
                  </div>
                </div>
                <div class="join" style="margin: auto 23px">
                  <div
                    :class="[
                      item.actorType == 'fillActor'
                        ? 'checked_fill'
                        : item.actorType == 'fillActorAndSignActor'
                        ? 'checked_fill'
                        : 'fill',
                    ]"
                  >
                    {{ $t("c754") }}
                  </div>
                  <div
                    :class="[
                      item.actorType == 'signActor'
                        ? 'checked_fill'
                        : item.actorType == 'fillActorAndSignActor'
                        ? 'checked_fill'
                        : 'fill',
                    ]"
                  >
                    {{ $t("c761") }}
                  </div>
                </div>
                <div class="res">
                  <el-radio-group
                    v-model="item.signerSignMethod"
                    style="width: 130px"
                    disabled
                  >
                    <el-radio label="unlimited">{{ $t("c789") }}</el-radio>
                    <el-radio label="hand_write" style="margin: 10px 0">{{
                      $t("c790")
                    }}</el-radio>
                    <el-radio label="standard">{{ $t("c791") }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- 底部 -->
          <div class="buttomBox">
            <div></div>
            <el-button
              type="primary"
              size="small"
              class="goContract"
              @click="contractSignVisibleMe"
              :loading="contractSignLoading"
              >{{ $t("c792") }}</el-button
            >
          </div>
        </div>
      </el-form>
    </el-dialog>
    <sequence-setting
      :dialogVisible="soltSetVisble"
      @closeSequence="closeSequence"
      :card1="card1"
      :card2="card2"
    ></sequence-setting>
    <!-- 联系人弹框 -->
    <add-contact
      ref="addContact"
      @closeContact="closeContact"
      @addContacts="addContacts"
    ></add-contact>
  </div>
</template>

<script>
import sequenceSetting from "@/views/contract/components/sequenceSetting.vue";
import addContact from "@/views/contract/components/addContact.vue";
import * as docusignApi from "./api.js";
export default {
  components: { sequenceSetting, addContact },
  props: {
    contractSignVisible: {
      type: Boolean,
      default: false,
    },
    templateData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      contractSign: false,
      select1: "",
      fillInOrder: true,
      signInOrder: true,
      select2: "",
      //顺序弹框是否显示
      soltSetVisble: false,
      //选择联系人
      dialogVisible: false,
      dialogData: {
        addData: [], //联系人
        type: [], //参与方式
        data1: "", //签署要求
      }, //弹框内数据
      card1Index: "",
      card2Index: "",
      cardFlag: "",
      fadadaDetailData: {},
      expirationTime: "", //合同过期时间
      contractDetailData: "", //合同信息
      urlObj: {}, //发起签署跳转链接
      signId: "", //id
      corpData: [], //企业
      personData: [], //个人
      contractSignLoading: false,
      checkList: [this.$i18n.t("c787")],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      }, //日期只能选择今天之后的
    };
  },
  mounted() {
    this.getDetail();
    this.initiate();
    // 获取选中的签署方信息
    this.$bus.$on("setTemplateData", this.ebFn);
  },
  beforeDestroy(){
    this.$bus.$off("setTemplateData", this.ebFn);
  },
   watch: {
    contractSignVisible: {
      handler(nval) {
        this.contractSign = nval;
      },
      immediate: true,
    },
  },
  //方法集合
  methods: {
    ebFn(target){
      // 填写的签署方式
      this.corpData = [];
      this.personData = [];
      this.fillInOrder = target.fillInOrder;
      this.signInOrder = target.signInOrder;
      target.actors.forEach((item) => {
        if (item.actorIdentType == "corp") {
          this.corpData.push(item);
        } else {
          this.personData.push(item);
        }
      });
      this.getParticipants();
    },
    closeContact() {
      this.$refs.addContact.dialogVisible = false;
    },
    closeSequence() {
      this.soltSetVisble = false;
    },
    //参与方信息回填
    async getParticipants() {
      let res = await docusignApi.getParticipantsInfo({
        contractId: this.$route.params.id,
      });
      this.fadadaDetailData = res.data;
      this.$set(this.corpData[0], "remark", res.data.company);
      this.$set(this.corpData[0], "name", res.data.gsqzr);
      this.$set(this.corpData[0], "phone", res.data.gsqzrEmail);
      if (this.corpData.length > 1) {
        this.$set(this.corpData[1], "remark", res.data.khmc);
        this.$set(this.corpData[1], "name", res.data.khqzr);
        this.$set(this.corpData[1], "phone", res.data.khqzrEmail);
      }
    },
    // 选中的联系人数据
    addContacts() {
      // 企业卡片
      if (this.cardFlag == "card1") {
        this.$set(
          this.corpData[this.card1Index],
          "phone",
          this.$children[2].addData.phone
        );
        this.$set(
          this.corpData[this.card1Index],
          "name",
          this.$children[2].addData.name
        );
      } else if (this.cardFlag == "card2") {
        //个人卡片
        this.$set(
          this.personData[this.card2Index],
          "phone",
          this.$children[2].addData.phone
        );
        this.$set(
          this.personData[this.card2Index],
          "name",
          this.$children[2].addData.name
        );
      }
    },
    //获取联系人
    getContact(val, index) {
      this.$refs.addContact.dialogVisible = true;
      this.$refs.addContact.queryContact();
      // 企业卡片选择联系人
      if (val == "card1") {
        this.card1Index = index;
        this.cardFlag = "card1";
      } else if (val == "card2") {
        //个人卡片
        this.card2Index = index;
        this.cardFlag = "card2";
      }
    },

    async getDetail() {
      let data = {
        id: this.$route.params.id,
        objectApi: "",
        operation: "DETAIL",
        recordType: "",
      };
      let res = await docusignApi.getDetail(data);
      if (res.data.dataList[0].data[0].right.value != "") {
        this.contractDetailData = res.data.dataList[0].data[0].right.value;
      } else {
        this.contractDetailData = res.data.dataList[0].data[0].left.value;
      }
    },
    initiate() {
      if (this.$route.query.signTaskId) {
        docusignApi
          .initiate({
            contractId: this.$route.params.id,
            signTaskId: this.$route.query.signTaskId,
          })
          .then(() => {});
      }
    },
    //确认发起
    contractSignVisibleMe() {
      this.contractSignLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //发起文档模板签署
          // 签署模板
          let fillArr = [], //填写
            signArr = []; //签署
          let listData = [...this.corpData, ...this.personData];
          //企业
          listData.forEach((item) => {
            if (
              item.actorType == "fillActor" ||
              item.actorType == "fillActorAndSignActor"
            ) {
              fillArr.push(item);
            }
            if (
              item.actorType == "signActor" ||
              item.actorType == "fillActorAndSignActor"
            ) {
              signArr.push(item);
            }
          });
          let fillActors = [];
          //地址字段为空数据总和
          let emptyAddress = 0;
          fillArr.forEach((item) => {
            item.name = item.name == null ? "" : item.name;
            fillActors.push({
              actorId: item.actorId,
              actorIdentType: item.actorIdentType,
              notifyAddress: item.phone, //手机号邮箱
              corpName: item.remark, //企业名称
              userName: item.name, //个人用户姓名
              signerSignMethod: item.signerSignMethod, //签署要求
            });
            if (item.phone == "") {
              emptyAddress = emptyAddress + 1;
            }
          });
          let signActors = [];
          signArr.forEach((item) => {
            item.name = item.name == null ? "" : item.name;
            signActors.push({
              actorId: item.actorId,
              actorIdentType: item.actorIdentType,
              notifyAddress: item.phone, //手机号邮箱
              corpName: item.remark, //企业名称
              userName: item.name, //个人用户姓名
              signerSignMethod: item.signerSignMethod, //签署要求
            });
            if (item.phone == "") {
              emptyAddress = emptyAddress + 1;
            }
          });
          let data = {
            contractId: this.$route.params.id,
            signTaskSubject: this.contractDetailData,
            expiresTime: this.expirationTime
              ? `${new Date(this.expirationTime).getTime()}`
              : "",
            redirectUrl: window.location.href,
            signTemplateId: this.templateData.signTemplateId,
            fillActors: fillActors,
            fillInOrder: this.fillInOrder,
            signActors: signActors,
            signInOrder: this.signInOrder,
          };
          let noEmailOrPhohe = false;
          signActors.forEach((list) => {
            if (!list.notifyAddress || list.notifyAddress === "") {
              noEmailOrPhohe = true;
            }
          });
          if (noEmailOrPhohe) {
            this.$message.warning(this.$i18n.t("c820"));
            this.contractSignLoading = false;
          } else {
            docusignApi
              .createWithSignTemplate(data)
              .then((res) => {
                if (res.returnCode == "1") {
                  this.contractSign = false;
                  this.contractSignLoading = false;
                  this.$emit("getEnvelopeId");
                  this.$emit("closeContractDialog");
                }
              })
              .catch(() => {
                this.contractSignLoading = false;
              });
          }
        }
      });
    },
    soltSet() {
      this.soltSetVisble = true;
    },
  },
};
</script>
<style lang='scss' scoped>
.head {
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 400;
    color: #000000;
    line-height: 16px;
  }
}
.solt_top {
  display: flex;
  width: 100%;
  .soltLeft {
    width: 50%;
    .mainLeft {
      height: 150px;
      border-right: 1px dotted;
      margin: 10px 0px;
    }
  }
  .soltRight {
    width: 50%;
    flex: 1;
    .mainRight {
      height: 150px;
      margin: 10px 0px;
    }
  }
}
.buttomSlotBox {
  height: 30px;
  display: flex;
  justify-content: right;
}
.bule {
  width: 5px;
  height: 12px;
  background: #006dcc 100%;
  display: inline-block;
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.top {
  .baseTitle {
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    margin: 16px 0px;
    font-size: 14px;
  }
  .baseInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .inputCell {
      width: 50%;
      display: flex;
      justify-content: left;
      color: #333333;
      line-height: 16px;
      align-items: center;
      .secTitle {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .panTitle {
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    margin: 16px 0px;
    font-size: 14px;
    .solt {
      cursor: pointer;
      width: 48px;
      font-size: 12px;
      font-weight: 400;
      color: #006dcc;
    }
  }
  .tips {
    padding: 20px;
    background: #f1f5f9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
  }
  .labelHead {
    display: flex;
    .labelLeft {
      width: 70px;
      height: 40px;
      background: #f1f5f9;
      border-radius: 4px 4px 4px 4px;
      margin: 10px 10px 10px 0px;
    }
    .labelRight {
      font-size: 14px;
      flex: 1;
      background: #f1f5f9;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      margin: 10px 0px;
      position: relative;
      span {
        line-height: 40px;
        position: absolute;
      }
    }
  }
  .main {
    position: relative;
    display: flex;
    .mainLeft {
      width: 70px;
      background: #f1f5f9;
      border-radius: 4px 4px 4px 4px;
      margin: 0px 10px 10px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #cae2f5;
      }
    }
    .mainRight {
      background: #ffffff;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      flex: 1;
      height: 128px;
      display: flex;
      justify-content: left;
      .logo {
        width: 12%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        span {
          max-width: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex-shrink: 0;
        }
      }
      .contant {
        width: 490px;
        .first {
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          .iconSearch {
            position: absolute;
            right: 20px;
            top: 22px;
            width: 10px;
            height: 10px;
            color: #bbb;
          }
          .businessInput {
            width: 480px;
            height: 40px;
            margin: 10px 10px 0px 0px;
            float: left;
          }
          .codeInput {
            width: 270px;
            float: right;
            margin-top: 10px;
          }
        }
        .second {
          width: 100%;
          display: flex;
          position: relative;
          .iconSearch {
            position: absolute;
            left: 210px;
            top: 33px;
            width: 10px;
            height: 10px;
            color: #bbb;
          }
          .samllInput {
            width: 234px;
            height: 40px;
          }
          ::v-deep .el-input__inner {
            min-width: 100px;
          }
        }
      }
      .join {
        display: flex;
        width: 240px;
        justify-content: center;
        .fill,
        .checked_fill {
          width: 80px;
          height: 40px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #e1e1e1;
          text-align: center;
          margin-right: 8px;
          cursor: pointer;
          position: relative;
          border: 1px solid #e1e1e1;
        }
        .fill:before {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          border: 10px solid #e1e1e1;
          border-top-color: transparent;
          border-left-color: transparent;
        }
        .checked_fill:before {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          border: 10px solid #abc4fe;
          border-top-color: transparent;
          border-left-color: transparent;
        }
        .fill:after,
        .checked_fill::after {
          content: "";
          width: 2px;
          height: 6px;
          position: absolute;
          right: 3px;
          bottom: 2px;
          border: 2px solid #fff;
          border-top-color: transparent;
          border-left-color: transparent;
          transform: rotate(45deg);
        }
      }
      .res {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .buttomBox {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    .addBox {
      display: flex;
      justify-content: left;
    }
    .goContract {
      display: flex;
      justify-content: right;
    }
  }
}
::v-deep .is-disabled .el-checkbox__label {
  color: #bbb;
  font-size: 14px;
}
</style>